import ReactDOM from "react-dom";
import React, { useState } from "react";
import "./Description.css";
import aboutLeft from "../assets/images/aboutLeft.svg";
import aboutRight from "../assets/images/aboutRight.svg";
import aboutFlower from "../assets/images/aboutFlower.svg";
import aboutCardImg from "../assets/images/aboutCardImg.svg";
import customBack from "../assets/images/Rectangle81.png";
import AboutImgCard from "../assets/images/AboutImgCard.png";
import aboutSecLeftImg from "../assets/images/aboutSecLeftImg.svg";
import earthLeft from "../assets/images/earthLeft.svg";
import earthRight from "../assets/images/earthRight.svg";
import verifyVector from "../assets/images/verifyVector.png";
import aboutImgBefore from "../assets/images/aboutImgBefore.svg";
import aboutBGMobile from "../assets/images/Trazado_2041.svg";
import aboutBottomLeft from "../assets/images/aboutBottomLeft.svg";
import ownerImg from "../assets/images/ownerImg.png";
import aboutBottomRight from "../assets/images/aboutBottomRight.svg";
import aboutDot1 from "../assets/images/aboutDot1.svg";
import aboutDot2 from "../assets/images/aboutDot2.svg";
import sendimg1 from "../assets/images/Grupo_862.png";
import sendimg2 from "../assets/images/imageAbout.svg";
import BottStar from "../assets/images/AboutStar.png";
import BotttomImg from "../assets/images/Grupo_1230.png";
import Group from "../assets/images/about_group.png";
import { withRouter } from "react-router-dom";

function Description(props) {
  const { history } = props;
  const moveSurvey = () => {
    // window.localStorage.clear();
    history.push("/survey");
  };
  const scrollToTop1 = () => {
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <section>
      <div className="aboutusdeskup">
        <div className="custom-description-div pl">
          <div className="conatiner-fluid">
            <div className="row m-0">
              <div className="col-2">
                <div className="pt-5">
                  <img
                    width="35px"
                    style={{ float: "right", marginLeft: -28 }}
                    src={aboutFlower}
                  />
                  <img
                    width="85px"
                    style={{ float: "right" }}
                    src={aboutLeft}
                  />
                </div>
              </div>
              <div className="col-8" style={{ padding: 0 }}>
                <div className="pt-5" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      fontWeight: "900",
                      paddingLeft: 50,
                      paddingRight: 50,
                      fontSize: 40,
                      color: "#20294D",
                      fontFamily: "Bungee",
                    }}
                  >
                    BUILT TO TAKE THE STRESS OUT <br /> OF GIFT SHOPPING
                  </p>
                </div>
                <div className="pt-4">
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 500,
                      textAlign: "center",
                      color: "#1F294E",
                    }}
                  >
                    We’re on a mission to transform how you shop for gifts{" "}
                    <br />
                    so you can stress less and celebrate more.
                  </p>
                </div>
                <div className="pt-4">
                  <p
                    style={{
                      fontFamily: "Bungee",
                      fontSize: 40,
                      fontWeight: 900,
                      textAlign: "center",
                      color: "#20294D",
                    }}
                  >
                    HERE’S HOW:
                  </p>
                </div>
                <div className="row pt-5" style={{ marginBottom: 150 }}>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 350,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -62, width: 125 }}
                          src={AboutImgCard}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 18,
                            fontWeight: "800",
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          EASY-TO-USE GIFT RECOMMENDER TOOL
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="50px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 40,
                            color: "#20294D",
                          }}
                        >
                          Our Ai-powered engine recommends the best products
                          from our brand partners
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 350,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -62, width: 125 }}
                          src={AboutImgCard}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 18,
                            fontWeight: "800",
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          ACCESS TO OUR BRAND PARTNER LIBRARY
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="50px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 64,
                            color: "#20294D",
                          }}
                        >
                          We allow users to search and explore specific
                          categories and products
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 350,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -62, width: 125 }}
                          src={AboutImgCard}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 18,
                            fontWeight: "800",
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          A CONTENT HUB TO GET SMART ON GIFTING
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="50px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 40,
                            color: "#20294D",
                          }}
                        >
                          We generate gift guides, how to’s, and spotlight
                          features for our favorite brands
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="pt-5">
                  <img width="85px" src={aboutRight} />
                  <img
                    width="35px"
                    style={{ paddingTop: 75, marginLeft: -20 }}
                    src={aboutFlower}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutusmobileup">
        <div
          style={{
            backgroundColor: "white",
            height: 103,
            display: "flex",
            marginTop: 30,
          }}
        >
          <div className="row">
            <div className="col-2">
              <img
                width="82%"
                src={sendimg1}
                style={{ alignSelf: "center", marginTop: 40, marginLeft: 10 }}
              />
            </div>
            <div className="col-8" style={{ padding: 0 }}>
              <div style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontWeight: "900",
                    // paddingLeft: 5,
                    // paddingRight: 5,
                    fontSize: "clamp(12px,5vw,18px)",
                    color: "#20294D",
                    fontFamily: "Bungee",
                    marginTop: 30,
                    whiteSpace: "nowrap",
                  }}
                >
                  BUILT TO TAKE THE STRESS <br></br> OUT OF GIFT SHOPPING
                </p>
              </div>
            </div>
            <div className="col-2">
              <div style={{ alignSelf: "center", marginTop: 40 }}>
                <img width="82%" src={sendimg2} />
              </div>
            </div>
          </div>
        </div>
        <div className="custom-description-div">
          <img
            src={verifyVector}
            style={{ marginTop: -53, height: "35px", width: "610px" }}
          />
          <div className="row">
            <div
              className="col-2"
              style={{ position: "relative", right: "20px" }}
            >
              <img
                src={aboutRight}
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: 5,
                  marginLeft: 40,
                }}
              />
              <img
                src={aboutFlower}
                style={{
                  height: "16px",
                  width: "16px",
                  marginLeft: 25,
                  marginTop: -35,
                }}
              />
            </div>
            <div
              className="col-8"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={BotttomImg}
                style={{
                  height: "70px",
                  width: "65px",

                  marginTop: -40,
                }}
              />
              <p
                style={{
                  fontFamily: "Nunito",
                  textAlign: "center",
                  width: "290px",
                  fontWeight: 550,
                  lineHeight: "18px",

                  fontSize: "16px",
                  marginTop: "-20px",
                }}
              >
                Call us crazy, but we believe holidays, birthdays, and special
                occasions should be celebrated, not stressed over!
                <br />
                <br />
                We’re on a mission to transform how people find and shop for
                gifts.
              </p>
            </div>
            <div className="col-2 pt-5">
              <img
                src={aboutRight}
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: 5,
                }}
              />
              <img
                src={aboutFlower}
                style={{
                  height: "16px",
                  width: "16px",
                  marginLeft: 40,
                  marginTop: -35,
                }}
              />
            </div>
          </div>
        </div>
        <div className="customBack" style={{ height: "680px", marginTop: -35 }}>
          <div className="pt-2">
            <div className="row">
              <div
                className="col-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 50,
                }}
              >
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={aboutBottomLeft}
                />
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "10px",
                    marginTop: "250px",
                  }}
                  src={aboutBottomLeft}
                />
              </div>
              <div
                className="col-10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    marginTop: 55,
                    height: 165,
                    width: 300,
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        marginTop: -39,
                        width: 125,
                        width: "63px",
                        height: "63px",
                      }}
                      src={AboutImgCard}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        padding: 10,
                        textAlign: "center",
                        paddingBottom: 0,
                        fontSize: 16,
                        fontWeight: "400",
                        color: "#1F294E",
                        fontFamily: "Bungee",
                      }}
                    >
                      EASY-TO-USE GIFT RECOMMENDER TOOL
                    </p>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img
                      width="50px"
                      src={aboutCardImg}
                      height="38px"
                      style={{ marginTop: "-30px" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        //padding: 20,
                        fontWeight: 500,
                        paddingTop: 0,
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        paddingBottom: 10,
                        color: "#20294D",
                        marginTop: "-15px",
                      }}
                    >
                      Our Ai-powered engine recommends the best products from
                      our brand partners
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    marginTop: 55,
                    height: 165,
                    width: 300,
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        marginTop: -39,
                        width: 125,
                        width: "63px",
                        height: "63px",
                      }}
                      src={AboutImgCard}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        padding: 10,
                        textAlign: "center",
                        paddingBottom: 0,
                        fontSize: 16,
                        fontWeight: "400",
                        color: "#1F294E",
                        fontFamily: "Bungee",
                      }}
                    >
                      Access to our brand partner library
                    </p>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img
                      width="50px"
                      src={aboutCardImg}
                      height="38px"
                      style={{ marginTop: "-30px" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        //padding: 20,
                        fontWeight: 500,
                        paddingTop: 0,
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        paddingBottom: 10,
                        color: "#20294D",
                        marginTop: "-15px",
                      }}
                    >
                      We allow users to search and explore specific categories
                      and products
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    marginTop: 55,
                    height: 165,
                    width: 300,
                    zIndex: "2",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{
                        marginTop: -39,
                        width: 125,
                        width: "63px",
                        height: "63px",
                      }}
                      src={AboutImgCard}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        padding: 10,
                        textAlign: "center",
                        paddingBottom: 0,
                        fontSize: 16,
                        fontWeight: "400",
                        color: "#1F294E",
                        fontFamily: "Bungee",
                      }}
                    >
                      A content hub to get smart on gifting
                    </p>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <img
                      width="50px"
                      src={aboutCardImg}
                      height="38px"
                      style={{ marginTop: "-30px" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        //padding: 20,
                        fontWeight: 500,
                        paddingTop: 0,
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        paddingBottom: 10,
                        color: "#20294D",
                        marginTop: "-15px",
                      }}
                    >
                      We generate gift guides, how to’s, and spotlight features
                      for our favorite brands
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "210px",
                }}
              >
                <img
                  style={{ width: "40px", height: "40px", marginLeft: "-45px" }}
                  src={aboutBottomLeft}
                />
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "-45px",
                    marginTop: "250px",
                  }}
                  src={aboutBottomLeft}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "14px", padding: "14px", paddingBottom: "0px" }}
          className="row"
        >
          <img width="20vw" src={Group} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={aboutImgBefore}
            style={{ width: "400px", height: "50px" }}
          />
        </div>
        <div>
          <p
            style={{
              fontSize: 20,
              fontWeight: 900,
              lineHeight: "26px",
              color: "#1F294E",
              fontFamily: "Nunito",
              textAlign: "center",
              padding: "40px",
              paddingTop: 0,
            }}
          >
            Hey, Hugh here.
            <br />
            My co-founder (Jon) said it was cool if I wrote our About Page. So,
            here it goes…
          </p>
        </div>
        <div>
          <p
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "left",

              fontWeight: 600,
              paddingTop: 0,
              marginTop: "-25px",
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontSize: "15px",
              lineHeight: "20px",
            }}
          >
            Jon and I first started talking about this business back in 2019
            while we were living in Boston. Back then he and I were roommates,
            and we always found ourselves stressing over holiday shopping.
            <br />
            <br />
            We each had a bunch of people to shop for and there wasn’t a tool to
            make the process any easier. Mega marketplaces like Amazon were a
            bear to sift through, and most gift blogs seemed super subjective
            and took way too long to read.
            <br />
            <br /> We thought, “gift buying should be fun, not stressful!” So as
            the world went into lockdown in early 2020, we got to work creating
            Outdone — a brand built to revolutionize gift shopping.
            <br />
            <br />
            Today, Outdone revolves around our Ai-powered gifting engine that
            recommends brands and products perfectly aligned to your
            giftgetter’s unique lifestyle and interests. All an Outdone user has
            to do is answer a few short questions about whomever they're
            shopping for!
            <br />
            <br /> There are also few values that we hold near and dear at
            Outdone. First and foremost, we’re data-driven. We infuse data-based
            decisions into everything we do.
            <br />
            <br />
            We’re also mission-driven, meaning we’re committed to sticking to
            our mission and partner with brands who stick to theirs. We want to
            work with brands who have their own north star — not brands that
            make products for making products’ sake.
            <br />
            <br /> And lastly, we’re trend-driven. We ensure our recommendation
            engine is always informed with the hottest trends and brands. We
            want the site to feel fresh each and every time you use it to find
            your next great gift.
            <br />
            <br /> We believe what really sets Outdone apart is our focus on
            brand partnerships. At the time I’m writing this we have over 100
            trusted brands on the site. And we’re working to add more every day!
            We have a pretty rigorous process for deciding which brands to
            include, and Jon and I have even had some debates over a few roster
            decisions.
            <br />
            <br />
            I’ll stop here, but you can check out more on this topic over on our{" "}
            <a
              href="/brandSubmission"
              style={{
                fontWeight: 600,
                color: "#9685C8",
              }}
            >
              Brand Submission page.
            </a>{" "}
            And if you still have questions (after all that) head over to our{" "}
            <a
              href="/faq"
              style={{
                fontWeight: 600,
                color: "#9685C8",
              }}
            >
              FAQs
            </a>{" "}
            or contact us{" "}
            <a
              href="mailto:support.outdone.io"
              style={{
                textDecoration: "none",
                fontWeight: 600,
                color: "#9685C8",
              }}
            >
              (support@outdone.io).
            </a>
          </p>
        </div>

        <h2 className="happy">HAPPY GIFTING!</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <img style={{ height: 58 }} src={aboutImgBefore} />
        </div>
      </div>

      <div className="bd_White">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2">
              <div className="pt-5">
                <img
                  style={{
                    marginTop: "-20%",
                    float: "right",
                    paddingRight: 100,
                  }}
                  src={aboutDot2}
                />
                <img
                  style={{ marginTop: "-20%", float: "right" }}
                  src={aboutDot1}
                />
                <img width="30%" style={{ float: "right" }} src={earthLeft} />
              </div>
            </div>
            <div className="col-8">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  paddingTop: 70,
                }}
              >
                <img width="70px" src={aboutSecLeftImg} />
                <p
                  style={{
                    fontWeight: "900",
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontSize: 45,
                    color: "#20294D",
                    fontFamily: "Bungee",
                  }}
                >
                  ABOUT US
                </p>
                <img width="70px" src={aboutSecLeftImg} />
              </div>
            </div>
            <div className="col-2">
              <div className="pt-5">
                <img width="30%" style={{ float: "left" }} src={earthRight} />
                <img style={{ marginTop: "-50%" }} src={aboutDot1} />
                <img
                  style={{ float: "right", paddingRight: "30px" }}
                  src={aboutDot2}
                />
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="pt-5">
                <img style={{ height: 110 }} src={aboutImgBefore} />
              </div>
              <div>
                <p
                  style={{
                    fontSize: 23,
                    fontWeight: "bold",
                    color: "#1F294E",
                    fontFamily: "Nunito",
                  }}
                >
                  Hey, Hugh here.
                  <br />
                  My co-founder (Jon) said it was cool if I wrote our About
                  Page. So, here it goes…
                </p>
              </div>
              <div
                className="row pt-4 clearfix"
                style={{ position: "relative", display: "flex" }}
              >
                <div className="col-6">
                  <img style={{ width: "95%" }} src={ownerImg} />
                </div>
                <div className="col-6">
                  <p
                    style={{
                      fontSize: 25,
                      fontWeight: 400,
                      color: "#000",
                      lineHeight: "40px",
                      marginTop: 12,
                    }}
                  >
                    Jon and I first started talking about this business back in
                    2019 while we were living in Boston. Back then he and I were
                    roommates, and we always found ourselves stressing over
                    holiday shopping. We each had a bunch of people to shop for
                    and there wasn’t a tool to make the process any easier. Mega
                    marketplaces like Amazon were a bear to sift through, and
                    most gift blogs seemed super subjective and took way too
                    long to read.
                  </p>
                </div>
              </div>
              <div className="pt-3">
                <div className="pt-5">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    We thought, “gift buying should be fun, not stressful!” So
                    as the world went into lockdown in early 2020, we got to
                    work creating Outdone — a brand built to revolutionize gift
                    shopping.
                  </p>
                </div>
                <div className="pt-5">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    Today, Outdone revolves around our Ai-powered gifting engine
                    that recommends brands and products perfectly aligned to
                    your giftgetter’s unique lifestyle and interests. All an
                    Outdone user has to do is answer a few short questions about
                    whomever they're shopping for!
                  </p>
                </div>
                <div className="pt-4">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    There are also few values that we hold near and dear at
                    Outdone. First and foremost, we’re data-driven. We infuse
                    data-based decisions into everything we do.
                  </p>
                </div>
                <div className="pt-4">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    We’re mission-driven, meaning we’re committed to sticking to
                    our mission and partner with brands who stick to theirs. We
                    want to work with brands who have their own north star — not
                    brands that make products for making products’ sake.
                  </p>
                </div>
                <div className="pt-4">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    And lastly, we’re trend-driven. We ensure our recommendation
                    engine is always informed with the hottest trends and
                    brands. We want the site to feel fresh each and every time
                    you use it to find your next great gift.
                  </p>
                </div>
                <div className="pt-4">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    We believe what really sets Outdone apart is our focus on
                    brand partnerships. At the time I’m writing this we have
                    over 100 trusted brands on the site. And we’re working to
                    add more every day! We have a pretty rigorous process for
                    deciding which brands to include, and Jon and I have even
                    had some{" "}
                    <span
                      style={{ color: "gray", textDecoration: "line-through" }}
                    >
                      fights
                    </span>{" "}
                    debates over a few roster decisions.
                  </p>
                </div>
                <div className="pt-4">
                  <p style={{ fontSize: 25, fontWeight: 400, color: "#000" }}>
                    I’ll stop here, but you can check out more on this topic
                    over on our{" "}
                    <a
                      href="/brandSubmission"
                      style={{
                        fontWeight: 600,
                        color: "#BABEC7",
                      }}
                    >
                      Brand Submission page.
                    </a>{" "}
                    And if you still have questions (after all that) head over
                    to our{" "}
                    <a
                      href="/faq"
                      style={{
                        fontWeight: 600,
                        color: "#BABEC7",
                      }}
                    >
                      FAQs
                    </a>{" "}
                    or contact us{" "}
                    <a
                      href="mailto:support.outdone.io"
                      style={{
                        textDecoration: "none",
                        fontWeight: 600,
                        color: "#BABEC7",
                      }}
                    >
                      (support@outdone.io).
                    </a>
                  </p>
                </div>
                <div className="pt-3">
                  <p
                    style={{
                      fontSize: 30,
                      fontWeight: 800,
                      color: "#1F294E",
                      fontFamily: "Bungee",
                    }}
                  >
                    HAPPY GIFTING!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 250,
                paddingRight: 250,
                paddingTop: 40,
              }}
            >
              <div>
                <img
                  style={{
                    width: 15,
                    position: "absolute",
                    left: 178,
                    marginTop: -36,
                  }}
                  src={aboutDot1}
                />
                <img
                  style={{
                    width: 15,
                    position: "absolute",
                    left: 241,
                    marginTop: 0,
                  }}
                  src={aboutDot2}
                />
                <img style={{ width: "100px" }} src={aboutBottomLeft} />
              </div>
              <div>
                <img style={{ width: "100px" }} src={aboutBottomRight} />
                <img
                  style={{
                    width: 15,
                    position: "absolute",
                    right: 200,
                    marginTop: -36,
                  }}
                  src={aboutDot1}
                />
                <img
                  style={{ width: 15, position: "absolute", marginTop: 10 }}
                  src={aboutDot2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_last">
        <div className="row m-0" style={{ position: "relative" }}>
          <div className="col-2"></div>
          <div className="col-8">
            <div
              style={{
                borderRadius: 20,
                backgroundColor: "#FFEDC1",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 30,
                }}
              >
                <div style={{ alignSelf: "center" }}>
                  <img width="62%" src={sendimg1} />
                </div>
                <div>
                  <p style={{ fontFamily: "Nunito" }}>
                    Interested in hearing about the lastest Outdone news?
                  </p>

                  <button
                    className="btn mt-4"
                    style={{
                      width: 150,
                      backgroundColor: "#1F294E",
                      color: "#fff",
                      fontFamily: "Bungee",
                      borderRadius: "10px",
                    }}
                    onClick={() => window.subscribe()}
                  >
                    Subscribe
                  </button>
                </div>
                <div style={{ alignSelf: "center" }}>
                  <img width="62%" src={sendimg2} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
          <img
            src={BottStar}
            style={{ position: "absolute", top: -97, left: 96, width: 60 }}
            alt=""
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", top: 100, left: 4, width: 51 }}
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", bottom: -90, left: 75, width: 51 }}
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", bottom: -61, left: 300, width: 51 }}
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", bottom: -134, left: 640, width: 51 }}
          />
          <img
            src={BottStar}
            style={{ position: "absolute", top: -97, right: 96, width: 60 }}
            alt=""
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", top: 100, right: 4, width: 51 }}
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", bottom: -90, right: 75, width: 51 }}
          />
          <img
            src={BottStar}
            alt=""
            style={{ position: "absolute", bottom: -61, right: 300, width: 51 }}
          />
          {/* <img src={BottStar} alt="" style={{position : "absolute", bottom : -134, right : 640, width : 51}} /> */}
        </div>
        <div className="row m-0 mt-5 mb-5 pb-5">
          <div className="col-4"></div>
          <div className="col-4">
            <button onClick={scrollToTop1} className="backwork">
              BACK TO TOP
            </button>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
      <button className="subscribe" onClick={() => window.subscribe()}>
        SUBSCRIBE
      </button>
    </section>
  );
}

export default withRouter(Description);
