import axios from "axios";
import SidebarMain from "../Sidebar/sidebar";
import "./Brand.css";
import Swal from "sweetalert2";
import { useHistory, withRouter } from "react-router";
import React, { useState, useEffect } from "react";
import { getBrandName } from "../../Services/ServicePhase2";
import { AuthGuardService } from "../../Services/ServicePhase2";

function addProduct(props) {
  const [file, setFile] = useState();
  const [description, setdescription] = useState("");
  const [gender, setgender] = useState("");
  const [age, setage] = useState();
  const [productPrice, setproductPrice] = useState();
  const [bacupUrl, setbacupUrl] = useState("");
  const [primaryUrl, setprimaryUrl] = useState("");
  const [productName, setproductName] = useState();
  const [brandId, setbrandId] = useState();
  const [brandData, setBrandData] = useState([]);
  const [priceCatogory, setpriceCateogory] = useState("");
  const [importProducFile, setimporProductFile] = useState(null);
  const [btLoading, setbtLoading] = useState(false);

  const [isauth, setisauth] = useState(false);

  var history = useHistory();
  let UPLOAD_ENDPOINT = "https://api.outdone.io/api/add_product";
  let IMPORT_PRODUCT_ENDPOINT = "https://api.outdone.io/api/importProducts";

  const onChangeFile = (e) => {
    setFile(e.target.value);
  };

  const onChangeImportFile = (e) => {
    setimporProductFile(e.target.files[0]);
  };

  const onChangeProductDes = (e) => {
    setdescription(e.target.value);
  };
  const onChangeGenderTag = (e) => {
    setgender(e.target.value);
  };
  const onChangeAgeTag = (e) => {
    setage(e.target.value);
  };
  const onChangeProductPrice = (e) => {
    setproductPrice(e.target.value);
  };
  const onChangeBackupUrl = (e) => {
    setbacupUrl(e.target.value);
  };
  const onChangePrimaryUrl = (e) => {
    setprimaryUrl(e.target.value);
  };
  const onChangeProductName = (e) => {
    setproductName(e.target.value);
  };

  useEffect(() => {
    AuthGuardService()
      .then((result) => {
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
      });
  }, []);

  const importFile = async () => {
    let res;

    if (importProducFile != null) {
      setbtLoading(true);
      let newFormData = new FormData();
      newFormData.append("file", importProducFile);

      let token = localStorage.getItem("admin_token");
      res = await axios.post(IMPORT_PRODUCT_ENDPOINT, newFormData, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      let ele = document.getElementById("Product-import");
      ele.value = "";
      if (res.data.success == true) {
        setbtLoading(false);
        setimporProductFile(null);
        alert("File Imported Successfully!");
        history.push("/viewbrand");
        return true;
      } else {
        setbtLoading(false);
        setimporProductFile(null);
        alert(res.data.message);
        return false;
      }
    } else {
      alert("Select a file to import ");
      return false;
    }
  };

  const onSubmit = async (e) => {
    const { history } = props;
    e.preventDefault();

    document.getElementById("fillee").checkValidity();
    if (brandId == null) {
      return false;
    } else if (productName == null) {
      return false;
    }

    const res = await uploadFile(
      productName,
      primaryUrl,
      bacupUrl,
      description,
      file,
      productPrice,
      age,
      gender
    );
    const dataa = res.data;
    if (dataa.success == true) {
      history.push("/viewbrand");
    } else {
      alert(dataa.message);
    }
  };

  const uploadFile = async (
    productName,
    primaryUrl,
    bacupUrl,
    description,
    file,
    productPrice,
    age,
    gender
  ) => {
    var formData = new FormData();
    formData.append("brands_id", brandId);
    formData.append("product_name", productName);
    formData.append("product_description", description);
    formData.append("primary_url", primaryUrl);
    formData.append("backup_url", bacupUrl);
    formData.append("price", productPrice);

    formData.append("age_tag", age);
    formData.append("gender_tag", gender);
    formData.append("image", file);
    formData.append("price_category", priceCatogory);
    return await axios.post(UPLOAD_ENDPOINT, formData, {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    });
  };

  useEffect(() => {
    getBrandName().then((result) => {
      setBrandData(result.data);
    });
  }, []);

  const gettingId = (e) => {
    setbrandId(e.target.value);
  };

  return isauth ? (
    <div className="full-dash">
      <div className="side-bar-dash">
        <SidebarMain />
      </div>

      <div className="add-Brand">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact-form" style={{ height: "125px" }}>
              <div className="contact-image"></div>
              {/* <form onSubmit={this.importFile} id="fillee"> */}
              <div style={{ marginTop: "12%" }} className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <div>
                    <h3 className="mt-3">Import Product</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          style={{ padding: "6px 0 0 10px" }}
                          type="file"
                          id="Product-import"
                          name="text"
                          maxLength="15"
                          className="form-control"
                          placeholder="Backup URL"
                          onChange={onChangeImportFile}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btnContact mb-3"
                        disabled={btLoading ? true : false}
                        onClick={importFile}
                      >
                        {btLoading ? "...Importing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>

      <div className="add-Brand">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact-form" style={{ height: "480px" }}>
              <div className="contact-image"></div>
              <form onSubmit={onSubmit} id="fillee">
                <div style={{ marginTop: "12%" }} className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div>
                      <h3 className="mt-3">Add Product</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Brand
                              </span>
                            </div>
                            <select
                              class="custom-select"
                              onChange={(e) => setbrandId(e.target.value)}
                              id="inputGroupSelect01"
                            >
                              <option disabled selected hidden value="">
                                Choose
                              </option>
                              {brandData &&
                                brandData.map((res) => (
                                  <option value={res.id}>{res.name}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Product Name
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              onChange={onChangeProductName}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Primary URL
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              onChange={onChangePrimaryUrl}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Backup Url
                              </span>
                            </div>
                            <input
                              type="text"
                              name="text"
                              className="form-control"
                              onChange={onChangeBackupUrl}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Product Price
                              </span>
                            </div>
                            <input
                              type="number"
                              name="text"
                              maxLength="15"
                              className="form-control"
                              onChange={onChangeProductPrice}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Age
                              </span>
                            </div>
                            <input
                              type="number"
                              name="text"
                              maxLength="15"
                              className="form-control"
                              onChange={onChangeAgeTag}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                Gender
                              </span>
                            </div>

                            <select
                              class="custom-select"
                              onChange={onChangeGenderTag}
                              id="inputGroupSelect01"
                            >
                              <option disabled selected hidden value="">
                                Choose
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Neutral">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                Product Description
                              </span>
                            </div>
                            <textarea
                              type="text"
                              onChange={onChangeProductDes}
                              name="text"
                              maxLength="800"
                              className="form-control"
                              id="bDes"
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Price Category
                                </span>
                              </div>
                              <select
                                class="custom-select"
                                onChange={(e) =>
                                  setpriceCateogory(e.target.value)
                                }
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="$50-$150">$50-$150</option>
                                <option value="<50">&lt;$50</option>
                                <option value=">$150">&gt;$150</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                Upload Product Image Url
                              </span>
                            </div>
                            <input
                              type="text"
                              onChange={onChangeFile}
                              className="form-control"
                              multiple=""
                            />
                          </div>
                        </div>
                        <button className="btnContact mb-3">Submit</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default addProduct;
