import React from "react";
import "./Navigation.css";
import Home from "../assets/images/Home.svg";
import Discover from "../assets/images/Discover.svg";
import Tips from "../assets/images/Tips.svg";
import More from "../assets/images/More.svg";
import downbtn from "../assets/images/downbtn.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const Navigationbar = (props) => {
  const [isBoxVisible, setBoxVisible] = useState("deactive");
  const history = useHistory();

  return (
    <>
      <div
        className={`popup ${isBoxVisible === "deactive" ? "" : "active"}`}
        id="popup"
      >
        <div className="row mt-4">
          <div className="col-1"></div>
          <div className="col-8 ">
            <div className="tabs" onClick={() => history.push("/faq")}>
              FAQs
            </div>

            <div className="tabs" onClick={() => history.push("/about-us")}>
              About Us
            </div>
            <div
              className="tabs"
              onClick={() => history.push("/brandSubmission")}
            >
              Brand Submission
            </div>
          </div>
          <div className="col-2">
            <img
              src={downbtn}
              className="downbtn"
              onClick={(e) => setBoxVisible("deactive")}
            />
          </div>
        </div>
      </div>

      <div className="navigationfixed">
        <div className="flex">
          <div>
            <button className="btnnav home" onClick={() => history.push("/")}>
              <img src={Home} />
              <span style={{ position: "relative", bottom: "4px" }}>Home</span>
            </button>
          </div>
          <div>
            <button
              className="btnnav discover"
              onClick={() => history.push("/discover")}
            >
              <img src={Discover} />
              <span style={{ position: "relative", bottom: "4px" }}>
                Discover
              </span>
            </button>
          </div>
          <div>
            <button
              className="btnnav tips"
              onClick={() =>
                (window.location.href = "https://blog.outdone.io/")
              }
            >
              <img src={Tips} />
              <span style={{ position: "relative", bottom: "4px" }}>Tips</span>
            </button>
          </div>
          <div onClick={(e) => setBoxVisible("active")}>
            <button className="btnnav morem">
              <img src={More} />
              More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigationbar;
