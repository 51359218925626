export default function StaticOverlay() {
  return (
    <>
      <div id="StaticOverlay" style={css.modal}>
        <div style={css.flexContainer}>
          <iframe
            style={css.form}
            src="https://dashboard.mailerlite.com/forms/133406/115741093849465911/share"
          />
        </div>
      </div>
    </>
  );
}

const css = {
  modal: {
    height: "100%",
    width: "100%",
    display: "block",
    position: "fixed",
    top: 0,
    zIndex: 100000,
    backgroundColor: "rgba(255, 255, 255, .5)",
    backdropFilter: "blur(5px)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  form: {
    display: "block",
    height: "460px",
    width: "410px",
    margin: "0 auto",
    top: "50%",
  },
};
