import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./SignIn.css";
import logo from "../../components/assets/images/footer_logo.png";
import { Link } from "react-router-dom";
import { SignIn } from "../Services/ServicePhase2";
import mainimg from "../../components/assets/images/signpic.png";
import ico from "../../components/assets/images/textico.png";
import SignNavbar from "../SignNavbar/SignNavbar";
import Footer from "../Footer/Footer";
import SepGro from "../assets/images/Grupo1230.png";
import ILLU from "../assets/images/ILLU5-011.png";
import CAKE from "../assets/images/Groupcake160.png";
import TF from "../assets/images/24.png";
import BELL from "../assets/images/bellone.png";
import Createside from "../assets/images/Grupo_851.png";
import facebook from "../assets/images/facebook_icon.png";
import Twitter from "../assets/images/pngegg.png";
import Googleplus from "../assets/images/gplus.png";
import Rightv from "../assets/images/right857.png";
import Leftv from "../assets/images/left857.png";
import Navbar from "../Navbar/Navbar";
import {
  SignUp,
  FaceBookSignUp,
  GmailSignUp,
  GettingLinkedinData,
} from "../Services/ServicePhase2";
import { error } from "jquery";
import { Helmet } from "react-helmet";
function signin(props) {
  const [email, setemail] = useState("");
  const [password, setLname] = useState("");
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [length, setlength] = useState(false);
  const [Fname, setFname] = useState("");
  const [Laname, setLaname] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Error, setError] = useState(false);
  const [signInError, setsigninError] = useState(false);
  const [mustMatch, setmustMatch] = useState(false);
  const [disable, setdisable] = useState(false);

  console.log("Props in Signin : ", props);

  const OnEmailChange = (e) => {
    setemail(e.target.value);
  };
  const OnPasswordChange = (e) => {
    setLname(e.target.value);
  };
  const OnSignInClick = () => {
    let i = 0;
    setdisable(1);
    setLoading(true);
    setsigninError(false);

    SignIn(email, password).then((result) => {
      if (result.success == false) {
        setLoading(false);
        setsigninError(result.message);
      } else {
        console.log("Innerererer=", result.data.id);
        localStorage.setItem("user_id", result.data.id);
        localStorage.setItem("user_email", result.data.email);
        localStorage.setItem("user_token", result.data.api_token);
        setLoading(false);
        props.history.push("/dashboard-user");
      }
    });

    setdisable(0);
  };
  // onForgotClick = () =>{
  //     props.history.push('/forgot-password')
  // }
  const SignUpClick = () => {
    props.history.push("/sign-up");
  };
  const OnNameChange = (e) => {
    setFname(e.target.value);
  };
  const OnLastNameChange = (e) => {
    setLaname(e.target.value);
  };
  const OnSignEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const OnSignPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const OnConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const OnContinueClick = () => {
    setdisable(1);
    setisLoading(true);
    setError(false);
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      Fname === "" ||
      Laname === "" ||
      Email === "" ||
      Password === "" ||
      ConfirmPassword === ""
    ) {
      setError("Fields should not be empty.");
      setisLoading(false);
    } else if (!Email.match(mailformat)) {
      setError("You have entered an invalid email address!");
      setisLoading(false);
    } else if (Password.length < 8) {
      setError("Password must be 8 characters long.");
      setisLoading(false);
    } else if (ConfirmPassword !== Password) {
      setError("Password and confirm password must match");
      setisLoading(false);
    } else if (Password === ConfirmPassword) {
      SignUp(Fname, Laname, Email, Password, ConfirmPassword).then((result) => {
        console.log("aldkdlkadkadl=-=-", result);

        if (result.success == true) {
          localStorage.setItem("user_id", result.data.id);
          localStorage.setItem("user_email", result.data.email);
          localStorage.setItem("user_password", Password);
          $("#exampleModalCenter").modal("hide");
          $("#exampletwo").modal("show");

          setFname("");
          setConfirmPassword("");
          setLaname("");
          setPassword("");
          setEmail("");
        } else {
          setError(result.message);
        }

        setisLoading(false);
      });
    }

    setdisable(0);
  };

  const authHandler = (err, data) => {
    console.log(err, data);
  };
  const ClosePopup = () => {
    $("#exampletwo").modal("hide");
  };

  const modaltoggle = () => {
    $("#exampleModalCenter").modal("show");
  };
  const modalhide = () => {
    $("#exampleModalCenter").modal("hide");
  };

  const siginfocus = () => {
    $("#exampleModalCenter").modal("hide");
    document.getElementById("email").focus();
  };

  return (
    <>
      <Helmet>
        {" "}
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <Navbar />
        <div className="DesktopView">
          <div className="backclassimage">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  width: "35%",
                  padding: 20,
                  marginRight: 30,
                  borderRadius: 10,
                }}
              >
                <p
                  style={{
                    fontFamily: "Bungee",
                    fontSize: 28,
                    marginBottom: -25,
                    fontWeight: 400,
                    color: "#1F294E",
                  }}
                >
                  SIGN IN
                </p>
                <img
                  style={{ width: 110, marginBottom: -40 }}
                  src={SepGro}
                  alt=""
                />
                <div className="fieldtype">
                  <input
                    type="email"
                    id="email"
                    onChange={OnEmailChange}
                    placeholder="Email"
                  />
                  <br />
                </div>
                <div className="fieldpass">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={OnPasswordChange}
                  />
                </div>

                <div>
                  {signInError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      }}
                    >
                      {signInError}
                    </p>
                  )}
                </div>

                <div className="btndiv">
                  <button
                    onClick={OnSignInClick}
                    className="signbut"
                    style={{ fontFamily: "Bungee" }}
                  >
                    SUBMIT
                  </button>
                  {loading && (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </div>

                <div className="forpas">
                  <a
                    href="/forgot-password"
                    style={{ fontFamily: "Nunito", fontWeight: 400 }}
                  >
                    FORGOT PASSWORD
                  </a>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: 35,
                  borderRadius: 10,
                }}
              >
                <p style={{ fontSize: 30, color: "#1F294E", fontWeight: 700 }}>
                  Don't have an Outdone account?
                </p>
                <p
                  style={{
                    fontSize: 20,
                    color: "#1F294E",
                    fontWeight: 600,
                    marginBottom: -20,
                  }}
                >
                  Create one for FREE today to...
                </p>
                <div className="allofelx">
                  <div>
                    <div className="allofelx" style={{ marginBottom: -10 }}>
                      <img className="cake" src={CAKE} alt="" />
                      <p
                        style={{
                          fontSize: 14,
                          color: "#1F294E",
                          fontWeight: 600,
                          paddingTop: 18,
                          marginLeft: 30,
                        }}
                      >
                        Automate birthday reminders for friends and family
                      </p>
                    </div>
                    <div
                      className="allofelx"
                      style={{ marginBottom: 15, marginTop: 18 }}
                    >
                      <img className="tf" src={TF} alt="" />
                      <p
                        style={{
                          fontSize: 14,
                          color: "#1F294E",
                          fontWeight: 600,
                          paddingTop: 18,
                          marginLeft: 30,
                        }}
                      >
                        Save giftgetter searches to make repeat gifting easier
                      </p>
                    </div>
                    <div className="allofelx" style={{ marginBottom: -10 }}>
                      <img className="bell" src={BELL} alt="" />
                      <p
                        style={{
                          fontSize: 14,
                          color: "#1F294E",
                          fontWeight: 600,
                          paddingTop: 18,
                          marginLeft: 35,
                        }}
                      >
                        Get notified about new Outdone brand additions you’ll
                        love
                      </p>
                    </div>
                  </div>
                  <div>
                    <img className="illu" src={ILLU} alt="" />
                  </div>
                </div>
                <button
                  onClick={modaltoggle}
                  style={{
                    display: "block",
                    marginTop: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontFamily: "Bungee",
                    width: 215,
                  }}
                  className="signbut"
                >
                  CREATE ACCOUNT
                </button>

                <div
                  class="modal fade"
                  id="exampletwo"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampletwoTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={ClosePopup}
                      >
                        <span
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{ position: "absolute", top: 15, left: 25 }}
                          aria-hidden="true"
                        >
                          &times;
                        </span>
                      </button>
                      <p
                        style={{
                          fontFamily: "Bungee",
                          textAlign: "center",
                          marginTop: "5%",
                          fontSize: 22,
                          color: "#1F294E",
                          fontWeight: 400,
                        }}
                      >
                        VERIFY YOUR ACCOUNT
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          marginTop: -30,
                        }}
                      >
                        <img
                          style={{ width: 40, height: 80 }}
                          src={Leftv}
                          alt=""
                        />
                        <img style={{ width: 110 }} src={SepGro} alt="" />
                        <img
                          style={{ width: 40, height: 80 }}
                          src={Rightv}
                          alt=""
                        />
                      </div>
                      <div style={{ marginLeft: "12%" }}>
                        <p
                          style={{
                            fontSize: 18,
                            fontStyle: "italic",
                            color: "#1F294E",
                            width: "65%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          We just sent a verification email to the address your
                          provided.
                          <br />
                          <br />
                          Please click the link in our email to verify your
                          identity and finish account setup!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View Start Here */}

        <div className="MobileView">
          <div className="backclassimage">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                className="MobileP"
                style={{
                  fontFamily: "Bungee",
                  fontSize: 23,
                  fontWeight: 400,
                  color: "#1F294E",
                  whiteSpace: "nowrap",
                }}
              >
                SIGN INTO YOUR ACCOUNT
              </p>
              <img
                className="MobileImg"
                style={{ width: 80 }}
                src={SepGro}
                alt=""
              />
            </div>
          </div>

          <div className="MobileBottom">
            <div className="fieldtype">
              <input
                type="email"
                id="email"
                onChange={OnEmailChange}
                placeholder="Email"
              />
            </div>
            <div className="fieldpass">
              <input
                type="password"
                placeholder="Password"
                onChange={OnPasswordChange}
              />
            </div>
            {signInError && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontFamily: "Nunito",
                  textAlign: "center",
                  margin: "0px 12px",
                }}
              >
                {signInError}
              </p>
            )}
            <div className="btndiv">
              <div style={{ display: "flex", width: "100%" }}>
                <button
                  onClick={OnSignInClick}
                  className="signbut"
                  style={{ fontFamily: "Bungee" }}
                  disabled={disable === 1}
                >
                  SUBMIT
                </button>
                {loading && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div className="forpas">
                <a
                  href="/forgot-password"
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: 250,
                    fontSize: 10,
                  }}
                >
                  FORGOT PASSWORD
                </a>
              </div>
            </div>
            <div>
              <p
                style={{
                  fontSize: 21,
                  color: "#1F294E",
                  fontWeight: 650,
                  textAlign: "center",
                }}
              >
                Don't have an Outdone account?
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: "#1F294E",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Create one for FREE today to...
              </p>
            </div>
            <div>
              <div
                className="allofelx"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img className="cake" src={CAKE} alt="" />
                <p
                  style={{
                    fontSize: 17,
                    color: "#1F294E",
                    fontWeight: 600,
                    textAlign: "center",
                    // paddingTop: 18,
                    // marginLeft: 20,
                  }}
                >
                  Automate birthday reminders for friends and family
                </p>
              </div>
              <div
                className="allofelx"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img className="tf" src={TF} alt="" />
                <p
                  style={{
                    fontSize: 17,
                    color: "#1F294E",
                    fontWeight: 600,
                    textAlign: "center",
                    // paddingTop: 18,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  Save giftgetter searches to make repeat gifting easier
                </p>
              </div>
              <div
                className="allofelx"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img className="bell" src={BELL} alt="" />
                <p
                  style={{
                    fontSize: 17,
                    color: "#1F294E",
                    fontWeight: 600,
                    textAlign: "center",
                    // paddingTop: 18,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  Get notified about new Outdone brand additions you’ll love
                </p>
              </div>
              <button
                onClick={(e) => props.history.push("/Sign-up-Mobile")}
                style={{
                  display: "block",
                  // marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontFamily: "Bungee",
                  marginBottom: 50,
                  width: 205,
                }}
                className="signbut"
              >
                CREATE ACCOUNT
              </button>
            </div>
          </div>
          <Footer />
        </div>

        <div className="DeskView">
          <Footer />
        </div>
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <button
                type="button"
                onClick={modalhide}
                class="close"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  style={{ position: "absolute", top: 15, left: 25 }}
                >
                  &times;
                </span>
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  marginTop: "5%",
                  marginBottom: "3%",
                }}
              >
                <img
                  style={{ marginRight: 30, width: 60, height: 18 }}
                  src={Createside}
                  alt=""
                />
                <p
                  style={{
                    fontFamily: "Bungee",
                    fontSize: 22,
                    color: "#1F294E",
                    fontWeight: 400,
                  }}
                >
                  CREATE AN ACCOUNT
                </p>
                <img
                  style={{ marginLeft: 30, width: 60, height: 18 }}
                  src={Createside}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                  <p
                    style={{ fontSize: 18, fontWeight: 600, color: "#1F294E" }}
                  >
                    Personal Info:
                  </p>
                  <div>
                    <input
                      style={{ width: 120, marginRight: 15 }}
                      onChange={OnNameChange}
                      value={Fname}
                      className="popcreate"
                      type="name"
                      placeholder="First"
                    />
                    <input
                      style={{ width: 165 }}
                      onChange={OnLastNameChange}
                      value={Laname}
                      className="popcreate"
                      type="name"
                      placeholder="Last"
                    />
                  </div>
                  <input
                    style={{ width: 300 }}
                    onChange={OnSignEmailChange}
                    value={Email}
                    className="popcreate"
                    type="email"
                    placeholder="Email"
                  />
                  <br />
                  <input
                    style={{ width: 250 }}
                    onChange={OnSignPasswordChange}
                    value={Password}
                    className="popcreate"
                    type="password"
                    placeholder="Password"
                  />
                  <br />

                  <input
                    style={{ width: 250 }}
                    onChange={OnConfirmPassChange}
                    value={ConfirmPassword}
                    className="popcreate"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  {Error && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      }}
                    >
                      {Error}
                    </p>
                  )}
                  <p
                    style={{ fontSize: 9, marginBottom: 10, color: "#1F294E" }}
                  >
                    By signing up, I agree to Outdone’s Terms of Service
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: 327,
                    }}
                  >
                    <button
                      onClick={OnContinueClick}
                      className="signbut"
                      style={{ fontFamily: "Bungee", marginTop: 0 }}
                      disabled={disable == 1}
                    >
                      CONTINUE
                    </button>
                    <div style={{ marginLeft: 10 }}>
                      <p
                        onClick={siginfocus}
                        style={{
                          fontSize: 12,
                          color: "#1F294E",
                          fontFamily: "Nunito",
                          cursor: "pointer",
                        }}
                      >
                        Have an account?
                        <br /> <p style={{ color: "#F19C8F" }}>Log in</p>{" "}
                      </p>
                    </div>
                    {isloading && (
                      <div
                        class="spinner-border"
                        style={{
                          float: "right",
                          marginLeft: 25,
                          marginBottom: 12,
                        }}
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div>
                                                <p style={{ fontSize: 18, fontWeight: 600, color: "#1F294E" }}>Or Sign Up Through Social...</p>
                                                <div style={{ backgroundColor: "#FAFAFA", padding: 15 }}>
                                                    <div style={{ backgroundColor: "#D8524E", display: "flex", alignItems: "center", borderRadius: 5, marginBottom: 25, cursor: "pointer" }}>
                                                        <div>
                                                            <img style={{ width: 40, backgroundColor: "#E48C8A", borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }} src={Googleplus} alt="" />
                                                        </div>
                                                        <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", color: "#fff" }}>
                                                            Sign in with Google
                                                        </div>
                                                    </div>
                                                    <div style={{ backgroundColor: "#428ACA", display: "flex", alignItems: "center", borderRadius: 5, marginBottom: 25, cursor: "pointer" }}>
                                                        <div>
                                                            <img style={{ width: 40, backgroundColor: "#82B2DA", borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }} src={facebook} alt="" />
                                                        </div>
                                                        <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", color: "#fff" }}>
                                                            Sign in with Facebook
                                                        </div>
                                                    </div>
                                                    <div style={{ backgroundColor: "#5AC0DE", display: "flex", alignItems: "center", borderRadius: 5, cursor: "pointer" }}>
                                                        <div>
                                                            <img style={{ width: 40, backgroundColor: "#92D4E6", borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }} src={Twitter} alt="" />
                                                        </div>
                                                        <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", color: "#fff" }}>
                                                            Sign in with Twitter
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default signin;
