import ReactDOM from "react-dom";
import React, { useState } from "react";
import Jumbotron from "./Jumbotron";
import Accordin from "./Accordin";
import Footer from "../Footer/Footer";
import MetaTags from "react-meta-tags";

import "./main.css";
import NewsLetter from "../Newsletter/NewsLetter";
import Navbar from "../Navbar/Navbar";
function faq(props) {
  return (
    <>
      <MetaTags>
        <meta
          id="meta-description"
          name="description"
          content="Have questions about Outdone? No problem — Outdone is the go-to destination for online shoppers looking to find gifts. We offer an Ai-powered engine that recommends apparel brands."
        />
      </MetaTags>
      <Navbar />
      <Jumbotron />
      <Accordin />
      <Footer />
      <button className="subscribe" onClick={() => window.subscribe()}>
        SUBSCRIBE
      </button>
    </>
  );
}

export default faq;
