import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Vframe from "../assets/images/VerifyFrame.png";
import Single from "../assets/images/Group173.png";
import Multiple from "../assets/images/Group178.png";
import CAKE from "../assets/images/Groupcake160.png";
import TF from "../assets/images/24.png";
import BELL from "../assets/images/bellone.png";
import Vect from "../assets/images/verifyVector.png";
import "./verification.css";
import { SignIn, VerifyAccount } from "../Services/ServicePhase2";
import Addmyself from "../AddMyself/Addmyself";
import Addfriendfamily from "../Addgiftget/Family&Friends";
import { useState } from "react";
import { Helmet } from "react-helmet";
import goldenbottom from "../assets/images/Grupo7605.png";
function verify(props) {
  var url = window.location;
  var access_token = url.toString().split("=");
  let AccToken = access_token[1];

  console.log(url);
  console.log(access_token);
  console.log(AccToken);
  localStorage.setItem("user_token", AccToken);

  useEffect(() => {
    VerifyAccount(AccToken).then((result) => {
      if (result.success == true) {
        console.log(result.message);
      }
    });
  }, []);

  const OnSignInClick = () => {
    props.history.push("/dashboard-user");
  };

  const [flow, setflow] = useState(0);
  const [flow2, setflow2] = useState(1);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>

      {flow === 0 && (
        <div style={{ backgroundColor: "#fff" }}>
          <div
            className="crossico"
            onClick={(event) => (window.location.href = "/dashboard-user")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="29"
              viewBox="0 0 26 29"
              fill="none"
            >
              <path
                d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
                fill="#1F294E"
              />
            </svg>
          </div>
          <div className="bacverify">
            <div>
              <img className="vframe" src={Vframe} alt="" />
              <p className="accverify" style={{ fontFamily: "Bungee" }}>
                ACCOUNT VERIFIED!
              </p>
            </div>
            <p className="bodytext" style={{ fontFamily: "Nunito" }}>
              <span style={{ fontWeight: 700 }}>
                To finish your account set-up,
              </span>{" "}
              please follow either of the prompts below to begin building your
              Outdone profile.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3%",
              }}
            >
              <div
                className="shadowbox"
                style={{ marginRight: 50, cursor: "pointer" }}
                onClick={(e) => setflow(1)}
              >
                <img className="singleimg" src={Single} alt="" />
                <p
                  style={{
                    color: "#1F294E",
                    fontSize: 14,
                    marginBottom: 0,
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  Add Myself
                </p>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="shadowbox"
                onClick={(e) => {
                  setflow2(2), setflow(2);
                }}
              >
                <img className="multipleimg" src={Multiple} alt="" />
                <p
                  style={{
                    color: "#1F294E",
                    fontSize: 14,
                    marginBottom: 0,
                    fontWeight: 700,
                    marginTop: 5,
                    textAlign: "center",
                  }}
                >
                  Add Family & Friends
                </p>
              </div>
            </div>
          </div>

          <div
            className="golden"
            style={{ display: "none", justifyContent: "center" }}
          >
            <img src={goldenbottom} />
          </div>

          <div
            className="completelater"
            style={{ display: "none", justifyContent: "left" }}
          >
            <p style={{ cursor: "pointer" }} onClick={OnSignInClick}>
              COMPLETE LATER
            </p>
          </div>

          <div>
            <img className="vectclass" src={Vect} alt="" />
            <div className="secondbackverify">
              <div className="backverifywhite">
                <p className="firstsecondtext">
                  As a reminder, sharing these details allows us to...
                </p>
                <div>
                  <div className="allofelx" style={{ marginBottom: 0 }}>
                    <img
                      style={{ width: 50 }}
                      className="vecake"
                      src={CAKE}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: 19,
                        color: "#1F294E",
                        fontWeight: 400,
                        paddingTop: 18,
                        marginLeft: 30,
                      }}
                    >
                      Notify you when your gifting occassions are around the
                      corner
                    </p>
                  </div>
                  <div className="allofelx" style={{ marginBottom: -15 }}>
                    <img
                      style={{ width: 50 }}
                      className="vetf"
                      src={TF}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: 19,
                        color: "#1F294E",
                        fontWeight: 400,
                        paddingTop: 18,
                        marginLeft: 30,
                      }}
                    >
                      Save you the trouble of always filling out our
                      recommendation survey for people you shop for often
                    </p>
                  </div>
                  <div className="allofelx" style={{ marginBottom: 0 }}>
                    <img
                      style={{ width: 50 }}
                      className="vebell"
                      src={BELL}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: 19,
                        color: "#1F294E",
                        fontWeight: 400,
                        paddingTop: 18,
                        marginLeft: 30,
                      }}
                    >
                      Automatically notify you when new brands are added to
                      Outdone that we think you or your friends & family will
                      love
                    </p>
                  </div>
                  <p
                    className="complater"
                    style={{ cursor: "pointer" }}
                    onClick={OnSignInClick}
                  >
                    COMPLETE LATER
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}

      {flow === 1 && <Addmyself flow={flow} flow2={flow2} setflow={setflow} />}

      {flow === 2 && (
        <Addfriendfamily flow={flow} flow2={flow2} setflow={setflow} />
      )}
    </>
  );
}
export default verify;
