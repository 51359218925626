import React, { useEffect, useState } from "react";
import "./Addmyself.css";
import editbottom from "../assets/images/Grupo1230.png";
import top from "../assets/images/Polygon7.png";
import bottom from "../assets/images/Polygon8.png";
import CAKE from "../assets/images/Groupcake160.png";
import Single from "../assets/images/Group173.png";
import Footer from "../Footer/Footer";
import { AddMYSELF } from "../Services/ServicePhase2";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import QuickSurvey from "../quickSurvey";

const Addmyself = (props) => {
  const [age, setage] = useState("");
  const [area, setarea] = useState("");
  const [birthday, setbirthday] = useState("12-1-2022");
  const [State, setState] = useState("");
  const [typeArray, settypeArray] = useState([]);
  const [typeArrayTwo, settypeArrayTwo] = useState([]);
  const [typeArrayThree, settypeArrayThree] = useState([]);
  const [typeArrayFour, settypeArrayFour] = useState([]);
  const [typeArrayFive, settypeArrayFive] = useState([]);
  const [typeArraySix, settypeArraySix] = useState([]);
  const [typeArraySeven, settypeArraySeven] = useState([]);
  const [typeArrayEight, settypeArrayEight] = useState([]);
  const [year, setyear] = useState(new Date().getFullYear());
  const [gender, setgender] = useState();

  let history = useHistory();

  useEffect(() => {
    if (typeArrayEight.length > 0) {
      AddMYSELF(
        age,
        typeArray,
        typeArrayTwo,
        typeArrayThree,
        typeArrayFour,
        typeArrayFive,
        typeArraySix,
        typeArraySeven,
        typeArrayEight,
        State,
        birthday
      ).then((result) => {
        if (result.success == true) {
          alert(result.message);

          history.push("/dashboard-user");
        } else {
          alert(result.message);
        }
      });
    }
  }, [typeArrayEight]);

  useEffect(() => {
    $(function () {
      $("#datepicker")
        .datepicker({
          changeYear: false,
          dateFormat: "m/d",
        })
        .focus(function () {
          $(".ui-datepicker-year").hide();
        });
    });
  }, []);

  const OnSaveClick = () => {
    var birthdate;
    if (document.getElementById("datepicker").value != "") {
      birthdate = calculatemybirthday();
    }
    var getState = $("#selectstate option:selected").text();

    const arr = [gender];
    AddMYSELF(
      age,
      arr,
      typeArray,
      typeArrayTwo,
      typeArrayThree,
      typeArrayFour,
      typeArrayFive,
      typeArraySix,
      typeArraySeven,
      getState,
      birthdate
    ).then((result) => {
      if (result.success == true) {
        alert(result.message);

        props.setflow(2);
      } else {
        alert(result.message);
      }
    });
  };

  const calculatemybirthday = () => {
    let y = document.getElementById("age").value;
    let yea = new Date().getFullYear();
    let month = new Date().getMonth();
    let din = new Date().getDate();

    let xage = parseFloat(y + "." + month);
    var year2 = Math.trunc(yea - xage);

    var i = $("#datepicker").val().split("/");

    const com = parseInt(i[0]);
    const dom = parseInt(i[1]);

    if (month > com) {
      year2 = yea - y;
    } else if (month == com) {
      if (dom < din) {
        year2 = yea - y;
      } else {
        year2 = yea - y - 1;
      }
    } else {
      year2 = yea - y - 1;
    }

    var birthdate = i[0] + "-" + i[1] + "-" + year2;

    return birthdate;
  };

  const OnSaveClick2 = () => {
    var birthdate;

    if (document.getElementById("datepicker").value != "") {
      birthdate = calculatemybirthday();
    }

    const arr = [gender];
    var getState = $("#selectstate option:selected").text();

    AddMYSELF(
      age,
      arr,
      typeArray,
      typeArrayTwo,
      typeArrayThree,
      typeArrayFour,
      typeArrayFive,
      typeArraySix,
      typeArraySeven,
      getState,
      birthdate
    ).then((result) => {
      if (result.success == true) {
        alert(result.message);

        window.location.href = "/dashboard-user";
      } else {
        alert(result.message);
      }
    });
  };

  const counter = (x) => {
    if (x === "incre") {
      if (age === "") {
        setage(1);
      } else if (age >= 109) {
      } else {
        setage(age + 1);
      }
    } else if (x === "decre") {
      if (age > 1) {
        setage(age - 1);
      }
    }
  };

  const changeage = (e) => {
    var enterage = e.target.valueAsNumber;
    if (enterage <= 109 && enterage > 0) {
      setage(enterage);
    } else if (enterage > 109) {
    } else {
      setage("");
      setyear(new Date().getFullYear());
    }
  };

  const farward = () => {
    if (document.getElementById("selectstate").value == "") {
      document.getElementById("selectstate").value = 0;
    } else {
      let i = document.getElementById("selectstate").value;
      let j = parseInt(i);
      document.getElementById("selectstate").value = j + 1;
    }
  };

  const backward = () => {
    let i = document.getElementById("selectstate").value;
    let j = parseInt(i);
    document.getElementById("selectstate").value = j - 1;
  };

  const toggleLike = (e) => {
    let sample = typeArray.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removeLike(e);
    } else {
      settypeArray([...typeArray, e.target.value]);
    }
  };

  const removeLike = (e) => {
    var array = [...typeArray]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArray(array);
    }
  };

  const togglePhysical = (e) => {
    let sample = typeArrayTwo.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removePhysical(e);
    } else {
      settypeArrayTwo([...typeArrayTwo, e.target.value]);
    }
  };

  const removePhysical = (e) => {
    var array = [...typeArrayTwo]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArrayTwo(array);
    }
  };

  const toggleClothing = (e) => {
    let sample = typeArrayThree.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removeClothing(e);
    } else {
      settypeArrayThree([...typeArrayThree, e.target.value]);
    }
  };

  const removeClothing = (e) => {
    var array = [...typeArrayThree]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArrayThree(array);
    }
  };

  const toggleFood = (e) => {
    let sample = typeArrayFour.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removeFood(e);
    } else {
      settypeArrayFour([...typeArrayFour, e.target.value]);
    }
  };

  const removeFood = (e) => {
    var array = [...typeArrayFour]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArrayFour(array);
    }
  };

  const togglePlaces = (e) => {
    let sample = typeArrayFive.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removePlaces(e);
    } else {
      settypeArrayFive([...typeArrayFive, e.target.value]);
    }
  };

  const removePlaces = (e) => {
    var array = [...typeArrayFive]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArrayFive(array);
    }
  };

  const toggleBusiness = (e) => {
    let sample = typeArraySix.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removeBusiness(e);
    } else {
      settypeArraySix([...typeArraySix, e.target.value]);
    }
  };

  const removeBusiness = (e) => {
    var array = [...typeArraySix]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArraySix(array);
    }
  };

  const toggleArea = (e) => {
    let sample = typeArraySeven.filter((obj) => {
      return obj == e.target.value;
    });

    if (sample.length == 1) {
      removeArea(e);
    } else {
      settypeArraySeven([...typeArraySeven, e.target.value]);
    }
  };

  const removeArea = (e) => {
    var array = [...typeArraySeven]; // make a separate copy of the array
    var index = array.indexOf(e.target.value);
    if (index !== -1) {
      array.splice(index, 1);
      settypeArraySeven(array);
    }
  };

  const datefarward = () => {
    var date1 = $("#datepicker").datepicker("getDate");
    var date = new Date(Date.parse(date1));
    date.setDate(date.getDate() + 1);

    $("#datepicker").datepicker("setDate", date);
  };

  const datebackward = () => {
    var date1 = $("#datepicker").datepicker("getDate");
    var date = new Date(Date.parse(date1));
    date.setDate(date.getDate() - 1);

    $("#datepicker").datepicker("setDate", date);
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>
      <div className="desktop">
        <div
          className="crossico"
          onClick={(event) => (window.location.href = "/dashboard-user")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="29"
            viewBox="0 0 26 29"
            fill="none"
          >
            <path
              d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
              fill="#1F294E"
            />
          </svg>
        </div>

        <div style={{ display: "flex", marginTop: "5%" }}>
          <div style={{ width: "25%" }}></div>
          <div>
            <div style={{ display: "flex" }}>
              <img
                style={{
                  width: 90,
                  height: 90,
                  marginRight: 20,
                  marginTop: -20,
                }}
                src={Single}
                alt=""
              />
              <p className="thinking">
                <span style={{ fontWeight: 600 }}>
                  Thinking about yourself,
                </span>{" "}
                please fill out the questions below to save your information to
                your account.
              </p>
            </div>
            <img
              className="editbottom"
              style={{ marginLeft: "35%" }}
              src={editbottom}
              alt=""
            />
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="agegen">Age </p>
                <div
                  className="ageinput"
                  style={{ display: "flex", alignItems: "center", width: 130 }}
                >
                  <div>
                    <img
                      style={{ width: 15 }}
                      src={top}
                      alt=""
                      onClick={() => counter("incre")}
                    />
                    <img
                      style={{ width: 15 }}
                      src={bottom}
                      alt=""
                      onClick={() => counter("decre")}
                    />
                  </div>
                  <input
                    type="number"
                    id="age"
                    name="age"
                    value={age}
                    onChange={(e) => changeage(e)}
                    style={{
                      border: "none",
                      width: 30,
                      textAlign: "center",
                      fontSize: 40,
                      color: "#478ba2",
                      fontWeight: 700,
                      width: 90,
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="agegen">Gender</p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a1"
                    name="a"
                    value="Male"
                    checked={gender === "Male"}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <label for="a1">Male</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a2"
                    name="a"
                    value="Female"
                    checked={gender === "Female"}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <label for="a2">Female</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a3"
                    name="a"
                    value="Other"
                    checked={gender === "Other"}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <label for="a3">Other</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">What do they like to do at home?</p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        id="t1"
                        value="Cook"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t1">Cook</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        id="t2"
                        value="Alcohol"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t2">Drink Alcohol</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Coffee"
                        id="t3"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t3">Make Coffee</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Read"
                        id="t4"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t4">Read</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Puzzlegame"
                        id="t5"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t5">Puzzles/Games</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Photo"
                        id="t6"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t6">Photography</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Videogame"
                        id="t7"
                        name="a"
                        onChange={(e) => toggleLike(e)}
                      />
                      <label for="t7">Video Games</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they enjoy any of these physical activities?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o1"
                        value="Biking"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o1">Biking</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o2"
                        value="Yoga"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o2">Yoga</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o3"
                        value="Golfing"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o3">Golfing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o4"
                        value="Lifting"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o4">Lifting</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o5"
                        value="Running"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o5">Running</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o6"
                        value="Camping"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="o6">Camping</label>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k1"
                        value="Hunting"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k1">Hunting</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k2"
                        value="Fishing"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k2">Fishing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k3"
                        value="Swimming"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k3">Swimming</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k4"
                        value="Skiing"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k4">Skiing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k5"
                        value="snowboard"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k5">Snowboarding</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k6"
                        value="Hiking"
                        name="a"
                        onChange={(e) => togglePhysical(e)}
                      />
                      <label for="k6">Hiking</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button className="shopnoww" style={{ fontFamily: "Bungee", marginTop : 20, display : "block", marginLeft : "auto", marginRight : "auto" }}>
          Shop Now!
        </button> */}
            </div>
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  What sorts of clothing styles do they like to wear?
                </p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q1"
                    name="a"
                    value="Sporty"
                    onChange={(e) => toggleClothing(e)}
                  />
                  <label for="q1">Sporty</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q2"
                    name="a"
                    value="Outdoorsy"
                    onChange={(e) => toggleClothing(e)}
                  />
                  <label for="q2">Outdoorsy</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q3"
                    name="a"
                    value="Loungewear"
                    onChange={(e) => toggleClothing(e)}
                  />
                  <label for="q3">Loungewear</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q4"
                    name="a"
                    value="Streetwear"
                    onChange={(e) => toggleClothing(e)}
                  />
                  <label for="q4">Streetwear</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they love any of the following foods or drinks?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r1"
                        name="a"
                        className="QuestFive"
                        value="Beer"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="r1">Beer</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r2"
                        name="a"
                        className="QuestFive"
                        value="Candy"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="r2">Candy</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r3"
                        name="a"
                        className="QuestFive"
                        value="Coffee"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="r3">Coffee</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r4"
                        name="a"
                        className="QuestFive"
                        value="Wine"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="r4">Wine</label>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g1"
                        name="a"
                        className="QuestFive"
                        value="Tea"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="g1">Tea</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g2"
                        name="a"
                        className="QuestFive"
                        value="Pasta"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="g2">Pasta</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g3"
                        name="a"
                        className="QuestFive"
                        value="Pizza"
                        onChange={(e) => toggleFood(e)}
                      />
                      <label for="g3">Pizza</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they regularly go to any of the following places?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e1"
                        name="a"
                        className="QuestSix"
                        value="Beach"
                        onChange={(e) => togglePlaces(e)}
                      />
                      <label for="e1">The Beach</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e2"
                        name="a"
                        className="QuestSix"
                        value="Bars"
                        onChange={(e) => togglePlaces(e)}
                      />
                      <label for="e2">Bars or Clubs</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e3"
                        name="a"
                        className="QuestSix"
                        value="Concerts"
                        onChange={(e) => togglePlaces(e)}
                      />
                      <label for="e3">Concerts</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e4"
                        name="a"
                        className="QuestSix"
                        value="Airport"
                        onChange={(e) => togglePlaces(e)}
                      />
                      <label for="e4">The Airport</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <button className="shopnoww" style={{ fontFamily: "Bungee", marginTop : 20, display : "block", marginLeft : "auto", marginRight : "auto" }}>
          Shop Now!
        </button> */}
            </div>
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Are they especially passionate about supporting any of the
                  following types of businesses?{" "}
                </p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j1"
                    name="a"
                    className="QuestSeven"
                    value="FemaleLed"
                    onChange={(e) => toggleBusiness(e)}
                  />
                  <label for="j1">Female-led</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j2"
                    name="a"
                    className="QuestSeven"
                    value="MadeinUSA"
                    onChange={(e) => toggleBusiness(e)}
                  />
                  <label for="j2">Made in USA</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j3"
                    name="a"
                    className="QuestSeven"
                    value="Sustainable"
                    onChange={(e) => toggleBusiness(e)}
                  />
                  <label for="j3">Sustainable</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j4"
                    name="a"
                    className="QuestSeven"
                    value="Charitable"
                    onChange={(e) => toggleBusiness(e)}
                  />
                  <label for="j4">Charitable</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">What kind of area do they live in?</p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v1"
                        name="a"
                        className="QuestEight"
                        checked={area === "Countryside"}
                        value="Countryside"
                        onChange={(e) => {
                          setarea(e.target.value), toggleArea(e);
                        }}
                      />
                      <label for="v1">Countryside</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v2"
                        name="a"
                        className="QuestEight"
                        checked={area === "City"}
                        value="City"
                        onChange={(e) => {
                          setarea(e.target.value), toggleArea(e);
                        }}
                      />
                      <label for="v2">City</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v3"
                        name="a"
                        className="QuestEight"
                        checked={area === "Suburbs"}
                        value="Suburbs"
                        onChange={(e) => {
                          setarea(e.target.value), toggleArea(e);
                        }}
                      />
                      <label for="v3">Suburbs</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v4"
                        name="a"
                        className="QuestEight"
                        checked={area === "Not Sure"}
                        value="Not Sure"
                        onChange={(e) => {
                          setarea(e.target.value), toggleArea(e);
                        }}
                      />
                      <label for="v4">Not Sure</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">Which state do you live? </p>
                <div
                  className="ageinput"
                  style={{ display: "flex", alignItems: "center", width: 180 }}
                >
                  <div>
                    <img
                      style={{ width: 15 }}
                      src={top}
                      alt=""
                      onClick={farward}
                    />
                    <br />
                    <img
                      style={{ width: 15 }}
                      src={bottom}
                      alt=""
                      onClick={backward}
                    />
                  </div>
                  <select
                    id="selectstate"
                    className="stateselect"
                    onChange={(e) => setState(e.target.value)}
                    style={{
                      height: 50,
                      border: "none",
                      appearance: "none",
                      width: 125,
                    }}
                  >
                    <option
                      className="value-style"
                      value=""
                      disabled
                      selected
                    ></option>
                    <option className="value-style" value="0">
                      Alabama
                    </option>
                    <option className="value-style" value="1">
                      Alaska
                    </option>
                    <option className="value-style" value="2">
                      Arizona
                    </option>
                    <option className="value-style" value="3">
                      Arkansas
                    </option>
                    <option className="value-style" value="4">
                      California
                    </option>

                    <option className="value-style" value="5">
                      Colorado
                    </option>
                    <option className="value-style" value="6">
                      Connecticut
                    </option>
                    <option className="value-style" value="7">
                      Delaware
                    </option>
                    <option className="value-style" value="8">
                      District of Columbia
                    </option>
                    <option className="value-style" value="9">
                      Florida
                    </option>

                    <option className="value-style" value="10">
                      Georgia
                    </option>
                    <option className="value-style" value="11">
                      Hawaii
                    </option>
                    <option className="value-style" value="12">
                      Idaho
                    </option>
                    <option className="value-style" value="13">
                      Illinois
                    </option>
                    <option className="value-style" value="14">
                      Indiana
                    </option>

                    <option className="value-style" value="15">
                      Iowa
                    </option>
                    <option className="value-style" value="16">
                      Kansas
                    </option>
                    <option className="value-style" value="17">
                      Kentucky
                    </option>
                    <option className="value-style" value="18">
                      Louisiana
                    </option>
                    <option className="value-style" value="19">
                      Maine
                    </option>

                    <option className="value-style" value="20">
                      Maryland
                    </option>
                    <option className="value-style" value="21">
                      Massachusetts
                    </option>
                    <option className="value-style" value="22">
                      Michigan
                    </option>
                    <option className="value-style" value="23">
                      Minnesota
                    </option>
                    <option className="value-style" value="24">
                      Mississippi
                    </option>

                    <option className="value-style" value="25">
                      Missouri
                    </option>
                    <option className="value-style" value="26">
                      Montana
                    </option>
                    <option className="value-style" value="27">
                      Nebraska
                    </option>
                    <option className="value-style" value="28">
                      Nevada
                    </option>
                    <option className="value-style" value="29">
                      New Hampshire
                    </option>

                    <option className="value-style" value="30">
                      New Jersey
                    </option>
                    <option className="value-style" value="31">
                      New Mexico
                    </option>
                    <option className="value-style" value="32">
                      New York
                    </option>
                    <option className="value-style" value="33">
                      North Carolina
                    </option>
                    <option className="value-style" value="34">
                      North Dakota
                    </option>

                    <option className="value-style" value="35">
                      Ohio
                    </option>
                    <option className="value-style" value="36">
                      Oklahoma
                    </option>
                    <option className="value-style" value="37">
                      Oregon
                    </option>
                    <option className="value-style" value="38">
                      Pennsylvania
                    </option>
                    <option className="value-style" value="39">
                      Rhode Island
                    </option>

                    <option className="value-style" value="40">
                      South Carolina
                    </option>
                    <option className="value-style" value="41">
                      South Dakota
                    </option>
                    <option className="value-style" value="42">
                      Tennessee
                    </option>
                    <option className="value-style" value="43">
                      Texas
                    </option>
                    <option className="value-style" value="44">
                      Utah
                    </option>

                    <option className="value-style" value="45">
                      Vermont
                    </option>
                    <option className="value-style" value="46">
                      Virginia
                    </option>
                    <option className="value-style" value="47">
                      Washington
                    </option>
                    <option className="value-style" value="48">
                      West Virginia
                    </option>
                    <option className="value-style" value="49">
                      Wisconsin
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: "3%" }}
            >
              <img
                src={CAKE}
                style={{
                  width: 50,
                  height: 59,
                  marginTop: 40,
                  marginRight: 35,
                }}
                alt=""
              />
              <div>
                <p className="checkhead" style={{ fontFamily: "Nunito" }}>
                  When is your birthday
                </p>
                <div
                  className="ageinput"
                  style={{ display: "flex", alignItems: "center", width: 310 }}
                >
                  <div>
                    <img
                      style={{ width: 15 }}
                      src={top}
                      alt=""
                      onClick={datefarward}
                    />
                    <br />
                    <img
                      style={{ width: 15 }}
                      src={bottom}
                      alt=""
                      onClick={datebackward}
                    />
                  </div>

                  <input
                    type="text"
                    id="datepicker"
                    style={{
                      border: "none",
                      padding: "3.5%",
                      fontColor: "#478ba2",
                      fontSize: 20,
                      fontWeight: 800,
                      textAlign: "center",
                      width: "88%",
                      caretColor: "transparent",
                    }}
                    onChange={(e) => setbirthday(e.target.value)}
                  />
                </div>
              </div>
              {props.flow2 === 2 ? (
                <button
                  className="shopsave"
                  onClick={OnSaveClick2}
                  style={{
                    fontFamily: "Bungee",
                    fontWeight: 400,
                    width: 150,
                    marginRight: "5%",
                    marginTop: "4%",
                    marginLeft: "7%",
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  className="shopsave"
                  onClick={OnSaveClick}
                  style={{
                    fontFamily: "Bungee",
                    fontWeight: 400,
                    width: 150,
                    marginRight: "5%",
                    marginTop: "4%",
                    marginLeft: "7%",
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>

      {/* Mobile View Start here */}

      <div className="mobile">
        <div
          className="crossico"
          onClick={(event) => (window.location.href = "/dashboard-user")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="29"
            viewBox="0 0 26 29"
            fill="none"
          >
            <path
              d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
              fill="#1F294E"
            />
          </svg>
        </div>

        <div className="flexin">
          <img style={{ height: 90 }} src={Single} alt="" />
          <img className="editbottom2" src={editbottom} alt="" />
          <p className="thinking2">
            <span style={{ fontWeight: 600 }}>Thinking about yourself,</span>{" "}
            please answer the questions below to save your information to your
            account.
          </p>
        </div>

        <div className="secnddiv">
          <div className="giftocc">
            <h3>Your Birthday?</h3>
          </div>
          <div className="giftcontainer">
            <div className="giftcard">
              <div className="dateinpo">
                <h3>Date</h3>
                <div className="dageinput">
                  <div>
                    <input
                      type="date"
                      className="occassiondate"
                      name=""
                      style={{ border: "none", width: 100, marginLeft: 10 }}
                      onChange={(e) =>
                        localStorage.setItem("birthdate", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <img
                      style={{
                        width: 8,
                        display: "block",
                        marginBottom: 5,
                        marginTop: 0,
                        marginRight: 6,
                        marginLeft: "auto",
                      }}
                      onClick={() => datefarward(props.id)}
                      src={top}
                      alt=""
                    />
                    <img
                      style={{
                        width: 8,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: 6,
                      }}
                      src={bottom}
                      onClick={() => datebackward(props.id)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="secnddiv" style={{ marginTop: 30, marginBottom: 137 }}>
          <div className="giftocc">
            <h3>engine inputs</h3>
          </div>
          <div className="surveyscreen">
            <QuickSurvey addmyself={true} />
          </div>
        </div>

        <Navigationbar />
      </div>
    </>
  );
};
export default Addmyself;
