import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import SidebarMain from "../Sidebar/sidebar";
import { Link } from "react-router-dom";
import "./dashboard.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { getBrands } from "../Services/Services";
import Logo from "../../assets/images/Outdone-Header-Logo.svg";
import { AuthGuardService } from "../../Services/ServicePhase2";

function dashboard(props) {
  const [data, setdata] = useState();
  const [datalent, setdatalent] = useState();
  const [loading, setloading] = useState(true);
  const [isauth, setisauth] = useState(false);
  const [totalproducts, settotalproducts] = useState(false);
  // const aa = localStorage.getItem("token");
  console.log("-=-=====>", data);
  useEffect(() => {
    getBrands().then((result) => {
      let responseJSON = result;
      setdata(responseJSON.data);
      setdatalent(responseJSON.data.length);
      settotalproducts(responseJSON.total_products);
      setloading(false);
    });
  }, []);

  useEffect(() => {
    console.log("AUTH SERVICE DASHBOARD");
    AuthGuardService()
      .then((result) => {
        console.log("Result >> ", result);
        if (result.success == true) {
          setisauth(true);
        } else {
          props.history.push("/login");
          setisauth(false);
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
        console.log("Error : ", err);
      });
  }, []);

  return isauth ? (
    loading ? (
      <Loader
        className="text-center loader"
        type="Oval"
        color="#323A40"
        height={200}
        width={200}
      />
    ) : (
      <Fragment>
        <section className="dashboard-page">
          <div className="container-fluid p-l ">
            <div className="row">
              <div className="full-dash">
                <div className="side-bar-dash">
                  <SidebarMain />
                </div>
                <div className="dashboard-content">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <img className="logo-dash" src={Logo} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-dash">
                      <div className="box-dashboard">
                        <h2>Total Brands</h2>
                        <span className="ad brands-counting">{datalent}</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-dash">
                      <div className="box-dashboard">
                        <h2>Total Products</h2>
                        <span className="ad brands-counting">
                          {totalproducts}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-dash">
                      <div className="box-dashboard">
                        <h2>View Brands</h2>
                        <button className="btn-view-dash">
                          <Link className="text-under" to="/viewbrand">
                            {" "}
                            View Brands{" "}
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-dash ">
                      <div className="box-dashboard">
                        <h2>Add New Brands</h2>
                        <button className="btn-view-dash">
                          <Link className="text-under" to="/addbrand">
                            Add Brands{" "}
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="table-dash">
                        <table class="table">
                          <thead>
                            <th>Id</th>
                            <th>Brand Name</th>
                            <th>Brand Founded Year</th>
                          </thead>
                          <tbody>
                            {data.slice(0, 15).map((row, i) => (
                              <tr>
                                <td data-label="S.No">{row.brand_id}</td>
                                <td data-label="Brand Name">{row.name}</td>
                                <td data-label="Brand Ratio">
                                  {row.year_founded}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  ) : (
    <div> </div>
  );
}

export default dashboard;
