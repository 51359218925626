import React, { useState } from "react";
import "./Changepassword.css";
import Footer from "../Footer/Footer";
import ReactDOM from "react-dom";
import Navbar from "../Navbar/Navbar";
import ForgotSepareate from "../assets/images/Grupo1230.png";
import { ChangeOldPassword } from "../Services/ServicePhase2";
import { useHistory } from "react-router-dom";

function ChangePassword(props) {
  let history = useHistory();
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [mustMatch, setmustMatch] = useState(false);
  const [mustLength, setmustLength] = useState(false);
  const [ForgotLoading, setForgotLoading] = useState(false);
  const [oldPassworderror, setoldPassworderror] = useState(false);

  var url = window.location;
  var access_token = url.toString().split("=");
  let AccToken = access_token[1];

  localStorage.setItem("token", AccToken);

  const OnChangePassword = () => {
    setmustLength(false);
    setmustMatch(false);
    setoldPassworderror("");

    if (newpassword.length < 8) {
      setmustLength(true);
    } else if (confirmpassword.length < 8) {
      setmustLength(true);
    } else if (newpassword !== confirmpassword) {
      setmustMatch(true);
    } else if (newpassword === confirmpassword && newpassword != "") {
      ChangeOldPassword(oldpassword, newpassword, confirmpassword).then(
        (result) => {
          if (result.success == false) {
            setoldPassworderror("Incorrect Old Password");
          } else {
            alert("Password updated Successully");
            history.push("/dashboard-user");
          }
        }
      );
    }
  };

  return (
    <>
      <div>
        <div className="forgotclassimage">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor: "#fff",
                width: "clamp(400px,100%,700px)",
                paddingTop: 25,
                paddingBottom: 0,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Bungee",
                  fontSize: 28,
                  marginBottom: -25,
                  fontWeight: 400,
                  color: "#1F294E",
                  textAlign: "center",
                }}
              >
                CHANGE PASSWORD
              </p>
              <img
                className="ForgotCenter"
                style={{ width: 110, marginBottom: -20, marginTop: 10 }}
                src={ForgotSepareate}
                alt=""
              />
              <div className="resetfieldtype" style={{ height: 250 }}>
                <input
                  className="ForgotCenter"
                  type="password"
                  onChange={(e) => setoldpassword(e.target.value)}
                  placeholder="Enter Old Password"
                  required
                />
                <br />
                <input
                  className="ForgotCenter"
                  type="password"
                  onChange={(e) => setnewpassword(e.target.value)}
                  placeholder="Enter New Password"
                  required
                />
                <br />
                <input
                  className="ForgotCenter"
                  type="password"
                  onChange={(e) => setconfirmpassword(e.target.value)}
                  placeholder="Enter Confirm password"
                  required
                />
                {mustLength ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: 5,
                        width: 275,
                      }}
                    >
                      New Password must be 8 characters long
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button><br /> */}
                  </div>
                )}
                {mustMatch ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: 5,
                        width: 375,
                      }}
                    >
                      Both New and Confrm Passwords must be same
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button><br /> */}
                  </div>
                )}

                {oldPassworderror ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "12px",
                        marginTop: 5,
                        width: 275,
                      }}
                    >
                      Old Password is Incorrect
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button><br /> */}
                  </div>
                )}
              </div>
              {ForgotLoading ? (
                <div
                  className="spinner-border"
                  style={{ float: "right" }}
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <div>
                  <button
                    className="ForgotButton"
                    onClick={OnChangePassword}
                    style={{ fontFamily: "Bungee" }}
                  >
                    CONTINUE
                  </button>
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ChangePassword;
