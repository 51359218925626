import React from "react";
import CsvImport from "./CsvImporter";

const CsvMain = () => {
  const data = async (e) => {
    e.preventDefault();
    var starting_date = jQuery("input#starting_date").val();

    localStorage.setItem("starting_date", starting_date);

    var ending_date = jQuery("input#ending_date").val();

    localStorage.setItem("ending_date", ending_date);

    var starting_time = jQuery("input#appt-time").val();

    localStorage.setItem("starting_time", starting_time);

    var ending_time = jQuery("input#appt-ending-time").val();

    localStorage.setItem("ending_time", ending_time);

    if (
      starting_date == "" &&
      ending_date == "" &&
      starting_time == "" &&
      ending_time == ""
    ) {
      // jQuery("input#starting_date").addClass("error");
      // jQuery("input#ending_date").addClass("error");
      // jQuery("input#appt-time").addClass("error");
      // jQuery("input#appt-ending-time").addClass("error");
      return false;
    } else {
      var starting_date = localStorage.getItem("starting_date");
      var ending_date = localStorage.getItem("ending_date");
      var starting_time = localStorage.getItem("starting_time");
      var ending_time = localStorage.getItem("ending_time");
      var url = `https://api.outdone.io/api/download_predictions?start_date=${starting_date}%20${starting_time}&end_date=${ending_date}%20${ending_time}`;
      window.open(url, "_blank");
    }
  };
  return (
    <div>
      <CsvImport />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="data-export">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label for="starting_date">Starting Date:</label>
                    <br />
                    <input
                      type="date"
                      id="starting_date"
                      name="start_date"
                      required
                    />
                    <br />
                    <label for="appt-time">Starting Time:</label> <br />
                    <input
                      id="appt-time"
                      type="time"
                      name="appt-time"
                      step="2"
                    ></input>
                    <br />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label for="birthday">Ending Date:</label>
                    <br />
                    <input
                      type="date"
                      id="ending_date"
                      name="ending_date"
                      required
                    />
                    <br />

                    <label for="appt-time">Ending Time:</label>
                    <br />
                    <input
                      id="appt-ending-time"
                      type="time"
                      name="appt-time"
                      step="2"
                    ></input>
                    <br />
                  </div>
                </div>
                <input type="submit" onClick={data} className="data-bar" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsvMain;
