import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Typical from "react-typical";
import { Link } from "react-router-dom";
import Countryside from "../../assets/images/Countryside.png";
import City from "../../assets/images/City.png";
import Suburbs from "../../assets/images/Suburbs.png";
import NotSure from "../../assets/images/NotSure.png";
import Back from "../../assets/images/Backside.png";
import { QuestionAnalytics } from "../../Services/ServicePhase2";
import { useHistory } from "react-router-dom";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateFourteen = (props) => {
  useEffect(() => {
    pageView("/surveyQ9");
  });

  const { watch, register } = useForm();

  const btClicked = (e) => {
    e.persist();
    let title = document.getElementById("kind-of-area").textContent;
    let answer = e.target.value;
    let question = e.target.name;
    QuestionAnalytics(title, answer);
    // .then((result) => {
    //   if (result.success == true) {
    //     console.log('Question-2 Responce : ' , result)
    //     props.onClick()
    //   }
    //   else { alert(result.message) }
    //   })

    localStorage.setItem("Area", answer);
    props.setarea(answer);
    props.onClick();

    var Stingvalue = localStorage.getItem("Sting");
    var iStingvalue = parseInt(Stingvalue);
  };

  function shuffle(id, kid) {
    var container = document.getElementById(id);
    var elementsArray = Array.prototype.slice.call(
      container.getElementsByClassName(kid)
    );
    elementsArray.forEach(function (element) {
      container.removeChild(element);
    });
    shuffleArray(elementsArray);
    elementsArray.forEach(function (element) {
      container.appendChild(element);
    });
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  useEffect(() => {
    shuffle("container", "area");
  }, []);

  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className="desktop">
        <h2
          id="kind-of-area"
          className="Text-header-main"
          style={{ marginBottom: 70 }}
        >
          What kind of area do they live in?
        </h2>

        <div className="step-1-form middle">
          <div className="myrow" id="container">
            <label>
              <input
                type="radio"
                name="Friend"
                value="1"
                checked={props.area == "1"}
                onClick={(e) => btClicked(e)}
              />
              <div className="front-end box myboxes2 myboxes">
                <img
                  style={{ marginTop: 0 }}
                  className="activity-image"
                  src={Countryside}
                />
                <span style={{ fontFamily: "Poppins", fontSize: 16 }}>
                  Countryside
                </span>
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="2"
                checked={props.area === "2"}
                onClick={(e) => btClicked(e)}
              />
              <div className="front-end box myboxes2 myboxes">
                <img
                  style={{ marginTop: -15 }}
                  className="activity-image"
                  src={City}
                />
                <span style={{ fontFamily: "Poppins", fontSize: 16 }}>
                  City
                </span>
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="3"
                checked={props.area == "3"}
                onClick={(e) => btClicked(e)}
              />
              <div className="front-end box myboxes2 myboxes">
                <img
                  style={{ marginTop: -15 }}
                  className="activity-image"
                  src={Suburbs}
                />
                <span style={{ fontFamily: "Poppins", fontSize: 16 }}>
                  Suburbs
                </span>
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="4"
                checked={props.area === "4"}
                onClick={(e) => btClicked(e)}
              />
              <div className="front-end box myboxes2 myboxes">
                <img
                  style={{ marginTop: -9 }}
                  className="activity-image"
                  src={NotSure}
                />
                <span style={{ fontFamily: "Poppins", fontSize: 16 }}>
                  Not Sure
                </span>
              </div>
            </label>
          </div>
          <div
            style={{
              borderBottom: "3px solid #FCC9BE",
              marginRight: "auto",
              color: "#1F294E",
              fontFamily: "Nunito",
              marginLeft: "auto",
              display: "block",
              width: 75,
              paddingBottom: 6,
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={props.onBackClick}
          >
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <a className="backbtn">Back</a>
          </div>
        </div>
      </div>

      {/* Mobile view start here */}

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>
          <div className="heigh">
            <h2
              style={{
                color: "#1F294E",
                textAlign: "center",
                fontSize: "18px",
                fontFamily: "Nunito",
                fontWeight: 700,
              }}
            >
              What kind of area do they live in?
            </h2>
          </div>
          <div className="mycol2">
            <label>
              <input
                type="checkbox"
                name="male"
                value="2"
                checked={props.area === "2"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes">
                <img
                  style={{ marginTop: -15 }}
                  className="activity-image resize"
                  src={City}
                />
                <p className="th-step-multi">City</p>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="male"
                value="3"
                checked={props.area == "3"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes">
                <img
                  style={{ marginTop: -15 }}
                  className="activity-image resize"
                  src={Suburbs}
                />
                <p className="th-step-multi">Suburbs</p>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Friend"
                value="1"
                checked={props.area == "1"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes">
                <img
                  style={{ marginTop: 0 }}
                  className="activity-image resize"
                  src={Countryside}
                />
                <p className="th-step-multi">Countryside</p>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="male"
                value="4"
                checked={props.area === "4"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes">
                <img
                  style={{ marginTop: -9 }}
                  className="activity-image resize"
                  src={NotSure}
                />
                <p className="th-step-multi">Not Sure</p>
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateFourteen;
