import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import cardimg1 from "./assets/images/cardImg1.svg";
import x from "./assets/images/x.svg";
import survayLeft from "./assets/images/survayLeft.svg";
import survayRight from "./assets/images/survayRight.svg";
import cardimg2 from "./assets/images/cardImg2.png";
import cardimg3 from "./assets/images/cardImg3.png";
import cardimg4 from "./assets/images/cardImg4.svg";
import cardimg5 from "./assets/images/cardImg5.svg";
import leftIco from "./assets/images/left_ico.png";
import rightIco from "./assets/images/right_ico.png";
import arrowImg from "./assets/images/Trazado.png";
import imgleft from "./assets/images/Grupo_858.png";
import cardimg from "./assets/images/Grupo_862.png";
import cardimg22 from "./assets/images/imageAbout.svg";
import productlogo from "./assets/images/LOGOS.png";
import BuiltInFuturelogo1 from "./assets/images/s.png";
import BuiltInFuturelogo from "./assets/images/f.png";
import upperImgHome from "./assets/images/upperImgHome.svg";
import imgright from "./assets/images/Grupo_857.png";
import whitedivi from "./assets/images/Grupo_1221.png";
import colordivi from "./assets/images/Grupo_7606.png";
import imgbottom from "./assets/images/Grupo_1230.png";
import currentimg from "./assets/images/Grupo_7607.png";
import waves from "./assets/images/waves.png";
import starimg from "./assets/images/Grupo_870.png";
import thisill from "./assets/images/thisillu.png";
import Illufirst from "./assets/images/flirt.png";
import thirdone from "./assets/images/thirdone.png";
import Footer from "./Footer/Footer";
import countingLogo from "./assets/images/Grupo7605.png";
import QuickSurvey from "./quickSurvey";
import Typical from "react-typical";
import Navbar from "./Navbar/Navbar";
import starsbg from "./assets/images/starsbg.svg";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
//
import leftImg from "./assets/images/left-char.png";
import centerImg from "./assets/images/circle_center.png";
import rightImg from "./assets/images/new-right-chah.png";
import stars from "./assets/images/Stars1.png";
import stars2 from "./assets/images/stars2.png";
import stars3 from "./assets/images/Grupo_706.svg";
import { QuestionAnalytics } from "./Services/ServicePhase2";
import purple from "./assets/images/homepurple.png";
import pinkwave from "./assets/images/pinkwave.png";
import bluewave from "./assets/images/bluewave.png";
import { getBrands } from "./Dashboard/Services/Services";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.css";
import { pageView } from "../ga4Service";

function Home(props) {
  useEffect(() => {
    pageView("/");
  }, []);

  const [showResults, setShowResults] = useState(false);
  const [survey, setSurvey] = useState(0);
  const [ageNew, setAgeNew] = useState("");
  const [Hide, setHide] = useState(true);
  const [logo, setlogo] = useState();
  let history = useHistory();

  let first =
    "“Outdone has a clean UI to contrast the messy gift search experience. Gift givers answer a few questions about the person they’re shopping for and Outdone recommends gifts that their Ai engine identifies as a great match.” — Product Hunt";
  let secound =
    "“All a user has to do is plug in some basic information about the person they want to give a gift to, and Outdone does the rest.” — Built In";
  let third =
    "“Outdone has built an Ai-powered recommendation engine that identifies gifts to match any giftgetter’s unique style and personality. All a shopper has to do is answer a few short  questions about the person they’re shopping for. — Storied";
  // let four = "Four Decided to test out the gift recommender for my sister for Xmas and landed on the Minipink website.NowI’m basically…"
  // let fifth = "Receive recommendations from our AI - powered engine We’ll recommend gifts from brands that are perfectly suited for your giftgetter"

  const [firstStateText, setFirstStateText] = useState(first);

  const OnLogoPressed = () => {
    setFirstStateText(first);
  };
  const OnLogoPressedTwo = () => {
    setFirstStateText(secound);
  };
  const OnLogoPressedThree = () => {
    setFirstStateText(third);
  };

  useEffect(() => {
    $(".carousel .carousel-item").each(function () {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next.children(":first-child").clone().appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next.children(":first-child").clone().appendTo($(this));
      }
    });

    localStorage.removeItem("age");

    getBrands().then((result) => {
      let responseJSON = result;

      setlogo(responseJSON.data);
    });

    $("a[href^='#']").click(function (e) {
      e.preventDefault();

      var position = $($(this).attr("href")).offset().top;

      $("body, html").animate(
        {
          scrollTop: position,
        } /* speed */
      );
    });
  }, []);

  const getValue = (e) => {
    const aa = e.target.value;
    setAgeNew(aa);
    localStorage.setItem("age", aa);
  };

  (function ($) {
    $.fn.inputFilter = function (inputFilter) {
      return this.on(
        "input keydown keyup mousedown mouseup select contextmenu drop",
        function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        }
      );
    };
  })(jQuery);

  jQuery("#age-gess").inputFilter(function (value) {
    return value === "" || (parseInt(value) != 0 && parseInt(value) <= 109);
  });

  $(document).ready(function () {
    $("#age-gess").keypress(function (e) {
      if (e.key === "Enter") {
        setSurvey(1);
        setShowResults(true);
      }
      var keyCode = e.keyCode || e.which;
      var regex = /^[0-9]+$/;
      var isValid = regex.test(String.fromCharCode(keyCode));
      return isValid;
    });
  });

  (function ($) {
    $.fn.inputFilter = function (inputFilter) {
      return this.on(
        "input keydown keyup mousedown mouseup select contextmenu drop",
        function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        }
      );
    };
  })(jQuery);
  jQuery("input#age-gess-2").inputFilter(function (value) {
    return (
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value) != 0 && parseInt(value) <= 109))
    );
  });

  const divClicked = () => {
    setHide(false);
  };

  const showSurveyTwo = () => {
    if (ageNew != "") {
      let title = document.getElementById("age-question").textContent;
      let answer = ageNew;
      QuestionAnalytics(title, answer);
      setSurvey(1);
      setShowResults(true);
      localStorage.setItem("age", ageNew);
      localStorage.setItem("homeentry", 1);
      history.push("/survey");
    } else {
      setSurvey(1);
      setShowResults(true);

      history.push("/survey");
    }
  };

  const handleEnterkey = (e) => {
    if (e.key === "Enter") {
      showSurveyTwo();
    }
  };

  const hideSurvey = () => {
    localStorage.removeItem("age");
    setSurvey(0);
    setHide(true);
    setShowResults(false);
  };

  const Results = () => (
    <div id="results" className="search-results">
      <div style={{ display: "flex", width: "100%" }}>
        <img
          src={survayLeft}
          style={{
            top: "15%",
            width: "240px",
            zIndex: 9,
            position: "absolute",
          }}
        />
        <div style={{ padding: 50, width: "100%" }}>
          <img
            style={{
              width: "15px",
              position: "absolute",
              top: "10%",
              left: "10%",
              cursor: "pointer",
            }}
            onClick={hideSurvey}
            className="circle-bar-custom"
            src={x}
          />
          <QuickSurvey hidden={hideSurvey} />
        </div>
        <img
          src={survayRight}
          style={{
            top: "15%",
            width: "240px",
            zIndex: 9,
            right: 0,
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
  const scrollToTop = () => {
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <MetaTags>
        <meta
          id="meta-description"
          name="description"
          content="Outdone's Ai-powered gift recommender makes it easy to shop for special occasions. Tell us a bit about who you’re shopping for and we’ll handle the rest!"
        />
      </MetaTags>
      {!showResults ? <Navbar state={showSurveyTwo} upperBar={true} /> : null}
      {showResults ? <Results /> : null}
      {survey === 0 && (
        <>
          <div className="onSurvey">
            <section className="home-screen ">
              <section
                className="home-top-banner"
                style={{ position: "relative" }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: 15,
                    height: "37%",
                    left: 30,
                    right: 0,
                    width: "-webkit-fill-available",
                  }}
                  src={upperImgHome}
                />
                <div className="container-fluid">
                  <div className="row" style={{ paddingTop: 60 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        style={{
                          height: "60%",
                          zIndex: "99",
                          marginRight: "-123px",
                        }}
                        src={leftImg}
                      />
                      <img
                        className="whitecircle"
                        style={{ height: "59%" }}
                        src={centerImg}
                      />
                      <div
                        style={{
                          position: "absolute",
                          paddingTop: 66,
                          width: 324,
                          textAlign: "center",
                          paddingLeft: 20,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 30,
                            fontWeight: 800,
                            fontFamily: "Bungee",
                            color: "#1F294E",
                          }}
                        >
                          FIND THE PERFECT GIFT IN A MINUTE OR LESS
                        </p>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          width: 500,
                          paddingTop: 210,
                          textAlign: "center",
                          paddingLeft: 20,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "Nunito",
                          }}
                        >
                          Special occasions are stressful. Outdone’s Ai-powered
                          gift recommender gives you one less thing to worry
                          about.
                        </p>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          paddingTop: 266,
                          width: 400,
                          textAlign: "center",
                          paddingLeft: 20,
                        }}
                      >
                        <p
                          id="age-question"
                          style={{
                            fontSize: 26,
                            fontWeight: 800,
                            color: "#DE5B6F",
                            fontFamily: "Bungee",
                          }}
                        >
                          HOW OLD IS THE PERSON YOU’RE SHOPPING FOR?
                        </p>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          paddingTop: 370,
                          textAlign: "center",
                        }}
                      >
                        <div className="row pl-4">
                          <div className="col-5" style={{ zIndex: 999 }}>
                            {Hide ? (
                              <div
                                className="age-box-wrapper"
                                onClick={divClicked}
                              >
                                <h1
                                  style={{
                                    border: "1px solid #CD6371",
                                    borderRadius: 15,
                                    height: 65,
                                    width: 130,
                                    paddingLeft: 10,
                                  }}
                                  className="int"
                                >
                                  <Typical
                                    steps={[
                                      "16",
                                      2000,
                                      "25",
                                      2000,
                                      "35",
                                      2000,
                                      "18",
                                      2000,
                                      "22",
                                      2000,
                                      "27",
                                      2000,
                                      "32",
                                      2000,
                                      "40",
                                      2000,
                                      "45",
                                      2000,
                                      "60",
                                      2000,
                                      "65",
                                      2000,
                                      "59",
                                      2000,
                                    ]}
                                    loop={Infinity}
                                    wrapper="div"
                                  />
                                </h1>
                              </div>
                            ) : (
                              <input
                                type="text"
                                id="age-gess-2"
                                name="age"
                                className="text-input"
                                onKeyDown={(e) => handleEnterkey(e)}
                                onChange={getValue}
                                autoFocus
                                style={{ border: "1px solid gray " }}
                              />
                            )}
                          </div>
                          <div className="col-7">
                            <button
                              className="continue-button"
                              onClick={showSurveyTwo}
                            >
                              CONTINUE
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          paddingTop: 440,
                          marginLeft: -172,
                          textAlign: "center",
                        }}
                      >
                        <img style={{ height: 55 }} src={arrowImg} />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          paddingTop: 480,
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#1F294E",
                          }}
                        >
                          Type Here
                        </p>
                      </div>
                      <img
                        style={{
                          height: "52%",
                          marginLeft: "-122px",
                          zIndex: 99,
                        }}
                        src={rightImg}
                      />
                    </div>
                  </div>
                </div>
                <div className="container" style={{ marginTop: -300 }}>
                  <div className="row">
                    <div className="col-1">
                      <img style={{ height: "15%" }} src={stars} />
                    </div>
                    <div className="col-10">
                      <p className="builttext">
                        Built to take The stress out
                        <br />
                        Of gift shopping
                      </p>
                      <p className="backedtext">
                        Backed by real consumer data and our thorough review
                        process, Outdone brings hundreds of the world’s best
                        brands to shoppers’ fingertips in a quick, "easy-to-use"
                        tool for finding great gifts.
                      </p>
                      {/* <img style={{ height: "20%", display: "block", marginLeft: "auto", marginRight: "auto" }} src={group} /> */}
                      <div className="slider">
                        <div className="slide-track">
                          {logo &&
                            logo.map((obj, index) => {
                              return (
                                <div className="slide" key={index}>
                                  <img
                                    className="homeSlider"
                                    src={obj.image_url}
                                    width="250"
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <button onClick={showSurveyTwo} className="newbutton">
                        GET STARTED
                      </button>
                    </div>
                    <div className="col-1">
                      <img style={{ height: "15%" }} src={stars2} />
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="homebanner">
                  <div style={{ maxWidth: 1150, margin: "0 auto" }}>
                    <div className="secondsection">
                      <div className="alignall">
                        <img
                          style={{ height: "90px", width: "60px" }}
                          src={imgleft}
                        />
                        <p className="workstext">HOW IT WORKS</p>
                        <img
                          style={{ height: "90px", width: "60px" }}
                          src={imgright}
                        />
                      </div>
                      <div>
                        <img className="newbottom" src={imgbottom} />
                      </div>
                    </div>
                    <div className="main-sect">
                      <div className="newillu">
                        <img className="illufirt" src={Illufirst} />
                      </div>
                      <div>
                        <p className="zeroone">01</p>
                        <p className="aboutone">
                          Tell Us About
                          <br />
                          Your Giftgetter
                        </p>
                        <p className="weneed">
                          We only need a few things — their age, <br />
                          interests, etc.
                        </p>
                      </div>
                    </div>
                    <div className="main-secti">
                      <div className="textdib">
                        <p className="zerotwo">02</p>
                        <p className="abouttwo">
                          BROWSE recommendations
                          <br />
                          from our AI engine
                        </p>
                        <p className="werecom">
                          We’ll recommend gifts from brands <br />
                          that are perfectly suited for your giftgetter{" "}
                        </p>
                      </div>
                      <div className="newillu">
                        <img className="illufirt" src={thisill} />
                      </div>
                    </div>
                    <div className="main-sect">
                      <div className="newillu">
                        <img className="illufirt" src={thirdone} />
                      </div>
                      <div>
                        <p className="zeroone">03</p>
                        <p className="aboutone">
                          Choose your favorite <br />
                          and shop their site
                        </p>
                        <p className="weneed">
                          We’ll tell you a bit about each brand <br />
                          and product. Just read, click and go purchase!
                        </p>
                      </div>
                    </div>
                    <button onClick={showSurveyTwo} className="newbutton">
                      TRY IT OUT!
                    </button>
                  </div>
                </div>
              </section>

              <section className="pt-5 pb-5">
                <div className="container-fluid">
                  <img className="cardmig" src={cardimg} />

                  <div className="container text-center my-3">
                    <div
                      id="recipeCarousel"
                      className="carousel slide w-100"
                      data-ride="carousel"
                      data-type="multi"
                      data-interval="false"
                    >
                      <div className="carousel-inner w-100" role="listbox">
                        <div
                          className="carousel-item active"
                          style={{ marginTop: 70 }}
                        >
                          <div className="col-md-4">
                            <div
                              style={{ height: 420, boxShadow: "none" }}
                              className="card"
                            >
                              <div className="newCountlogo">
                                <img
                                  className="newCountimg"
                                  src={countingLogo}
                                />
                              </div>
                              <img
                                className="card-img-top mt-5 small-s "
                                src={cardimg1}
                                alt="Card image cap"
                              />
                              <div className="card-body">
                                <p className="card-text pb-3">
                                  I used Outdone to find a gift for my sister
                                  and discovered MinkPink. Now I’m basically
                                  buying myself the entire website for both of
                                  us!
                                </p>
                                <div
                                  className="pb-3"
                                  style={{
                                    bottom: 0,
                                    position: "absolute",
                                    width: "90%",
                                  }}
                                >
                                  <p className="karben">Meghan Sullivan</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item"
                          style={{ marginTop: 70 }}
                        >
                          <div className="col-md-4">
                            <div style={{ height: 420 }} className="card">
                              <div className="newCountlogo">
                                <img
                                  className="newCountimg"
                                  src={countingLogo}
                                />
                              </div>
                              <img
                                className="card-img-top mt-5 small-s "
                                src={cardimg2}
                                alt="Card image cap"
                              />
                              <div className="card-body">
                                <p className="card-text pb-3">
                                  Amazing tool for a problem that everybody runs
                                  into. It’s easy to use and delivers great
                                  results no matter who you’re shopping for!
                                </p>
                                <div
                                  className="pb-3"
                                  style={{
                                    bottom: 0,
                                    position: "absolute",
                                    width: "90%",
                                  }}
                                >
                                  <p className="karben">Andres Sanchez</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item"
                          style={{ marginTop: 70 }}
                        >
                          <div className="col-md-4">
                            <div style={{ height: 420 }} className="card">
                              <div className="newCountlogo">
                                <img
                                  className="newCountimg"
                                  src={countingLogo}
                                />
                              </div>
                              <img
                                className="card-img-top mt-5 small-s "
                                src={cardimg4}
                                alt="Card image cap"
                              />
                              <div className="card-body">
                                <p className="card-text pb-3">
                                  I love Outdone! Just don’t tell my wife I used
                                  it so last minute 😅
                                </p>
                                <div
                                  className="pb-3"
                                  style={{
                                    bottom: 0,
                                    position: "absolute",
                                    width: "90%",
                                  }}
                                >
                                  <p className="karben">Victor Matus </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item"
                          style={{ marginTop: 70 }}
                        >
                          <div className="col-md-4">
                            <div style={{ height: 420 }} className="card">
                              <div className="newCountlogo">
                                <img
                                  className="newCountimg"
                                  src={countingLogo}
                                />
                              </div>
                              <img
                                className="card-img-top mt-5 small-s "
                                src={cardimg3}
                                alt="Card image cap"
                              />
                              <div className="card-body">
                                <p className="card-text pb-3">
                                  Outdone is an incredible solution for such a
                                  stressful task. I love using this for my
                                  notoriously difficult to shop for in-laws.
                                </p>
                                <div
                                  className="pb-3"
                                  style={{
                                    bottom: 0,
                                    position: "absolute",
                                    width: "90%",
                                  }}
                                >
                                  <p className="karben">Alexa Drake</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item"
                          style={{ marginTop: 70 }}
                        >
                          <div className="col-md-4">
                            <div className="card" style={{ height: 420 }}>
                              <div className="newCountlogo">
                                <img
                                  className="newCountimg"
                                  src={countingLogo}
                                />
                              </div>
                              <img
                                className="card-img-top mt-5 small-s "
                                src={cardimg5}
                                alt="Card image cap"
                              />
                              <div className="card-body">
                                <p className="card-text pb-3">
                                  I just used Outdone for my husband, Mike. Its
                                  first choice was johnnie-O, which blows my
                                  mind. This brand screams him — they should be
                                  called Mikey-O!!
                                </p>
                                <div
                                  className="pb-3"
                                  style={{
                                    bottom: 0,
                                    position: "absolute",
                                    width: "90%",
                                  }}
                                >
                                  <p className="karben">Austin O’Brien</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginLeft: -45, marginTop: 60 }}
                        className="carousel-control-prev w-auto"
                        href="#recipeCarousel"
                        role="button"
                        data-slide="prev"
                      >
                        <img src={leftIco} />
                      </div>
                      <div
                        style={{ marginRight: -45, marginTop: 60 }}
                        className="carousel-control-next w-auto"
                        href="#recipeCarousel"
                        role="button"
                        data-slide="next"
                      >
                        <img src={rightIco} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <img className="cardamig" src={cardimg22} />
              <section>
                <div className="newdivv">
                  <img className="whitedivi" src={whitedivi} />
                  <div className="wholedivi">
                    <div>
                      <img className="starimg" src={starimg} />
                    </div>
                    <div>
                      <p className="feat">AS FEATURED IN…</p>
                      <p className="vivamus">
                        <p
                          style={{
                            width: 1000,
                            fontStyle: "italic",
                            fontSize: 18,
                          }}
                        >
                          {firstStateText.split("—")[0]}
                          <span
                            style={{ fontStyle: "normal", fontWeight: 600 }}
                          >
                            {" "}
                            — {firstStateText.split("—")[1]}
                          </span>
                        </p>
                      </p>
                    </div>
                    <div>
                      <img className="currentimg" src={currentimg} />
                    </div>
                  </div>
                  <div className="prelogo">
                    <div onClick={OnLogoPressed}>
                      <img
                        style={{
                          cursor: "pointer",
                          filter:
                            firstStateText != first
                              ? "grayscale(1)"
                              : "inherit",
                        }}
                        className="prologo"
                        src={productlogo}
                      />
                    </div>
                    <div onClick={OnLogoPressedTwo}>
                      <img
                        style={{
                          cursor: "pointer",
                          filter:
                            firstStateText != secound
                              ? "grayscale(1)"
                              : "inherit",
                        }}
                        className="prologo"
                        src={BuiltInFuturelogo}
                      />
                    </div>
                    <div onClick={OnLogoPressedThree}>
                      <img
                        style={{
                          cursor: "pointer",
                          filter:
                            firstStateText != third
                              ? "grayscale(1)"
                              : "inherit",
                          marginLeft: 80,
                        }}
                        className="prologo2"
                        src={BuiltInFuturelogo1}
                      />
                    </div>
                  </div>
                </div>
                <img className="colordivi" src={colordivi} />
              </section>

              {/* <NewsLetter /> */}
              <section>
                <div className="backcolor">
                  <img className="countlogo" src={countingLogo} />
                  <p className="stree">DITCH The GIFT RECEIPT</p>
                  <img className="logocount" src={countingLogo} />

                  <button onClick={scrollToTop} className="backwork">
                    BACK TO TOP
                  </button>
                </div>
              </section>
            </section>
            {/* ==================== Mobile Layout End ============================== */}
            <Footer />
          </div>

          <div className="onSurveymobile">
            <div className="mobilesectionone">
              <img src={purple} className="purpleback" />
              <img src={starsbg} className="bgstars" />
              <img
                className="whitecircle"
                style={{ height: "59%" }}
                src={centerImg}
              />
              <img
                className="whitecircle"
                style={{ height: "59%" }}
                src={centerImg}
              />
              <div className="para1">
                <p>FIND THE PERFECT GIFT IN A MINUTE OR LESS</p>
              </div>
              <div className="para2">
                How old is the person you’re shopping for?
              </div>
              <div className="inputdiv">
                <div
                  className="review"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: 15,
                  }}
                >
                  {Hide ? (
                    <div className="age-box-wrapper" onClick={divClicked}>
                      <h1
                        style={{
                          border: "2px solid #CD6371",
                          borderRadius: 15,
                          height: 49,
                          width: 102,
                          paddingLeft: 10,
                        }}
                        className="int"
                      >
                        <Typical
                          steps={[
                            "16",
                            2000,
                            "25",
                            2000,
                            "35",
                            2000,
                            "18",
                            2000,
                            "22",
                            2000,
                            "27",
                            2000,
                            "32",
                            2000,
                            "40",
                            2000,
                            "45",
                            2000,
                            "60",
                            2000,
                            "65",
                            2000,
                            "59",
                            2000,
                          ]}
                          loop={Infinity}
                          wrapper="div"
                        />
                      </h1>
                    </div>
                  ) : (
                    <input
                      type="text"
                      id="age-gess-2"
                      name="age"
                      className="text-input"
                      onKeyDown={(e) => handleEnterkey(e)}
                      onChange={getValue}
                      autoFocus
                      style={{ border: "2px solid gray !important " }}
                    />
                  )}

                  <button className="continue-button" onClick={showSurveyTwo}>
                    CONTINUE
                  </button>
                </div>

                <img
                  className="rightimg"
                  style={{ height: "250px", marginLeft: "-122px", zIndex: 99 }}
                  src={rightImg}
                />
                <img
                  className="leftimg"
                  style={{
                    height: "250px",
                    zIndex: "99",
                    marginRight: "-123px",
                  }}
                  src={leftImg}
                />
              </div>
            </div>

            <div className="middlesection">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <div className="col-xs-2">
                  <img className="stars" src={stars} style={{ right: 10 }} />
                </div>
                <div className="col-xs-10">
                  {" "}
                  <p className="built">
                    Built to take the stress
                    <br /> out of gift shopping
                  </p>
                </div>
                <div className="col-xs-2">
                  <img className="stars" src={stars2} style={{ left: 10 }} />
                </div>
              </div>

              <div>
                <img className="newbottommobile" src={stars3} />
              </div>

              <div className="para3">
                <p>
                  Backed by real consumer data and our thorough review processs,
                  Outdone brings hundreds of the world’s best brands to your
                  fingertips in a quick, easy to use tool.
                </p>
                <button onClick={showSurveyTwo} className="newbuttonmobiletry">
                  TRY IT OUT!
                </button>
              </div>
            </div>
            <div className="mobilesectiontwo">
              <div className="secondsection">
                <div className="alignall">
                  <img style={{ height: "40px" }} src={imgleft} />
                  <p className="workstextmobile">HOW IT WORKS</p>
                  <img style={{ height: "40px" }} src={imgright} />
                </div>
                <img className="bottomimage" src={stars3} />
              </div>

              <div className="main-sectmobile mt-4">
                <div className="row">
                  <div className="col-6">
                    <div className="newillu2">
                      <img className="illufirtmobile" src={Illufirst} />
                    </div>
                  </div>
                  <div className="col-6">
                    <p className="zeroonemobile">01</p>
                    <p className="aboutonemobile">
                      Tell Us About
                      <br />
                      Your Giftgetter
                    </p>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6 p-0">
                    {" "}
                    <p style={{ textAlign: "right" }} className="zeroonemobile">
                      02
                    </p>
                    <p className="aboutonemobile">
                      BROWSE OUR AI-ENGINE
                      <br />
                      RECOMMENDATIONS
                    </p>{" "}
                  </div>
                  <div className="col-6">
                    <div className="newillu2">
                      <img className="illufirtmobile" src={thisill} />
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    <div className="newillu2">
                      {" "}
                      <img className="illufirtmobile" src={thirdone} />
                    </div>
                  </div>
                  <div className="col-6">
                    {" "}
                    <p className="zeroonemobile">03</p>
                    <p className="aboutonemobile">
                      Choose your favorite
                      <br />
                      and shop their site
                    </p>{" "}
                  </div>
                  <button
                    onClick={showSurveyTwo}
                    className="newbuttonmobiletry"
                  >
                    TRY IT OUT!
                  </button>
                </div>
              </div>
            </div>

            <div className="mobilesectionthree">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: 40,
                }}
              >
                <div className="col-xs-2">
                  <img className="stars" src={stars} />
                </div>
                <div className="col-xs-10">
                  {" "}
                  <p className="built">Our Partner Brands</p>
                </div>
                <div className="col-xs-2">
                  <img className="stars" src={stars2} />
                </div>
              </div>

              <div className="brandcol mt-3">
                {logo &&
                  logo.map((obj, index) => {
                    return (
                      <div className="img" key={index}>
                        <img className="" width="82" src={obj.image_url} />
                      </div>
                    );
                  })}
              </div>

              <div>
                {" "}
                <div className="brandcol2 mt-3">
                  {logo &&
                    logo.map((obj, index) => {
                      return (
                        <div className="img" key={index}>
                          <img className="" width="82" src={obj.image_url} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="mobilesectionfour">
              <img src={waves} className="waves" />
              <div className="row">
                <div className="col-2">
                  {" "}
                  <img className="cardmigmobile" src={cardimg} />
                </div>

                <div
                  className="col-8"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper2"
                  >
                    <SwiperSlide>
                      <div className="card">
                        <div className="cardimg">
                          <img
                            className="cardperson"
                            src={cardimg1}
                            alt="Card image cap"
                          />
                        </div>
                        <div className="cardimg">
                          <img src={countingLogo} className="cardline" />
                        </div>
                        <div className="paramar">
                          <p className="cardpara">
                            I used Outdone to find a gift for my sister and
                            discovered MinkPink. Now I’m basically buying myself
                            the entire website for both of us!
                          </p>
                        </div>
                        <p className="pink">Meghan Sullivan</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="card">
                        <div className="cardimg">
                          <img
                            className="cardperson"
                            src={cardimg2}
                            alt="Card image cap"
                          />
                        </div>
                        <div className="cardimg">
                          <img src={countingLogo} className="cardline" />
                        </div>
                        <div className="paramar">
                          <p className="cardpara">
                            Amazing tool for a problem that everybody runs into.
                            It’s easy to use and delivers great results no
                            matter who you’re shopping for!
                          </p>
                        </div>
                        <p className="pink">Andres Sanchez</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="card">
                        <div className="cardimg">
                          <img
                            className="cardperson"
                            src={cardimg4}
                            alt="Card image cap"
                          />
                        </div>
                        <div className="cardimg">
                          <img src={countingLogo} className="cardline" />
                        </div>
                        <div className="paramar">
                          <p className="cardpara">
                            I love Outdone! Just don’t tell my wife I used it so
                            last minute 😅
                          </p>
                        </div>
                        <p className="pink">Victor Matus </p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="card">
                        <div className="cardimg">
                          <img
                            className="cardperson"
                            src={cardimg3}
                            alt="Card image cap"
                          />
                        </div>
                        <div className="cardimg">
                          <img src={countingLogo} className="cardline" />
                        </div>
                        <div className="paramar">
                          <p className="cardpara">
                            Outdone is an incredible solution for such a
                            stressful task. I love using this for my notoriously
                            difficult to shop for in-laws.
                          </p>
                        </div>
                        <p className="pink">Alexa Drake</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="card">
                        <div className="cardimg">
                          <img
                            className="cardperson"
                            src={cardimg5}
                            alt="Card image cap"
                          />
                        </div>
                        <div className="cardimg">
                          <img src={countingLogo} className="cardline" />
                        </div>
                        <div className="paramar">
                          <p className="cardpara">
                            I just used Outdone for my husband, Mike. Its first
                            choice was johnnie-O, which blows my mind. This
                            brand screams him — they should be called Mikey-O!!
                          </p>
                        </div>
                        <p className="pink">Austin O’Brien</p>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="col-2">
                  <img
                    className="cardmigmobile"
                    style={{
                      position: "relative",
                      bottom: "-406px",
                      right: 11,
                    }}
                    src={cardimg22}
                  />
                </div>
              </div>

              <div></div>
            </div>
            <div className="mobilesectionfive">
              <img src={pinkwave} className="waves" />

              <div className="headerdiv">
                <p className="built">AS FEATURED IN…</p>
              </div>

              <div className="prelogomobile">
                <div onClick={OnLogoPressed} className="prologodiv1">
                  <img
                    style={{ cursor: "pointer" }}
                    className="prologo1"
                    src={productlogo}
                  />
                </div>
                <div onClick={OnLogoPressedTwo} className="prologodiv2">
                  <img
                    style={{ cursor: "pointer" }}
                    className="prologo2"
                    src={BuiltInFuturelogo}
                  />
                </div>
                <div onClick={OnLogoPressedThree} className="prologodiv3">
                  <img
                    style={{ cursor: "pointer" }}
                    className="prologo3"
                    src={BuiltInFuturelogo1}
                  />
                </div>
              </div>
            </div>
            <img src={bluewave} className="waves" />

            <Footer />
          </div>

          <button className="subscribe" onClick={() => window.subscribe()}>
            SUBSCRIBE
          </button>
        </>
      )}
    </>
  );
}

export default Home;
