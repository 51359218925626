import Cookies from "js-cookie";

const BaseUrl = "https://api.outdone.io/";

export function Adminlogin(email, password) {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/login", {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function SendEmail(email) {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/save_email", {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateBrand(
  id,
  name,
  year_founded,
  headquarters,
  description,
  brand_category_tag1,
  brand_category_tag2,
  brand_category_tag3,
  image
) {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/update_brand", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        name: name,
        year_founded: year_founded,
        headquarters: headquarters,
        description: description,
        brand_category_tag1: brand_category_tag1,
        brand_category_tag2: brand_category_tag2,
        brand_category_tag3: brand_category_tag3,
        image: image,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function SendzResponce(res2) {
  // Values of Survey

  var ageValue = localStorage.getItem("age");
  var iAge = parseInt(ageValue);

  var genderValue = localStorage.getItem("gender");
  var iGender = parseInt(genderValue);

  var shopingforValue = localStorage.getItem("shopingfor");
  var ishopingfor = parseInt(shopingforValue);

  var cookvalue = localStorage.getItem("Cook");
  var icookvalue = parseInt(cookvalue);

  var alcoholvalue = localStorage.getItem("Alcohol");
  var ialcoholvalue = parseInt(alcoholvalue);

  var coffeevalue = localStorage.getItem("Coffee");
  var icoffeevalue = parseInt(coffeevalue);

  var Photovalue = localStorage.getItem("Photo");
  var iPhotovalue = parseInt(Photovalue);

  var Readvalue = localStorage.getItem("Read");
  var iReadvalue = parseInt(Readvalue);

  var Puzzlegamevalue = localStorage.getItem("Puzzlegame");
  var iPuzzlegamevalue = parseInt(Puzzlegamevalue);

  var Gardenvalue = localStorage.getItem("Garden");
  var iGardenvalue = parseInt(Gardenvalue);

  var Videogamevalue = localStorage.getItem("Videogame");
  var iVideogamevalue = parseInt(Videogamevalue);

  var Bikingvalue = localStorage.getItem("Biking");
  var iBikingvalue = parseInt(Bikingvalue);

  var Yogavalue = localStorage.getItem("Yoga");
  var iYogavalue = parseInt(Yogavalue);

  var Golfingvalue = localStorage.getItem("Golfing");
  var iGolfingvalue = parseInt(Golfingvalue);

  var liftingvalue = localStorage.getItem("lifting");
  var iliftingvalue = parseInt(liftingvalue);

  var Runningvalue = localStorage.getItem("Running");
  var iRunningvalue = parseInt(Runningvalue);

  var Swimmingvalue = localStorage.getItem("Swimming");
  var iSwimmingvalue = parseInt(Swimmingvalue);

  var Skiingvalue = localStorage.getItem("Skiing");
  var iSkiingvalue = parseInt(Skiingvalue);

  var snowboardvalue = localStorage.getItem("snowboard");
  var isnowboardvalue = parseInt(snowboardvalue);

  var Campingvalue = localStorage.getItem("Camping");
  var iCampingvalue = parseInt(Campingvalue);

  var Hikingvalue = localStorage.getItem("Hiking");
  var iHikingvalue = parseInt(Hikingvalue);

  var Fishingvalue = localStorage.getItem("Fishing");
  var iFishingvalue = parseInt(Fishingvalue);

  var Huntingvalue = localStorage.getItem("Hunting");
  var iHuntingvalue = parseInt(Huntingvalue);

  var Beervalue = localStorage.getItem("Beer");
  var iBeervalue = parseInt(Beervalue);

  var Candyvalue = localStorage.getItem("Candy");
  var iCandyvalue = parseInt(Candyvalue);

  var Coffeevalue = localStorage.getItem("Coffee");
  var iCoffeevalue = parseInt(Coffeevalue);

  var Winevalue = localStorage.getItem("Wine");
  var iWinevalue = parseInt(Winevalue);

  var Pastavalue = localStorage.getItem("Pasta");
  var iPastavalue = parseInt(Pastavalue);

  var Pizzavalue = localStorage.getItem("Pizza");
  var iPizzavalue = parseInt(Pizzavalue);

  var Teavalue = localStorage.getItem("Tea");
  var iTeavalue = parseInt(Teavalue);

  var Sportyvalue = localStorage.getItem("Sporty");
  var iSportyvalue = parseInt(Sportyvalue);

  var Longwearvalue = localStorage.getItem("Longwear");
  var iLongwearvalue = parseInt(Longwearvalue);

  var OutDoorsyvalue = localStorage.getItem("OutDoorsy");
  var iOutDoorsyvalue = parseInt(OutDoorsyvalue);

  var Streetwearvalue = localStorage.getItem("Streetwear");
  var iStreetwearvalue = parseInt(Streetwearvalue);

  var barParty = localStorage.getItem("bearParty");
  var iParty = parseInt(barParty);

  var areaLive = localStorage.getItem("Area");
  var iArea = parseInt(areaLive);

  var Airport = localStorage.getItem("Airport");
  var iAirport = parseInt(Airport);

  var activity = localStorage.getItem("activity");
  var iActivity = parseInt(activity);

  var beachParty = localStorage.getItem("beachParty");
  var ibeachParty = parseInt(beachParty);

  var lakeCheck = localStorage.getItem("lakeCheck");
  var ilakeCheck = parseInt(lakeCheck);

  var homeCheck = localStorage.getItem("homeCheck");
  var ihomeCheck = parseInt(homeCheck);

  var careCheck = localStorage.getItem("careCheck");
  var icareCheck = parseInt(careCheck);

  var ukState = localStorage.getItem("State");
  var iState = parseInt(ukState);

  var Weightlifting = localStorage.getItem("Weightlifting");
  var iWeightlifting = parseInt(Weightlifting);

  var Running = localStorage.getItem("Running");
  var iRunning = parseInt(Running);
  var golf = localStorage.getItem("golf");
  var igolf = parseInt(golf);

  var skateboarding = localStorage.getItem("skateboarding");
  var iskateboarding = parseInt(skateboarding);

  var sailing = localStorage.getItem("sailing");
  var isailing = parseInt(sailing);

  var hunting = localStorage.getItem("hunting");
  var ihunting = parseInt(hunting);

  var surfing = localStorage.getItem("surfing");
  var isurfing = parseInt(surfing);

  var skiing = localStorage.getItem("skiing");
  var iskiing = parseInt(skiing);

  var hiking = localStorage.getItem("hiking");
  var ihiking = parseInt(hiking);

  var yoga = localStorage.getItem("yoga");
  var iyoga = parseInt(yoga);

  var Concertsvalue = localStorage.getItem("Concerts");
  var iConcertsvalue = parseInt(Concertsvalue);

  var FemaleLedvalue = localStorage.getItem("FemaleLed");
  var iFemaleLedvalue = parseInt(FemaleLedvalue);

  var MadeinUSAvalue = localStorage.getItem("MadeinUSA");
  var iMadeinUSAvalue = parseInt(MadeinUSAvalue);

  var Sustainablevalue = localStorage.getItem("Sustainable");
  var iSustainablevalue = parseInt(Sustainablevalue);

  var Charitablevalue = localStorage.getItem("Charitable");
  var iCharitablevalue = parseInt(Charitablevalue);

  var jsonResponce = res2;

  var values = {};

  values["AGE (0-65)"] = iAge ? iAge : 25;
  values["STATE: District of Columbia + 50 US states (0-50)"] = iState
    ? iState
    : 25;
  values["LIVING (1 = Rural , 2 = Suburban , 3 = Urban , 4 = Not sure)"] = iArea
    ? iArea
    : 1;
  values[
    "ACTIVITIES_A_1: Do you regularly Cook and/or bake in your free time? (0: No , 1: yes)"
  ] = icookvalue ? icookvalue : 0;
  values[
    "ACTIVITIES_A_3: Do you regularly Drink alcohol in your free time? (0: No , 1: yes)"
  ] = ialcoholvalue ? ialcoholvalue : 0;
  values[
    "ACTIVITIES_A_5: Do you regularly Make coffee in your free time? (0: No , 1: yes)"
  ] = icoffeevalue ? icoffeevalue : 0;
  values[
    "ACTIVITIES_A_7: Do you regularly do Gardening in your free time? (0: No , 1: yes)"
  ] = iGardenvalue ? iGardenvalue : 0;
  values[
    "ACTIVITIES_A_9: Do you regularly Play board games, do puzzles, etc. in your free time? (0: No , 1: yes)"
  ] = iPuzzlegamevalue ? iPuzzlegamevalue : 0;
  values[
    "ACTIVITIES_A_10: Do you regularly Play video games in your free time? (0: No , 1: yes)"
  ] = iVideogamevalue ? iVideogamevalue : 0;
  values[
    "ACTIVITIES_A_11: Do you regularly Read for pleasure in your free time? (0: No , 1: yes)"
  ] = iReadvalue ? iReadvalue : 0;
  values[
    "ACTIVITIES_A_12: Do you regularly Take photographs in your free time? (0: No , 1: yes)"
  ] = iPhotovalue ? iPhotovalue : 0;
  values[
    "ACTIVITIES_A_13: Do you regularly travel by plane? (0: No , 1: yes)"
  ] = iAirport ? iAirport : 0;
  values[
    "ACTIVITIES_B_18: Do you regularly ride a bike in your free time? (0: No , 1: yes)"
  ] = iBikingvalue ? iBikingvalue : 0;
  values[
    "ACTIVITIES_B_19: Do you regularly Camp and/or hike in your free time? (0: No , 1: yes)"
  ] = iHikingvalue ? iHikingvalue : 0;
  values[
    "ACTIVITIES_B_20: Do you regularly Do yoga, pilates, barre, etc. in your free time? (0: No , 1: yes)"
  ] = iYogavalue ? iYogavalue : 0;
  values[
    "ACTIVITIES_B_21: Do you regularly Go to bars and/or clubs in your free time? (0: No , 1: yes)"
  ] = ialcoholvalue ? ialcoholvalue : 0;
  values[
    "ACTIVITIES_B_22: Do you regularly Go to live shows and/or concerts in your free time? (0: No , 1: yes)"
  ] = iConcertsvalue ? iConcertsvalue : 0;
  values[
    "ACTIVITIES_B_23: Do you regularly play Golf in your free time? (0: No , 1: yes)"
  ] = igolf ? igolf : 0;
  values[
    "ACTIVITIES_B_24: Do you regularly Hunt and/or fish in your free time? (0: No , 1: yes)"
  ] = iFishingvalue ? iFishingvalue : 0;

  values[
    "ACTIVITIES_B_25: Do you regularly Lift weights in your free time? (0: No , 1: yes)"
  ] = iWeightlifting ? iWeightlifting : 0;
  values[
    "ACTIVITIES_B_28: Do you regularly Run in your free time? (0: No , 1: yes)"
  ] = iRunningvalue ? iRunningvalue : 0;
  values[
    "ACTIVITIES_B_31: Do you regularly Ski and/or snowboard in your free time? (0: No , 1: yes)"
  ] = iSkiingvalue ? iSkiingvalue : 0;
  values[
    "ACTIVITIES_B_32: Do you regularly Spend time by the ocean in your free time? (0: No , 1: yes)"
  ] = 1;
  values[
    "ACTIVITIES_B_33: Do you regularly Swim in your free time? (0: No , 1: yes)"
  ] = iSwimmingvalue ? iSwimmingvalue : 0;

  values["CONSUMPTION_2: Do Like Beer? (0: No , 1: yes)"] = iBeervalue
    ? iBeervalue
    : 0;
  values["CONSUMPTION_3: Do Like Candy? (0: No , 1: yes)"] = iCandyvalue
    ? iCandyvalue
    : 0;
  values["CONSUMPTION_4: Do Like Coffee? (0: No , 1: yes)"] = icoffeevalue
    ? icoffeevalue
    : 0;
  values["CONSUMPTION_5: Do Like Pasta? (0: No , 1: yes)"] = iPastavalue
    ? iPastavalue
    : 0;
  values["CONSUMPTION_6: Do Like Pizza? (0: No , 1: yes)"] = iPizzavalue
    ? iPizzavalue
    : 0;
  values["CONSUMPTION_7: Do Like Tea? (0: No , 1: yes)"] = iTeavalue
    ? iTeavalue
    : 0;
  values["CONSUMPTION_8: Do Like Wine? (0: No , 1: yes)"] = iWinevalue
    ? iWinevalue
    : 0;

  values[
    "CLOTHING_STYLE_1: Do you think your clothing style is Athletic/Sporty? (0: No , 1: yes)"
  ] = iSportyvalue ? iSportyvalue : 0;
  values[
    "CLOTHING_STYLE_4: Do you think your clothing style is Loungewear / Leisure? (0: No , 1: yes)"
  ] = iLongwearvalue ? iLongwearvalue : 0;
  values[
    "CLOTHING_STYLE_6: Do you think your clothing style is Outdoorsy/Adventurous? (0: No , 1: yes)"
  ] = iOutDoorsyvalue ? iOutDoorsyvalue : 0;
  values[
    "CLOTHING_STYLE_9: Do you think your clothing style is Streetwear? (0: No , 1: yes)"
  ] = iStreetwearvalue ? iStreetwearvalue : 0;

  values[
    "BUSINESS_FACTORS_3: Are you more likely to support Female-owned business? (0: No , 1: yes)"
  ] = iFemaleLedvalue ? iFemaleLedvalue : 0;
  values[
    "BUSINESS_FACTORS_4: Are you more likely to support Black-owned business? (0: No , 1: yes)"
  ] = iMadeinUSAvalue ? iMadeinUSAvalue : 0;
  values[
    "BUSINESS_FACTORS_8: Are you more likely to support Business with environmentally-conscious manufacturing? (0: No , 1: yes)"
  ] = iSustainablevalue ? iSustainablevalue : 0;
  values[
    "BUSINESS_FACTORS_9: Are you more likely to support Businesses that gives back? (0: No , 1: yes)"
  ] = iCharitablevalue ? iCharitablevalue : 0;
  values["GENDER(1 = male, 2 = female, 3 = other)"] = iGender ? iGender : 1;

  const body = values;

  var formdata = new FormData();
  formdata.append("request", JSON.stringify(body));
  formdata.append("response", JSON.stringify(jsonResponce));

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/save_predictions", requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//Data-Download
export function downloadBrans() {
  var starting_date = localStorage.getItem("starting_date");
  var ending_date = localStorage.getItem("ending_date");
  var starting_time = localStorage.getItem("starting_time");
  var ending_time = localStorage.getItem("ending_time");

  return new Promise((resolve, reject) => {
    fetch(
      BaseUrl +
        `api/download_predictions?start_date=${starting_date} ${starting_time}&end_date=${ending_date} ${ending_time}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBrands() {
  const token = localStorage.getItem("admin_token");

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/get_brands", {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getProducts() {
  const aa = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/show_product", {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEmails() {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/get_emails", {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function EditBrand(id) {
  const token = localStorage.getItem("admin_token");
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/edit_brand", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function ProductClicked(brand, product, link) {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/product-clicked", {
      method: "POST",
      body: JSON.stringify({ brand: brand, product: product, url: link }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteBrand(id) {
  const token = localStorage.getItem("admin_token");
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/delete_brand", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteUser(id) {
  const token = localStorage.getItem("admin_token");
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/delete-user", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function offlineBrands(age) {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "api/offline_brand", {
      method: "POST",
      body: JSON.stringify({ age: age }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function isAuthenticate() {
  if (Cookies.get("token")) {
    return true;
  }
  return false;
}

export function logOut() {
  Cookies.remove("token");
  window.location = "/login";
}
