import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import under50 from "../../assets/images/under50.png";
import onefifty from "../../assets/images/150.png";
import over from "../../assets/images/over.png";
import { Link, useHistory } from "react-router-dom";
import Back from "../../assets/images/Backside.png";
import { QuestionAnalytics } from "../../Services/ServicePhase2";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateSixthteen = (props) => {
  useEffect(() => {
    pageView("/surveyQ12");
  });

  const { watch, register } = useForm();
  const ageValue = localStorage.getItem("age");
  const history = useHistory();
  const getResult = () => {
    const x = localStorage.getItem("Spending Amount");
    QuestionAnalytics("How much are you looking to spend?", x);
    history.push("/data");
  };

  const changestorage = (e) => {
    localStorage.setItem("Spending Amount", e.target.name);
  };

  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };
  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className="desktop">
        <p className="paredesc" style={{ top: "9%" }}>
          And we know it’s the thought that counts, but…
        </p>

        <h2 className="Text-header-main">How much are you looking to spend?</h2>
        {/* <p style={{fontStyle : "italic", color : "#1F294E", fontSize : 14, textAlign : "center"}}>Select all that apply, or skip!</p> */}

        <div className="step-1-form middle">
          <div className="myrow">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Matter"
                  value="0"
                  id="Matter"
                  checked={props.lookingspend == 0}
                  onChange={(e) => {
                    props.setlookingspend(e.target.value), changestorage(e);
                  }}
                />
                <div
                  className={`front-end box1 area-icon myboxes ${
                    props.activityValue == "1" ? "background-checked" : ""
                  }`}
                >
                  <div className="passionate-activity">
                    <p
                      style={{
                        marginTop: 30,
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                      className="th-step-multi"
                    >
                      Doesn’t <br /> Matter!
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div
              style={{
                backgroundColor: "#CCE0F4",
                width: 2,
                height: 90,
                margin: 10,
              }}
            ></div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="under"
                  value="1"
                  id="under"
                  checked={props.lookingspend == 1}
                  onChange={(e) => {
                    props.setlookingspend(e.target.value), changestorage(e);
                  }}
                />
                <div
                  className={`front-end box1 area-icon myboxes ${
                    props.activityValue == "1" ? "background-checked" : ""
                  }`}
                >
                  <img
                    style={{ marginTop: 22 }}
                    className="activity-image"
                    src={under50}
                  />
                  <div className="passionate-activity">
                    <p
                      style={{
                        marginTop: 14,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                      className="th-step-multi"
                    >
                      Under $50
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="onefifty"
                  value="2"
                  id="onefifty"
                  checked={props.lookingspend == 2}
                  onChange={(e) => {
                    props.setlookingspend(e.target.value), changestorage(e);
                  }}
                />
                <div
                  className={`front-end box1 area-icon myboxes ${
                    props.activityValue == "1" ? "background-checked" : ""
                  }`}
                >
                  <img
                    style={{ marginTop: 20 }}
                    className="activity-image"
                    src={onefifty}
                  />
                  <div className="passionate-activity">
                    <p
                      style={{
                        marginTop: 15,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                      className="th-step-multi"
                    >
                      $50 to $150
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="over"
                  value="3"
                  id="over"
                  checked={props.lookingspend == 3}
                  onChange={(e) => {
                    props.setlookingspend(e.target.value), changestorage(e);
                  }}
                />
                <div
                  className={`front-end box1 area-icon myboxes ${
                    props.activityValue == "1" ? "background-checked" : ""
                  }`}
                >
                  <img
                    style={{ marginTop: 30 }}
                    className="activity-image"
                    src={over}
                  />
                  <div className="passionate-activity">
                    <p
                      style={{
                        marginTop: 4,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                      className="th-step-multi"
                    >
                      Over $150
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                borderBottom: "3px solid #FCC9BE",
                marginRight: 25,
                paddingBottom: 6,
                color: "#1F294E",
                fontFamily: "Nunito",
                cursor: "pointer",
              }}
              onClick={props.onBackClick}
            >
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <a className="backbtn">Back</a>
            </div>
            <div className="button-render">
              <button
                type="button"
                onClick={getResult}
                style={{
                  backgroundColor: "#478BA2",
                  color: "#fff",
                  fontFamily: "Bungee",
                  padding: 15,
                  border: "none",
                  borderRadius: 20,
                }}
              >
                GET RECOMMENDATIONS!{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View start here */}

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>
          <div className="heigh">
            <h2
              style={{
                color: "#1F294E",
                textAlign: "center",
                fontSize: "18px",
                fontFamily: "Nunito",
                fontWeight: 700,
                top: "9%",
              }}
            >
              How much are you looking to spend?
            </h2>
            <p className="paredesc" style={{ top: "4%", whiteSpace: "nowrap" }}>
              It’s the thought that counts, but…
            </p>
          </div>
          <div className="mycol2">
            <label>
              <input
                type="checkbox"
                name="Matter"
                value="0"
                id="Matter"
                checked={props.lookingspend == 0}
                onChange={(e) => {
                  props.setlookingspend(e.target.value), changestorage(e);
                }}
              />
              <div className="area-icon myboxes">
                <div className="passionate-activity">
                  <p
                    style={{
                      marginBottom: 13,
                      fontSize: 20,
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                    className="th-step-multi"
                  >
                    Doesn’t <br /> Matter!
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="under"
                value="1"
                id="under"
                checked={props.lookingspend == 1}
                onChange={(e) => {
                  props.setlookingspend(e.target.value), changestorage(e);
                }}
              />
              <div className="area-icon myboxes">
                <img
                  style={{ marginTop: 22 }}
                  className="activity-image"
                  src={under50}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Under $50</p>
                </div>
              </div>
            </label>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="onefifty"
                  value="2"
                  id="onefifty"
                  checked={props.lookingspend == 2}
                  onChange={(e) => {
                    props.setlookingspend(e.target.value), changestorage(e);
                  }}
                />
                <div className="area-icon myboxes">
                  <img
                    style={{ marginTop: 12 }}
                    className="activity-image"
                    src={onefifty}
                  />
                  <div className="passionate-activity">
                    <p className="th-step-multi">$50 to $150</p>
                  </div>
                </div>
              </label>
            </div>

            <label>
              <input
                type="checkbox"
                name="over"
                value="3"
                id="over"
                checked={props.lookingspend == 3}
                onChange={(e) => {
                  props.setlookingspend(e.target.value), changestorage(e);
                }}
              />
              <div className="front-end box1 area-icon myboxes">
                <img
                  style={{ marginTop: 12 }}
                  className="activity-image"
                  src={over}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Over $150</p>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateSixthteen;
