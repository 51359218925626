const BaseUrl = "https://api.outdone.io";
export function SignUp(Fname, Laname, Email, Password, ConfirmPassword) {
  return new Promise((resolve, reject) => {
    let body = {
      first_name: Fname,
      last_name: Laname,
      email: Email,
      password: Password,
      cnfrm_password: ConfirmPassword,
    };
    fetch(BaseUrl + "/api/user_signup", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteProduct(id) {
  const token = localStorage.getItem("admin_token");
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/delete_product", {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function Forgotpassword(emailForgot) {
  return new Promise((resolve, reject) => {
    let body = {
      email: emailForgot,
    };
    fetch(BaseUrl + "/api/forgot/password", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function Resetpassword(password, AccToken) {
  return new Promise((resolve, reject) => {
    let body = {
      password: password,
      token: AccToken,
    };
    fetch(BaseUrl + "/api/reset/password", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetProductDetail(id) {
  let token = localStorage.getItem("admin_token");
  return new Promise((resolve, reject) => {
    let body = {
      brand_id: id,
    };
    fetch(BaseUrl + "/api/getProductDetail", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function importBrandCSV(importFile) {
  console.log("FILE INSIDE SERVICE : ", importFile);
  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let body = {
      file: importFile,
    };

    var formData = new FormData();
    formData.append("file", importFile);

    console.log(">>>>", formData.get("file"));

    fetch(BaseUrl + "/api/importBrands", {
      method: "POST",
      // body: JSON.stringify(body),
      body: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Auth Guard Service :

export function AuthGuardService(isAdmin = true) {
  let token;
  if (isAdmin) {
    console.log("Check Admin");
    token = localStorage.getItem("admin_token");
  } else {
    console.log("Check User");
    token = localStorage.getItem("user_token");
  }
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/authenticateUser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetGiftGetterById(id) {
  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let body = {
      id: id,
    };
    fetch(BaseUrl + "/api/getGiftGetterById", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function QuestionAnalytics(title, answer) {
  // let token = localStorage.getItem("user_token")
  //     let body = {
  //         "title": title,
  //         'answer' : answer
  //     };
  // fetch(BaseUrl+"/api/questionAnalytics", {
  //     method: "POST",
  //     body: JSON.stringify(body),
  //     headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer "+ token,
  //     },
  // })
}

export function AddMYSELF(
  age,
  gender,
  typeArray,
  typeArrayTwo,
  typeArrayThree,
  typeArrayFour,
  typeArrayFive,
  typeArraySix,
  typeArraySeven,
  State,
  birthday
) {
  console.log("NEEEEEEEEEEEEEEEEEEE");
  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let boody = {
      age: age,
      gender: gender,
      question1: typeArray,
      question2: typeArrayTwo,
      question3: typeArrayThree,
      question4: typeArrayFour,
      question5: typeArrayFive,
      question6: typeArraySix,
      question7: typeArraySeven,
      state: State,
      birthday: birthday,
    };
    fetch(BaseUrl + "/api/addMySelf", {
      method: "POST",
      body: JSON.stringify(boody),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN", responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

export function editMySelf(
  age,
  gender,
  typeArray,
  typeArrayTwo,
  typeArrayThree,
  typeArrayFour,
  typeArrayFive,
  typeArraySix,
  typeArraySeven,
  State,
  birthday
) {
  console.log("NEEEEEEEEEEEEEEEEEEE");
  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let boody = {
      age: age,
      gender: gender,
      question1: typeArray,
      question2: typeArrayTwo,
      question3: typeArrayThree,
      question4: typeArrayFour,
      question5: typeArrayFive,
      question6: typeArraySix,
      question7: typeArraySeven,
      state: State,
      birthday: birthday,
    };
    fetch(BaseUrl + "/api/editMySelf", {
      method: "POST",
      body: JSON.stringify(boody),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN", responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

export function AddMember(
  firstname,
  lastname,
  age,
  nickname,
  occarray,
  typeArray,
  typeArrayTwo,
  typeArrayThree,
  typeArrayFour,
  typeArrayFive,
  typeArraySix,
  typeArraySeven,
  typeArrayEight,
  State
) {
  console.log("NEEEEEEEEEEEEEEEEEEE");
  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let Docline = [];
    occarray.forEach((item) => {
      if (item.name) {
        Docline.push({
          name: item.name,
          date: item.date,
        });
      }
    });
    let boody = {
      first_name: firstname,
      last_name: lastname,
      nick_name: nickname,
      age: age,
      gender: typeArray,
      question1: typeArrayTwo,
      question2: typeArrayThree,
      question3: typeArrayFour,
      question4: typeArrayFive,
      question5: typeArraySix,
      question6: typeArraySeven,
      question7: typeArrayEight,
      state: State,
      ocassions: Docline,
    };
    fetch(BaseUrl + "/api/addMember", {
      method: "POST",
      body: JSON.stringify(boody),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN", responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

export function UpdateMember(
  id,
  firstname,
  lastname,
  age,
  gender,
  nickname,
  occarray,
  typeArrayTwo,
  typeArrayThree,
  typeArrayFour,
  typeArrayFive,
  typeArraySix,
  typeArraySeven,
  typeArrayEight,
  State
) {
  console.log("Update Memeber");

  let token = localStorage.getItem("user_token");
  return new Promise((resolve, reject) => {
    let Docline = [];
    occarray.forEach((item) => {
      if (item.name) {
        Docline.push({
          name: item.name,
          date: item.date,
        });
      }
    });
    let boody = {
      id: id,
      first_name: firstname,
      last_name: lastname,
      nick_name: nickname,
      age: age,
      gender: gender,
      question1: typeArrayTwo,
      question2: typeArrayThree,
      question3: typeArrayFour,
      question4: typeArrayFive,
      question5: typeArraySix,
      question6: typeArraySeven,
      question7: typeArrayEight,
      state: State,
      ocassions: Docline,
    };
    fetch(BaseUrl + "/api/updateMember", {
      method: "POST",
      body: JSON.stringify(boody),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN", responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

export function SignIn(email, password) {
  return new Promise((resolve, reject) => {
    let body = {
      email: email,
      password: password,
    };
    fetch(BaseUrl + "/api/user_signin", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function VerifyAccount() {
  const token = localStorage.getItem("user_token");

  let body2 = {
    token: token,
  };

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/verifyEmail", {
      method: "POST",
      body: JSON.stringify(body2),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function ChangeOldPassword(oldPassword, newPassword, confirmPassword) {
  return new Promise((resolve, reject) => {
    let body = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    fetch(BaseUrl + "/api/changePassword", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("user_token"),
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GmailSignUp(firstName, lastName, Email, id) {
  return new Promise((resolve, reject) => {
    let body = {
      first_name: firstName,
      last_name: lastName,
      email: Email,
      google_id: id,
    };
    fetch(BaseUrl + "/api/google_signup", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function FaceBookSignUp(firstName, lastName, Email, id) {
  return new Promise((resolve, reject) => {
    let body = {
      first_name: firstName,
      last_name: lastName,
      email: Email,
      facebook_id: id,
    };
    fetch(BaseUrl + "/api/facebook_signup", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetHubSignUp(firstName, lastName, Email, id) {
  return new Promise((resolve, reject) => {
    let body = {
      first_name: firstName,
      last_name: lastName,
      email: Email,
      google_id: id,
    };
    fetch(BaseUrl + "/api/github_signup", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GettingLinkedinData(code) {
  console.log(code);
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "https://api.linkedin.com/v2/me", {
      method: "GET",

      headers: {
        Authorization:
          "Bearer AQVL31MQITZ2sQemH1x27sq2zLExK5SXXgYtf0-b6w6OO5ULrdx28FpqnkAkCu10GRQWv3XZ0BVrfcKzYkGQdObWjE8AyJOqfV24zZOiH2KiKTkBdlyflWmsygF2GWuwhHoScKWjHkhZH8USOm36NE1Mo1ArNoGVG5lTna7C9DrvX64xvBEXnLTtl2K64l_J21agVNZU5sD30x3zJQ4SwjHe6OYe-H_68BdISZsG7f7bGQGzTRenER0NgE9VKwsrS4361KYYVCtcJCIYJkbHQPJjZYtbGkYD7qepOr_jZtOnliGYCRI-1XcFUk5z6ADJoDMEUyIYCiRPZCuWbSAV9BpvvwYNhw",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "OPTIONS, GET, POST",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetUserData() {
  console.log("pakisyattatatattat", U_ID);

  return new Promise((resolve, reject) => {
    let body = {
      user_id: U_ID,
    };
    fetch(BaseUrl + "/api/GetUserData", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetUserDetails() {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("user_token");
    fetch(BaseUrl + "/api/getUserDetail", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateUserData(name, Fname, Lname, age, gender, location) {
  let U_ID = localStorage.getItem("user_id");
  console.log(U_ID);
  return new Promise((resolve, reject) => {
    let body = {
      user_id: U_ID,
      first_name: Fname,
      last_name: Lname,
      name: name,
      age: age,
      gender: gender,
      location: location,
    };
    fetch(BaseUrl + "/api/UpdateUserData", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function ChangePassWord(Oldpass, Newpass, ConNewpass) {
  let U_ID = localStorage.getItem("user_id");
  console.log(U_ID);
  return new Promise((resolve, reject) => {
    let body = {
      user_id: U_ID,
      old_password: Oldpass,
      new_password: Newpass,
      confirm_password: ConNewpass,
    };
    fetch(BaseUrl + "/api/changePassword", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function VerifyEmail(otp) {
  let U_EMAIL = localStorage.getItem("user_email");
  return new Promise((resolve, reject) => {
    let body = {
      email: U_EMAIL,
      otp: otp,
    };
    fetch(BaseUrl + "/api/verify_email", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function AddGiftGetter(Fname, Lname, NickName) {
  let U_ID = localStorage.getItem("user_id");
  return new Promise((resolve, reject) => {
    let body = {
      first_name: Fname,
      last_name: Lname,
      nickname: NickName,
      user_id: U_ID,
      occassions: [
        {
          occassion: "birthday",
          created_at: "2022-04-19 08:15:58",
        },
      ],
    };
    fetch(BaseUrl + "/api/addGiftGetters", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function SavedGiftGetter() {
  let U_ID = localStorage.getItem("user_id");
  return new Promise((resolve, reject) => {
    let body = {
      user_id: U_ID,
    };
    fetch(BaseUrl + "/api/savedGiftGetters", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function SearchBrand(keyword) {
  let token = localStorage.getItem("user_token");

  return new Promise((resolve, reject) => {
    let body = {
      keyword: keyword,
    };
    fetch(BaseUrl + "/api/searchBrand", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function SearchBrandDtails(id) {
  let token = localStorage.getItem("user_token");

  return new Promise((resolve, reject) => {
    let body = {
      brand_id: id,
    };
    fetch(BaseUrl + "/api/getProductDetail", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function PopularSearches() {
  let token = localStorage.getItem("user_token");

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/getTopSearchedBrands", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function filterDiscover(key, gender, cata, speciality, extras, head) {
  let token = localStorage.getItem("user_token");

  return new Promise((resolve, reject) => {
    let body = {
      keyword: key,
      gender: gender,
      categories: cata,
      headquarter: head,
      speciality: speciality,
      extras: extras,
    };
    fetch(BaseUrl + "/api/filterBrandSearch", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBrandName() {
  let token = localStorage.getItem("admin_token");

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/getBrandNames", {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteMember(id) {
  let token = localStorage.getItem("user_token");

  return new Promise((resolve, reject) => {
    let body = {
      id: id,
    };
    fetch(BaseUrl + "/api/deleteMember", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function peopleAlsoLike(id) {
  console.log("api hit");

  return new Promise((resolve, reject) => {
    let body = {
      brand_id: id,
    };
    fetch(BaseUrl + "/api/people-also-like", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getfiltercategories() {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/get-filters", {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getsurveydata() {
  let token = localStorage.getItem("admin_token");

  return new Promise((resolve, reject) => {
    fetch(BaseUrl + "/api/get-survey-data", {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
