import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Cook from "../../assets/images/Cook.png";
import Alcohol from "../../assets/images/Alcohol.png";
import Coffee from "../../assets/images/Coffee.png";
import Photo from "../../assets/images/Photo.png";
import Read from "../../assets/images/Read.png";
import Puzzlegame from "../../assets/images/Puzzlegame.png";
import Garden from "../../assets/images/Garden.png";
import Videogame from "../../assets/images/Videogame.png";
import trekking from "../../assets/images/trekking.webp";
import yoga from "../../assets/images/yoga.webp";
import Back from "../../assets/images/Backside.png";
import { NavbarText } from "reactstrap";
import x from "../../assets/images/x2.png";
import { useHistory } from "react-router-dom";
import { pageView } from "../../../ga4Service";

const StateNine = (props) => {
  useEffect(() => {
    pageView("/surveyQ4");
  });

  const { watch, register } = useForm();
  const [Items, setItems] = useState([]);

  console.log("State Nine : ", props, props.chnageCheckboxValue);

  const btClicked = (e) => {
    e.persist();
    let value = e.target.name;
    console.log("Event Value : ");
    let allItems = [...Items];
    if (allItems.includes(value)) {
      let index = allItems.indexOf(value);
      allItems.splice(index, 1);
    } else {
      allItems.push(value);
    }
    setItems(allItems);
  };

  const toggletotal = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    var inputs, index;

    console.log("age is " + localStorage.getItem("age"));
    inputs = document.getElementsByClassName("datacollect");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }
    console.log("age is " + localStorage.getItem("age"));

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }

      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  const toggletotal2 = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    var inputs, index;

    inputs = document.getElementsByClassName("datacollect2");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }

      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  function shuffle(id, kid) {
    var container = document.getElementById(id);
    var elementsArray = Array.prototype.slice.call(
      container.getElementsByClassName(kid)
    );
    elementsArray.forEach(function (element) {
      container.removeChild(element);
    });
    shuffleArray(elementsArray);
    elementsArray.forEach(function (element) {
      container.appendChild(element);
    });
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  useEffect(() => {
    shuffle("container", "wrapper-passion");
    shuffle("container2", "wrapper-passion");
  }, []);

  return (
    <Fragment>
      <div className="desktop">
        <h2
          id="do-they-like"
          className="Text-header-main"
          style={{ fontFamily: "Nunito", fontWeight: 700 }}
        >
          Do they like to do any of the following?
        </h2>
        <p className="paredesc">Select all that apply, or skip!</p>
        <input type="checkbox" />
        <input type="checkbox" />
        <input type="checkbox" />
        <div className="step-1-form middle" style={{ top: "60%" }}>
          <div className="myrow" id="container">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Cook"
                  value="0"
                  id="Cook"
                  className="datacollect"
                  checked={props.arr[0] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img className="activity-image" src={Cook} />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 18 }} className="th-step-multi">
                      Cook
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Alcohol"
                  value="1"
                  className="datacollect"
                  id="Alcohol"
                  checked={props.arr[1] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    style={{ marginTop: 20 }}
                    className="activity-image"
                    src={Alcohol}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 20 }} className="th-step-multi">
                      Drink Alcohol
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Coffee"
                  value="2"
                  className="datacollect"
                  id="Coffee"
                  checked={props.arr[2] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    style={{ marginTop: 13 }}
                    className="activity-image"
                    src={Coffee}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 8 }} className="th-step-multi">
                      Make Coffee
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Photo"
                  value="3"
                  id="Photo"
                  className="datacollect"
                  checked={props.arr[3] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    style={{ marginTop: 22 }}
                    className="activity-image"
                    src={Photo}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 18 }} className="th-step-multi">
                      Photography
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="myrow" id="container2">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Read"
                  value="4"
                  id="Read"
                  checked={props.arr[4] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                  className="datacollect"
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    className="activity-image"
                    style={{
                      width: 162,
                      height: 83,
                      marginTop: 2,
                      marginLeft: -21,
                    }}
                    src={Read}
                  />
                  <div className="passionate-activity">
                    <p className="th-step-multi">Read</p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Puzzlegame"
                  value="5"
                  id="Puzzlegame"
                  checked={props.arr[5] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                  className="datacollect"
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img className="activity-image" src={Puzzlegame} />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 8 }} className="th-step-multi">
                      Puzzles or
                      <br /> Board Games
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Garden"
                  value="6"
                  id="Garden"
                  checked={props.arr[6] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                  className="datacollect"
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    style={{ width: 65, marginTop: 22 }}
                    className="activity-image"
                    src={Garden}
                  />
                  <div className="passsionate-activity">
                    <p style={{ marginTop: 12 }} className="th-step-multi">
                      Garden
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Videogame"
                  value="7"
                  id="Videogame"
                  checked={props.arr[7] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                  className="datacollect"
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ width: 130, height: 130 }}
                >
                  <img
                    style={{ marginTop: 25 }}
                    className="activity-image"
                    src={Videogame}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 17 }} className="th-step-multi">
                      Video Games
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                borderBottom: "3px solid #FCC9BE",
                marginRight: 25,
                paddingBottom: 6,
                color: "#1F294E",
                fontFamily: "Nunito",
                cursor: "pointer",
              }}
              onClick={props.onBackClick}
            >
              <img src={Back} alt="" />
              <img src={Back} alt="" />
              <a className="backbtn">Back</a>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#478BA2",
                  border: "none",
                  fontFamily: "Bungee",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "10px 20px",
                  color: "#fff",
                  borderRadius: 10,
                }}
                onClick={(e) =>
                  props.onClick({
                    title: document.getElementById("do-they-like").textContent,
                    listItems: Items,
                  })
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* .moble view start here */}

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh" style={{ position: "relative", bottom: 15 }}>
            {" "}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2
                style={{
                  color: "#1F294E",
                  textAlign: "center",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                Do they like to do any of the following?
              </h2>
            </div>
            <div className="selectall">Select all that apply, or skip!</div>
          </div>

          <div class="mycol">
            <label>
              <input
                type="checkbox"
                name="Cook"
                value="0"
                id="Cook"
                className="datacollect2"
                checked={props.arr[0] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img className="activity-image" src={Cook} />
                <div className="passionate-activity">
                  <p style={{ marginTop: 4 }} className="th-step-multi">
                    Cook
                  </p>
                </div>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Read"
                value="4"
                className="datacollect2"
                id="Read"
                checked={props.arr[4] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ width: 162, height: 83, marginBottom: 20 }}
                  src={Read}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Read</p>
                </div>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Alcohol"
                value="1"
                id="Alcohol"
                className="datacollect2"
                checked={props.arr[1] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ marginTop: 20 }}
                  className="activity-image"
                  src={Alcohol}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 20 }} className="th-step-multi">
                    Drink Alcohol
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Puzzlegame"
                value="5"
                className="datacollect2"
                id="Puzzlegame"
                checked={props.arr[5] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img className="activity-image" src={Puzzlegame} />
                <div className="passionate-activity">
                  <p
                    style={{ marginTop: 8, fontSize: 11, width: 130 }}
                    className="th-step-multi"
                  >
                    Puzzles or Games
                  </p>
                </div>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Coffee"
                value="2"
                id="Coffee"
                className="datacollect2"
                checked={props.arr[2] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ marginTop: 13, width: 43 }}
                  className="activity-image"
                  src={Coffee}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 8 }} className="th-step-multi">
                    Make Coffee
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Garden"
                value="6"
                id="Garden"
                className="datacollect2"
                checked={props.arr[6] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ width: 65, marginTop: 22 }}
                  className="activity-image"
                  src={Garden}
                />
                <div className="passsionate-activity">
                  <p style={{ marginTop: 12 }} className="th-step-multi">
                    Garden
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Photo"
                value="3"
                id="Photo"
                className="datacollect2"
                checked={props.arr[3] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ marginTop: 22, width: 53 }}
                  className="activity-image"
                  src={Photo}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 4 }} className="th-step-multi">
                    Photography
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Videogame"
                value="7"
                className="datacollect2"
                id="Videogame"
                checked={props.arr[7] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal(e);
                }}
              />
              <div className="myboxes area-icon">
                <img
                  style={{ marginTop: 16, width: 56 }}
                  className="activity-image"
                  src={Videogame}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 4 }} className="th-step-multi">
                    Video Games
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateNine;
