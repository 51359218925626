import React, { useState } from "react";
import "./Forgotpassword.css";
import Footer from "../Footer/Footer";
import ReactDOM from "react-dom";
import Navbar from "../Navbar/Navbar";
import ForgotSepareate from "../assets/images/Grupo1230.png";
import { Resetpassword } from "../Services/ServicePhase2";

function ResetPassword(props) {
  const [password, setpassword] = useState("");
  const [resetpassword, setresetpassword] = useState("");
  const [mustMatch, setmustMatch] = useState(false);
  const [mustLength, setmustLength] = useState(false);
  const [ForgotLoading, setForgotLoading] = useState(false);

  var url = window.location;
  var access_token = url.toString().split("=");
  let AccToken = access_token[1];
  console.log(url);
  console.log(access_token);
  console.log(AccToken);
  localStorage.setItem("token", AccToken);

  const OnForgotPassword = () => {
    if (password < 8) {
      setmustLength(true);
    }
    if (password !== resetpassword) {
      setmustMatch(true);
    }
    if (password === resetpassword && password != "") {
      setForgotLoading(true);
      Resetpassword(password, AccToken).then((result) => {
        if (result.success == false) {
          setForgotLoading(false);
          alert(result.message);
        } else {
          setForgotLoading(false);
          setmustLength(false);
          setmustMatch(false);
          props.history.push("/sign-in");
        }
      });
    }
  };

  return (
    <>
      <div>
        <div className="forgotclassimage">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor: "#fff",
                width: "max(34vw,400px)",
                paddingTop: 25,
                paddingBottom: 0,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Bungee",
                  fontSize: 28,
                  marginBottom: -25,
                  fontWeight: 400,
                  color: "#1F294E",
                  textAlign: "center",
                }}
              >
                RESET PASSWORD
              </p>
              <img
                className="ForgotCenter"
                style={{ width: 110, marginBottom: -20, marginTop: 10 }}
                src={ForgotSepareate}
                alt=""
              />
              <div className="resetfieldtype">
                <input
                  className="ForgotCenter"
                  type="password"
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Enter Password"
                />
                <br />
                <input
                  className="ForgotCenter"
                  type="password"
                  onChange={(e) => setresetpassword(e.target.value)}
                  placeholder="Enter confirm password"
                />
                <br />
                {mustLength ? (
                  <div>
                    <p style={{ color: "red", textAlign: "center" }}>
                      Pasword must be 8 characters long
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button><br /> */}
                  </div>
                )}
                {mustMatch ? (
                  <div>
                    <p style={{ color: "red", textAlign: "center" }}>
                      Pasword must be same
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button><br /> */}
                  </div>
                )}
              </div>
              {ForgotLoading ? (
                <div
                  class="spinner-border"
                  style={{ float: "right" }}
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <div>
                  <button
                    className="ForgotButton"
                    onClick={OnForgotPassword}
                    style={{ fontFamily: "Bungee" }}
                  >
                    CONTINUE
                  </button>
                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ResetPassword;
