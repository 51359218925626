// import React from "react";
import React, { useState, useEffect } from "react";
import "./Addgiftget.css";
import editbottom from "../assets/images/Grupo1230.png";
import {
  AddMember,
  GetGiftGetterById,
  UpdateMember,
  AuthGuardService,
} from "../Services/ServicePhase2";
import Calendar from "./Calendar";
import Footer from "../Footer/Footer";
import bottom from "../assets/images/Polygon8.png";
import top from "../assets/images/Polygon7.png";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import userimg from "../assets/images/Frame 2.svg";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import QuickSurvey from "../quickSurvey";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.css";
import Giftcard from "./Giftcard";
function giftget(props) {
  const [RecievedData, setRecievedData] = useState("");
  const [BddayData, setBddayData] = useState([]);

  const [CountBdday, setCountBdday] = useState(0);

  const [isauth, setisauth] = useState(false);

  useEffect(() => {
    AuthGuardService(false)
      .then((result) => {
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/sign-in", { isError: true });
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/sign-in", { isError: true });
      });
    $(function () {
      $("datepicker")
        .datepicker({
          changeYear: false,
          dateFormat: "dd-mm",
        })
        .focus(function () {
          $(".ui-datepicker-year").hide();
        });
    });
  }, []);

  useEffect(() => {
    const bddayList = [];
    props.location.state?.data.id
      ? GetGiftGetterById(props.location.state.data.id)
          .then((result) => {
            result.data[0].member_ocassions.map((item, index) => {
              bddayList.push(
                <div style={{ marginRight: "10%" }}>
                  <p className="colfade">#{index}</p>
                  <input
                    style={{ height: 40 }}
                    id={`bdname-${index}`}
                    className="firstinput"
                    defaultValue={item.name}
                    type="text"
                    name="name"
                    placeholder="Birthday"
                    onChange={(e) => SaveInputValues(e, index)}
                  />
                  <div style={{ display: "flex", marginTop: 20 }}>
                    <p className="colfade" style={{ marginRight: 20 }}>
                      Date
                    </p>
                    <div>
                      <div className="dageinput">
                        <div>
                          <input
                            type="text"
                            id={`date-${index}`}
                            defaultValue={item.date}
                            name="date"
                            style={{
                              border: "none",
                              width: 100,
                              marginLeft: 10,
                            }}
                            onChange={(e) => {
                              SaveInputValues(e, index);
                            }}
                          />
                        </div>
                        <div>
                          <img
                            style={{
                              width: 8,
                              display: "block",
                              marginBottom: 5,
                              marginTop: 0,
                              marginRight: 6,
                              marginLeft: "auto",
                            }}
                            src={top}
                            alt=""
                          />
                          <img
                            style={{
                              width: 8,
                              display: "block",
                              marginLeft: "auto",
                              marginRight: 6,
                            }}
                            src={bottom}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    style={{
                      marginTop: 8,
                      marginBottom: 35,
                      fontSize: 12,
                      color: "#1F294E",
                      borderBottom: "1px solid #F3A493",
                    }}
                  >
                    REMOVE
                  </a>
                </div>
              );
            });
            setCountBdday(bddayList.length);
            setBddayData(bddayList);
            setRecievedData(result.data[0]);
          })
          .catch((err) => {})
      : console.log("NO Data sent, It will Add new user");
  }, []);

  useEffect(() => {
    if (typeArrayEight.length > 0) {
      if (props.location.state?.data.id == undefined) {
        // alert('Add')
        AddMember(
          firstname,
          lastname,
          age,
          nickname,
          occarray,
          typeArray,
          typeArrayTwo,
          typeArrayThree,
          typeArrayFour,
          typeArrayFive,
          typeArraySix,
          typeArraySeven,
          typeArrayEight,
          State
        ).then((result) => {
          if (result.success == true) {
            alert(result.message);

            props.history.push("/dashboard-user");
          } else {
            alert(result.message);
          }
        });
      } else {
        // alert('UPDATE')
        UpdateMember(
          props.location.state?.data.id,
          firstname,
          lastname,
          age,
          nickname,
          occarray,
          typeArray,
          typeArrayTwo,
          typeArrayThree,
          typeArrayFour,
          typeArrayFive,
          typeArraySix,
          typeArraySeven,
          typeArrayEight,
          State
        ).then((result) => {
          if (result.success == true) {
            alert(result.message);

            props.history.push("/dashboard-user");
          } else {
            alert(result.message);
          }
        });
      }
    }
  }, [typeArrayEight]);

  const [Giftoc, setGiftoc] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [nickname, setnickname] = useState("");

  const [occasiononename, setoccasiononename] = useState("");
  const [occasiononedate, setoccasiononedate] = useState("");
  const [gender, setgender] = useState();
  const [area, setarea] = useState();
  const [checked, setchecked] = useState(false);
  const [age, setage] = useState("");
  const [birthday, setbirthday] = useState("");
  const [State, setState] = useState("");
  const [typeArray, settypeArray] = useState([]);
  const [typeArrayTwo, settypeArrayTwo] = useState([]);
  const [typeArrayThree, settypeArrayThree] = useState([]);
  const [typeArrayFour, settypeArrayFour] = useState([]);
  const [typeArrayFive, settypeArrayFive] = useState([]);
  const [typeArraySix, settypeArraySix] = useState([]);
  const [typeArraySeven, settypeArraySeven] = useState([]);
  const [typeArrayEight, settypeArrayEight] = useState([]);
  const [occarray, setoccarray] = useState([0, 1]);

  const [mobileoccuray, setmobileoccuray] = useState([
    {
      name: "",
      date: "",
    },
    {
      name: "",
      date: "",
    },
  ]);

  var otypeArrayeight = [];
  var otypeArraySeven = [];
  var otypeArraythree = [];
  var otypeArraytwo = [];
  var otypeArrayFour = [];
  var otypeArrayFive = [];
  var otypeArraySix = [];
  var occaions = [];

  const Stategender = () => {
    var inputs = document.querySelectorAll(".gendercheck");
    var otypeArray = [];
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].checked === true) {
        otypeArray.push(inputs[i].value);
      }
    }
    settypeArray(otypeArray);
  };

  const QuestionTwo = () => {
    var inputstwo = document.querySelectorAll(".QuestTwo");

    for (var i = 0; i < inputstwo.length; i++) {
      if (inputstwo[i].checked === true) {
        otypeArraytwo.push(inputstwo[i].value);
      }
    }
    settypeArrayTwo(otypeArraytwo);
  };
  const QuestionThree = () => {
    var inputsthree = document.querySelectorAll(".QuestThree");

    for (var i = 0; i < inputsthree.length; i++) {
      if (inputsthree[i].checked === true) {
        otypeArraythree.push(inputsthree[i].value);
      }
    }
    settypeArrayThree(otypeArraythree);
  };
  const QuestionFour = () => {
    var inputsFour = document.querySelectorAll(".QuestFour");

    for (var i = 0; i < inputsFour.length; i++) {
      if (inputsFour[i].checked === true) {
        otypeArrayFour.push(inputsFour[i].value);
      }
    }
    settypeArrayFour(otypeArrayFour);
  };
  const QuestionFive = () => {
    var inputsFive = document.querySelectorAll(".QuestFive");

    for (var i = 0; i < inputsFive.length; i++) {
      if (inputsFive[i].checked === true) {
        otypeArrayFive.push(inputsFive[i].value);
      }
    }
    settypeArrayFive(otypeArrayFive);
  };
  const QuestionSix = () => {
    var inputsSix = document.querySelectorAll(".QuestSix");

    for (var i = 0; i < inputsSix.length; i++) {
      if (inputsSix[i].checked === true) {
        otypeArraySix.push(inputsSix[i].value);
      }
    }
    settypeArraySix(otypeArraySix);
  };
  const QuestionSeven = () => {
    var inputsSeven = document.querySelectorAll(".QuestSeven");

    for (var i = 0; i < inputsSeven.length; i++) {
      if (inputsSeven[i].checked === true) {
        otypeArraySeven.push(inputsSeven[i].value);
      }
    }
    settypeArraySeven(otypeArraySeven);
  };
  const QuestionEight = () => {
    var inputseight = document.querySelectorAll(".QuestEight");

    for (var i = 0; i < inputseight.length; i++) {
      if (inputseight[i].checked === true) {
        otypeArrayeight.push(inputseight[i].value);
      }
    }
    settypeArrayEight(otypeArrayeight);
  };

  const Occasions = () => {
    var inputname = document.querySelectorAll(".occassionname");

    var inputdate = document.querySelectorAll(".occassiondate");
    for (var i = 0; i < inputname.length; i++) {
      if (inputname[i].value != "") {
        occaions.push({
          name: inputname[i].value,
          date: inputdate[i].value,
        });
      }
    }
    settypeArrayEight(otypeArrayeight);
  };

  const AllQuestions = () => {
    Stategender();
    QuestionTwo();
    QuestionThree();
    QuestionFour();
    QuestionFive();
    QuestionSix();
    QuestionSeven();
    QuestionEight();
    Occasions();
  };

  const OnSaveClick = (obj) => {
    AllQuestions();

    const arr = [gender];
    AddMember(
      firstname,
      lastname,
      age,
      nickname,
      occaions,
      arr,
      otypeArraytwo,
      otypeArraythree,
      otypeArrayFour,
      otypeArrayFive,
      otypeArraySix,
      otypeArraySeven,
      otypeArrayeight,
      State
    ).then((result) => {
      if (result.success == true) {
        alert(result.message);

        props.history.push("/dashboard-user");
      } else {
        alert(result.message);
      }
    });
  };

  const counter = (x) => {
    if (x === "incre") {
      if (age === "") {
        setage(1);
      } else if (age >= 109) {
      } else {
        setage(age + 1);
      }
    } else if (x === "decre") {
      if (age > 1) {
        setage(age - 1);
      }
    }
  };

  const changeage = (e) => {
    var enterage = e.target.valueAsNumber;
    if (enterage <= 109 && enterage > 0) {
      setage(e.target.valueAsNumber);
    } else if (enterage > 109) {
    } else {
      setage("");
    }
  };

  const farward = () => {
    if (document.getElementById("selectstate").value == "") {
      setState(0);
      document.getElementById("selectstate").value = 0;
    } else {
      let i = document.getElementById("selectstate").value;
      let j = parseInt(i);
      document.getElementById("selectstate").value = j + 1;
      setState(State + 1);
    }
  };

  const backward = () => {
    let i = document.getElementById("selectstate").value;
    let j = parseInt(i);
    document.getElementById("selectstate").value = j - 1;
    setState(State - 1);
  };

  const removeappend = (obj) => {
    var array = [...occarray]; // make a separate copy of the array
    var index = array.indexOf(obj);

    if (index !== -1) {
      array.splice(index, 1);
      setoccarray(array);
    }
  };

  const removeappendmobile = (obj) => {
    var array = [...mobileoccuray]; // make a separate copy of the array
    var index = obj;
    if (index !== -1) {
      array.splice(index, 1);
      setmobileoccuray(array);
    }
  };

  const clearall = () => {
    setGiftoc(false);
    setfirstname("");
    setlastname("");
    setnickname("");
    setoccasionname("");
    setoccasiondate("");
    setoccasiononename("");
    setoccasiononedate("");
    setgender();
    setchecked(false);
    setage("");
    setbirthday("");
    setState("");
    settypeArray([]);
    settypeArrayTwo([]);
    settypeArrayThree([]);
    settypeArrayFour([]);
    settypeArrayFive([]);
    settypeArraySix([]);
    settypeArraySeven([]);
    settypeArrayEight([]);
    setoccarray([1]);
    otypeArrayeight = [];
    otypeArraySeven = [];
    otypeArraythree = [];
    otypeArraytwo = [];
    otypeArrayFour = [];
    otypeArrayFive = [];
    otypeArraySix = [];
    occaions = [];
  };

  const areaselection = (e) => {
    if (area == e.target.value) {
      setarea("");
    } else {
      setarea(e.target.value);
    }
  };

  const genderselection = (e) => {
    if (gender == e.target.value) {
      setgender("");
    } else {
      setgender(e.target.value);
    }
  };

  const updateinfo = (index, name, date) => {
    let newarr = [...mobileoccuray];

    if (name) {
      newarr[index].name = name;
    } else {
      newarr[index].date = date;
    }

    setmobileoccuray[newarr];
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>
      <div className="desktop">
        <div
          className="crossico"
          onClick={(e) => props.history.push("/dashboard-user")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="29"
            viewBox="0 0 26 29"
            fill="none"
          >
            <path
              d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
              fill="#1F294E"
            />
          </svg>
        </div>

        <div style={{ display: "flex", marginTop: "5%" }}>
          <div style={{ width: "15%" }}></div>
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ fontFamily: "Bungee" }} className="addedit">
                Add / Edit Giftgetter{" "}
              </p>
            </div>
            <img className="editbottom" src={editbottom} alt="" />
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="infirst">First Name</p>
                <input
                  style={{ marginRight: 60 }}
                  className="firstinput"
                  type="name"
                  onChange={(e) => setfirstname(e.target.value)}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="infirst">Last Name</p>
                <input
                  className="firstinput"
                  type="name"
                  onChange={(e) => setlastname(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 22 }}>
              <div>
                <p className="infiirst"> Nick Name</p>
                <p style={{ fontSize: 12 }} className="infiirst">
                  (what you call them)
                </p>
              </div>
              <input
                style={{ width: "37%" }}
                className="firstinput"
                type="name"
                onChange={(e) => setnickname(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", marginTop: "5%" }}>
              <p style={{ fontFamily: "Bungee" }} className="addedit">
                Gifting Ocassions{" "}
              </p>
              {occarray.length < 10 ? (
                <a
                  style={{
                    marginLeft: 15,
                    marginTop: 8,
                    marginBottom: 35,
                    fontSize: 12,
                    color: "#1F294E",
                    borderBottom: "1px solid #F3A493",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setoccarray([
                      ...occarray,
                      occarray[occarray.length - 1] + 1,
                    ]),
                      append;
                  }}
                >
                  ADD
                </a>
              ) : null}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {occarray.map((obj, i) => {
                return (
                  <>
                    <Calendar removeappend={removeappend} obj={obj} id={i} />

                    <hr></hr>
                  </>
                );
              })}
            </div>
            <div style={{ display: "flex", marginTop: "5%" }}>
              <p
                style={{ fontFamily: "Bungee", textTransform: "uppercase" }}
                className="addedit"
              >
                engine inputs
              </p>
            </div>
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="agegen">Age </p>
                <div
                  className="ageinput"
                  style={{ display: "flex", alignItems: "center", width: 130 }}
                >
                  <div>
                    <img
                      style={{ width: 15 }}
                      src={top}
                      alt=""
                      onClick={() => counter("incre")}
                    />
                    <img
                      style={{ width: 15 }}
                      src={bottom}
                      alt=""
                      onClick={() => counter("decre")}
                    />
                  </div>
                  <input
                    type="number"
                    name="age"
                    value={age}
                    onChange={(e) => changeage(e)}
                    style={{
                      border: "none",
                      width: 30,
                      textAlign: "center",
                      fontSize: 40,
                      color: "#478ba2",
                      fontWeight: 700,
                      width: 90,
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="agegen">Gender</p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a1"
                    name="a"
                    checked={gender === "Male"}
                    value="Male"
                    onChange={(e) => genderselection(e)}
                  />
                  <label for="a1">Male</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a2"
                    name="a"
                    checked={gender === "Female"}
                    value="Female"
                    onChange={(e) => genderselection(e)}
                  />
                  <label for="a2">Female</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    className="gendercheck"
                    type="checkbox"
                    id="a3"
                    name="a"
                    value="Other"
                    checked={gender === "Other"}
                    onChange={(e) => genderselection(e)}
                  />
                  <label for="a3">Other</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">What do they like to do at home?</p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        id="t1"
                        value="Cook"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t1">Cook</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        id="t2"
                        value="Alcohol"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t2">Drink Alcohol</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Coffee"
                        id="t3"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t3">Make Coffee</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Read"
                        id="t4"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t4">Read</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Puzzlegame"
                        id="t5"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t5">Puzzles/Games</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Photo"
                        id="t6"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t6">Photography</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestTwo"
                        type="checkbox"
                        value="Videogame"
                        id="t7"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="t7">Video Games</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they enjoy any of these physical activities?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o1"
                        value="Biking"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o1">Biking</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o2"
                        value="Yoga"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o2">Yoga</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o3"
                        value="Golfing"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o3">Golfing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o4"
                        value="Lifting"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o4">Lifting</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o5"
                        value="Running"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o5">Running</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="o6"
                        value="Camping"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="o6">Camping</label>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k1"
                        value="Hunting"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k1">Hunting</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k2"
                        value="Fishing"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k2">Fishing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k3"
                        value="Swimming"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k3">Swimming</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k4"
                        value="Skiing"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k4">Skiing</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k5"
                        value="snowboard"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k5">Snowboarding</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        className="QuestThree"
                        type="checkbox"
                        id="k6"
                        value="Hiking"
                        name="a"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="k6">Hiking</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button className="shopnoww" style={{ fontFamily: "Bungee", marginTop : 20, display : "block", marginLeft : "auto", marginRight : "auto" }}>
              Shop Now!
            </button> */}
            </div>
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  What sorts of clothing styles do they like to wear?
                </p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q1"
                    name="a"
                    value="Sporty"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="q1">Sporty</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q2"
                    name="a"
                    value="Outdoorsy"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="q2">Outdoorsy</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q3"
                    name="a"
                    value="Loungewear"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="q3">Loungewear</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    className="QuestFour"
                    id="q4"
                    name="a"
                    value="Streetwear"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="q4">Streetwear</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they love any of the following foods or drinks?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r1"
                        name="a"
                        className="QuestFive"
                        value="Beer"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="r1">Beer</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r2"
                        name="a"
                        className="QuestFive"
                        value="Candy"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="r2">Candy</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r3"
                        name="a"
                        className="QuestFive"
                        value="Coffee"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="r3">Coffee</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="r4"
                        name="a"
                        className="QuestFive"
                        value="Wine"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="r4">Wine</label>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g1"
                        name="a"
                        className="QuestFive"
                        value="Tea"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="g1">Tea</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g2"
                        name="a"
                        className="QuestFive"
                        value="Pasta"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="g2">Pasta</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="g3"
                        name="a"
                        className="QuestFive"
                        value="Pizza"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="g3">Pizza</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Do they regularly go to any of the following places?
                </p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e1"
                        name="a"
                        className="QuestSix"
                        value="beachParty"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="e1">The Beach</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e2"
                        name="a"
                        className="QuestSix"
                        value="Bars or Clubs"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="e2">Bars or Clubs</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e3"
                        name="a"
                        className="QuestSix"
                        value="Concerts"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="e3">Concerts</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="e4"
                        name="a"
                        className="QuestSix"
                        value="Airport"
                        onChange={() => setchecked(!checked)}
                      />
                      <label for="e4">The Airport</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <button className="shopnoww" style={{ fontFamily: "Bungee", marginTop : 20, display : "block", marginLeft : "auto", marginRight : "auto" }}>
          Shop Now!
        </button> */}
            </div>
            <div style={{ display: "flex", marginTop: 25 }}>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">
                  Are they especially passionate about supporting any of the
                  following types of businesses?{" "}
                </p>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j1"
                    name="a"
                    className="QuestSeven"
                    value="FemaleLed"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="j1">Female-led</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j2"
                    name="a"
                    className="QuestSeven"
                    value="MadeinUSA"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="j2">Made in USA</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j3"
                    name="a"
                    className="QuestSeven"
                    value="Sustainable"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="j3">Sustainable</label>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <input
                    type="checkbox"
                    id="j4"
                    name="a"
                    className="QuestSeven"
                    value="Charitable"
                    onChange={() => setchecked(!checked)}
                  />
                  <label for="j4">Charitable</label>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">What kind of area do they live in?</p>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v1"
                        name="a"
                        className="QuestEight"
                        checked={area === "Countryside"}
                        value="Countryside"
                        onChange={(e) => areaselection(e)}
                      />
                      <label for="v1">Countryside</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v2"
                        name="a"
                        className="QuestEight"
                        checked={area === "City"}
                        value="City"
                        onChange={(e) => areaselection(e)}
                      />
                      <label for="v2">City</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v3"
                        name="a"
                        className="QuestEight"
                        checked={area === "Suburbs"}
                        value="Suburbs"
                        onChange={(e) => areaselection(e)}
                      />
                      <label for="v3">Suburbs</label>
                    </div>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <input
                        type="checkbox"
                        id="v4"
                        name="a"
                        className="QuestEight"
                        checked={area === "Not Sure"}
                        value="Not Sure"
                        onChange={(e) => areaselection(e)}
                      />
                      <label for="v4">Not Sure</label>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 20, marginRight: 30 }}>
                <p className="checkhead">Which state do you live? </p>
                <div
                  className="ageinput"
                  style={{ display: "flex", alignItems: "center", width: 200 }}
                >
                  <div>
                    <img
                      style={{ width: 15 }}
                      src={top}
                      alt=""
                      onClick={farward}
                    />
                    <br />
                    <img
                      style={{ width: 15 }}
                      src={bottom}
                      alt=""
                      onClick={backward}
                    />
                  </div>
                  <select
                    id="selectstate"
                    className="stateselect"
                    onChange={(e) => setState(e.target.value)}
                    style={{
                      height: 50,
                      border: "none",
                      appearance: "none",
                      width: 125,
                    }}
                  >
                    <option
                      className="value-style"
                      value=""
                      disabled
                      selected
                    ></option>
                    <option className="value-style" value="0">
                      Alabama
                    </option>
                    <option className="value-style" value="1">
                      Alaska
                    </option>
                    <option className="value-style" value="2">
                      Arizona
                    </option>
                    <option className="value-style" value="3">
                      Arkansas
                    </option>
                    <option className="value-style" value="4">
                      California
                    </option>

                    <option className="value-style" value="5">
                      Colorado
                    </option>
                    <option className="value-style" value="6">
                      Connecticut
                    </option>
                    <option className="value-style" value="7">
                      Delaware
                    </option>
                    <option className="value-style" value="8">
                      District of Columbia
                    </option>
                    <option className="value-style" value="9">
                      Florida
                    </option>

                    <option className="value-style" value="10">
                      Georgia
                    </option>
                    <option className="value-style" value="11">
                      Hawaii
                    </option>
                    <option className="value-style" value="12">
                      Idaho
                    </option>
                    <option className="value-style" value="13">
                      Illinois
                    </option>
                    <option className="value-style" value="14">
                      Indiana
                    </option>

                    <option className="value-style" value="15">
                      Iowa
                    </option>
                    <option className="value-style" value="16">
                      Kansas
                    </option>
                    <option className="value-style" value="17">
                      Kentucky
                    </option>
                    <option className="value-style" value="18">
                      Louisiana
                    </option>
                    <option className="value-style" value="19">
                      Maine
                    </option>

                    <option className="value-style" value="20">
                      Maryland
                    </option>
                    <option className="value-style" value="21">
                      Massachusetts
                    </option>
                    <option className="value-style" value="22">
                      Michigan
                    </option>
                    <option className="value-style" value="23">
                      Minnesota
                    </option>
                    <option className="value-style" value="24">
                      Mississippi
                    </option>

                    <option className="value-style" value="25">
                      Missouri
                    </option>
                    <option className="value-style" value="26">
                      Montana
                    </option>
                    <option className="value-style" value="27">
                      Nebraska
                    </option>
                    <option className="value-style" value="28">
                      Nevada
                    </option>
                    <option className="value-style" value="29">
                      New Hampshire
                    </option>

                    <option className="value-style" value="30">
                      New Jersey
                    </option>
                    <option className="value-style" value="31">
                      New Mexico
                    </option>
                    <option className="value-style" value="32">
                      New York
                    </option>
                    <option className="value-style" value="33">
                      North Carolina
                    </option>
                    <option className="value-style" value="34">
                      North Dakota
                    </option>

                    <option className="value-style" value="35">
                      Ohio
                    </option>
                    <option className="value-style" value="36">
                      Oklahoma
                    </option>
                    <option className="value-style" value="37">
                      Oregon
                    </option>
                    <option className="value-style" value="38">
                      Pennsylvania
                    </option>
                    <option className="value-style" value="39">
                      Rhode Island
                    </option>

                    <option className="value-style" value="40">
                      South Carolina
                    </option>
                    <option className="value-style" value="41">
                      South Dakota
                    </option>
                    <option className="value-style" value="42">
                      Tennessee
                    </option>
                    <option className="value-style" value="43">
                      Texas
                    </option>
                    <option className="value-style" value="44">
                      Utah
                    </option>

                    <option className="value-style" value="45">
                      Vermont
                    </option>
                    <option className="value-style" value="46">
                      Virginia
                    </option>
                    <option className="value-style" value="47">
                      Washington
                    </option>
                    <option className="value-style" value="48">
                      West Virginia
                    </option>
                    <option className="value-style" value="49">
                      Wisconsin
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <button
              className="shopsave"
              onClick={OnSaveClick}
              style={{
                fontFamily: "Bungee",
                fontWeight: 400,
                width: 150,
                marginTop: 35,
                display: "block",
                marginLeft: 35,
              }}
            >
              Save
            </button>
          </div>
        </div>

        <Footer />
      </div>

      <div className="mobile">
        <Navbar />
        <div className="firstdiv">
          <img src={userimg} />
        </div>
        <div className="secnddiv">
          <h3>ADD / EDIT GIFTGETTER</h3>

          <div className="inputtype">
            <h3>First Name</h3>
            <input
              onChange={(e) =>
                localStorage.setItem("firstname", e.target.value)
              }
            />
          </div>

          <div className="inputtype">
            <h3>Last Name</h3>
            <input
              onChange={(e) => localStorage.setItem("lastname", e.target.value)}
            />
          </div>

          <div className="inputtype">
            <h3>Nick Name(what you call them)</h3>
            <input
              onChange={(e) => localStorage.setItem("nickname", e.target.value)}
            />
          </div>
        </div>

        <div className="secnddiv">
          <div className="giftocc">
            <h3>Gifting Ocassions</h3>
            {mobileoccuray.length < 5 ? (
              <a
                className="borderbottom"
                onClick={(e) => {
                  setmobileoccuray([...mobileoccuray, { name: "", date: "" }]);
                }}
              >
                ADD
              </a>
            ) : null}
          </div>
          <div className="giftcontainer">
            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper4 redefine"
            >
              {mobileoccuray.map((obj, i) => {
                return (
                  <>
                    <SwiperSlide key={i}>
                      <Giftcard
                        removeappend={removeappendmobile}
                        updateinfo={updateinfo}
                        name={obj.name}
                        date={obj.date}
                        obj={obj}
                        id={i}
                      />
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>

        <div className="secnddiv" style={{ marginTop: 20, marginBottom: 137 }}>
          <div className="giftocc">
            <h3>engine inputs</h3>
            <a className="borderbottom">Edit</a>
          </div>
          <div className="surveyscreen">
            <span
              class="comingsoon"
              style={{ fontSize: 41, textAlign: "center" }}
            >
              COMING SOON
            </span>
            <QuickSurvey occassions={mobileoccuray} addfriend={true} />
          </div>
        </div>
        <Navigationbar />
      </div>
    </>
  );
}
export default giftget;
