import React, { useState } from "react";
import "./giftgetter.css";
import { AddGiftGetter } from "../Services/ServicePhase2";

function giftget(props) {
  const [Fname, setFName] = useState("");
  const [Lname, setLName] = useState("");
  const [NickName, setNickName] = useState("");
  const [addOccassion, setAddOccassion] = useState([1]);

  const Firstname = (e) => {
    setFName(e.target.value);
  };
  const Lastname = (e) => {
    setLName(e.target.value);
  };
  const Nicknames = (e) => {
    setNickName(e.target.value);
  };
  const AddOccassion = () => {
    const temp = [...addOccassion];
    temp.push(1);
    setAddOccassion(temp);
  };
  const RemoveOccassion = (i) => {
    const temp = [...addOccassion];
    temp.splice(i);
    setAddOccassion(temp);
  };

  const Save = () => {
    AddGiftGetter(Fname, Lname, NickName).then((res) => {
      if (res.success == true) {
        props.history.push("/user-dashboard");
      } else {
        alert(res.message);
      }
    });
  };
  return (
    <div>
      <div className="gfire">
        <span className="ggiftext">Add/Edit Giftgetter</span>
        <br />
        <div className="gnewfire">
          <span className="gna">Name</span>
          <span className="gena">Edit</span>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div className="gfistfiil">
              <div className="gspot">
                <span className="glabelin">First</span>
              </div>
              <input
                className="gfipass"
                onChange={Firstname}
                type="text"
                placeholder="First Name"
              />
            </div>
            <div className="gsecfil">
              <div className="gspot">
                <span className="glabelin">Last</span>
              </div>
              <input
                className="gfipass"
                onChange={Lastname}
                type="text"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="gecfil">
            <span className="glabelin">Nickname</span>
            <input
              onChange={Nicknames}
              className="gfipass"
              type="text"
              placeholder="NickName"
            />
          </div>
        </div>
        <div className="gnewfire">
          <span className="gna">Special Occassions</span>
          <span onClick={AddOccassion} className="gena">
            Add new Occassion
          </span>
          <div style={{ display: "flex", marginTop: "20px" }}>
            {addOccassion.map((_, index) => (
              <div key={index}>
                <div className="gfistfiil">
                  <div className="gocas">
                    <span className="glabelin">Occassions#1</span>
                  </div>
                  <input
                    className="gfipass"
                    type="text"
                    placeholder="Occassion"
                  />
                </div>
                <div className="gdatefil">
                  <div className="gocas">
                    <span className="gdatein">Date</span>
                  </div>
                  <input className="gfipass" type="date" placeholder="Date" />
                </div>
                <span
                  onClick={() => RemoveOccassion(index)}
                  style={{
                    float: "right",
                    color: "blue",
                    marginTop: -10,
                    cursor: "pointer",
                  }}
                >
                  Remove
                </span>
              </div>
            ))}
            {/* {addOccassion &&
                                <div>
                                    <div className="gfistfiil">
                                        <div className="gocas">
                                            <span className="glabelin">Occassions#1</span>
                                        </div>
                                        <input className="gfipass" type="text" placeholder="Occassion" />
                                    </div>
                                    <div className="gdatefil">
                                        <div className="gocas">
                                            <span className="gdatein">Date</span>
                                        </div>
                                        <input className="gfipass" type="date" placeholder="Date" />
                                    </div>
                                </div>
                            } */}
          </div>
        </div>
        <div>
          <span className="gnna">Engine Inputs</span>
          <span className="gena">Edit</span>
          <div className="glasttext" contentEditable="true">
            <span className="gentext">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </span>
          </div>
          <button onClick={Save} className="gbutsave">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default giftget;
