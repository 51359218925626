import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./fontawesome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Faq from "./faq/faq";

// import createPosts from "./createPosts";
// import EditPosts from "./EditPosts";
import Navbar from "./Navbar/Navbar";
import AboutUs from "./AboutUS/AboutUs";

import QuickSurvey from "./quickSurvey";
import CsvMain from "./CSV/CsvMain";
import FooterScrollTop from "./Footer/FooterScrollTop";
import ApiResultData from "./Survey-states/results/ResultApiData/ApiResultData";
import sidebarmain from "./Dashboard/Sidebar/sidebar";
import dashboard from "./Dashboard/Dashboard-Main/dashboard";
import Login from "./Dashboard/Login/login";
import AddBrand from "./Dashboard/Brands/AddBrand";
import ViewProducts from "./Dashboard/Brands/ViewProducts";
import QuickSurveyScreen from "./Survey/quickSurvey-Screen";
import ViewBrand from "./Dashboard/Brands/ViewBrand";
import Logout from "./Dashboard/Logout/Logout";
import ExportBrand from "./Dashboard/Brands/ExportBrand";
import UserEmail from "./Dashboard/UserEmail/UserEmail";
import SignUp from "./SignUp/SignUp";
import Verification from "./SignUp/Verification";
import SignIn from "./SignIn/SignIn";
import Discoverpage2 from "./Discover/DiscoverPage2";
import Discoverpage3 from "./Discover/DiscoverPage3";
import AddGiftGetter from "./AddGiftGetter/AddGiftGetter";
import Editgiftgetter from "./AddGiftGetter/Editgiftgetter";

import Discover from "./Discover/Discover";
import discover from "./Discover/DiscoverPage3";
import BrandSubmission from "./BrandSubmissionForm/BrandSubmission";
import TipsSpotlight from "./Tips&Spotlight/Tips&Spotlight";
import DashUser from "./User-Dashboard/Userdashboard";
import giftget from "./Addgiftget/Addgiftget";
import familyfriends from "./Addgiftget/Family&Friends";
import verify from "./Verification/verification";
import Addmyself from "./AddMyself/Addmyself";
import Editmyself from "./AddMyself/Editmyself";
import Addingmyself from "./AddMyself/Addingmyself";
import AddProduct from "./Dashboard/Brands/AddProduct";
import ForgotPassword from "./ForgotPassword/Forgotpassword";
import ResetPassword from "./ForgotPassword/ResetPassword";
import ChangePassword from "./ChangePassword/ChangePassword";
import SignUpMobile from "./SignIn/SignUpMobile";
import Editgiftgettermobile from "./AddGiftGetter/Editgiftgettermobile";
import ApiResultDataMobile2 from "./Survey-states/results/ResultApiData/ApiResultDataMobile2";
import PrivacyPolicy from "../PrivacyPolicy";
import { pageView } from "../ga4Service";

function Example() {
  useEffect(() => {
    pageView("/example");
  }, []);

  return (
    <Router>
      <>
        <Switch>
          <FooterScrollTop>
            <Route path="/" exact component={Home}></Route>
            <Route path="/faq" component={Faq} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/export-test" exact component={CsvMain}></Route>
            <Route path="/data" exact component={ApiResultData}></Route>
            <Route path="/dashboard" exact component={dashboard}></Route>
            <Route path="/login" exact component={Login}></Route>
            <Route path="/addbrand" exact component={AddBrand}></Route>
            <Route path="/addproduct" exact component={AddProduct}></Route>
            <Route path="/userEmail" exact component={UserEmail}></Route>
            <Route path="/viewbrand" exact component={ViewBrand}></Route>
            <Route
              path="/Editgiftgetter"
              exact
              component={Editgiftgetter}
            ></Route>
            <Route path="/survey" exact component={QuickSurveyScreen}></Route>
            <Route path="/logout" exact component={Logout}></Route>
            <Route path="/export" exact component={ExportBrand}></Route>
            <Route path="/sign-up" exact component={SignUp}></Route>
            <Route path="/verification" exact component={Verification}></Route>
            <Route path="/sign-in" exact component={SignIn}></Route>
            <Route
              path="/add-giftGetter"
              exact
              component={AddGiftGetter}
            ></Route>
            <Route path="/discover" exact component={Discover}></Route>
            <Route
              path="/discoverpage2"
              exact
              component={Discoverpage2}
            ></Route>
            <Route
              path="/discoverpage3"
              exact
              component={Discoverpage3}
            ></Route>
            <Route
              path="/brandSubmission"
              exact
              component={BrandSubmission}
            ></Route>
            <Route path="/tips" exact component={TipsSpotlight}></Route>
            <Route path="/dashboard-user" exact component={DashUser}></Route>
            <Route path="/addgiftgetter" exact component={giftget}></Route>
            <Route
              path="/addfriendsandfamily"
              exact
              component={familyfriends}
            ></Route>
            <Route path="/accountverification" exact component={verify}></Route>
            <Route path="/addmyself" exact component={Addmyself}></Route>
            <Route path="/Editmyself" exact component={Editmyself}></Route>
            <Route path="/addingmyself" exact component={Addingmyself}></Route>
            <Route
              path="/forgot-password"
              exact
              component={ForgotPassword}
            ></Route>
            <Route
              path="/change-password"
              exact
              component={ChangePassword}
            ></Route>
            <Route
              path="/reset-password"
              exact
              component={ResetPassword}
            ></Route>
            <Route
              path="/Sign-up-Mobile"
              exact
              component={SignUpMobile}
            ></Route>
            <Route
              path="/Editgiftgettermobile"
              exact
              component={Editgiftgettermobile}
            ></Route>
            <Route path="/data2" exact component={ApiResultDataMobile2}></Route>
            <Route
              path="/privacy-policy"
              exact
              component={PrivacyPolicy}
            ></Route>
          </FooterScrollTop>
        </Switch>
      </>
    </Router>
  );
}

export default Example;

if (document.getElementById("example")) {
  var al = document.getElementById("example").getAttribute("data-id");
  localStorage.setItem("user_id", parseInt(al));
  ReactDOM.render(<Example />, document.getElementById("example"));
}
