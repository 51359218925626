import React, { useContext, useEffect, useState } from "react";
import SignNavbar from "../SignNavbar/SignNavbar";
import Footer from "../Footer/Footer";
import Frame from "../assets/images/UserFrame2.png";
import { GetUserDetails, deleteMember } from "../Services/ServicePhase2";
import gif from "../../../js/components/assets/images/Loading.gif";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { removeFavoriteBrand } from "../Services/service";
import { AuthGuardService, GetGiftGetterById } from "../Services/ServicePhase2";
import { Helmet } from "react-helmet";
import userimg from "../assets/images/Frame 2.svg";
import "./Userdashboard.css";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.css";
function DashUser(props) {
  const [userData, setUserData] = useState("");
  const [MemberDeatail, setMemberDeatail] = useState("");
  const [GiftData, setGiftData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isauth, setisauth] = useState(false);

  const navigatefun = (id) => {
    props.history.push({
      pathname: "/discoverpage3",
      state: { id: id },
    });
  };

  const remove = (obj) => {
    deleteMember(obj).then((result) => {
      if (result.success == true) {
        if (confirm("Are you sure to delete this Giftgetter? ")) {
          let giftList = [];
          GetUserDetails().then((result) => {
            console.log("---->", result.data);
            result.data.gift_getters.map((obj) => {
              giftList.push(
                <div style={{ paddingRight: 60, width: 200, marginBottom: 20 }}>
                  <p
                    style={{ fontSize: 16, fontWeight: 600, color: "#1F294E" }}
                  >
                    {obj.first_name}
                  </p>
                  <a
                    onClick={() => populate(obj.id)}
                    style={{
                      marginBottom: 25,
                      fontSize: 12,
                      color: "#1F294E",
                      borderBottom: "1px solid #F3A493",
                      cursor: "pointer",
                    }}
                  >
                    Shop
                  </a>
                  <br />
                  <Link
                    style={{
                      marginBottom: 25,
                      fontSize: 12,
                      color: "#1F294E",
                      borderBottom: "1px solid #F3A493",
                    }}
                    to={{
                      pathname: "/Editgiftgetter",
                      state: { data: obj.id },
                    }}
                  >
                    Edit
                  </Link>
                  <br></br>
                  <a
                    onClick={() => remove(obj.id)}
                    style={{
                      marginBottom: 25,
                      fontSize: 12,
                      color: "#1F294E",
                      borderBottom: "1px solid #F3A493",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </a>
                  <br />
                </div>
              );
            });
            setGiftData(giftList);
            setUserData(result.data);
            setMemberDeatail(result.data.member_detail);
            setLoading(false);
          });
        }
      }
    });
  };

  useEffect(() => {
    console.log("AUTH SERVICE USER-DASHBOARD");
    AuthGuardService(false)
      .then((result) => {
        console.log("Result >> ", result);
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/sign-in", { isError: true });
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/sign-in", { isError: true });
        console.log("Error : ", err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let giftList = [];
    GetUserDetails().then((result) => {
      console.log("---->", result.data);
      result.data.gift_getters.map((obj) => {
        giftList.push(
          <div style={{ paddingRight: 60, width: 200, marginBottom: 20 }}>
            <p style={{ fontSize: 16, fontWeight: 600, color: "#1F294E" }}>
              {obj.first_name}
            </p>
            <a
              onClick={() => populate(obj.id)}
              style={{
                marginBottom: 25,
                fontSize: 12,
                color: "#1F294E",
                borderBottom: "1px solid #F3A493",
                cursor: "pointer",
              }}
            >
              Shop
            </a>
            <br />
            <Link
              style={{
                marginBottom: 25,
                fontSize: 12,
                color: "#1F294E",
                borderBottom: "1px solid #F3A493",
              }}
              to={{
                pathname: "/Editgiftgetter",
                state: { data: obj.id },
              }}
            >
              Edit
            </Link>
            <br></br>
            <a
              onClick={() => remove(obj.id)}
              style={{
                marginBottom: 25,
                fontSize: 12,
                color: "#1F294E",
                borderBottom: "1px solid #F3A493",
                cursor: "pointer",
              }}
            >
              Remove
            </a>
            <br />
          </div>
        );
      });
      setGiftData(giftList);
      setUserData(result.data);
      setMemberDeatail(result.data.member_detail);
      setLoading(false);
    });
  }, []);

  const OnLogOutClick = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_token");
    props.history.push("/sign-in");
  };

  const changePassword = () => {
    props.history.push("/change-password");
  };

  const populate = (id) => {
    GetGiftGetterById(id).then((result) => {
      localStorage.setItem("age", result.data[0].member_detail.age);

      if (result.data[0].member_detail.gender == "Male") {
        localStorage.setItem("gender", 1);
      } else if (result.data[0].member_detail.gender == "Female") {
        localStorage.setItem("gender", 2);
      } else {
        localStorage.setItem("gender", 3);
      }

      result.data[0].member_detail.question1.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question2.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question3.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question4.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question5.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question6.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      result.data[0].member_detail.question7.split(",").map((obj) => {
        localStorage.setItem(obj, 1);
      });

      localStorage.setItem("State", result.data[0].member_detail.state);
    });

    props.history.push("/data");
  };

  const removelike = (arg) => {
    if (confirm("Are you sure to remove this brand?")) {
      removeFavoriteBrand(arg).then((result) => {
        if (result.success == true) {
          GetUserDetails().then((result) => {
            setUserData(result.data);
            setLoading(false);
          });
        } else {
          alert(result);
        }
      });
    }
  };

  return (
    <>
      {" "}
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>
      <div>
        {isauth ? (
          loading ? (
            <div className="center-loading">
              <img className="loading" type="video/mp4" src={gif} />
            </div>
          ) : (
            <>
              <div className="desktop">
                <Navbar />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10%",
                    marginTop: "3%",
                    marginBottom: -25,
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <img style={{ width: 250 }} src={Frame} alt="" /> <br />
                    <button
                      onClick={OnLogOutClick}
                      className="signbut"
                      style={{
                        fontFamily: "Bungee",
                        marginLeft: 60,
                        width: 123,
                        marginTop: 50,
                      }}
                    >
                      LOG OUT
                    </button>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Bungee",
                        fontSize: "20px",
                        color: "#1F294E",
                        fontWeight: 400,
                      }}
                    >
                      ACCOUNT INFO
                    </p>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Name:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {userData.name}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Primary Email:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {userData.email}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Birthday:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {MemberDeatail ? MemberDeatail.birthday : null}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Password:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        ***********
                      </p>
                      <a
                        onClick={changePassword}
                        style={{
                          marginLeft: 20,
                          marginBottom: 15,
                          fontSize: 14,
                          color: "#1F294E",
                          borderBottom: "3px solid #F3A493",
                          cursor: "pointer",
                        }}
                      >
                        Change Password
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10%",
                  }}
                >
                  <div style={{ width: "30%" }}></div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontFamily: "Bungee",
                          fontSize: "20px",
                          color: "#1F294E",
                          fontWeight: 400,
                        }}
                      >
                        USER ENGINE INFO
                      </p>
                      {MemberDeatail ? (
                        <a
                          onClick={(e) =>
                            props.history.push({
                              pathname: "/Editmyself",
                              state: { userDetail: userData },
                            })
                          }
                          style={{
                            marginLeft: 20,
                            marginBottom: 40,
                            marginTop: 5,
                            fontSize: 12,
                            color: "#1F294E",
                            borderBottom: "3px solid #F3A493",
                          }}
                        >
                          Edit
                        </a>
                      ) : (
                        <a
                          onClick={(e) => props.history.push("/addingmyself")}
                          style={{
                            marginLeft: 20,
                            marginBottom: 40,
                            marginTop: 5,
                            fontSize: 12,
                            color: "#1F294E",
                            borderBottom: "3px solid #F3A493",
                          }}
                        >
                          Edit
                        </a>
                      )}
                    </div>
                    <div style={{ display: "flex", marginTop: -25 }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Age:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {MemberDeatail?.age}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Gender:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {" "}
                        {MemberDeatail?.gender}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        State:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {" "}
                        {MemberDeatail?.state}
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        Urbanity:{" "}
                      </p>
                      <p
                        className="pl-1"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "rgba(31, 41, 78, 0.47)",
                        }}
                      >
                        {" "}
                        {MemberDeatail?.question7}
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: 12,
                        fontStyle: "italic",
                        fontWeight: 400,
                        color: "rgba(31, 41, 78, 0.47)",
                      }}
                    >
                      Edit all engine information (including hobbies, interests,
                      etc.) with ‘Edit’ feature above.
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10%",
                  }}
                >
                  <div style={{ width: "30%" }}></div>
                  <div style={{ width: "60%" }}>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontFamily: "Bungee",
                          fontSize: "20px",
                          color: "#1F294E",
                          fontWeight: 400,
                        }}
                      >
                        SAVED GIFTGETTERS
                      </p>
                      <a
                        onClick={(e) => props.history.push("/addgiftgetter")}
                        style={{
                          marginLeft: 20,
                          marginBottom: 28,
                          marginTop: 5,
                          fontSize: 12,
                          color: "#1F294E",
                          borderBottom: "3px solid #F3A493",
                          cursor: "pointer",
                        }}
                      >
                        ADD
                      </a>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {GiftData}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "3%",
                    alignItems: "center",
                    marginLeft: "10%",
                    marginTop: "3%",
                  }}
                >
                  <div style={{ width: "30%" }}></div>
                  <div style={{ width: "60%" }}>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontFamily: "Bungee",
                          fontSize: "20px",
                          color: "#1F294E",
                          fontWeight: 400,
                        }}
                      >
                        FAVORITED BRANDS
                      </p>
                      <a
                        onClick={(e) => props.history.push("/discover")}
                        style={{
                          marginLeft: 35,
                          marginBottom: 28,
                          marginTop: 4,
                          fontSize: 12,
                          color: "#1F294E",
                          borderBottom: "3px solid #F3A493",
                          cursor: "pointer",
                        }}
                      >
                        DISCOVER MORE
                      </a>
                    </div>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", rowGap: 20 }}
                    >
                      {userData.favorit_brands.slice(0, 9).map((obj) => {
                        return (
                          <div style={{ paddingRight: 60, width: 200 }}>
                            <p
                              title={obj.name}
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#1F294E",
                              }}
                            >
                              {obj.name.length > 12
                                ? obj.name.substring(0, 12) + "..."
                                : obj.name}
                            </p>
                            <a
                              href=""
                              onClick={() => navigatefun(obj.id)}
                              style={{
                                marginBottom: 25,
                                fontSize: 12,
                                color: "#1F294E",
                                borderBottom: "1px solid #F3A493",
                              }}
                            >
                              View
                            </a>
                            <br />
                            <a
                              href={obj.link}
                              target="_blank"
                              style={{
                                marginBottom: 25,
                                fontSize: 12,
                                color: "#1F294E",
                                borderBottom: "1px solid #F3A493",
                              }}
                            >
                              Shop
                            </a>
                            <br />
                            <a
                              onClick={() => removelike(obj.id)}
                              style={{
                                marginBottom: 25,
                                fontSize: 12,
                                color: "#1F294E",
                                borderBottom: "1px solid #F3A493",
                                cursor: "pointer",
                              }}
                            >
                              Remove
                            </a>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>

              {/* Mobile View Start Here */}

              <div className="mobile" style={{ marginTop: 48 }}>
                <Navbar />
                <div className="firstdiv">
                  <img src={userimg} />
                  <button onClick={OnLogOutClick}>Logout</button>
                </div>

                <div className="secnddiv">
                  <h3>Account Info</h3>

                  <div className="inputtype">
                    <div className="firstname">Name:</div>
                    <div className="lastname">{userData.name}</div>
                  </div>

                  <div className="inputtype">
                    <div className="firstname">Primary Email:</div>
                    <div className="lastname">{userData.email}</div>
                  </div>

                  <div className="inputtype">
                    <div className="firstname">Birthday:</div>
                    <div className="lastname">
                      {MemberDeatail ? MemberDeatail.birthday : null}
                    </div>
                  </div>
                  <div className="inputtype">
                    <div className="firstname">Password:</div>
                    <div className="lastname">***********</div>
                  </div>

                  <div className="inputtype">
                    <div
                      onClick={changePassword}
                      style={{
                        borderBottom: "2px solid #F3A493",
                        position: "relative",
                        bottom: "22px",
                        fontSize: 13,
                      }}
                    >
                      CHANGE PASSWORD
                    </div>
                  </div>
                </div>

                <div className="secnddiv">
                  <div className="giftocc">
                    <h3>USER ENGINE INFO</h3>

                    {MemberDeatail ? (
                      <a
                        className="borderbottom"
                        onClick={(e) =>
                          props.history.push({
                            pathname: "/Editmyself",
                            state: { userDetail: userData },
                          })
                        }
                      >
                        Edit
                      </a>
                    ) : (
                      <a
                        className="borderbottom"
                        onClick={(e) => props.history.push("/addingmyself")}
                      >
                        Edit
                      </a>
                    )}
                  </div>
                  <div className="inputtype">
                    <div className="firstname">Age:</div>
                    <div className="lastname">{MemberDeatail?.age}</div>
                  </div>

                  <div className="inputtype">
                    <div className="firstname">Gender:</div>
                    <div className="lastname">{MemberDeatail?.gender}</div>
                  </div>

                  <div className="inputtype">
                    <div className="firstname">State:</div>
                    <div className="lastname">{MemberDeatail?.state}</div>
                  </div>

                  <div className="inputtype">
                    <div className="firstname">Urbanity:</div>
                    <div className="lastname">{MemberDeatail?.question7}</div>
                  </div>

                  <div className="inputtype parateo">
                    Edit all engine information (including hobbies, interests,
                    etc.) with ‘Edit’ feature above.
                  </div>
                </div>

                <div className="secnddiv">
                  <div className="giftocc">
                    <h3>SAVED GIFTGETTERS</h3>
                    <a
                      className="borderbottom"
                      onClick={(e) => props.history.push("/addgiftgetter")}
                    >
                      Add
                    </a>
                  </div>

                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper4 redefine"
                  >
                    {userData.gift_getters.length > 0 ? (
                      <>
                        {userData.gift_getters.map((obj, i) => {
                          return (
                            <>
                              <SwiperSlide>
                                <div
                                  className="occard"
                                  style={{ marginTop: 5 }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 26 29"
                                    fill="none"
                                    onClick={() => remove(obj.id)}
                                  >
                                    <path
                                      d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
                                      fill="#1F294E"
                                    />
                                  </svg>
                                  <div className="innercard colgap">
                                    <span>{obj.first_name}</span>
                                    <button onClick={() => populate(obj.id)}>
                                      Shop
                                    </button>

                                    <a
                                      style={{ color: "black" }}
                                      onClick={() =>
                                        props.history.push({
                                          pathname: "/Editgiftgettermobile",
                                          state: { data: obj.id },
                                        })
                                      }
                                    >
                                      Edit
                                    </a>
                                  </div>
                                </div>
                              </SwiperSlide>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <h2>No Giftgetter Added Yet</h2>
                    )}
                  </Swiper>
                </div>

                <div
                  className="secnddiv"
                  style={{ marginTop: 20, marginBottom: 153 }}
                >
                  <div className="giftocc">
                    <h3>FAVORITED BRANDS</h3>
                    <a
                      className="borderbottom"
                      onClick={() => props.history.push("/discover")}
                    >
                      Discover
                    </a>
                  </div>

                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper4 redefine"
                  >
                    {userData.favorit_brands.slice(0, 9).map((obj) => {
                      return (
                        <SwiperSlide>
                          <div className="occard">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 26 29"
                              fill="none"
                            >
                              <path
                                d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
                                fill="#1F294E"
                              />
                            </svg>
                            <div className="innercard colgap">
                              <span>{obj.name}</span>
                              <button
                                onClick={(event) =>
                                  (window.location.href = obj.link)
                                }
                              >
                                Shop
                              </button>
                              <a
                                style={{ fontSize: 11 }}
                                onClick={() => navigatefun(obj.id)}
                              >
                                View in discover
                              </a>
                            </div>
                          </div>{" "}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>

                <Navigationbar />
              </div>
            </>
          )
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}
export default DashUser;
