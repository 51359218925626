import ReactDOM from "react-dom";
import React, { useState } from "react";
import fbImage from "../assets/images/facebook.png";
import instaImage from "../assets/images/twitter.png";
import footerlogo from "../assets/images/footer-new-logo.png";
import logo from "../assets/images/Grupo_868.png";
import line from "../assets/images/linea.png";
import { Link, useHistory, withRouter } from "react-router-dom";
import fbImg from "../assets/images/face.png";
import pintrest from "../assets/images/pin.png";
import insta from "../assets/images/insta.png";
import tiktok from "../assets/images/tiktok.png";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import "./Footer.css";

function Footer(props) {
  const history = useHistory();

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-3">
              <div className="firstcolumn">
                <Link to="/">
                  <img className="footlogo" src={logo}></img>{" "}
                </Link>
                <img className="footline" src={line} />
              </div>
            </div>
            <div className="col-2" style={{ marginRight: "5%" }}>
              <div className="pt-3">
                <p
                  className="footer-wrapper-text"
                  style={{ fontFamily: "Nunito" }}
                >
                  Thoughts, questions, or concerns? <br /> Send us a message!
                </p>
                <p className="support" style={{ fontFamily: "Nunito" }}>
                  support@outdone.io
                </p>
              </div>
            </div>
            <div className="col-2 pl-0">
              <div className="pt-2">
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("/")
                        : () => history.push("/")
                    }
                  >
                    {" "}
                    Home{" "}
                  </Link>
                </p>
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("/discover")
                        : () => history.push("/discover")
                    }
                  >
                    {" "}
                    Discover{" "}
                  </Link>
                </p>
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("https://blog.outdone.io/")
                        : () =>
                            (window.location.href = "https://blog.outdone.io/")
                    }
                  >
                    {" "}
                    Tips & Spotlights{" "}
                  </Link>
                </p>
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("/faq")
                        : () => history.push("/faq")
                    }
                  >
                    {" "}
                    FAQ{" "}
                  </Link>
                </p>
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("/about-us")
                        : () => history.push("/about-us")
                    }
                  >
                    {" "}
                    About Us{" "}
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-1">
              <div className="pt-2">
                <p
                  className="footer-text-links"
                  style={{ marginBottom: "-8px", fontFamily: "Nunito" }}
                >
                  <Link
                    onClick={
                      props.openmodal
                        ? () => props.openmodal("/privacy-policy")
                        : () => window.open("/privacy-policy", "_blank")
                    }
                  >
                    {" "}
                    Privacy Policy{" "}
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="row">
                <div className=" col-3">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={
                      props.openmodal
                        ? () =>
                            props.openmodal(
                              "https://www.facebook.com/outdone.io/"
                            )
                        : () =>
                            window.open(
                              "https://www.facebook.com/outdone.io/",
                              "_blank"
                            )
                    }
                  >
                    <div className="circle_img">
                      <img height="30" src={fbImg} />
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={
                      props.openmodal
                        ? () =>
                            props.openmodal(
                              "https://www.tiktok.com/@outdone.io"
                            )
                        : () =>
                            window.open(
                              "https://www.tiktok.com/@outdone.io",
                              "_blank"
                            )
                    }
                  >
                    <div className="circle_img">
                      <img height="30" src={tiktok} />
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={
                      props.openmodal
                        ? () =>
                            props.openmodal(
                              "https://www.instagram.com/outdone.io/"
                            )
                        : () =>
                            window.open(
                              "https://www.instagram.com/outdone.io/",
                              "_blank"
                            )
                    }
                  >
                    <div className="circle_img">
                      <img height="30" src={insta} />
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={
                      props.openmodal
                        ? () =>
                            props.openmodal(
                              "https://www.pinterest.com/outdonegifts/"
                            )
                        : () =>
                            window.open(
                              "https://www.pinterest.com/outdonegifts/",
                              "_blank"
                            )
                    }
                  >
                    <div className="circle_img">
                      <img height="30" src={pintrest} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="introbar">
          <p className="introtext">
            © 2022-2023. Outdone Corp. All Rights Reserved.
          </p>
        </div>
      </footer>

      <footer className="footermobile">
        <div className="row">
          <div className="col-6">
            <div className="ml-5">
              <Link
                onClick={
                  props.openmodal
                    ? () => props.openmodal("/")
                    : () => history.push("/")
                }
              >
                <img className="footlogomobile" src={logo}></img>{" "}
              </Link>
              <p className="ftq">
                Thoughts, questions, or concerns? <br /> Send us a message!
                <br />
                <strong>support@outdone.io</strong>
              </p>

              <p className="linked">
                <Link
                  onClick={
                    props.openmodal
                      ? () => props.openmodal("/privacy-policy")
                      : () => window.open("/privacy-policy", "_blank")
                  }
                  style={{ color: "black" }}
                >
                  {" "}
                  Privacy Policy{" "}
                </Link>
              </p>
            </div>
          </div>

          <div className="col-6" style={{ marginTop: 8 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://www.facebook.com/outdone.io/"
                target="_blank"
                style={{ margin: 7 }}
              >
                <div className="circle_img">
                  {" "}
                  <img height="25" src={fbImg} />
                </div>
              </a>

              <a
                href="https://www.tiktok.com/@outdone.io"
                target="_blank"
                style={{ margin: 7 }}
              >
                <div className="circle_img">
                  {" "}
                  <img height="25" src={tiktok} />
                </div>
              </a>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://www.instagram.com/outdone.io/"
                target="_blank"
                style={{ margin: 7 }}
              >
                <div className="circle_img">
                  {" "}
                  <img height="25" src={insta} />
                </div>
              </a>

              <a
                href="https://www.pinterest.com/outdonegifts/"
                target="_blank"
                style={{ margin: 7 }}
              >
                <div className="circle_img">
                  <img height="30" src={pintrest} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <Navigationbar />
    </>
  );
}

export default Footer;
