import React from "react";
import bottom from "../assets/images/Polygon8.png";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Giftcard = (props) => {
  useEffect(() => {
    localStorage.setItem("id", props.id);
    localStorage.setItem("occassionName" + "-" + props.id, props.name);
    localStorage.setItem("occassionDate" + "-" + props.id, props.date);
    $(function () {
      $(`#${props.id}`)
        .datepicker({
          changeYear: false,
          dateFormat: "m/d",
        })
        .focus(function () {
          $(".ui-datepicker-year").hide();
        });
    });

    $(function () {
      $(`#${props.id}`).change(function (e) {
        props.updateinfo(props.id, false, e.target.value);
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>
      <div className="giftcard">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 26 29"
          fill="none"
          onClick={() => props.removeappend(props.id)}
        >
          <path
            d="M3.25884 28.3618C2.43751 28.3618 1.74684 28.1378 1.18684 27.6898C0.664177 27.2045 0.384177 26.5885 0.346844 25.8418C0.346844 25.0951 0.664177 24.3111 1.29884 23.4898L10.3708 12.2898V15.5938L1.85884 5.12179C1.18684 4.30045 0.869511 3.51645 0.906844 2.76979C0.944177 2.02312 1.22418 1.42579 1.74684 0.977786C2.30684 0.492453 2.99751 0.249787 3.81884 0.249787C4.67751 0.249787 5.38684 0.39912 5.94684 0.697786C6.54418 0.996452 7.08551 1.46312 7.57084 2.09779L14.2908 10.7218H11.8268L18.6028 2.09779C19.1255 1.46312 19.6668 0.996452 20.2268 0.697786C20.7868 0.39912 21.4775 0.249787 22.2988 0.249787C23.1575 0.249787 23.8482 0.492453 24.3708 0.977786C24.8935 1.46312 25.1548 2.07912 25.1548 2.82579C25.1922 3.57245 24.8748 4.35645 24.2028 5.17779L15.7468 15.5378V12.4018L24.8188 23.4898C25.4908 24.2738 25.8082 25.0391 25.7708 25.7858C25.7335 26.5325 25.4348 27.1485 24.8748 27.6338C24.3522 28.1191 23.6615 28.3618 22.8028 28.3618C21.9815 28.3618 21.2908 28.2125 20.7308 27.9138C20.1708 27.6151 19.6295 27.1485 19.1068 26.5138L11.8268 17.3298H14.1788L6.95484 26.5138C6.46951 27.1111 5.94684 27.5778 5.38684 27.9138C4.82684 28.2125 4.11751 28.3618 3.25884 28.3618Z"
            fill="#1F294E"
          />
        </svg>

        <select
          defaultValue={props.name}
          onChange={(e) => props.updateinfo(props.id, e.target.value)}
        >
          <option className="value-style" value=""></option>
          <option className="value-style" value="Birthday">
            Birthday
          </option>
          <option className="value-style" value="Wedding Anniversary">
            Wedding Anniversary
          </option>
          <option className="value-style" value="Christmas">
            Christmas
          </option>
          <option className="value-style" value="Hanukkah">
            Hanukkah
          </option>
          <option className="value-style" value="Christmas">
            Christmas
          </option>
          <option className="value-style" value="Valentine’s Day">
            Valentine’s Day
          </option>
          <option className="value-style" value="Mother’s Day">
            Mother’s Day
          </option>
          <option className="value-style" value="Birthday">
            Father’s Day
          </option>
          <option className="value-style" value="Other Special Occasion">
            Other Special Occasion
          </option>
        </select>

        <div className="dateinpo">
          <h3>Date</h3>
          <div className="dageinput">
            <div>
              <input
                type="text"
                defaultValue={props.date}
                id={props.id}
                onChange={(e) =>
                  props.updateinfo(props.id, false, e.target.value)
                }
                className="occassiondate"
                name=""
                style={{ border: "none", width: 100, marginLeft: 10 }}
              />
            </div>
            <div>
              {/* <img style={{width : 8, display : "block", marginBottom : 5,marginTop : 0, marginRight : 6, marginLeft : "auto"}} onClick={()=>datefarward(props.id)}  src={top} alt="" />
                  <img style={{width : 8, display : "block", marginLeft : "auto", marginRight : 6}} src={bottom} onClick={()=>datebackward(props.id)}  alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Giftcard;
