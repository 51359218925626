import React, { useState } from "react";
import "./Forgotpassword.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import ForgotSepareate from "../assets/images/Grupo1230.png";
import { Forgotpassword } from "../Services/ServicePhase2";

function ForgotPassword(props) {
  const [emailForgot, setemailForgot] = useState("");
  const [ForgotLoading, setForgotLoading] = useState(false);
  const [emailexist, setemailexist] = useState(false);
  const [emailsuccess, setemailsuccess] = useState(false);

  const OnForgotPassword = () => {
    setForgotLoading(true);
    Forgotpassword(emailForgot).then((result) => {
      if (result.success == false) {
        if (result.message == "Email not found!") {
          setemailexist(true);
        }
        setForgotLoading(false);
        // alert(result.message)
      } else {
        setemailsuccess(true);
        setemailexist(false);
        setForgotLoading(false);
      }
    });
  };

  return (
    <>
      <div className="desktop">
        <div className="forgotclassimage">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor: "#fff",
                width: "35%",
                paddingTop: 25,
                paddingBottom: 0,
                paddingLeft: 25,
                paddingRight: 25,
                marginRight: 30,
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Bungee",
                  fontSize: 28,
                  marginBottom: -25,
                  fontWeight: 400,
                  color: "#1F294E",
                  textAlign: "center",
                }}
              >
                FORGOT PASSWORD
              </p>
              <img
                className="ForgotCenter"
                style={{ width: 110, marginBottom: -20, marginTop: 10 }}
                src={ForgotSepareate}
                alt=""
              />
              {emailsuccess ? (
                <div>
                  <p className="succesmessage">
                    Email successfully sent to your email address
                  </p>
                </div>
              ) : (
                <div>
                  {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button> */}
                </div>
              )}
              <div className="forgotfieldtype" style={{ height: 100 }}>
                <input
                  className="ForgotCenter"
                  style={{ width: "80%" }}
                  type="email"
                  onChange={(e) => setemailForgot(e.target.value)}
                  placeholder="Email"
                />
                {emailexist ? (
                  <div>
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: 7,
                        fontSize: 12,
                      }}
                    >
                      Email is not registered
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button> */}
                  </div>
                )}
              </div>
              <div className="btnLoader">
                {ForgotLoading && (
                  <div
                    class="spinner-border"
                    style={{ position: "absolute", right: 20, top: 5 }}
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                )}

                <div>
                  <button
                    className="ForgotButton"
                    onClick={OnForgotPassword}
                    style={{ fontFamily: "Bungee" }}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="forgotclassimage">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                backgroundColor: "#fff",
                width: "100%",
                paddingTop: 25,
                paddingBottom: 0,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  fontFamily: "Bungee",
                  fontSize: 15,
                  marginBottom: -25,
                  fontWeight: 400,
                  color: "#1F294E",
                  textAlign: "center",
                }}
              >
                FORGOT PASSWORD
              </p>
              <img
                className="ForgotCenter"
                style={{ width: 50, marginBottom: -20, marginTop: 10 }}
                src={ForgotSepareate}
                alt=""
              />
              {emailsuccess ? (
                <div>
                  <p className="succesmessage">
                    Email successfully sent to your email address
                  </p>
                </div>
              ) : (
                <div>
                  {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button> */}
                </div>
              )}
              <div className="forgotfieldtype" style={{ height: 62 }}>
                <input
                  className="ForgotCenter"
                  style={{ height: 40, width: "90%", marginTop: 20 }}
                  type="email"
                  onChange={(e) => setemailForgot(e.target.value)}
                  placeholder="Email"
                />
                {emailexist ? (
                  <div>
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: 7,
                        fontSize: 12,
                      }}
                    >
                      Email is not registered
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <button className="ForgotButton" onClick={OnForgotPassword} style={{ fontFamily: "Bungee" }} >CONTINUE</button> */}
                  </div>
                )}
              </div>
              <div className="btnLoader">
                {ForgotLoading && (
                  <div
                    class="spinner-border"
                    style={{ position: "absolute", right: 0, top: 5 }}
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                )}

                <div>
                  <button
                    className="ForgotButton"
                    onClick={OnForgotPassword}
                    style={{ fontFamily: "Bungee" }}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ForgotPassword;
