import React from "react";
import crown from "../../../assets/images/recoCrwon.svg";
import whitewav from "../../../assets/images/whitewav.png";
import homeicon from "../../../assets/images/Home.svg";
import { useState } from "react";
import ProductmanuplationMobile from "./ProductmanuplationMobile";
import { useHistory } from "react-router-dom";
const ApiResultDataMobile2 = (props) => {
  console.log("data 2 =>");
  console.log(props.location.state.data);

  const [loading, isLoading] = useState(true);
  const [data, setData] = useState(props.location.state.data);
  const [selectedBrand, setSelectedBrand] = useState(props.location.state.id);
  const [getId, setGetId] = useState(props.location.state.id);
  const [url, seturl] = useState();

  const history = useHistory();
  const GetId = (id) => {
    if (selectedBrand != id) {
      setSelectedBrand(id != selectedBrand ? id : null);
      setGetId(id != selectedBrand ? id : null);
    }
  };

  console.log(props.location.state.id);
  console.log(props.location.state.id);

  const openmodal2 = () => {
    $("#exampleModalCenter2").modal("show");
    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const stylemodal2 = {
    position: "absolute",
    width: 327,
    height: 304,

    top: "50%",
    left: "50%",

    transform: "translateX(-50%) translateY(-50%)",
    background: "#FFFFFF",
    border: "3px solid #1F294E",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: 15,
  };

  const navigate2 = () => {
    $("#exampleModalCenter2").modal("hide");
    history.push("/");
  };

  const dismiss = () => {
    $("#exampleModalCenter").modal("hide");
  };

  const dismiss2 = () => {
    $("#exampleModalCenter2").modal("hide");
  };
  return (
    <div className="mobile1">
      {!loading ? (
        <div className="center-loading">
          <img className="loading" type="video/mp4" src={gif} />
        </div>
      ) : (
        <>
          <div className="header2">Recommendations are in!</div>

          <div className="brand1">
            {data.map((row, i) => (
              <>
                <div
                  onClick={() => GetId(row.id)}
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    position: "relative",
                  }}
                >
                  {i == 0 ? (
                    <>
                      <div className="crown3">
                        <img className="crown-img active" src={crown} />
                      </div>
                      <img
                        className={
                          row.id == selectedBrand
                            ? "imgsrc2 activety"
                            : "imgsrc2"
                        }
                        src={row.image_url ? row.image_url : noimage}
                      />
                    </>
                  ) : (
                    <img
                      className={
                        row.id == selectedBrand ? "imgsrc2 activety" : "imgsrc2"
                      }
                      src={row.image_url ? row.image_url : noimage}
                    />
                  )}
                </div>
              </>
            ))}
          </div>

          <div className="centerdiv">
            {data.map((row, i) => (
              <>
                {getId == row.id && (
                  <>
                    <h4>{row.name}</h4>
                    <div>
                      {" "}
                      <p className="head">
                        {row.headquarters} {row.year_founded}{" "}
                      </p>
                    </div>
                    <p>{row.mission} </p>
                  </>
                )}
              </>
            ))}
          </div>

          <div className="purplediv">
            <img src={whitewav} style={{ width: "100%" }} />

            {data.map((row, i) => (
              <>
                {getId == row.id && (
                  <>
                    {row.brand_products.length > 1 && (
                      <h2 className="shopee">Click to shop</h2>
                    )}
                    <ProductmanuplationMobile
                      brandname={row.name}
                      brandproduct={row.brand_products}
                    />
                    {row.brand_products.length > 1 && (
                      <h2 className="shopee2">Swipe to browse</h2>
                    )}
                  </>
                )}
              </>
            ))}
          </div>
          <div className="homenav">
            <img onClick={openmodal2} src={homeicon} />
          </div>
        </>
      )}

      <div
        className="modal fade"
        style={stylemodal2}
        id="exampleModalCenter2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ height: 290, margin: 0 }}
          role="document"
        >
          <div
            class="modal-content"
            style={{ textAlign: "center", marginTop: 28 }}
          >
            <p className="para">
              You will not be able to return to these recommendations after
              leaving this page.{" "}
            </p>
            <p className="para">Are you sure you’d like to leave?</p>

            <div>
              <button
                onClick={() => navigate2()}
                className="btnsize"
                style={{
                  marginRight: 15,
                  backgroundColor: "rgba(190, 191, 199, 0.58)",
                }}
              >
                Yes
              </button>
              <button
                className="btnsize"
                onClick={() => dismiss2()}
                style={{
                  marginLeft: 15,
                  backgroundColor: "rgba(172, 244, 193, 0.55)",
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiResultDataMobile2;
