import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import SidebarMain from "../Sidebar/sidebar";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { getEmails, DeleteUser } from "../Services/Services";
import { AuthGuardService } from "../../Services/ServicePhase2";
import Swal from "sweetalert2";

function UserEmail(props) {
  const [data, setdata] = useState();
  const [cdata, setcdata] = useState();
  const [loading, setloading] = useState(true);

  const [isauth, setisauth] = useState(false);
  const [art, setart] = useState(true);

  useEffect(() => {
    console.log("AUTH SERVICE DASHBOARD");
    AuthGuardService()
      .then((result) => {
        console.log("Result >> ", result);
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
        console.log("Error : ", err);
      });
  }, []);

  useEffect(() => {
    getEmails().then((result) => {
      let responseJSON = result;
      setdata(responseJSON.data);
      setcdata(responseJSON.data);
      setloading(false);
    });
  }, []);

  const handlesearch = (e) => {
    setart(false);
    const arr = cdata.filter((obj) => {
      if (
        obj.name.includes(e.target.value) ||
        obj.email.includes(e.target.value)
      ) {
        return obj;
      }
    });

    setdata(arr);
    setart(true);
  };

  function exportcsv() {
    var json = data;
    var fields = Object.keys(json[0]);
    var replacer = function (key, value) {
      return value === null ? "" : value;
    };
    var csv = json.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    csv = csv.join("\r\n");

    let csvContent = "data:text/csv;charset=utf-8," + csv;
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users_detail.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  function deletemember(id) {
    if (confirm("Are you sure? you want to delete?")) {
      DeleteUser(id).then((result) => {
        if (result.success == true) {
          var arr = data.filter((obj) => {
            if (obj.id !== id) return obj;
          });
          setdata(arr);
          Swal.fire("User deleted successfully", "", "success");
        }
      });
    }
  }

  return isauth ? (
    loading ? (
      <Loader
        className="text-center loader"
        type="Oval"
        color="#323A40"
        height={200}
        width={200}
      />
    ) : (
      <Fragment>
        <section className="dashboard-page">
          <div className="container-fluid p-l">
            <div className="row">
              <div className="full-dash">
                <div className="side-bar-dash">
                  <SidebarMain />
                </div>
                <div className="dashboard-content">
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{ height: 1100, overflowY: "scroll" }}
                    >
                      <div className="table-dash">
                        <div className="flex2">
                          <div>
                            <table className="mytable">
                              <tr>
                                <td>Total Users</td>
                                <td>{data.length}</td>
                              </tr>
                              <tr>
                                <td>Verified Users</td>
                                <td>
                                  {
                                    data.filter((obj) => {
                                      if (obj.email_verified_at) return obj;
                                    }).length
                                  }
                                </td>
                              </tr>
                            </table>
                          </div>
                          <input
                            type="text"
                            className="searchinput form-control"
                            placeholder="Search"
                            onChange={(e) => handlesearch(e)}
                          />
                        </div>
                        <div>
                          {" "}
                          <input
                            type="button"
                            value="Export CSV"
                            onClick={exportcsv}
                            className="data-bar2"
                          />
                        </div>
                        <table class="table">
                          <thead>
                            <th>Id</th>
                            <th>Name</th>
                            <th>User Email</th>
                            <th>Verify Date</th>
                            <th></th>
                          </thead>
                          <tbody>
                            {art &&
                              data.map((row, i) => (
                                <tr>
                                  <td data-label="S.No">{row.id}</td>
                                  <td data-label="Brand Name">{row.name}</td>
                                  <td data-label="Brand Name">{row.email}</td>
                                  <td data-label="Brand Name">
                                    {row.email_verified_at &&
                                      row.email_verified_at.substring(0, 10)}
                                  </td>
                                  <td>
                                    <button
                                      className="data-bar2"
                                      onClick={() => deletemember(row.id)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  ) : (
    <div></div>
  );
}

export default UserEmail;
