import React from "react";
import axios from "axios";
import SidebarMain from "../Sidebar/sidebar";
import "./Brand.css";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { withRouter } from "react-router";

import { importBrandCSV } from "../../Services/ServicePhase2";
import { AuthGuardService } from "../../Services/ServicePhase2";
import { faShekelSign } from "@fortawesome/free-solid-svg-icons";

class FileUploadForm extends React.Component {
  UPLOAD_ENDPOINT = "https://api.outdone.io/api/brand_create";

  IMPORT_BRAND_ENDPOINT = "https://api.outdone.io/api/importBrands";

  constructor(props) {
    super(props);
    this.state = {
      isauth: false,
      file: "",
      importFile: null,
      bName: "",
      bId: "",
      bYear: "",
      Bhead: "",
      bDes: "",
      bCat1: "",
      bCat2: "",
      bCat3: "",
      link: "",
      aboutUsUrl: "",
      gender: "",
      priceCatagory: "",
      productCatagory: "",
      productFocus: "",
      productName: "",
      primaryUrl: "",
      backupUrl: "",
      productDescription: "",
      ProductFile: "",
      productPrice: "",
      ageTag: "",
      genderTag: "",
      btLoading: false,
      mission: "",
      lifstyle: "",
      speciality: "",
      image: "",
      madeinusa: "",
      femaleled: "",
      blackled: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);

    this.onChangeImportFile = this.onChangeImportFile.bind(this);
    this.importFile = this.importFile.bind(this);

    this.onAuthVerify = this.onAuthVerify.bind(this);

    this.onChangeNmae = this.onChangeNmae.bind(this);
    this.onChangeId = this.onChangeId.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.onChangeHead = this.onChangeHead.bind(this);
    this.onChangebDes = this.onChangebDes.bind(this);
    this.onChangebCat1 = this.onChangebCat1.bind(this);
    this.onChangebCat2 = this.onChangebCat2.bind(this);
    this.onChangebCat3 = this.onChangebCat3.bind(this);
    this.onChangelink = this.onChangelink.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onChangeAboutUrl = this.onChangeAboutUrl.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangePriceCatagory = this.onChangePriceCatagory.bind(this);
    this.onChangeProductCatagory = this.onChangeProductCatagory.bind(this);
    this.onChangeProductFocus = this.onChangeProductFocus.bind(this);

    this.onChangeProductFile = this.onChangeProductFile.bind(this);
    this.onChangeProductName = this.onChangeProductName.bind(this);
    this.onChangePrimaryUrl = this.onChangePrimaryUrl.bind(this);
    this.onChangeBackupUrl = this.onChangeBackupUrl.bind(this);
    this.onChangeProductDes = this.onChangeProductDes.bind(this);
    this.onChangeProductPrice = this.onChangeProductPrice.bind(this);
    this.onChangeAgeTag = this.onChangeAgeTag.bind(this);
    this.onChangeGenderTag = this.onChangeGenderTag.bind(this);
    this.onMissionChange = this.onMissionChange.bind(this);
    this.onLifestyleChange = this.onLifestyleChange.bind(this);
    this.onSpecialityChange = this.onSpecialityChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.onMadeInUsaChange = this.onMadeInUsaChange.bind(this);
    this.onFemaleLedChange = this.onFemaleLedChange.bind(this);
    this.onBlackLedChange = this.onBlackLedChange.bind(this);
  }

  componentDidMount() {
    AuthGuardService()
      .then((result) => {
        if (result.success == true) {
          this.setState({ isauth: true });
        } else {
          this.setState({ isauth: false });
          this.props.history.push("/login", { showError: true });
        }
      })
      .catch((err) => {
        this.setState({ isauth: false });
        this.props.history.push("/login", { showError: true });
      });
  }

  async onSubmit(e) {
    const { history } = this.props;
    e.preventDefault();

    document.getElementById("fillee").checkValidity();
    if (this.state.bName == "") {
      return false;
    } else if (this.state.bId == "") {
      return false;
    }

    const res = await this.uploadFile(
      this.state.file,
      this.state.bName,
      this.state.bId,
      this.state.bYear,
      this.state.Bhead,
      this.state.bDes,
      this.state.bCat1,
      this.state.bCat2,
      this.state.bCat3,
      this.state.link,
      this.state.aboutUsUrl,
      this.state.gender,
      this.state.priceCatagory,
      this.state.productCatagory,
      this.state.productFocus,
      this.state.productName,
      this.state.primaryUrl,
      this.state.backupUrl,
      this.state.productDescription,
      this.state.ProductFile,
      this.state.productPrice,
      this.state.ageTag,
      this.state.genderTag,
      this.state.mission,
      this.state.lifstyle,
      this.state.speciality,
      this.state.image,
      this.state.madeinusa,
      this.state.femaleled,
      this.state.blackled
    );
    const dataa = res.data;

    if (dataa.success == true) {
      history.push("/viewbrand");
    } else {
      alert(dataa.message);
    }
  }

  onAuthVerify() {}

  onChangeFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  onChangeImportFile(e) {
    this.setState({ importFile: e.target.files[0] });
  }

  onChangeNmae(e) {
    this.setState({ bName: e.target.value });
  }
  onChangelink(e) {
    this.setState({ link: e.target.value });
  }
  onChangeAboutUrl(e) {
    this.setState({ aboutUsUrl: e.target.value });
  }
  onChangeGender(e) {
    this.setState({ gender: e.target.value });
  }
  onChangePriceCatagory(e) {
    this.setState({ priceCatagory: e.target.value });
  }
  onChangeProductCatagory(e) {
    this.setState({ productCatagory: e.target.value });
  }
  onChangeProductFocus(e) {
    this.setState({ productFocus: e.target.value });
  }
  onChangeId(e) {
    this.setState({ bId: e.target.value });
  }
  onChangeYear(e) {
    this.setState({ bYear: e.target.value });
  }
  onChangeHead(e) {
    this.setState({ Bhead: e.target.value });
  }
  onChangebDes(e) {
    this.setState({ bDes: e.target.value });
  }
  onChangebCat1(e) {
    this.setState({ bCat1: e.target.value });
  }
  onChangebCat2(e) {
    this.setState({ bCat2: e.target.value });
  }
  onChangebCat3(e) {
    this.setState({ bCat3: e.target.value });
  }

  onChangeProductFile(e) {
    this.setState({ ProductFile: e.target.files[0] });
  }
  onChangeProductName(e) {
    this.setState({ productName: e.target.value });
  }
  onChangePrimaryUrl(e) {
    this.setState({ primaryUrl: e.target.value });
  }
  onChangeBackupUrl(e) {
    this.setState({ backupUrl: e.target.value });
  }
  onChangeProductPrice(e) {
    this.setState({ productPrice: e.target.value });
  }
  onChangeAgeTag(e) {
    this.setState({ ageTag: e.target.value });
  }
  onChangeGenderTag(e) {
    this.setState({ genderTag: e.target.value });
  }
  onChangeProductDes(e) {
    this.setState({ productDescription: e.target.value });
  }
  onMissionChange(e) {
    this.setState({ mission: e.target.value });
  }
  onLifestyleChange(e) {
    this.setState({ lifstyle: e.target.value });
  }
  onSpecialityChange(e) {
    this.setState({ speciality: e.target.value });
  }
  onImageChange(e) {
    this.setState({ image: e.target.value });
  }
  onMadeInUsaChange(e) {
    this.setState({ madeinusa: e.target.value });
  }
  onFemaleLedChange(e) {
    this.setState({ femaleled: e.target.value });
  }
  onBlackLedChange(e) {
    this.setState({ blackled: e.target.value });
  }
  async importFile() {
    let res;
    const { history } = this.props;

    if (this.state.importFile != null) {
      this.setState({ btLoading: true });
      let newFormData = new FormData();
      newFormData.append("file", this.state.importFile);

      let token = localStorage.getItem("admin_token");
      res = await axios.post(this.IMPORT_BRAND_ENDPOINT, newFormData, {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      let ele = document.getElementById("Brand-import");
      ele.value = "";
      if (res.data.success == true) {
        this.setState({ btLoading: false });
        alert(res.data.message);
        history.push("/viewbrand");
      } else {
        this.setState({ btLoading: false });
        alert(res.data.message);
        return false;
      }
    } else {
      alert("Select a file to import ");
      return false;
    }
  }

  async uploadFile(
    file,
    bname,
    bId,
    byear,
    bhead,
    bdes,
    bcat1,
    bcat2,
    bcat3,
    link,
    aboutUsUrl,
    gender,
    priceCatagory,
    productCatagory,
    productFocus,
    produceName,
    productPrimaryUrl,
    productBackupUrl,
    productDes,
    productImg,
    price,
    age,
    Pgender,
    mission,
    lifestyle,
    speciality,
    image,
    madeinusa,
    female,
    black
  ) {
    var formData = new FormData();
    formData.append("name", bname);
    formData.append("brand_id", bId);
    formData.append("year_founded", byear);
    formData.append("headquarters", bhead);
    formData.append("description", bdes);
    formData.append("link", link);
    formData.append("about_us_url", aboutUsUrl);
    formData.append("gender", gender);
    formData.append("price_category", priceCatagory);
    formData.append("product_category", productCatagory);
    formData.append("product_focus", productFocus);
    formData.append("product_name", produceName);
    formData.append("product_description", productDes);
    formData.append("primary_url", productPrimaryUrl);
    formData.append("backup_url", productBackupUrl);
    formData.append("price", price);
    formData.append("age_tag", age);
    formData.append("price_tag", "");
    formData.append("gender_tag", Pgender);
    formData.append("image", image);
    formData.append("mission", mission);
    formData.append("lifestyle_imagery", lifestyle);
    formData.append("specialty", speciality);
    formData.append("made_in_usa", madeinusa);
    formData.append("female_led", female);
    formData.append("black_led", black);

    return await axios.post(this.UPLOAD_ENDPOINT, formData, {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    });
  }

  render() {
    return this.state.isauth ? (
      <div className="full-dash">
        <div className="side-bar-dash">
          <SidebarMain />
        </div>

        <div className="add-Brand">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-form" style={{ height: "125px" }}>
                <div className="contact-image"></div>
                {/* <form onSubmit={this.importFile} id="fillee"> */}
                <div style={{ marginTop: "12%" }} className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <div>
                      <h3 className="mt-3">Import Brand</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            style={{ padding: "6px 0 0 10px" }}
                            type="file"
                            id="Brand-import"
                            name="text"
                            maxLength="15"
                            className="form-control"
                            placeholder="Backup URL"
                            onChange={this.onChangeImportFile}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="btnContact mb-3"
                          disabled={this.state.btLoading ? true : false}
                          onClick={this.importFile}
                        >
                          {this.state.btLoading ? "...Importing..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>

        <div className="add-Brand">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-form" style={{ height: "1200px" }}>
                <div className="contact-image"></div>
                <form onSubmit={this.onSubmit} id="fillee">
                  <div className="row mt-5 pt-3">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 mt-1">
                      <div>
                        <h3 className="mt-3">Add Brand</h3>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <span style={{ color: "red" }} id="BNAME"></span>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Name
                                </span>
                              </div>
                              <input
                                type="text"
                                onChange={this.onChangeNmae}
                                name="text"
                                maxLength="35"
                                className="form-control"
                                id="bName"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Id
                                </span>
                              </div>
                              <input
                                type="text"
                                onChange={this.onChangeId}
                                name="text"
                                maxLength="35"
                                className="form-control"
                                id="bName"
                                required
                              />
                            </div>

                            <span style={{ color: "red" }} id="BNAME"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Year Founded
                                </span>
                              </div>
                              <input
                                type="text"
                                onChange={this.onChangeYear}
                                name="text"
                                maxLength="4"
                                className="form-control"
                                id="bYear"
                              />
                            </div>
                            <span style={{ color: "red" }} id="BYEAR"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Headquarters
                                </span>
                              </div>
                              <input
                                type="text"
                                onChange={this.onChangeHead}
                                name="text"
                                maxLength="35"
                                className="form-control"
                                id="bHead"
                              />
                            </div>
                            <span style={{ color: "red" }} id="BHEAD"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  Brand Description
                                </span>
                              </div>
                              <textarea
                                type="text"
                                onChange={this.onChangebDes}
                                name="text"
                                maxLength="800"
                                className="form-control"
                                id="bDes"
                              ></textarea>
                            </div>

                            <span style={{ color: "red" }} id="BDES"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Climate Neutral Certified
                                </span>
                              </div>

                              <select
                                class="custom-select"
                                onChange={this.onChangebCat1}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <span style={{ color: "red" }} id="BTAG1"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Certified B Corporation
                                </span>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onChangebCat2}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <span style={{ color: "red" }} id="BTAG2"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  1% For The Planet
                                </span>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onChangebCat3}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>

                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="inputGroupSelect01"
                                >
                                  Made in USA
                                </label>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onMadeInUsaChange}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <span style={{ color: "red" }} id="BTAG1"></span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="inputGroupSelect01"
                                >
                                  Female-Led
                                </label>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onFemaleLedChange}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <span style={{ color: "red" }} id="BTAG2"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="inputGroupSelect01"
                                >
                                  Black-Led
                                </label>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onBlackLedChange}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Speciality
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onSpecialityChange}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Lifestyle Image URL
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onLifestyleChange}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Brand Link
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onChangelink}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  About Us URL
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onChangeAboutUrl}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <label
                                  class="input-group-text"
                                  for="inputGroupSelect01"
                                >
                                  Gender
                                </label>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onChangeGender}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Price Category
                                </span>
                              </div>
                              <select
                                class="custom-select"
                                onChange={this.onChangePriceCatagory}
                                id="inputGroupSelect01"
                              >
                                <option selected disabled>
                                  Choose...
                                </option>
                                <option value="$50-$150">$50-$150</option>
                                <option value="<50">&lt;$50</option>
                                <option value=">$150">&gt;$150</option>
                              </select>

                              <span style={{ color: "red" }} id="BTAG3"></span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Product Category
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onChangeProductCatagory}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Image URL
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onImageChange}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>

                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  Product Focus
                                </span>
                              </div>

                              <input
                                type="text"
                                onChange={this.onChangeProductFocus}
                                name="text"
                                maxLength="200"
                                className="form-control"
                                id="bTag3"
                              />
                            </div>
                            <span style={{ color: "red" }} id="BTAG3"></span>
                          </div>
                          <div className="form-group">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  Brand Mission
                                </span>
                              </div>
                              <textarea
                                type="text"
                                onChange={this.onMissionChange}
                                name="text"
                                maxLength="800"
                                className="form-control"
                                id="bDes"
                              ></textarea>
                            </div>

                            <span style={{ color: "red" }} id="BDES"></span>
                          </div>
                          <div className="form-group">
                            <button
                              className="btnContact mb-3"
                              style={{ marginRight: 250, marginTop: 20 }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                  {/*     <div style={{ marginTop: "12%" }} className="row">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <div>
                          <h3 className="mt-3">Add Product</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Product Name"
                                onChange={this.onChangeProductName}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Primary URL"
                                onChange={this.onChangePrimaryUrl}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Backup URL"
                                onChange={this.onChangeBackupUrl}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Product Price"
                                onChange={this.onChangeProductPrice}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Age"
                                onChange={this.onChangeAgeTag}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="text"
                                maxLength="15"
                                className="form-control"
                                placeholder="Gender"
                                onChange={this.onChangeGenderTag}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <textarea
                                type="text"
                                name="text"
                                maxLength="800"
                                className="form-control"
                                placeholder="Product Description"
                                onChange={this.onChangeProductDes}
                              ></textarea>
                            </div>
                            <div className="form-group files">
                              <label>Upload Product Image </label>
                              <input
                                type="file"
                                onChange={this.onChangeProductFile}
                                className="form-control-dash"
                                multiple=""
                              />
                            </div>

                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div>*/}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <p></p>
      </div>
    );
  }
}

export default FileUploadForm;
