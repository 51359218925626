import React, { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import logo from "../../components/assets/images/footer_logo.png";
import "./SignUp.css";
import { VerifyEmail } from "../Services/ServicePhase2";

function Verification(props) {
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const OtpVerify = () => {
    setLoading(true);
    VerifyEmail(OTP).then((result) => {
      if (result.success == true) {
        setLoading(false);
        props.history.push("/account_info");
      } else {
        setLoading(false);
        alert(result.message);
      }
    });
  };
  return (
    <>
      <div className="Vydiv">
        <div className="vediv">
          <img className="verimg" src={logo} />
          <p>Please Enter OTP</p>
          <div className="otop">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          <button
            onClick={OtpVerify}
            className="mt-3 btn btn-info"
            style={{ float: "right" }}
          >
            {loading ? (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              "Verify"
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default Verification;
