import ReactDOM from "react-dom";
import React, { useState } from "react";
import "./Accordin.css";
import { Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import tryitout from "../assets/images/Get started Button.png";
import NewsLetter from "../Newsletter/NewsLetter";

function Accordin(props) {
  const { history } = props;

  const moveSurvey = () => {
    // window.localStorage.clear();
    history.push("/survey");
  };

  return (
    <>
      <section className="color-wrapper-faq ">
        <div className="bg_Container">
          <div className="container ">
            <div
              style={{ backgroundColor: "#fff", borderRadius: 10, padding: 50 }}
              className="accordion mt-4 accordionExample"
              id="accordionExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button open-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <h3 className="heading-setting">What is Outdone?</h3>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      Outdone is the go-to destination to find great gifts. We
                      firmly believe that special occasions should be
                      celebrated, not stressed over. So, we’re here to help you
                      stress less and celebrate more! Visit our About Us page to
                      learn more.
                    </p>
                    <div className="image-try-out">
                      <button
                        style={{ fontFamily: "Bungee" }}
                        className="button-try-out  onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button
                          className="try-out"
                          onClick={moveSurvey}
                          style={{ fontFamily: "Bungee" }}
                        >
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button open-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h3 className="heading-setting">
                      {" "}
                      How does the Ai-powered gifting tool know what to
                      recommend?
                    </h3>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      After surveying tens of thousands of consumers about their
                      favorite brands and products, we’ve developed an
                      Ai-powered recommendation engine to predict which brands
                      your giftgetter is most likely to enjoy. We surveyed
                      consumers young and old to ensure our engine has a
                      complete picture of each and every giftgetter you may be
                      shopping for.
                    </p>
                    <div className="image-try-out">
                      <button
                        className="button-try-out onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button className="try-out" onClick={moveSurvey}>
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button open-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h3 className="heading-setting">
                      Do brands pay to get recommended to shoppers?
                    </h3>
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      Nope! Our engine only recommends brands that perfectly
                      match your giftgetter’s unique personality and interests,
                      according to our survey research. At Outdone, data does
                      the talking — we’ll never let money undermine our
                      commitment to only recommend the best of the best.
                    </p>
                    <div className="image-try-out">
                      <button
                        className="button-try-out onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button className="try-out" onClick={moveSurvey}>
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button open-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h3 className="heading-setting">
                      How do you decide which brands to partner with?
                    </h3>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      Each brand featured on our site is hand-selected for its
                      quality and positive mission. We only partner with brands
                      that are making high quality goods and are pushing
                      positive brand stories we believe in. For a full breakdown
                      of our partnership criteria, check out our Brand
                      Submission page!
                    </p>
                    <div className="image-try-out">
                      <button
                        className="button-try-out onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button className="try-out" onClick={moveSurvey}>
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button open-button collapsed panel-title"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    data-iconpos="right"
                  >
                    <h3 className="heading-setting">
                      How does Outdone make money?
                    </h3>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      Good question, we get this one a lot :)
                      <br />
                      <br />
                      Whenever you complete a purchase from a recommended
                      brand’s website, we will receive a small cut of that sale.
                      This has no impact on what you pay, though. Brands pay us
                      this commision, sort of as a “thank you” for us sending
                      you their way.
                      <br />
                      <br />
                      But again, brands can NEVER pay their way up the
                      recommendation rankings!
                    </p>
                    <div className="image-try-out">
                      <button
                        className="button-try-out onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button className="try-out" onClick={moveSurvey}>
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button open-button collapsed panel-title"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    data-iconpos="right"
                  >
                    <h3 className="heading-setting">
                      Have you thought about recommending other types of gifts?
                    </h3>
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="item-text">
                      Yes — we like how you think!
                      <br />
                      <br />
                      In its current form, our recommender tool covers a variety
                      of gifting categories, including apparel, accessories,
                      homegoods, toys, and jewelry. But there are tons of other
                      gifts out there you could be considering, and we
                      absolutely want to help you shop for those as well. We’re
                      working hard to get all of these different categories
                      (like tech devices, virtual classes, foods, and more)
                      added to our engine soon!!
                    </p>
                    <div className="image-try-out">
                      <button
                        className="button-try-out onMobile"
                        onClick={moveSurvey}
                      >
                        TRY IT OUT!
                      </button>
                      <div className="text-center-button-faq onDesktop">
                        <Button
                          className="try-out"
                          onClick={moveSurvey}
                          style={{ fontFamily: "Bungee" }}
                        >
                          TRY IT OUT!
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <NewsLetter />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mobView">
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: "0px 40px",
          }}
          className="accordion accordionExample"
          id="accordionExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button open-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <h3 className="heading-setting">What is Outdone?</h3>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  Outdone is the go-to destination to find great gifts. We
                  firmly believe that special occasions should be celebrated,
                  not stressed over. So, we’re here to help you stress less and
                  celebrate more! Visit our{" "}
                  <a
                    href="/about-us"
                    style={{ color: "#9685C8", fontWeight: "bold" }}
                  >
                    About Us
                  </a>{" "}
                  page to learn more.
                </p>
                <div className="image-try-out">
                  <button
                    style={{ fontFamily: "Bungee" }}
                    className="button-try-out  onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button
                      className="try-out"
                      onClick={moveSurvey}
                      style={{ fontFamily: "Bungee" }}
                    >
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button open-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h3 className="heading-setting">
                  {" "}
                  How does the Ai-powered gifting tool know what to recommend?
                </h3>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  After surveying tens of thousands of consumers about their
                  favorite brands and products, we’ve developed an Ai-powered
                  recommendation engine to predict which brands your giftgetter
                  is most likely to enjoy. We surveyed consumers young and old
                  to ensure our engine has a complete picture of each and every
                  giftgetter you may be shopping for.
                </p>
                <div className="image-try-out">
                  <button
                    className="button-try-out onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button className="try-out" onClick={moveSurvey}>
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button open-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h3 className="heading-setting">
                  Do brands pay to get recommended to shoppers?
                </h3>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  Nope! Our engine only recommends brands that perfectly match
                  your giftgetter’s unique personality and interests, according
                  to our survey research. At Outdone, data does the talking —
                  we’ll never let money undermine our commitment to only
                  recommend the best of the best.
                </p>
                <div className="image-try-out">
                  <button
                    className="button-try-out onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button className="try-out" onClick={moveSurvey}>
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button open-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h3 className="heading-setting">
                  How do you decide which brands to partner with?
                </h3>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  Each brand featured on our site is hand-selected for its
                  quality and positive mission. We only partner with brands that
                  are making high quality goods and are pushing positive brand
                  stories we believe in. For a full breakdown of our partnership
                  criteria, check out our{" "}
                  <a
                    href="/brandSubmission"
                    style={{ color: "#9685C8", fontWeight: "bold" }}
                  >
                    Brand Submission page!
                  </a>
                </p>
                <div className="image-try-out">
                  <button
                    className="button-try-out onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button className="try-out" onClick={moveSurvey}>
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button open-button collapsed panel-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseTwo"
                data-iconpos="right"
              >
                <h3 className="heading-setting">
                  How does Outdone make money?
                </h3>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  Good question, we get this one a lot :)
                  <br />
                  <br />
                  Whenever you complete a purchase from a recommended brand’s
                  website, we will receive a small cut of that sale. This has no
                  impact on what you pay, though. Brands pay us this commision,
                  sort of as a “thank you” for us sending you their way.
                  <br />
                  <br />
                  But again, brands can NEVER pay their way up the
                  recommendation rankings!
                </p>
                <div className="image-try-out">
                  <button
                    className="button-try-out onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button className="try-out" onClick={moveSurvey}>
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button open-button collapsed panel-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseTwo"
                data-iconpos="right"
              >
                <h3 className="heading-setting">
                  Have you thought about recommending other types of gifts?
                </h3>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="item-text">
                  Yes — we like how you think!
                  <br />
                  <br />
                  In its current form, our recommender tool covers a variety of
                  gifting categories, including apparel, accessories, homegoods,
                  toys, and jewelry. But there are tons of other gifts out there
                  you could be considering, and we absolutely want to help you
                  shop for those as well. We’re working hard to get all of these
                  different categories (like tech devices, virtual classes,
                  foods, and more) added to our engine soon!!
                </p>
                <div className="image-try-out">
                  <button
                    className="button-try-out onMobile"
                    onClick={moveSurvey}
                  >
                    TRY IT OUT!
                  </button>
                  <div className="text-center-button-faq onDesktop">
                    <Button
                      className="try-out"
                      onClick={moveSurvey}
                      style={{ fontFamily: "Bungee" }}
                    >
                      TRY IT OUT!
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Accordin);
