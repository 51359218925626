import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import noimage from "../../../assets/images/No-Image-Placeholder.png";
import { ProductClicked } from "../../../Dashboard/Services/Services";

const Productmanuplation = (props) => {
  const [pricerange, setpricerange] = useState();
  var product;
  const [length, setlength] = useState();
  useEffect(() => {
    if (localStorage.getItem("Spending Amount") === "under") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price < 50) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    } else if (localStorage.getItem("Spending Amount") === "onefifty") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price >= 50 && obj.price <= 150) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    } else if (localStorage.getItem("Spending Amount") === "over") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price > 150) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    }
  }, []);

  const recordClick = (brand, product) => {
    ProductClicked(brand, product).then((result) => {
      if (result.sucess) {
      } else {
        console.log(result);
      }
    });
  };

  return (
    <>
      {localStorage.getItem("Spending Amount") === "under" && (
        <>
          {props.brandproduct
            .filter((obj) => {
              if (obj.price < 50) {
                return obj;
              }
            })
            .slice(0, 3)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price
                          ? `$${obj.price}.00`
                          : obj.product_description}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
          {props.brandproduct
            .filter((obj) => {
              if (obj.price > 50) {
                return obj;
              }
            })
            .slice(0, 3 - length)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {" "}
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price
                          ? `$${obj.price}.00 above 50$ range`
                          : obj.product_description}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
        </>
      )}

      {localStorage.getItem("Spending Amount") === "onefifty" && (
        <>
          {props.brandproduct
            .filter((obj) => {
              if (obj.price >= 50 && obj.price <= 150) {
                return obj;
              }
            })
            .slice(0, 3)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {" "}
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price
                          ? `$${obj.price}.00`
                          : obj.product_description}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
          {props.brandproduct
            .filter((obj) => {
              if (obj.price < 50 || obj.price > 150) {
                return obj;
              }
            })
            .slice(0, 3 - length)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {" "}
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price < 50
                          ? `$${obj.price}.00 below the range`
                          : `$${obj.price}.00 above the range`}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
        </>
      )}

      {localStorage.getItem("Spending Amount") === "over" && (
        <>
          {props.brandproduct
            .filter((obj) => {
              if (obj.price > 150) {
                return obj;
              }
            })
            .slice(0, 3)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {" "}
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price
                          ? `$${obj.price}.00`
                          : obj.product_description}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
          {props.brandproduct
            .filter((obj) => {
              if (obj.price <= 150) {
                return obj;
              }
            })
            .slice(0, 3 - length)
            .map((obj) => {
              return (
                <div
                  className="imagediv"
                  onClick={() => recordClick(props.brandname, obj.product_name)}
                >
                  <a href={obj.primary_url} target="_blank">
                    <img
                      className="longimage"
                      src={obj.product_image ? obj.product_image : noimage}
                    />
                  </a>
                  <div className="desc2">
                    <a href={obj.primary_url} target="_blank">
                      {" "}
                      {obj.product_name.length > 25 ? (
                        <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                          {obj.product_name}
                        </p>
                      ) : (
                        <p className="proname2 pl-3 ">{obj.product_name}</p>
                      )}
                    </a>
                    <a href={obj.primary_url} target="_blank">
                      <p
                        className="pl-3"
                        style={{
                          fontSize: 16,
                          fontWeight: 800,
                          fontStyle: "italic",
                        }}
                      >
                        {obj.price
                          ? `$${obj.price}.00 below $150 range`
                          : obj.product_description}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })}
        </>
      )}

      {localStorage.getItem("Spending Amount") === "Matter" && (
        <>
          {props.brandproduct.slice(0, 3).map((obj) => {
            return (
              <div
                className="imagediv"
                onClick={() => recordClick(props.brandname, obj.product_name)}
              >
                <a href={obj.primary_url} target="_blank">
                  <img
                    className="longimage"
                    src={obj.product_image ? obj.product_image : noimage}
                  />
                </a>
                <div className="desc2">
                  <a href={obj.primary_url} target="_blank">
                    {" "}
                    {obj.product_name.length > 25 ? (
                      <p className="proname2 pl-3 " style={{ fontSize: 12 }}>
                        {obj.product_name}
                      </p>
                    ) : (
                      <p className="proname2 pl-3 ">{obj.product_name}</p>
                    )}
                  </a>
                  <a href={obj.primary_url} target="_blank">
                    <p
                      className="pl-3"
                      style={{
                        fontSize: 16,
                        fontWeight: 800,
                        fontStyle: "italic",
                      }}
                    >
                      {obj.price ? `$${obj.price}.00` : obj.product_description}
                    </p>
                  </a>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default Productmanuplation;
