import React, { useState } from "react";
import { Fragment } from "react";
import reco_asset from "../assets/images/reco_asset.png";
import reco_asset_right from "../assets/images/reco_asset_right.png";
import "./Newsletter.css";

function NewsLetter() {
  return (
    <Fragment>
      <section style={{ borderRadius: 10 }} className="news-letter">
        <div className="container">
          <div className="news-letter-box">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-xs-12 col-sm-12">
                <img
                  className="pt-4 mt-3"
                  width="80"
                  style={{ float: "right" }}
                  src={reco_asset}
                />
              </div>
              <div
                style={{ textAlign: "center" }}
                className="col-lg-8 col-md-8 col-xs-12 col-sm-12"
              >
                <h2 className="heading">
                  Interested in hearing about the latest Outdone news?
                  <br />
                </h2>
                <div className="email-template">
                  <div className="wrapper-news-search">
                    <p className="test-news">Email submitted!</p>
                    <div className="">
                      <button
                        style={{
                          padding: " 5px 5px",
                          width: 150,
                          backgroundColor: "#1F294E",
                          color: "#fff",
                          fontFamily: "Bungee",
                          borderRadius: "10px",
                          textAlign: "center",
                        }}
                        type="submit"
                        onClick={() => window.subscribe()}
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-xs-12 col-sm-12">
                <img className="pt-4 mt-3" width="80" src={reco_asset_right} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NewsLetter;
