import React, { useState, useEffect } from "react";
import "./giftgetter.css";
import { GetGiftGetterById } from "../Services/ServicePhase2";
import { Helmet } from "react-helmet";
import gif from "../assets/images/Loading.gif";
import Navbar from "../Navbar/Navbar";
import userimg from "../assets/images/Frame 2.svg";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import QuickSurvey from "../quickSurvey";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.css";
import Giftcard from "../Addgiftget/Giftcard";

function Editgiftgettermobile(props) {
  const [loading, setLoading] = useState(false);
  const [user, setuser] = useState();

  const [occassion, setoccassion] = useState([]);

  useEffect(() => {
    setLoading(true);
    const bddayList = [];

    props.location.state.data &&
      GetGiftGetterById(props.location.state.data)
        .then((result) => {
          if (result.success == true) {
            setuser(result.data[0].member_detail);
            document.getElementById("firstname1").value =
              result.data[0].first_name;
            document.getElementById("lastname1").value =
              result.data[0].last_name;
            document.getElementById("nickname1").value =
              result.data[0].nick_name;
            localStorage.setItem("firstname", result.data[0].first_name);
            localStorage.setItem("lastname", result.data[0].last_name);
            localStorage.setItem("nickname", result.data[0].nick_name);
            localStorage.setItem("user_id", result.data[0].id);
            localStorage.setItem("age", result.data[0].member_ocassions.age);

            setoccassion(result.data[0].member_ocassions);
          } else {
            alert(result);
          }
        })
        .catch((err) => {
          console.log("GetGiftById Error : ", err);
        });

    setLoading(false);
  }, []);

  const removeappend = (obj) => {};

  const removeappendmobile = (obj) => {
    var array = [...occassion]; // make a separate copy of the array
    var index = obj;
    if (index !== -1) {
      array.splice(index, 1);
      setoccassion(array);
    }
  };

  const updateinfo = (index, name, date) => {
    let newarr = [...occassion];

    if (name) {
      newarr[index].name = name;
    } else {
      newarr[index].date = date;
    }

    setoccassion[newarr];
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>
      {loading ? (
        <div
          className="center-loading"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#8d8a8aa1",
          }}
        >
          <img className="loading" type="video/mp4" src={gif} />
        </div>
      ) : (
        <div className="mobile">
          <Navbar />
          <div className="firstdiv" style={{ marginTop: 70 }}>
            <img src={userimg} />
          </div>
          <div className="secnddiv">
            <h3>ADD / EDIT GIFTGETTER</h3>

            <div className="inputtype">
              <h3>First Name</h3>
              <input
                id="firstname1"
                onChange={(e) =>
                  localStorage.setItem("firstname", e.target.value)
                }
              />
            </div>

            <div className="inputtype">
              <h3>Last Name</h3>
              <input
                id="lastname1"
                onChange={(e) =>
                  localStorage.setItem("lastname", e.target.value)
                }
              />
            </div>

            <div className="inputtype">
              <h3>Nick Name(what you call them)</h3>
              <input
                id="nickname1"
                onChange={(e) =>
                  localStorage.setItem("nickname", e.target.value)
                }
              />
            </div>
          </div>

          <div className="secnddiv">
            <div className="giftocc">
              <h3>Gifting Ocassions</h3>
              {occassion.length < 3 ? (
                <a
                  className="borderbottom"
                  onClick={(e) => {
                    setoccassion([...occassion, { name: "", date: "" }]),
                      focusme();
                  }}
                >
                  ADD
                </a>
              ) : null}
            </div>
            <div className="giftcontainer">
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper4 redefine"
              >
                {occassion.map((obj, i) => {
                  return (
                    <>
                      <SwiperSlide key={i}>
                        <Giftcard
                          name={obj.name}
                          date={obj.date}
                          updateinfo={updateinfo}
                          removeappend={removeappendmobile}
                          obj={obj}
                          id={i}
                        />
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div
            className="secnddiv"
            style={{ marginTop: 20, marginBottom: 137 }}
          >
            <div className="giftocc">
              <h3>engine inputs</h3>
            </div>
            <div className="surveyscreen">
              <span
                class="comingsoon"
                style={{ fontSize: 41, textAlign: "center" }}
              >
                COMING SOON
              </span>
              <QuickSurvey
                editoccassion={occassion}
                editfriend={user}
                gift={true}
              />
            </div>
          </div>
          <Navigationbar />
        </div>
      )}
    </>
  );
}

export default Editgiftgettermobile;
