import React from "react";
import dashboardLogo from "../../assets/images/Outdone-Footer-Logo.webp";
import "./dashboard.css";
import { Link, withRouter } from "react-router-dom";
import { getsurveydata } from "../../Services/ServicePhase2";
import { useState } from "react";
import btn from "../../assets/images/export2.png";
const SidebarMain = (props) => {
  const [loading2, setloading2] = useState(false);
  const { history } = props;
  const nav__items = document.querySelectorAll("div.nav__item");
  function ActiveLink() {
    nav__items.forEach((item) => {
      item.classList.remove("active");
      this.classList.add("active");
    });
  }
  nav__items.forEach((item) => {
    item.addEventListener("click", ActiveLink);
  });

  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.body.style.backgroundColor = "white";
  }
  const [data, setdata] = useState();
  function exporting() {
    setloading2(true);

    getsurveydata().then((result) => {
      var json = result.data;
      var fields = Object.keys(json[0]);
      var replacer = function (key, value) {
        return value === null ? "" : value;
      };
      var csv = json.map(function (row) {
        return fields
          .map(function (fieldName) {
            return JSON.stringify(row[fieldName], replacer);
          })
          .join(",");
      });
      csv.unshift(fields.join(",")); // add header column
      csv = csv.join("\r\n");

      let csvContent = "data:text/csv;charset=utf-8," + csv;
      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "survey.csv");
      document.body.appendChild(link); // Required for FF

      link.click();

      setloading2(false);
    });
  }

  return (
    <div className="wrapper-nav-side">
      <section className="dashboard-wrapper sidebar-desktop">
        <nav className="dashboard-sidebar ">
          <div className="nav__container">
            <h3 className="outdone-dashboard">Outdone</h3>
            <div className="text-under">
              <Link
                to="/dashboard"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/dashboard"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fas fa-home"></i>
                </div>
                <div className="nav__item-name">Home</div>
              </Link>
            </div>

            <div className="text-under">
              <Link
                to="/userEmail"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/userEmail"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="nav__item-name">User Email</div>
              </Link>
            </div>

            <div className="text-under">
              <Link
                to="/addbrand"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/addbrand"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fab fa-autoprefixer"></i>
                </div>
                <div className="nav__item-name">Add Brand</div>
              </Link>
            </div>

            <div className="text-under">
              <Link
                to="/addproduct"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/addproduct"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fab fa-autoprefixer"></i>
                </div>
                <div className="nav__item-name">Add Product</div>
              </Link>
            </div>

            <div className="text-under">
              <Link
                to="/viewbrand"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/viewbrand"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fab fa-digital-ocean"></i>
                </div>
                <div className="nav__item-name">View Brand</div>
              </Link>
            </div>
            <div className="text-under">
              <Link
                to="/export"
                className={`nav__item mt-dash" ${isSidebar(
                  history,
                  "/export"
                )} `}
              >
                <div className="nav__item-icon">
                  <i className="fa fa-list" aria-hidden="true"></i>
                </div>
                <div className="nav__item-name">Export Brand</div>
              </Link>
            </div>
            <div className="exportbtn" onClick={exporting}>
              <div className="icon">
                <img src={btn} width="18" />
              </div>
              <div className="csv">
                Export CSV{" "}
                {loading2 && (
                  <div
                    className="spinner-border"
                    style={{ marginLeft: 5 }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
            <Link to="/logout" className="nav__item">
              <div className="nav__item-icon">
                <i className="fas fa-sign-out-alt"></i>
              </div>
              <div className="nav__item-name">Log Out</div>
            </Link>
          </div>
        </nav>
      </section>
      <nav className="sidebar-mobile">
        <div id="mySidenav" className="sidenav">
          <a href="#" className="closebtn" onClick={closeNav}>
            &times;
          </a>
          <Link to="/dashboard" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  <i className="fas fa-home mobo-pad"></i> Home
                </p>
              </div>
            </div>
          </Link>

          <Link to="/userEmail" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  <i className="fas fa-home mobo-pad"></i> User Email
                </p>
              </div>
            </div>
          </Link>
          <Link to="/addbrand" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  {" "}
                  <i className="fab fa-autoprefixer mobo-pad"></i> Add Brand
                </p>
              </div>
            </div>
          </Link>
          <Link to="/addbrand" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  {" "}
                  <i className="fab fa-autoprefixer mobo-pad"></i> View Brand
                </p>
              </div>
            </div>
          </Link>
          <Link to="/viewproducts" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  {" "}
                  <i className="fab fa-autoprefixer mobo-pad"></i> View Brand
                </p>
              </div>
            </div>
          </Link>
          <Link to="/export" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  {" "}
                  <i className="fab fa-autoprefixer mobo-pad"></i> Export Brand
                </p>
              </div>
            </div>
          </Link>

          <Link to="/addbrand" className="text-under">
            <div className="nav__item">
              <div className="nav__item-icon">
                <p className="wrapper-nav">
                  {" "}
                  <i className="fab fa-autoprefixer mobo-pad"></i> Log Out
                </p>
              </div>
            </div>
          </Link>
        </div>
        <span className="mobo-nav" onClick={openNav}>
          &#9776;
        </span>
      </nav>
    </div>
  );
};
export const isSidebar = (history, path) => {
  if (history.location.pathname === path) {
    return "active";
  }
};
export default withRouter(SidebarMain);
