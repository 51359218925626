import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import SidebarMain from "../Sidebar/sidebar";
import { Link } from "react-router-dom";
import Nike from "../../assets/images/Nike.webp";

// import {
//   getBrands,
//   EditBrand,
//   UpdateBrand,
//   DeleteBrand,
//   DeleteProduct
// } from "../Services/Services";

import { DeleteProduct } from "../../Services/ServicePhase2";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { AuthGuardService } from "../../Services/ServicePhase2";
import ViewBrand from "./ViewBrand";

function ViewProducts(props) {
  const [loading, setloading] = useState(true);

  const [bChangeImg, setChangebImg] = useState();
  const [renderImg, setrenderImg] = useState();

  const [Price, setPrice] = useState();
  const [GenderTag, setGenderTag] = useState();
  const [ProdName, setProdName] = useState();
  const [AgeTag, setAgeTag] = useState();
  const [ProdDes, setProdDes] = useState();
  const [PriceTag, setPriceTag] = useState();
  const [PrimaryUrl, setPrimaryUrl] = useState();
  const [BackupUrl, setBackupUrl] = useState();
  const [BrandId, setbBrandId] = useState();
  const [ProdId, setProdId] = useState();

  const [isauth, setisauth] = useState(false);
  const [brandPage, setbrandPage] = useState(false);

  useEffect(() => {
    if (bChangeImg != renderImg) {
      console.log("IMAGE HAS BEEN UPDATED");
    } else {
      console.log("IMAGE NOT CHANGED");
    }
  });

  useEffect(() => {
    console.log("AUTH SERVICE View Products");
    AuthGuardService()
      .then((result) => {
        console.log("Result >> ", result);
        if (result.success == true) {
          setisauth(true);
          setloading(false);
        } else {
          setisauth(false);
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
        console.log("Error : ", err);
      });
  }, []);

  const onhangeFile = (e) => {
    // setChangebImg(e.target.files[0]);
    setrenderImg(e.target.files[0]);
  };

  const backPage = (e) => {
    console.log("BACK");
    setbrandPage(true);
  };

  function EditProd(ProdBody) {
    console.log("Edit Called : ", ProdBody);
    setPrice(ProdBody.price ? ProdBody.price : "");
    setGenderTag(ProdBody.gender_tag);
    setProdName(ProdBody.product_name);
    setAgeTag(ProdBody.age_tag ? ProdBody.age_tag : "");
    setProdDes(
      ProdBody.product_description ? ProdBody.product_description : ""
    );
    setPriceTag(ProdBody.price_tag);
    setPrimaryUrl(ProdBody.primary_url);
    setBackupUrl(ProdBody.backup_url);
    setrenderImg(ProdBody.product_image);
    setChangebImg(ProdBody.product_image);
    setbBrandId(ProdBody.brands_id);
    setProdId(ProdBody.id);
  }

  function DeleteProd(ProdId) {
    console.log("Delete Called : ", ProdId);
    DeleteProduct(ProdId).then((result) => {
      let responseJSON = result;
      console.log(responseJSON);
      if (responseJSON.success == true) {
        Swal.fire(responseJSON.message, "", "success");
        setTimeout(function () {
          location.reload();
        }, 2000);

        // this.props.history.push("/dashboard");
      } else if (responseJSON.success == false) {
        alert(responseJSON.message);
      }
    });
  }

  const uploadFile = async (
    BrandId,
    ProdId,
    ProdName,
    ProdDes,
    PrimaryUrl,
    Price,
    PriceTag,
    AgeTag,
    GenderTag,
    renderImg
  ) => {
    let token = localStorage.getItem("admin_token");
    var formData = new FormData();
    formData.append("brands_id", BrandId);
    formData.append("product_id", ProdId);
    formData.append("product_name", ProdName);
    formData.append("product_description", ProdDes);
    formData.append("primary_url", PrimaryUrl);
    formData.append("price", Price);
    formData.append("price_category", PriceTag);
    formData.append("age_tag", AgeTag);
    formData.append("gender_tag", GenderTag);
    formData.append("backup_url", BackupUrl);
    formData.append("image", bChangeImg);

    return await axios.post(
      "https://api.outdone.io/api/update_product",
      formData,
      {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  const UpdateApiCall = async (e) => {
    console.log("Update Api Call");

    const res = await uploadFile(
      BrandId,
      ProdId,
      ProdName,
      ProdDes,
      PrimaryUrl,
      Price,
      PriceTag,
      AgeTag,
      GenderTag,
      bChangeImg
    );
    const dataa = res.data;
    console.log("Upload File", dataa);
    if (dataa.success == true) {
      Swal.fire(dataa.message, "", "success");
      location.reload();
    } else {
      alert(dataa.message);
      e.preventDefault();
    }
  };

  return isauth ? (
    brandPage ? (
      <ViewBrand />
    ) : loading ? (
      <Loader
        className="text-center loader"
        type="Oval"
        color="#323A40"
        height={200}
        width={200}
      />
    ) : (
      <Fragment>
        <section className="dashboard-page">
          <div className="container-fluid p-l ">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0">
                <div className="full-dash">
                  <div className="side-bar-dash">
                    <SidebarMain />
                  </div>
                  <div className="dashboard-content">
                    <div className="row">
                      <div className="col-lg-12 scrool-bar">
                        <table className="table table-image">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Product Image</th>
                              <th scope="col">Price</th>
                              <th scope="col">Price Tag</th>
                              <th scope="col">Product Description</th>
                              <th scope="col">Product Name</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.dataProd.brand_products.map((row, i) => (
                              <tr key={i}>
                                <td scope="row">{row.id}</td>
                                <td>
                                  <img
                                    src={row.product_image}
                                    className="img-fluid img-thumbnail img-view"
                                    alt="logo"
                                  />
                                </td>
                                <td>{row.price}</td>
                                <td>{row.price_tag}</td>
                                <td>{row.product_description}</td>
                                <td>{row.product_name}</td>

                                <td className="row">
                                  <button
                                    className="update"
                                    onClick={() =>
                                      EditProd({
                                        age_tag: row.age_tag,
                                        backup_url: row.backup_url,
                                        brands_id: row.brands_id,
                                        created_at: row.created_at,
                                        gender_tag: row.gender_tag,
                                        id: row.id,
                                        price: row.price,
                                        price_tag: row.price_tag,
                                        primary_url: row.primary_url,
                                        product_description:
                                          row.product_description,
                                        product_image: row.product_image,
                                        product_name: row.product_name,
                                        updated_at: row.updated_at,
                                      })
                                    }
                                  >
                                    Edit
                                  </button>{" "}
                                  <button
                                    onClick={() => DeleteProd(row.id)}
                                    className="delete"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button className="update" onClick={(e) => backPage(e)}>
                          Back to View Brands
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="form-update">
                  <form
                    className="form-wrapper-update"
                    onsubmit="return false;"
                  >
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="form-item">
                          <input
                            value={ProdName}
                            onChange={(e) => setProdName(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="35"
                            className="form-control"
                            id="Product Name"
                          />
                          <label for="Product Name">Product Name</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={AgeTag}
                            onChange={(e) => setAgeTag(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="35"
                            className="form-control"
                            id="Age Tag"
                          />
                          <label for="Age Tag">Age Tag</label>
                        </div>

                        <div className="form-item">
                          <textarea
                            value={ProdDes}
                            onChange={(e) => setProdDes(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Product Description"
                            maxLength="255"
                          ></textarea>

                          <label for="Product Description">
                            Product Description
                          </label>
                        </div>
                        <div className="form-item">
                          <select
                            class="custom-select"
                            value={PriceTag}
                            onChange={(e) => setPriceTag(e.target.value)}
                            id="inputGroupSelect01"
                          >
                            <option selected hidden>
                              Choose...
                            </option>
                            <option value="$50-$150">$50-$150</option>
                            <option value="<50">&lt;$50</option>
                            <option value=">$150">&gt;$150</option>
                          </select>
                          <label for="Image URL">Price Cateogry</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={Price}
                            onChange={(e) => setPrice(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="15"
                            className="form-control"
                            id="Price"
                          />
                          <label for="Price">Price</label>
                        </div>
                        <div className="form-item">
                          <select
                            value={GenderTag}
                            onChange={(e) => setGenderTag(e.target.value)}
                            class="custom-select"
                            id="Gender Tag"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Neutral">Other</option>
                          </select>

                          <label for="Gender Tag">Gender Tag</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={PrimaryUrl}
                            onChange={(e) => setPrimaryUrl(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Primary Url"
                            required
                          />
                          <label for="Price">Primary Url</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={BackupUrl}
                            onChange={(e) => setBackupUrl(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Backup Url"
                            required
                          />
                          <label for="Backup Url">Backup Url</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={bChangeImg}
                            onChange={(e) => setChangebImg(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Image Url"
                            required
                          />
                          <label for="Image Url">Image Url</label>
                        </div>

                        <button
                          type="button"
                          className="btnContact"
                          onClick={UpdateApiCall}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  ) : (
    <div></div>
  );
}

export default ViewProducts;
