import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPencilAlt,
  faEdit,
  faTrashAlt,
  faPlus,
  faHome,
  faDesktop,
  faBookOpen,
  faCalendar,
  faFlag,
  faShare,
  faTags,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPencilAlt,
  faEdit,
  faTrashAlt,
  faPlus,
  faHome,
  faDesktop,
  faBookOpen,
  faCalendar,
  faFlag,
  faShare,
  faTags
);
