import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import bottom from "../assets/images/Polygon8.png";
import top from "../assets/images/Polygon7.png";

const Calendar = (props) => {
  const [occasionname, setoccasionname] = useState("");
  const [occasiondate, setoccasiondate] = useState("");
  useEffect(() => {
    $(function () {
      $(`#${props.id}`)
        .datepicker({
          changeYear: false,
          dateFormat: "m/d",
        })
        .focus(function () {
          $(".ui-datepicker-year").hide();
        });
    });
  }, []);

  const datefarward = (id) => {
    var date1 = $(`#${props.id}`).datepicker("getDate");
    var date = new Date(Date.parse(date1));
    date.setDate(date.getDate() + 1);
    $(`#${props.id}`).datepicker("setDate", date);
  };

  const datebackward = () => {
    var date1 = $(`#${props.id}`).datepicker("getDate");
    var date = new Date(Date.parse(date1));
    date.setDate(date.getDate() - 1);

    $(`#${props.id}`).datepicker("setDate", date);
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css"
        />
        <link rel="stylesheet" href="/resources/demos/style.css" />
        <script src="https://code.jquery.com/jquery-3.6.0.js"></script>
        <script src="https://code.jquery.com/ui/1.13.2/jquery-ui.js"></script>
      </Helmet>

      <div style={{ marginRight: "10%", marginBottom: 20 }}>
        <p className="colfade">#{props.id + 1}</p>

        <select
          id="select"
          defaultValue={props.name}
          className="firstinput occassionname"
        >
          <option className="value-style" value=""></option>
          <option className="value-style" value="Birthday">
            Birthday
          </option>
          <option className="value-style" value="Wedding Anniversary">
            Wedding Anniversary
          </option>
          <option className="value-style" value="Christmas">
            Christmas
          </option>
          <option className="value-style" value="Hanukkah">
            Hanukkah
          </option>
          <option className="value-style" value="Christmas">
            Christmas
          </option>
          <option className="value-style" value="Valentine’s Day">
            Valentine’s Day
          </option>
          <option className="value-style" value="Mother’s Day">
            Mother’s Day
          </option>
          <option className="value-style" value="Birthday">
            Father’s Day
          </option>
          <option className="value-style" value="Other Special Occasion">
            Other Special Occasion
          </option>
        </select>
        <div style={{ display: "flex", marginTop: 20 }}>
          <p className="colfade" style={{ marginRight: 20 }}>
            Date
          </p>
          <div>
            <div className="dageinput">
              <div>
                <input
                  type="text"
                  id={props.id}
                  defaultValue={props.date}
                  className="occassiondate"
                  name=""
                  style={{ border: "none", width: 100, marginLeft: 10 }}
                  onChange={(e) => setoccasiondate(e.target.value)}
                />
              </div>
              <div>
                <img
                  style={{
                    width: 8,
                    display: "block",
                    marginBottom: 5,
                    marginTop: 0,
                    marginRight: 6,
                    marginLeft: "auto",
                  }}
                  onClick={() => datefarward(props.id)}
                  src={top}
                  alt=""
                />
                <img
                  style={{
                    width: 8,
                    display: "block",
                    marginLeft: "auto",
                    marginRight: 6,
                  }}
                  src={bottom}
                  onClick={() => datebackward(props.id)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        {props.id != 0 && (
          <a
            style={{
              marginTop: 8,
              marginBottom: 35,
              fontSize: 12,
              color: "#1F294E",
              borderBottom: "1px solid #F3A493",
              cursor: "pointer",
            }}
            onClick={() => props.removeappend(props.obj)}
          >
            REMOVE
          </a>
        )}
      </div>
    </>
  );
};

export default Calendar;
