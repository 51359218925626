import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./SignUpMobile.css";
import logo from "../../components/assets/images/footer_logo.png";
import { Link, useHistory } from "react-router-dom";
import { SignIn } from "../Services/ServicePhase2";

import Footer from "../Footer/Footer";
import SepGro from "../assets/images/Grupo1230.png";

import Navbar from "../Navbar/Navbar";
import Navigationbar from "../NavigationBarMobile/Navigationbar";
import { SignUp } from "../Services/ServicePhase2";
import { error } from "jquery";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function SignUpMobile(props) {
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [length, setlength] = useState(false);
  const [Fname, setFname] = useState("");
  const [Laname, setLaname] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Error, setError] = useState(false);

  const history = useHistory();

  const OnEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const OnNameChange = (e) => {
    setFname(e.target.value);
  };
  const OnLastNameChange = (e) => {
    setLaname(e.target.value);
  };
  const OnSignEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const OnPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const OnConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const OnContinueClick = () => {
    setisLoading(true);
    setError(false);
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      Fname === "" ||
      Laname === "" ||
      Email === "" ||
      Password === "" ||
      ConfirmPassword === ""
    ) {
      setError("Fields should not be empty.");
      setisLoading(false);
    } else if (!Email.match(mailformat)) {
      setError("You have entered an invalid email address!");
      setisLoading(false);
    } else if (Password.length < 8) {
      setError("Password must be 8 characters long.");
      setisLoading(false);
    } else if (ConfirmPassword !== Password) {
      setError("Password and confirm password must match");
      setisLoading(false);
    } else if (Password === ConfirmPassword) {
      SignUp(Fname, Laname, Email, Password, ConfirmPassword).then((result) => {
        console.log("aldkdlkadkadl=-=-", result);

        if (result.success == true) {
          localStorage.setItem("user_id", result.data.id);
          localStorage.setItem("user_email", result.data.email);
          localStorage.setItem("user_password", Password);
          $("#exampleModalCenter").modal("hide");
          $("#exampletwo").modal("show");

          setFname("");
          setConfirmPassword("");
          setLaname("");
          setPassword("");
          setEmail("");
        } else {
          setError(result.message);
        }

        setisLoading(false);
      });
    }
  };

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  const ClosePopup = () => {
    $("#exampletwo").modal("hide");
  };

  const modaltoggle = () => {
    $("#exampleModalCenter").modal("show");
  };
  const modalhide = () => {
    $("#exampleModalCenter").modal("hide");
  };

  const siginfocus = () => {
    $("#exampleModalCenter").modal("hide");
    document.getElementById("email").focus();
  };

  return (
    <>
      <Helmet>
        {" "}
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <Navbar />

      <div className="MobileView" style={{ marginTop: 20 }}>
        <div className="backclassimage">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              className="MobileP"
              style={{
                fontFamily: "Bungee",
                fontSize: 23,
                fontWeight: 400,
                color: "#1F294E",
              }}
            >
              CREATE AN ACCOUNT
            </p>
            <img
              className="MobileImg"
              style={{ width: 80 }}
              src={SepGro}
              alt=""
            />
          </div>
        </div>
        <div style={{ position: "relative", top: 44, paddingBottom: 40 }}>
          <div
            className="fieldtypee"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <lable>First Name</lable>
            <input
              type="name"
              value={Fname}
              onChange={OnNameChange}
              style={{ border: "5px solid #fcc9be" }}
            />
          </div>
          <div
            className="fieldtypeee"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <lable>Last Name</lable>
            <input
              type="name"
              value={Laname}
              onChange={OnLastNameChange}
              style={{ border: "5px solid #fcc9be" }}
            />
          </div>

          <div
            className="fieldtypeee"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <lable>Email</lable>
            <input
              type="Email"
              value={Email}
              onChange={OnEmailChange}
              style={{ border: "5px solid #fcc9be" }}
            />
          </div>

          <div
            className="fieldtypeee"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <lable>Password</lable>
            <input
              type="password"
              value={Password}
              onChange={OnPasswordChange}
              style={{ border: "5px solid #fcc9be" }}
            />
          </div>

          <div
            className="fieldtypeee"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <lable>Confirm Password</lable>
            <input
              type="password"
              value={ConfirmPassword}
              onChange={OnConfirmPassChange}
              style={{ border: "5px solid #fcc9be" }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              marginLeft: 80,
              marginRight: 80,
            }}
          >
            {Error && (
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontFamily: "Nunito",
                }}
              >
                {Error}
              </p>
            )}
            <p>
              By signing up, I agree to Outdone’s{" "}
              <a
                style={{ color: "blue" }}
                onClick={() => history.push("/privacy-policy")}
              >
                Terms of Service
              </a>
            </p>
          </div>

          <div style={{ display: "flex", alignItems: "end" }}>
            <button
              onClick={OnContinueClick}
              className="signbutt"
              style={{ fontFamily: "Bungee", marginTop: 0 }}
            >
              CONTINUE
            </button>
            {isloading && (
              <div
                class="spinner-border"
                style={{
                  float: "right",
                  marginLeft: 25,
                  marginBottom: 12,
                }}
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <p onClick={(e) => history.push("/sign-in")}>
              Have an Account? <lable> Log In </lable>
            </p>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampletwo"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampletwoTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={ClosePopup}
              >
                <span
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{
                    position: "absolute",
                    top: 2,
                    left: 10,
                    fontSize: 50,
                  }}
                  aria-hidden="true"
                >
                  &times;
                </span>
              </button>
              <p className="pare">VERIFY YOUR ACCOUNT</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: -30,
                }}
              >
                <img style={{ width: 85 }} src={SepGro} alt="" />
              </div>
              <div style={{ padding: 20 }}>
                <p className="pare2">
                  We just sent a verification email to the address your
                  provided.
                  <br />
                  <br />
                  Please click the link in our email to verify your identity and
                  finish account setup!
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <Navigationbar />
      </div>
    </>
  );
}

export default SignUpMobile;
