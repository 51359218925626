import {
  AddMYSELF,
  AddMember,
  editMySelf,
  UpdateMember,
} from "./Services/ServicePhase2";

export const mobiledatasaving = () => {
  let age = localStorage.getItem("age");
  let gender = localStorage.getItem("gender");
  let arr = [];
  let typeArray = [];
  let typeArrayTwo = [];
  let typeArrayFive = [];
  let typeArrayThree = [];
  let typeArrayFour = [];
  let typeArraySeven = [];
  let state = localStorage.getItem("State");
  let typeArraySix = [];
  let birthdate = calculatemybirthday();

  if (gender == 1) {
    arr.push("Male");
  }
  if (gender == 2) {
    arr.push("Female");
  }
  if (gender == 3) {
    arr.push("Other");
  }

  if (localStorage.getItem("Cook") === "1") {
    typeArray.push("Cook");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArray.push("Alcohol");
  }
  if (localStorage.getItem("Coffee") === "1") {
    typeArray.push("Make Coffee");
  }
  if (localStorage.getItem("Photo") === "1") {
    typeArray.push("Photo");
  }
  if (localStorage.getItem("Read") === "1") {
    typeArray.push("Read");
  }
  if (localStorage.getItem("Puzzlegame") === "1") {
    typeArray.push("Puzzlegame");
  }
  if (localStorage.getItem("Garden") === "1") {
    typeArray.push("Garden");
  }
  if (localStorage.getItem("Videogame") === "1") {
    typeArray.push("Videogame");
  }

  if (localStorage.getItem("Biking") === "1") {
    typeArrayTwo.push("Biking");
  }
  if (localStorage.getItem("Yoga") === "1") {
    typeArrayTwo.push("Yoga");
  }
  if (localStorage.getItem("Golfing") === "1") {
    typeArrayTwo.push("Golfing");
  }
  if (localStorage.getItem("lifting") === "1") {
    typeArrayTwo.push("lifting");
  }
  if (localStorage.getItem("Running") === "1") {
    typeArrayTwo.push("Running");
  }
  if (localStorage.getItem("Swimming") === "1") {
    typeArrayTwo.push("Swimming");
  }
  if (localStorage.getItem("Skiing") === "1") {
    typeArrayTwo.push("Skiing");
  }
  if (localStorage.getItem("snowboard") === "1") {
    typeArrayTwo.push("snowboard");
  }
  if (localStorage.getItem("Camping") === "1") {
    typeArrayTwo.push("Camping");
  }
  if (localStorage.getItem("Hiking") === "1") {
    typeArrayTwo.push("Hiking");
  }
  if (localStorage.getItem("Fishing") === "1") {
    typeArrayTwo.push("Fishing");
  }
  if (localStorage.getItem("Hunting") === "1") {
    typeArrayTwo.push("Hunting");
  }

  if (localStorage.getItem("Airport") === "1") {
    typeArrayFive.push("Airport");
  }
  if (localStorage.getItem("Concerts") === "1") {
    typeArrayFive.push("Concerts");
  }
  if (localStorage.getItem("Beach") === "1") {
    typeArrayFive.push("Beach");
  }
  if (localStorage.getItem("Bars") === "1") {
    typeArrayFive.push("Bars");
  }

  if (localStorage.getItem("Sporty") === "1") {
    typeArrayThree.push("Sporty");
  }
  if (localStorage.getItem("Longwear") === "1") {
    typeArrayThree.push("Longwear");
  }
  if (localStorage.getItem("OutDoorsy") === "1") {
    typeArrayThree.push("OutDoorsy");
  }
  if (localStorage.getItem("Streetwear") === "1") {
    typeArrayThree.push("Streetwear");
  }

  if (localStorage.getItem("Beer") === "1") {
    typeArrayFour.push("Beer");
  }

  if (localStorage.getItem("Candy") === "1") {
    typeArrayFour.push("Candy");
  }

  if (localStorage.getItem("Coffee") === "1") {
    typeArrayFour.push("Coffee");
  }
  if (localStorage.getItem("Wine") === "1") {
    typeArrayFour.push("Wine");
  }
  if (localStorage.getItem("Pasta") === "1") {
    typeArrayFour.push("Pasta");
  }
  if (localStorage.getItem("Pizza") === "1") {
    typeArrayFour.push("Pizza");
  }
  if (localStorage.getItem("Tea") === "1") {
    typeArrayFour.push("Tea");
  }
  if (localStorage.getItem("Area") === "1") {
    typeArraySeven.push("CountrySide");
  }
  if (localStorage.getItem("Area") === "2") {
    typeArraySeven.push("City");
  }
  if (localStorage.getItem("Area") === "3") {
    typeArraySeven.push("Suburbs");
  }
  if (localStorage.getItem("Area") === "4") {
    typeArraySeven.push("Not Sure");
  }

  if (localStorage.getItem("FemaleLed") === "1") {
    typeArraySix.push("FemaleLed");
  }
  if (localStorage.getItem("MadeinUSA") === "1") {
    typeArraySix.push("MadeinUSA");
  }
  if (localStorage.getItem("Sustainable") === "1") {
    typeArraySix.push("Sustainable");
  }
  if (localStorage.getItem("Charitable") === "1") {
    typeArraySix.push("Charitable");
  }

  AddMYSELF(
    age,
    arr,
    typeArray,
    typeArrayTwo,
    typeArrayThree,
    typeArrayFour,
    typeArrayFive,
    typeArraySix,
    typeArraySeven,
    state,
    birthdate
  ).then((result) => {
    if (result.success == true) {
      alert(result.message);

      localStorage.removeItem("age");
    } else {
      alert(result.message);
    }
  });
};

export const mobiledatasaving2 = (occassions) => {
  let firstname = localStorage.getItem("firstname");
  let lastname = localStorage.getItem("lastname");
  let nickname = localStorage.getItem("nickname");

  let age = localStorage.getItem("age");
  let gender = localStorage.getItem("gender");
  let arr = [];
  let typeArray = [];
  let typeArrayTwo = [];
  let typeArrayFive = [];
  let typeArrayThree = [];
  let typeArrayFour = [];
  let typeArraySeven = [];
  let state = localStorage.getItem("State");
  let typeArraySix = [];
  let birthdate = localStorage.getItem("birthdate");

  if (gender == 1) {
    arr.push("Male");
  }
  if (gender == 2) {
    arr.push("Female");
  }
  if (gender == 3) {
    arr.push("Other");
  }

  if (localStorage.getItem("Cook") === "1") {
    typeArray.push("Cook");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArray.push("Alcohol");
  }
  if (localStorage.getItem("Coffee") === "1") {
    typeArray.push("Coffee");
  }
  if (localStorage.getItem("Photo") === "1") {
    typeArray.push("Photo");
  }
  if (localStorage.getItem("Read") === "1") {
    typeArray.push("Read");
  }
  if (localStorage.getItem("Puzzlegame") === "1") {
    typeArray.push("Puzzlegame");
  }
  if (localStorage.getItem("Garden") === "1") {
    typeArray.push("Garden");
  }
  if (localStorage.getItem("Videogame") === "1") {
    typeArray.push("Videogame");
  }

  if (localStorage.getItem("Biking") === "1") {
    typeArrayTwo.push("Biking");
  }
  if (localStorage.getItem("Yoga") === "1") {
    typeArrayTwo.push("Yoga");
  }
  if (localStorage.getItem("Golfing") === "1") {
    typeArrayTwo.push("Golfing");
  }
  if (localStorage.getItem("lifting") === "1") {
    typeArrayTwo.push("lifting");
  }
  if (localStorage.getItem("Running") === "1") {
    typeArrayTwo.push("Running");
  }
  if (localStorage.getItem("Swimming") === "1") {
    typeArrayTwo.push("Swimming");
  }
  if (localStorage.getItem("Skiing") === "1") {
    typeArrayTwo.push("Skiing");
  }
  if (localStorage.getItem("snowboard") === "1") {
    typeArrayTwo.push("snowboard");
  }
  if (localStorage.getItem("Camping") === "1") {
    typeArrayTwo.push("Camping");
  }
  if (localStorage.getItem("Hiking") === "1") {
    typeArrayTwo.push("Hiking");
  }
  if (localStorage.getItem("Fishing") === "1") {
    typeArrayTwo.push("Fishing");
  }
  if (localStorage.getItem("Hunting") === "1") {
    typeArrayTwo.push("Hunting");
  }

  if (localStorage.getItem("Airport") === "1") {
    typeArrayFive.push("Airport");
  }
  if (localStorage.getItem("Concerts") === "1") {
    typeArrayFive.push("Concerts");
  }
  if (localStorage.getItem("Beach") === "1") {
    typeArrayFive.push("Beach");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArrayFive.push("Bars or Clubs");
  }

  if (localStorage.getItem("Sporty") === "1") {
    typeArrayThree.push("Sporty");
  }
  if (localStorage.getItem("Longwear") === "1") {
    typeArrayThree.push("Longwear");
  }
  if (localStorage.getItem("OutDoorsy") === "1") {
    typeArrayThree.push("OutDoorsy");
  }
  if (localStorage.getItem("Streetwear") === "1") {
    typeArrayThree.push("Streetwear");
  }

  if (localStorage.getItem("Beer") === "1") {
    typeArrayFour.push("Beer");
  }

  if (localStorage.getItem("Candy") === "1") {
    typeArrayFour.push("Candy");
  }

  if (localStorage.getItem("Coffee") === "1") {
    typeArrayFour.push("Coffee");
  }
  if (localStorage.getItem("Wine") === "1") {
    typeArrayFour.push("Wine");
  }
  if (localStorage.getItem("Pasta") === "1") {
    typeArrayFour.push("Pasta");
  }
  if (localStorage.getItem("Pizza") === "1") {
    typeArrayFour.push("Pizza");
  }
  if (localStorage.getItem("Tea") === "1") {
    typeArrayFour.push("Tea");
  }
  if (localStorage.getItem("Area") === "1") {
    typeArraySeven.push("CountrySide");
  }
  if (localStorage.getItem("Area") === "2") {
    typeArraySeven.push("City");
  }
  if (localStorage.getItem("Area") === "3") {
    typeArraySeven.push("Suburbs");
  }
  if (localStorage.getItem("Area") === "4") {
    typeArraySeven.push("Not Sure");
  }

  if (localStorage.getItem("FemaleLed") === "1") {
    typeArraySix.push("FemaleLed");
  }
  if (localStorage.getItem("MadeinUSA") === "1") {
    typeArraySix.push("MadeinUSA");
  }
  if (localStorage.getItem("Sustainable") === "1") {
    typeArraySix.push("Sustainable");
  }
  if (localStorage.getItem("Charitable") === "1") {
    typeArraySix.push("Charitable");
  }

  AddMember(
    firstname,
    lastname,
    age,
    nickname,
    occassions,
    arr,
    typeArray,
    typeArrayTwo,
    typeArrayThree,
    typeArrayFour,
    typeArrayFive,
    typeArraySix,
    typeArraySeven,
    state,
    birthdate
  ).then((result) => {
    if (result.success == true) {
      alert(result.message);

      localStorage.removeItem("age");
    } else {
      alert(result.message);
    }
  });
};

const calculatemybirthday = () => {
  let y = parseInt(localStorage.getItem("age"));
  let yea = new Date().getFullYear();
  let month = new Date().getMonth();
  let din = new Date().getDate();

  let xage = parseFloat(y + "." + month);
  var year2 = Math.trunc(yea - xage);

  var i = localStorage.getItem("birthdate").split("/");

  const com = parseInt(i[0]);
  const dom = parseInt(i[1]);

  if (month > com) {
    year2 = yea - y;
  } else if (month == com) {
    if (dom < din) {
      year2 = yea - y;
    } else {
      year2 = yea - y - 1;
    }
  } else {
    year2 = yea - y - 1;
  }

  var birthdate = i[0] + "-" + i[1] + "-" + year2;

  return birthdate;
};

export const updatemobiledata = () => {
  let age = localStorage.getItem("age");
  let gender = localStorage.getItem("gender");
  let arr = [];
  let typeArray = [];
  let typeArrayTwo = [];
  let typeArrayFive = [];
  let typeArrayThree = [];
  let typeArrayFour = [];
  let typeArraySeven = [];
  let state = localStorage.getItem("State");
  let typeArraySix = [];
  let birthdate = calculatemybirthday();
  let occaions = [];
  if (gender == 1) {
    arr.push("Male");
  }
  if (gender == 2) {
    arr.push("Female");
  }
  if (gender == 3) {
    arr.push("Other");
  }

  if (localStorage.getItem("Cook") === "1") {
    typeArray.push("Cook");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArray.push("Alcohol");
  }
  if (localStorage.getItem("Coffee") === "1") {
    typeArray.push("Coffee");
  }
  if (localStorage.getItem("Photo") === "1") {
    typeArray.push("Photo");
  }
  if (localStorage.getItem("Read") === "1") {
    typeArray.push("Read");
  }
  if (localStorage.getItem("Puzzlegame") === "1") {
    typeArray.push("Puzzlegame");
  }
  if (localStorage.getItem("Garden") === "1") {
    typeArray.push("Garden");
  }
  if (localStorage.getItem("Videogame") === "1") {
    typeArray.push("Videogame");
  }

  if (localStorage.getItem("Biking") === "1") {
    typeArrayTwo.push("Biking");
  }
  if (localStorage.getItem("Yoga") === "1") {
    typeArrayTwo.push("Yoga");
  }
  if (localStorage.getItem("Golfing") === "1") {
    typeArrayTwo.push("Golfing");
  }
  if (localStorage.getItem("lifting") === "1") {
    typeArrayTwo.push("lifting");
  }
  if (localStorage.getItem("Running") === "1") {
    typeArrayTwo.push("Running");
  }
  if (localStorage.getItem("Swimming") === "1") {
    typeArrayTwo.push("Swimming");
  }
  if (localStorage.getItem("Skiing") === "1") {
    typeArrayTwo.push("Skiing");
  }
  if (localStorage.getItem("snowboard") === "1") {
    typeArrayTwo.push("snowboard");
  }
  if (localStorage.getItem("Camping") === "1") {
    typeArrayTwo.push("Camping");
  }
  if (localStorage.getItem("Hiking") === "1") {
    typeArrayTwo.push("Hiking");
  }
  if (localStorage.getItem("Fishing") === "1") {
    typeArrayTwo.push("Fishing");
  }
  if (localStorage.getItem("Hunting") === "1") {
    typeArrayTwo.push("Hunting");
  }

  if (localStorage.getItem("Airport") === "1") {
    typeArrayFive.push("Airport");
  }
  if (localStorage.getItem("Concerts") === "1") {
    typeArrayFive.push("Concerts");
  }
  if (localStorage.getItem("Beach") === "1") {
    typeArrayFive.push("Beach");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArrayFive.push("Bars or Clubs");
  }

  if (localStorage.getItem("Sporty") === "1") {
    typeArrayThree.push("Sporty");
  }
  if (localStorage.getItem("Longwear") === "1") {
    typeArrayThree.push("Longwear");
  }
  if (localStorage.getItem("Outdoorsy") === "1") {
    typeArrayThree.push("Outdoorsy");
  }
  if (localStorage.getItem("Streetwear") === "1") {
    typeArrayThree.push("Streetwear");
  }

  if (localStorage.getItem("Beer") === "1") {
    typeArrayFour.push("Beer");
  }

  if (localStorage.getItem("Candy") === "1") {
    typeArrayFour.push("Candy");
  }

  if (localStorage.getItem("Coffee") === "1") {
    typeArrayFour.push("Coffee");
  }
  if (localStorage.getItem("Wine") === "1") {
    typeArrayFour.push("Wine");
  }
  if (localStorage.getItem("Pasta") === "1") {
    typeArrayFour.push("Pasta");
  }
  if (localStorage.getItem("Pizza") === "1") {
    typeArrayFour.push("Pizza");
  }
  if (localStorage.getItem("Tea") === "1") {
    typeArrayFour.push("Tea");
  }
  if (localStorage.getItem("Area") === "1") {
    typeArraySeven.push("CountrySide");
  }
  if (localStorage.getItem("Area") === "2") {
    typeArraySeven.push("City");
  }
  if (localStorage.getItem("Area") === "3") {
    typeArraySeven.push("Suburbs");
  }
  if (localStorage.getItem("Area") === "4") {
    typeArraySeven.push("Not Sure");
  }

  if (localStorage.getItem("FemaleLed") === "1") {
    typeArraySix.push("FemaleLed");
  }
  if (localStorage.getItem("MadeinUSA") === "1") {
    typeArraySix.push("MadeinUSA");
  }
  if (localStorage.getItem("Sustainable") === "1") {
    typeArraySix.push("Sustainable");
  }
  if (localStorage.getItem("Charitable") === "1") {
    typeArraySix.push("Charitable");
  }

  editMySelf(
    age,
    arr,
    typeArray,
    typeArrayTwo,
    typeArrayThree,
    typeArrayFour,
    typeArrayFive,
    typeArraySix,
    typeArraySeven,
    state,
    birthdate
  ).then((result) => {
    if (result.success == true) {
      alert(result.message);

      window.location.href = "/dashboard-user";
    } else {
      alert(result.message);
    }
  });
};

export const updatememberdata = (editoccassion) => {
  let age = localStorage.getItem("age");
  let gender = localStorage.getItem("gender");
  let firstname = localStorage.getItem("firstname");
  let lastname = localStorage.getItem("lastname");
  let nickname = localStorage.getItem("nickname");
  let arr = [];
  let typeArray = [];
  let typeArrayTwo = [];
  let typeArrayFive = [];
  let typeArrayThree = [];
  let typeArrayFour = [];
  let typeArraySeven = [];
  let otypeoccassion = [];

  let state = localStorage.getItem("State");
  let typeArraySix = [];

  if (gender == 1) {
    arr.push("Male");
  }
  if (gender == 2) {
    arr.push("Female");
  }
  if (gender == 3) {
    arr.push("Other");
  }

  if (localStorage.getItem("Cook") === "1") {
    typeArray.push("Cook");
  }
  if (localStorage.getItem("Alcohol") === "1") {
    typeArray.push("Alcohol");
  }
  if (localStorage.getItem("Coffee") === "1") {
    typeArray.push("Make Coffee");
  }
  if (localStorage.getItem("Photo") === "1") {
    typeArray.push("Photo");
  }
  if (localStorage.getItem("Read") === "1") {
    typeArray.push("Read");
  }
  if (localStorage.getItem("Puzzlegame") === "1") {
    typeArray.push("Puzzlegame");
  }
  if (localStorage.getItem("Garden") === "1") {
    typeArray.push("Garden");
  }
  if (localStorage.getItem("Videogame") === "1") {
    typeArray.push("Videogame");
  }

  if (localStorage.getItem("Biking") === "1") {
    typeArrayTwo.push("Biking");
  }
  if (localStorage.getItem("Yoga") === "1") {
    typeArrayTwo.push("Yoga");
  }
  if (localStorage.getItem("Golfing") === "1") {
    typeArrayTwo.push("Golfing");
  }
  if (localStorage.getItem("lifting") === "1") {
    typeArrayTwo.push("lifting");
  }
  if (localStorage.getItem("Running") === "1") {
    typeArrayTwo.push("Running");
  }
  if (localStorage.getItem("Swimming") === "1") {
    typeArrayTwo.push("Swimming");
  }
  if (localStorage.getItem("Skiing") === "1") {
    typeArrayTwo.push("Skiing");
  }
  if (localStorage.getItem("snowboard") === "1") {
    typeArrayTwo.push("snowboard");
  }
  if (localStorage.getItem("Camping") === "1") {
    typeArrayTwo.push("Camping");
  }
  if (localStorage.getItem("Hiking") === "1") {
    typeArrayTwo.push("Hiking");
  }
  if (localStorage.getItem("Fishing") === "1") {
    typeArrayTwo.push("Fishing");
  }
  if (localStorage.getItem("Hunting") === "1") {
    typeArrayTwo.push("Hunting");
  }

  if (localStorage.getItem("Airport") === "1") {
    typeArrayFive.push("Airport");
  }
  if (localStorage.getItem("Concerts") === "1") {
    typeArrayFive.push("Concerts");
  }
  if (localStorage.getItem("Beach") === "1") {
    typeArrayFive.push("Beach");
  }
  if (localStorage.getItem("Bars") === "1") {
    typeArrayFive.push("Bars");
  }

  if (localStorage.getItem("Sporty") === "1") {
    typeArrayThree.push("Sporty");
  }
  if (localStorage.getItem("Longwear") === "1") {
    typeArrayThree.push("Longwear");
  }
  if (localStorage.getItem("Outdoorsy") === "1") {
    typeArrayThree.push("Outdoorsy");
  }
  if (localStorage.getItem("Streetwear") === "1") {
    typeArrayThree.push("Streetwear");
  }

  if (localStorage.getItem("Beer") === "1") {
    typeArrayFour.push("Beer");
  }

  if (localStorage.getItem("Candy") === "1") {
    typeArrayFour.push("Candy");
  }

  if (localStorage.getItem("Coffee") === "1") {
    typeArrayFour.push("Coffee");
  }
  if (localStorage.getItem("Wine") === "1") {
    typeArrayFour.push("Wine");
  }
  if (localStorage.getItem("Pasta") === "1") {
    typeArrayFour.push("Pasta");
  }
  if (localStorage.getItem("Pizza") === "1") {
    typeArrayFour.push("Pizza");
  }
  if (localStorage.getItem("Tea") === "1") {
    typeArrayFour.push("Tea");
  }
  if (localStorage.getItem("Area") === "1") {
    typeArraySeven.push("CountrySide");
  }
  if (localStorage.getItem("Area") === "2") {
    typeArraySeven.push("City");
  }
  if (localStorage.getItem("Area") === "3") {
    typeArraySeven.push("Suburbs");
  }
  if (localStorage.getItem("Area") === "4") {
    typeArraySeven.push("Not Sure");
  }

  if (localStorage.getItem("FemaleLed") === "1") {
    typeArraySix.push("FemaleLed");
  }
  if (localStorage.getItem("MadeinUSA") === "1") {
    typeArraySix.push("MadeinUSA");
  }
  if (localStorage.getItem("Sustainable") === "1") {
    typeArraySix.push("Sustainable");
  }
  if (localStorage.getItem("Charitable") === "1") {
    typeArraySix.push("Charitable");
  }

  let occassion = editoccassion.map((obj) => {
    return {
      name: obj.name,
      date: obj.date,
    };
  });
  const id = localStorage.getItem("user_id");

  UpdateMember(
    id,
    firstname,
    lastname,
    age,
    arr,
    nickname,
    occassion,
    typeArray,
    typeArrayTwo,
    typeArrayThree,
    typeArrayFour,
    typeArrayFive,
    typeArraySix,
    typeArraySeven,
    state
  ).then((result) => {
    if (result.success == true) {
      alert(result.message);

      // window.location.href = "/dashboard-user";
    } else {
      alert(result.message);
    }
  });
};

export function arraymanuplate1(userdata) {
  let arr = [
    "Cook",
    "Alcohol",
    "Coffee",
    "Photo",
    "Read",
    "Puzzlegame",
    "Garden",
    "Videogame",
  ];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}

export function arraymanuplate2(userdata) {
  let arr = [
    "Biking",
    "Yoga",
    "Golfing",
    "lifting",
    "Running",
    "Swimming",
    "Skiing",
    "snowboard",
    "Camping",
    "Hiking",
    "Fishing",
    "Hunting",
  ];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}

export function arraymanuplate3(userdata) {
  let arr = ["Beach", "Bars or Clubs", "Concerts", "Airport"];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}

export function arraymanuplate4(userdata) {
  let arr = ["Sporty", "Longwear", "Outdoorsy", "Streetwear"];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}

export function arraymanuplate5(userdata) {
  let arr = ["Beer", "Candy", "Coffee", "Wine", "Pasta", "Pizza", "Tea"];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}

export function arraymanuplate6(userdata) {
  let arr = ["Countryside", "City", "Suburbs", "Not Sure"];

  return arr.findIndex((obj, i) => {
    if (obj == userdata) {
      localStorage.setItem("Area", i + 1);
      return i + 1;
    }
  });
}

export function arraymanuplate7(userdata) {
  if (userdata == "Albama") {
    return 0;
  }
  if (userdata == "Alaska") {
    return 1;
  }
  if (userdata == "Arizona") {
    return 2;
  }
  if (userdata == "Arkansas") {
    return 3;
  }
  if (userdata == "California") {
    return 4;
  }

  // tion>
  // if(

  // tion>
  // if(
  //       California
  // tion>

  // if(
  //       Colorado
  // tion>
  // if(
  //       Connecticut
  // tion>
  // if(
  //       Delaware
  // tion>
  // if(
  //       District of Columbia
  // tion>
  // if(
  //       Florida
  // tion>

  // if(" >
  //       Georgia
  // tion>
  // if(" >
  //       Hawaii
  // tion>
  // if(" >
  //       Idaho
  // tion>
  // if(" >
  //       Illinois
  // tion>
  // if(" >
  //       Indiana
  // tion>

  // if(" >
  //       Iowa
  // tion>
  // if(" >
  //       Kansas
  // tion>
  // if(" >
  //       Kentucky
  // tion>
  // if(" >
  //       Louisiana
  // tion>
  // if(" >
  //       Maine
  // tion>

  // if(" >
  //       Maryland
  // tion>
  // if(" >
  //       Massachusetts
  // tion>
  // if(" >
  //       Michigan
  // tion>
  // if(" >
  //       Minnesota
  // tion>
  // if(" >
  //       Mississippi
  // tion>

  // if(" >
  //       Missouri
  // tion>
  // if(" >
  //       Montana
  // tion>
  // if(" >
  //       Nebraska
  // tion>
  // if(" >
  //       Nevada
  // tion>
  // if(" >
  //       New Hampshire
  // tion>

  // if(" >
  //       New Jersey
  // tion>
  // if(" >
  //       New Mexico
  // tion>
  // if(" >
  //       New York
  // tion>
  // if(" >
  //       North Carolina
  // tion>
  // if(" >
  //       North Dakota
  // tion>

  // if(" >
  //       Ohio
  // tion>
  // if(" >
  //       Oklahoma
  // tion>
  // if(" >
  //       Oregon
  // tion>
  // if(" >
  //       Pennsylvania
  // tion>
  // if(" >
  //       Rhode Island
  // tion>

  // if(" >
  //       South Carolina
  // tion>
  // if(" >
  //       South Dakota
  // tion>
  // if(" >
  //       Tennessee
  // tion>
  // if(" >
  //       Texas
  // tion>
  // if(" >
  //       Utah
  // tion>

  // if(" >
  //       Vermont
  // tion>
  // if(" >
  //       Virginia
  // tion>
  // if(" >
  //       Washington
  // tion>
  // if(" >
  //       West Virginia
  // tion>
  // if(" >
  //       Wisconsin
  // tion>
}

export function arraymanuplate8(userdata) {
  let arr = ["FemaleLed", "MadeinUSA", "Sustainable", "Charitable"];

  let manuplatearr = arr.map((obj) => {
    if (userdata.includes(obj)) {
      localStorage.setItem(obj, 1);
      return 1;
    } else {
      localStorage.setItem(obj, 0);
      return 0;
    }
  });

  return manuplatearr;
}
