import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Beach from "../../assets/images/Beach.png";
import Alcohol from "../../assets/images/Alcohol.png";
import Concerts from "../../assets/images/Concerts.png";
import Airport from "../../assets/images/Airport.png";
import Back from "../../assets/images/Backside.png";

import { useHistory } from "react-router-dom";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateTwelve = (props) => {
  useEffect(() => {
    pageView("/surveyQ7");
  });

  const { watch, register } = useForm();
  const [Items, setItems] = useState([]);

  useEffect(() => {
    console.log("Items Selected : ", Items);
  });
  const btClicked = (e) => {
    e.persist();
    let value = e.target.name;
    console.log("Event Value : ");
    console.log();
    let allItems = [...Items];
    if (allItems.includes(value)) {
      let index = allItems.indexOf(value);
      allItems.splice(index, 1);
    } else {
      allItems.push(value);
    }
    setItems(allItems);
  };

  const toggletotal = (e) => {
    let value = e.target.value;
    var inputs, index;
    let name = e.target.name;
    inputs = document.getElementsByClassName("dataselect");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }
      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  const toggletotal2 = (e) => {
    let value = e.target.value;
    var inputs, index;
    let name = e.target.name;
    inputs = document.getElementsByClassName("dataselect2");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }
      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  function shuffle(id, kid) {
    var container = document.getElementById(id);
    var elementsArray = Array.prototype.slice.call(
      container.getElementsByClassName(kid)
    );
    elementsArray.forEach(function (element) {
      container.removeChild(element);
    });
    shuffleArray(elementsArray);
    elementsArray.forEach(function (element) {
      container.appendChild(element);
    });
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  useEffect(() => {
    shuffle("container", "wrapper-passion");
  }, []);

  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className="desktop">
        <h2 id="clothing-style" className="Text-header-main">
          What sorts of clothing styles do they like to wear?
        </h2>
        <p className="paredesc">Select all that apply, or skip!</p>
        <input type="checkbox" />
        <input type="checkbox" />
        <input type="checkbox" />
        <div className="step-1-form middle">
          <div className="myrow" id="container">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Sporty"
                  value="0"
                  id="Sporty"
                  className="datacollect"
                  checked={props.arr[0] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ position: "relative" }}
                >
                  <div className="passionate-activity">
                    <p className="th-step-multi centertext">Sporty</p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Longwear"
                  value="1"
                  id="Longwear"
                  className="datacollect"
                  checked={props.arr[1] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ position: "relative" }}
                >
                  <div className="passionate-activity">
                    <p className="th-step-multi centertext">Loungewear</p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="OutDoorsy"
                  value="2"
                  id="OutDoorsy"
                  className="datacollect"
                  checked={props.arr[2] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ position: "relative" }}
                >
                  <div className="passionate-activity">
                    <p className="th-step-multi centertext">Outdoorsy</p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Streetwear"
                  value="3"
                  id="Streetwear"
                  className="datacollect"
                  checked={props.arr[3] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div
                  className="front-end box1 myboxes area-icon"
                  style={{ position: "relative" }}
                >
                  <div className="passionate-activity">
                    <p className="th-step-multi centertext">Streetwear</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                borderBottom: "3px solid #FCC9BE",
                marginRight: 25,
                color: "#1F294E",
                fontFamily: "Nunito",
                paddingBottom: 6,
                cursor: "pointer",
              }}
              onClick={props.onBackClick}
            >
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <a className="backbtn">Back</a>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#478BA2",
                  border: "none",
                  fontFamily: "Bungee",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "10px 20px",
                  color: "#fff",
                  borderRadius: 10,
                }}
                onClick={(e) =>
                  props.onClick({
                    title:
                      document.getElementById("clothing-style").textContent,
                    listItems: Items,
                  })
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile view  start */}

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3 cloth">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh" style={{ position: "relative", bottom: 15 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2
                style={{
                  color: "#1F294E",
                  fontSize: "18px",
                  textAlign: "center",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                {" "}
                What sorts of clothing styles do they like to wear?
              </h2>
            </div>

            <div className="selectall">Select all that apply, or skip!</div>
          </div>

          <div className="mycol2">
            <label>
              <input
                type="checkbox"
                name="Sporty"
                value="0"
                id="Sporty"
                className="datacollect2"
                checked={props.arr[0] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div
                className="myboxes area-icon"
                style={{ position: "relative" }}
              >
                <div className="passionate-activity">
                  <p className="th-step-multi centertext">Sporty</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Longwear"
                value="1"
                id="Longwear"
                className="datacollect2"
                checked={props.arr[1] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div
                className="myboxes area-icon"
                style={{ position: "relative" }}
              >
                <div className="passionate-activity">
                  <p className="th-step-multi centertext">Loungewear</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="OutDoorsy"
                value="2"
                id="OutDoorsy"
                className="datacollect2"
                checked={props.arr[2] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div
                className="myboxes area-icon"
                style={{ position: "relative" }}
              >
                <div className="passionate-activity">
                  <p className="th-step-multi centertext">Outdoorsy</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Streetwear"
                value="3"
                id="Streetwear"
                className="datacollect2"
                checked={props.arr[3] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div
                className="myboxes area-icon"
                style={{ position: "relative" }}
              >
                <div className="passionate-activity">
                  <p className="th-step-multi centertext">Streetwear</p>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateTwelve;
