import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import banimage from "../assets/images/dation.png";
import "./Style.css";

function Spotlight() {
  return (
    <>
      <Navbar />
      <div className="row m-0">
        <div className="col-1"></div>
        <div className="col-10">
          <div>
            <img className="banner-spot" src={banimage} />
          </div>
          <div>
            <p className="spottext">Looking for something specific?</p>
            <p className="spottext2">
              Search for gifting topics on your own using the search bar!
            </p>
          </div>
          <div>
            <input
              className="spotinput"
              type="search"
              placeholder="Search for brands, gift topics, questions,etc...."
            />
          </div>
          <div className="row margispot">
            <div className="col-md-4">
              <div className="cardspot">
                <p className="headspot">Expertly Curated Gift Guides</p>
                <p className="tspot">
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cardspot">
                <p className="headspot">Helpful HOW-to's & Gift Tips</p>
                <p className="tspot">
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cardspot">
                <p className="headspot">Featured Brands Spotlights</p>
                <p className="tspot">
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua
                </p>
              </div>
            </div>
          </div>
          <button className="subscribe">SUBSCRIBE</button>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
}
export default Spotlight;
