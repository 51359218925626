import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import top from "../../assets/images/Polygon7.png";
import bottom from "../../assets/images/Polygon8.png";
import Back from "../../assets/images/Backside.png";
import { useHistory } from "react-router-dom";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateSeventeen = (props) => {
  useEffect(() => {
    pageView("/surveyQ10");
  });

  const { watch, register } = useForm();

  const getValue = () => {
    var select = document.getElementById("state-select");
    var value = select.options[select.selectedIndex].value;
    localStorage.setItem("State", value);

    console.log(value); // en
  };

  const getValue2 = (e) => {
    localStorage.setItem("State", e.target[e.target.selectedIndex].text);
    props.setselected(e.target.value);
  };

  const history = useHistory();
  const navigate = () => {
    console.log("home");
    history.push("/");
  };

  const farward = () => {
    if (document.getElementById("state-select").value == "") {
      document.getElementById("state-select").value = 0;
    } else {
      let i = document.getElementById("state-select").value;
      let j = parseInt(i);
      document.getElementById("state-select").value = j + 1;
    }
  };

  const farward2 = () => {
    if (props.selected === 49) {
      props.setselected(1);
      localStorage.setItem("State", 1);
    } else {
      var i = parseInt(props.selected);
      props.setselected(i + 1);
      localStorage.setItem("State", i + 1);
    }
  };

  const backward2 = () => {
    if (props.selected === 0) {
      props.setselected(49);
      localStorage.setItem("State", 49);
    } else {
      var i = parseInt(props.selected);
      props.setselected(i - 1);
      localStorage.setItem("State", i - 1);
    }
  };

  const backward = () => {
    if (document.getElementById("state-select").value == "") {
      document.getElementById("state-select").value = 49;
    } else {
      let i = document.getElementById("state-select").value;
      let j = parseInt(i);
      document.getElementById("state-select").value = j - 1;
    }
  };
  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className="desktop">
        <h2 id="which-state-do" className="Text-header-main">
          Which state do they live in?
        </h2>
        <input type="checkbox" />
        <input type="checkbox" />
        <input type="checkbox" />
        <div className="step-1-form middle">
          <div className="row">
            <div>
              <div>
                <div
                  style={{
                    border: "3px solid #9685C8",
                    borderRadius: 9,
                    position: "relative",
                    marginTop: "15%",
                    marginBottom: "15%",
                  }}
                >
                  <select
                    id="state-select"
                    style={{ width: "100%", padding: 11 }}
                    onChange={(e) => {
                      props.setselected(e.target.value), getValue();
                    }}
                    className="select-wrapper"
                  >
                    <option></option>
                    <option
                      className="value-style"
                      value="0"
                      selected={props.selected == 0}
                    >
                      Alabama
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 1}
                      value="1"
                    >
                      Alaska
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 2}
                      value="2"
                    >
                      Arizona
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 3}
                      value="3"
                    >
                      Arkansas
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 4}
                      value="4"
                    >
                      California
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 5}
                      value="5"
                    >
                      Colorado
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 6}
                      value="6"
                    >
                      Connecticut
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 7}
                      value="7"
                    >
                      Delaware
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 8}
                      value="8"
                    >
                      District of Columbia
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 9}
                      value="9"
                    >
                      Florida
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 10}
                      value="10"
                    >
                      Georgia
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 10}
                      value="11"
                    >
                      Hawaii
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 12}
                      value="12"
                    >
                      Idaho
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 13}
                      value="13"
                    >
                      Illinois
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 14}
                      value="14"
                    >
                      Indiana
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 15}
                      value="15"
                    >
                      Iowa
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 16}
                      value="16"
                    >
                      Kansas
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 17}
                      value="17"
                    >
                      Kentucky
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 18}
                      value="18"
                    >
                      Louisiana
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 19}
                      value="19"
                    >
                      Maine
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 20}
                      value="20"
                    >
                      Maryland
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 21}
                      value="21"
                    >
                      Massachusetts
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 22}
                      value="22"
                    >
                      Michigan
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 23}
                      value="23"
                    >
                      Minnesota
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 24}
                      value="24"
                    >
                      Mississippi
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 25}
                      value="25"
                    >
                      Missouri
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 26}
                      value="26"
                    >
                      Montana
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 27}
                      value="27"
                    >
                      Nebraska
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 28}
                      value="28"
                    >
                      Nevada
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 29}
                      value="29"
                    >
                      New Hampshire
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 30}
                      value="30"
                    >
                      New Jersey
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 31}
                      value="31"
                    >
                      New Mexico
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 32}
                      value="32"
                    >
                      New York
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 33}
                      value="33"
                    >
                      North Carolina
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 34}
                      value="34"
                    >
                      North Dakota
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 35}
                      value="35"
                    >
                      Ohio
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 36}
                      value="36"
                    >
                      Oklahoma
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 37}
                      value="37"
                    >
                      Oregon
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 38}
                      value="38"
                    >
                      Pennsylvania
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 39}
                      value="39"
                    >
                      Rhode Island
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 40}
                      value="40"
                    >
                      South Carolina
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 41}
                      value="41"
                    >
                      South Dakota
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 42}
                      value="42"
                    >
                      Tennessee
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 43}
                      value="43"
                    >
                      Texas
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 44}
                      value="44"
                    >
                      Utah
                    </option>

                    <option
                      className="value-style"
                      selected={props.selected == 45}
                      value="45"
                    >
                      Vermont
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 46}
                      value="46"
                    >
                      Virginia
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 47}
                      value="47"
                    >
                      Washington
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 48}
                      value="48"
                    >
                      West Virginia
                    </option>
                    <option
                      className="value-style"
                      selected={props.selected == 49}
                      value="49"
                    >
                      Wisconsin
                    </option>
                  </select>
                  <div>
                    <img
                      style={{
                        width: 10,
                        top: 10,
                        right: 15,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      src={top}
                      alt=""
                      onClick={farward}
                    />
                    <img
                      style={{
                        width: 10,
                        bottom: 10,
                        right: 15,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                      src={bottom}
                      alt=""
                      onClick={backward}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderBottom: "3px solid #FCC9BE",
                  color: "#1F294E",
                  fontFamily: "Nunito",
                  marginRight: 25,
                  paddingBottom: 6,
                  cursor: "pointer",
                }}
                onClick={props.onBackClick}
              >
                <img src={Back} alt="" style={{ paddingBottom: 5 }} />
                <img src={Back} alt="" style={{ paddingBottom: 5 }} />
                <a className="backbtn">Back</a>
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: "#478BA2",
                    border: "none",
                    fontFamily: "Bungee",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "10px 20px",
                    color: "#fff",
                    borderRadius: 10,
                  }}
                  onClick={(e) =>
                    props.onClick({
                      title:
                        document.getElementById("which-state-do").textContent,
                      select: document.getElementById("state-select"),
                    })
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="mobile-survey" style={{ height: "100%" }}>
        <div className="bg-white m-3 p-3" style={{ height: "79%" }}>
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh">
            <h2
              style={{
                color: "#1F294E",
                textAlign: "center",
                fontSize: "18px",
                fontFamily: "Nunito",
                fontWeight: 700,
              }}
            >
              {" "}
              Which state do they live in?
            </h2>
          </div>
          <div
            className="margin-auto"
            style={{
              border: "3px solid #9685C8",
              borderRadius: 9,
              position: "relative",
              marginTop: "86px",
              width: 200,
            }}
          >
            <select
              id="state-select2"
              value={props.selected}
              style={{ width: "100%", padding: 11 }}
              onChange={(e) => {
                getValue2(e);
              }}
              className="select-wrapper"
            >
              <option value=""></option>
              <option
                className="value-style"
                value="0"
                selected={props.selected == 0}
              >
                Alabama
              </option>
              <option
                className="value-style"
                selected={props.selected == 1}
                value="1"
              >
                Alaska
              </option>
              <option
                className="value-style"
                selected={props.selected == 2}
                value="2"
              >
                Arizona
              </option>
              <option
                className="value-style"
                selected={props.selected == 3}
                value="3"
              >
                Arkansas
              </option>
              <option
                className="value-style"
                selected={props.selected == 4}
                value="4"
              >
                California
              </option>

              <option
                className="value-style"
                selected={props.selected == 5}
                value="5"
              >
                Colorado
              </option>
              <option
                className="value-style"
                selected={props.selected == 6}
                value="6"
              >
                Connecticut
              </option>
              <option
                className="value-style"
                selected={props.selected == 7}
                value="7"
              >
                Delaware
              </option>
              <option
                className="value-style"
                selected={props.selected == 8}
                value="8"
              >
                District of Columbia
              </option>
              <option
                className="value-style"
                selected={props.selected == 9}
                value="9"
              >
                Florida
              </option>

              <option
                className="value-style"
                selected={props.selected == 10}
                value="10"
              >
                Georgia
              </option>
              <option
                className="value-style"
                selected={props.selected == 10}
                value="11"
              >
                Hawaii
              </option>
              <option
                className="value-style"
                selected={props.selected == 12}
                value="12"
              >
                Idaho
              </option>
              <option
                className="value-style"
                selected={props.selected == 13}
                value="13"
              >
                Illinois
              </option>
              <option
                className="value-style"
                selected={props.selected == 14}
                value="14"
              >
                Indiana
              </option>

              <option
                className="value-style"
                selected={props.selected == 15}
                value="15"
              >
                Iowa
              </option>
              <option
                className="value-style"
                selected={props.selected == 16}
                value="16"
              >
                Kansas
              </option>
              <option
                className="value-style"
                selected={props.selected == 17}
                value="17"
              >
                Kentucky
              </option>
              <option
                className="value-style"
                selected={props.selected == 18}
                value="18"
              >
                Louisiana
              </option>
              <option
                className="value-style"
                selected={props.selected == 19}
                value="19"
              >
                Maine
              </option>

              <option
                className="value-style"
                selected={props.selected == 20}
                value="20"
              >
                Maryland
              </option>
              <option
                className="value-style"
                selected={props.selected == 21}
                value="21"
              >
                Massachusetts
              </option>
              <option
                className="value-style"
                selected={props.selected == 22}
                value="22"
              >
                Michigan
              </option>
              <option
                className="value-style"
                selected={props.selected == 23}
                value="23"
              >
                Minnesota
              </option>
              <option
                className="value-style"
                selected={props.selected == 24}
                value="24"
              >
                Mississippi
              </option>

              <option
                className="value-style"
                selected={props.selected == 25}
                value="25"
              >
                Missouri
              </option>
              <option
                className="value-style"
                selected={props.selected == 26}
                value="26"
              >
                Montana
              </option>
              <option
                className="value-style"
                selected={props.selected == 27}
                value="27"
              >
                Nebraska
              </option>
              <option
                className="value-style"
                selected={props.selected == 28}
                value="28"
              >
                Nevada
              </option>
              <option
                className="value-style"
                selected={props.selected == 29}
                value="29"
              >
                New Hampshire
              </option>

              <option
                className="value-style"
                selected={props.selected == 30}
                value="30"
              >
                New Jersey
              </option>
              <option
                className="value-style"
                selected={props.selected == 31}
                value="31"
              >
                New Mexico
              </option>
              <option
                className="value-style"
                selected={props.selected == 32}
                value="32"
              >
                New York
              </option>
              <option
                className="value-style"
                selected={props.selected == 33}
                value="33"
              >
                North Carolina
              </option>
              <option
                className="value-style"
                selected={props.selected == 34}
                value="34"
              >
                North Dakota
              </option>

              <option
                className="value-style"
                selected={props.selected == 35}
                value="35"
              >
                Ohio
              </option>
              <option
                className="value-style"
                selected={props.selected == 36}
                value="36"
              >
                Oklahoma
              </option>
              <option
                className="value-style"
                selected={props.selected == 37}
                value="37"
              >
                Oregon
              </option>
              <option
                className="value-style"
                selected={props.selected == 38}
                value="38"
              >
                Pennsylvania
              </option>
              <option
                className="value-style"
                selected={props.selected == 39}
                value="39"
              >
                Rhode Island
              </option>

              <option
                className="value-style"
                selected={props.selected == 40}
                value="40"
              >
                South Carolina
              </option>
              <option
                className="value-style"
                selected={props.selected == 41}
                value="41"
              >
                South Dakota
              </option>
              <option
                className="value-style"
                selected={props.selected == 42}
                value="42"
              >
                Tennessee
              </option>
              <option
                className="value-style"
                selected={props.selected == 43}
                value="43"
              >
                Texas
              </option>
              <option
                className="value-style"
                selected={props.selected == 44}
                value="44"
              >
                Utah
              </option>

              <option
                className="value-style"
                selected={props.selected == 45}
                value="45"
              >
                Vermont
              </option>
              <option
                className="value-style"
                selected={props.selected == 46}
                value="46"
              >
                Virginia
              </option>
              <option
                className="value-style"
                selected={props.selected == 47}
                value="47"
              >
                Washington
              </option>
              <option
                className="value-style"
                selected={props.selected == 48}
                value="48"
              >
                West Virginia
              </option>
              <option
                className="value-style"
                selected={props.selected == 49}
                value="49"
              >
                Wisconsin
              </option>
            </select>
            <div>
              {/* <img style={{width : 10, top : 10, right : 15, position : "absolute" , cursor : "pointer"}} src={top} alt="" onClick={farward2} /> */}
              {/* <img style={{width : 10, bottom : 10, right : 15, position : "absolute", cursor : "pointer"}} src={bottom} alt="" onClick={backward2}/> */}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default StateSeventeen;
