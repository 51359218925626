import "./App.css";
import Example from "./js/components/Example";
import StaticOverlay from "./js/components/StaticOverlay";

function App() {
  return (
    <div className="App">
      <Example />
      <StaticOverlay />
    </div>
  );
}

export default App;
