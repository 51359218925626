import React, { Component } from "react";
import { Label } from "reactstrap";
import { Form, Button } from "react-bootstrap";
import "./login.css";
import { Adminlogin, isAuthenticate } from "../Services/Services";
// import md5 from 'md5';
import { withRouter, Redirect } from "react-router";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Logo from "../../assets/images/Outdone-Header-Logo.svg";

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }
  state = {
    email: "",
    password: "",
    iserror: false,
    errormsg: "no error",
    isloading: false,
  };

  componentDidMount() {
    // PostApi();
    console.log("Login Page Props : ", this.props);
  }

  login = () => {
    this.setState({ isloading: true });
    Adminlogin(this.state.email, this.state.password).then((result) => {
      let responseJSON = result;
      if (responseJSON.success == true) {
        localStorage.setItem("admin_token", responseJSON.data.api_token, {
          expires: 1,
        });
        Cookies.set("token", responseJSON.data.api_token, { expires: 1 });
        this.props.history.push("/dashboard");
      } else if (responseJSON.success == false) {
        this.setState({
          iserror: true,
          errormsg: responseJSON.message,
        });
      }
      this.setState({ isloading: false });
    });
  };

  handleChange = (e) => {
    if (e.target.id === "id") {
      const email = e.target.value;
      this.setState({ email: email });
    } else if (e.target.id === "pass") {
      const pass = e.target.value;
      this.setState({ password: pass });
    }
  };

  render() {
    const { iserror, errormsg, isloading } = this.state;

    return (
      <Form className="Login-form p-5">
        <div className="row">
          <div className="logo-login">
            <img src={Logo} width="200px" />
          </div>
        </div>
        {iserror ? <p className="text-danger text-center">{errormsg}</p> : ""}
        <Form.Group controlId="id">
          <Label>Email</Label>
          <Form.Control
            type="email"
            placeholder="Enter Your Email"
            required
            name="email"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group className="pt-3" controlId="pass">
          <Label>Password</Label>
          <Form.Control
            type="password"
            placeholder="Enter Your Password"
            required
            name="password"
            onChange={this.handleChange}
          />
        </Form.Group>
        {isloading ? (
          <Loader
            className="text-center mt-3"
            type="Oval"
            color="#323A40"
            height={50}
            width={50}
            // timeout={3000} //3 secs
          />
        ) : (
          <Button
            className="btn-lg btn-dark btn-block mt-4"
            onClick={this.login}
          >
            Login
          </Button>
        )}
      </Form>
    );
  }
}

export default Login;
