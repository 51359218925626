import React, { Component } from "react";
import { CSVLink } from "react-csv";
import "./CsvImport.css";

const headers = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Username", key: "username" },
  { label: "Email", key: "email" },
  { label: "City", key: "address.city" },
  { label: "Website", key: "website" },
];

class CsvImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
    this.csvLinkEl = React.createRef();
  }

  getUserList = () => {
    return fetch("https://jsonplaceholder.typicode.com/users").then((res) =>
      res.json()
    );
  };

  downloadReport = async () => {
    this.setState({ loading: true });
    const data = await this.getUserList();

    this.setState({ data: data, loading: false }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  };

  render() {
    const { data, loading } = this.state;

    return (
      <div className="container">
        <div className="data-export">
          <input
            type="button"
            value={loading ? "Downloading..." : "Export User Data"}
            disabled={loading}
            onClick={this.downloadReport}
          />
          <CSVLink
            headers={headers}
            filename="Clue_Mediator_Report_Async.csv"
            data={data}
            ref={this.csvLinkEl}
          />
        </div>
      </div>
    );
  }
}

export default CsvImport;
