import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import StateTwo from "./Survey-states/FormBody/StateTwo";
import StateEight from "./Survey-states/FormBody/StateEight";
import StateNine from "./Survey-states/FormBody/StateNine";
import StateTen from "./Survey-states/FormBody/StateTen";
import StateEleven from "./Survey-states/FormBody/StateEleven";
import StateTwelve from "./Survey-states/FormBody/StateTwelve";
import StateThirteen from "./Survey-states/FormBody/StateThirteen";
import StateFourteen from "./Survey-states/FormBody/StateFourten";
import StateFifthteen from "./Survey-states/FormBody/StateFifthteen";
import StateSixthteen from "./Survey-states/FormBody/StateSixthteen";
import StateSeventeen from "./Survey-states/FormBody/StateSeventeen";
import { QuestionAnalytics } from "./Services/ServicePhase2";
import ProgressBar from "./Survey-states/progressBarSurvey/ProgressBar";
import faqcenter from "./assets/images/Grupo_1230.png";
import Back from "./assets/images/Group 100.png";
import { useHistory } from "react-router-dom";
import x from "./assets/images/x2.png";
import {
  mobiledatasaving,
  updatemobiledata,
  mobiledatasaving2,
  arraymanuplate1,
  arraymanuplate2,
  arraymanuplate3,
  arraymanuplate4,
  arraymanuplate5,
  arraymanuplate6,
  arraymanuplate7,
  arraymanuplate8,
  updatememberdata,
} from "./mobiledatasaving";
import { pageView, conversionEvent } from "../ga4Service";

pageView("/quickSurvey");
conversionEvent("funnel", "funnel-start");

const QuickSurvey = (props) => {
  const [formStep, setFormStep] = useState(0);
  const [shopingFor, setShopingfor] = useState();
  const [gender, setGender] = useState();

  const [Like, setLike] = useState([]);
  const [physical, setphysical] = useState([]);
  const [bear, setBear] = useState();
  const [stateLive, setStateLive] = useState();
  const [activity, checkActivity] = useState();
  const { register, watch } = useForm();
  const [error, setError] = useState(false);
  const [value, setValue] = useState();
  const [beach, setBeach] = useState();
  const [lake, setLake] = useState();
  const [home, setHome] = useState();
  const [care, setCare] = useState();

  const [Cooks, setCooks] = useState(0);
  const [Alcohol, setAlcohol] = useState(0);
  const [Coffee, setCoffee] = useState(0);

  const [arr, setarr] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [arr2, setarr2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [arr3, setarr3] = useState([0, 0, 0, 0]);
  const [arr4, setarr4] = useState([0, 0, 0, 0]);
  const [arr5, setarr5] = useState([0, 0, 0, 0, 0, 0, 0]);

  const [area, setarea] = useState();
  const [selected, setselected] = useState();
  const [business, setbusiness] = useState([0, 0, 0, 0]);
  const [lookingspend, setlookingspend] = useState();

  useEffect(() => {
    if (props.editmyself) {
      setDd(props.editmyself.age);
      localStorage.setItem("age", props.editmyself.age);

      if (props.editmyself.gender == "Male") {
        setGender(1);
      } else if (props.editmyself.gender == "Female") {
        setGender(2);
      } else if (props.editmyself.gender == "Other") {
        setGender(3);
      }

      setarr(arraymanuplate1(props.editmyself.question1));
      setarr2(arraymanuplate2(props.editmyself.question2));
      setarr3(arraymanuplate3(props.editmyself.question5));

      setarr4(arraymanuplate4(props.editmyself.question3));
      setarr5(arraymanuplate5(props.editmyself.question4));
      setarea(arraymanuplate6(props.editmyself.question7));
      setselected(arraymanuplate7(props.editmyself.state));
      setbusiness(arraymanuplate8(props.editmyself.question6));
    }
  }, [props.editmyself]);

  useEffect(() => {
    if (props.editfriend) {
      setDd(props.editfriend.age);
      localStorage.setItem("age", props.editfriend.age);

      if (props.editfriend.gender == "Male") {
        setGender(1);
      } else if (props.editfriend.gender == "Female") {
        setGender(2);
      } else if (props.editfriend.gender == "Other") {
        setGender(3);
      }

      setarr(arraymanuplate1(props.editfriend.question1));
      setarr2(arraymanuplate2(props.editfriend.question2));
      setarr3(arraymanuplate3(props.editfriend.question5));
      setarr4(arraymanuplate4(props.editfriend.question3));
      setarr5(arraymanuplate5(props.editfriend.question4));
      setarea(arraymanuplate6(props.editfriend.question7));
      setselected(arraymanuplate7(props.editfriend.state));
      setbusiness(arraymanuplate8(props.editfriend.question6));
    }
  }, [props.editfriend]);

  const localNotes = localStorage.getItem("age");
  const history = useHistory();

  if (localNotes == "undefined") {
    jQuery("input#age-gess").val("");
  }
  const [dd, setDd] = useState(localNotes);

  const handleChange = (e) => {
    localStorage.setItem("age", e.target.value);
    setDd(e.target.value);
  };
  (function ($) {
    $.fn.inputFilter = function (inputFilter) {
      return this.on(
        "input keydown keyup mousedown mouseup select contextmenu drop",
        function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        }
      );
    };
  })(jQuery);
  jQuery("input#age-gess").inputFilter(function (value) {
    return (
      /^\d*$/.test(value) &&
      (value === "" || (parseInt(value) != 0 && parseInt(value) <= 109))
    );
  });
  useEffect(() => {
    const homeAgeValue = localStorage.getItem("age");
    // jQuery("input#age-gess").val(homeAgeValue)

    if (homeAgeValue === null || props.editmyself) {
      setFormStep(0);
    } else {
      if (localStorage.getItem("homeentry") === "1") {
        setFormStep(1);
        window.scrollTo(0, 0);
      } else {
        setFormStep(0);
      }
    }

    data();

    window.scroll({
      top: document.body.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  $(document).ready(function () {
    $("#age-gess").keypress(function (e) {
      if (e.key === "Enter") {
        setFormStep(1);
      }
      var keyCode = e.keyCode || e.which;
      var regex = /^[0-9]+$/;
      var isValid = regex.test(String.fromCharCode(keyCode));
      return isValid;
    });
  });

  async function data() {
    var careCheckValue = localStorage.getItem("age");
    var icareCheck = parseInt(careCheckValue);
    await jQuery("input#age-gess").val(icareCheck);
    if (careCheckValue == null || careCheckValue == "undefined") {
      jQuery("input#age-gess").val("");
    }
  }

  const completeFormStep = (e) => {
    const dataValue = jQuery("input#age-gess").val();

    setFormStep((cur) => cur + 1);
  };
  const prevFormStep = () => {
    let x = formStep;
    setFormStep((cur) => cur - 1);
    setprogressStep(progressStep.filter((item) => item !== x));
  };

  //  gender
  const genderCheck = (e) => {
    const gender = e.target.value;
    localStorage.setItem("gender", gender);
    setGender(gender);

    setFormStep((cur) => cur + 1);
  };

  const genderCheckChild = (e) => {
    const radioBtn = e.target.value;
    setGender(radioBtn);
  };

  const setfunctionforshoping = (e) => {
    const shoping = e.target.value;
    localStorage.setItem("shopingfor", shoping);
    setShopingfor(shoping);

    setFormStep((cur) => cur + 1);
  };

  // State Nine 4
  const LikeToDo = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    localStorage.setItem("liketodo", Like);

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };

  const OnBackPress = (e) => {
    setFormStep((cur) => cur - 1);
  };
  // State Ten
  const DoEnjoy = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    QuestionAnalytics(title, answer);
    setFormStep((cur) => cur + 1);
  };

  // State 11
  const ElevenState = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };
  // State 11
  const TwelveState = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };
  // State 13
  const ThirteenState = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };
  // State 14
  const FourteenthState = (e) => {
    setFormStep((cur) => cur + 1);
  };
  // State 15
  const FifthteenState = (obj) => {
    const items = [...obj.listItems];
    const title = obj.title;
    const answer = items.join(",");

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };
  // State 16
  const SixthteenState = (e) => {
    setFormStep((cur) => cur + 1);
  };
  // State 17
  const SeventeenState = (obj) => {
    const title = obj.title;
    const sel = obj.select;
    const answer = sel.options[sel.selectedIndex].value;

    QuestionAnalytics(title, answer);

    setFormStep((cur) => cur + 1);
  };
  //gendercheckchild
  const handleinput = () => {
    console.log(document.getElementById("age-gess").value);
    if (dd == null || dd == "") {
      setError(true);
    } else {
      setFormStep((cur) => cur + 1);
      setError(false);
    }
  };

  //  beach
  const beachCheck = (e) => {
    const beachParty = e.target.value;
    localStorage.setItem("beachParty", beachParty);
    setBeach(beachParty);

    setFormStep((cur) => cur + 1);
  };
  //  Lake
  const lakeCheck = (e) => {
    const lakeCheck = e.target.value;
    localStorage.setItem("lakeCheck", lakeCheck);
    setLake(lakeCheck);

    setFormStep((cur) => cur + 1);
  };
  //  Home
  const homeCheck = (e) => {
    const homeCheck = e.target.value;
    localStorage.setItem("homeCheck", homeCheck);
    setHome(homeCheck);

    setFormStep((cur) => cur + 1);
  };
  //  bear
  const bearCheck = (e) => {
    const bearParty = e.target.value;
    localStorage.setItem("bearParty", bearParty);
    setBear(bearParty);

    setFormStep((cur) => cur + 1);
  };
  //  Care
  const careCheck = (e) => {
    const careCheck = e.target.value;
    localStorage.setItem("careCheck", careCheck);
    setCare(careCheck);

    setFormStep((cur) => cur + 1);
  };
  // Sort of Area
  const areaCheck = (e) => {
    const areaLive = e.target.value;
    localStorage.setItem("areaLive", areaLive);
    setArea(areaLive);

    setFormStep((cur) => cur + 1);
  };

  const chnageCheckboxValue = (e) => {
    $("input#weightlifting").change(function () {
      if (this.checked) {
        $("input#weightlifting").val("1");
      }
    });
    $("input#running").change(function () {
      if (this.checked) {
        $("input#running").val("1");
      }
    });
    $("input#golf").change(function () {
      if (this.checked) {
        $("input#golf").val("1");
      }
    });
    $("input#skateboarding").change(function () {
      if (this.checked) {
        $("input#skateboarding").val("1");
      }
    });
    $("input#sailing").change(function () {
      if (this.checked) {
        $("input#sailing").val("1");
      }
    });
    $("input#hunting-or-fishing").change(function () {
      if (this.checked) {
        $("input#hunting-or-fishing").val("1");
      }
    });
    $("input#surfing").change(function () {
      if (this.checked) {
        $("input#surfing").val("1");
      }
    });
    $("input#skiing-or-snowboarding").change(function () {
      if (this.checked) {
        $("input#skiing-or-snowboarding").val("1");
      }
    });
    $("input#hiking-or-camping").change(function () {
      if (this.checked) {
        $("input#hiking-or-camping").val("1");
      }
    });
    $("input#yoga").change(function () {
      if (this.checked) {
        $("input#yoga").val("1");
      }
    });
  };

  const valueActivity = () => {
    var weight = $("input#weightlifting").val();
    var weightApi = localStorage.setItem("Weightlifting", weight);
    var running = $("input#running").val();
    var runningAPi = localStorage.setItem("Running", running);
    var golf = $("input#golf").val();
    var golfAPi = localStorage.setItem("golf", golf);
    var skateboarding = $("input#skateboarding").val();
    var skateboardingAPi = localStorage.setItem("skateboarding", skateboarding);
    var sailing = $("input#sailing").val();
    var sailingAPi = localStorage.setItem("sailing", sailing);
    var hunting = $("input#hunting-or-fishing").val();
    var huntingAPi = localStorage.setItem("hunting", hunting);
    var surfing = $("input#surfing").val();
    var surfingAPi = localStorage.setItem("surfing", surfing);
    var hiking = $("input#hiking-or-camping").val();
    var hikingAPi = localStorage.setItem("hiking", hiking);
    var yoga = $("input#yoga").val();
    var yogaAPi = localStorage.setItem("yoga", yoga);
    var skinning = $("input#skiing-or-snowboarding").val();
    var skinningApi = localStorage.setItem("skiing", skinning);
  };

  // Activity
  const activityCheck = async (e) => {
    var activity = jQuery("input[type='checkbox']").val();
    checkActivity(activity);

    await valueActivity();
    setFormStep((cur) => cur + 1);
  };
  //StateLive
  const stateCheck = (e) => {
    const ukState = e.target.value;
    localStorage.setItem("ukState", ukState);
    setStateLive(ukState);

    // setFormStep(cur => cur + 1 )
  };
  // Validate Check
  const validateAge = (e) => {
    e.preventDefault();
    const dataValue = jQuery("input#age-gess").val();
    localStorage.setItem("age", dataValue);
    if (dataValue == "" || dataValue == null || dataValue == "undefined") {
      jQuery("input#age-gess").val("");
      setError(true);
    } else if (dataValue == null || dataValue == "undefined") {
      jQuery("input#age-gess").val("");
    } else {
      setError(false);

      QuestionAnalytics(
        "How old is the person you’re shopping for?",
        dataValue
      );
      setFormStep((cur) => cur + 1);
    }
  };
  //State jump
  const stateSkip = () => {
    const dataValues = jQuery("input#age-gess").val();

    setValue(dataValues);
    localStorage.setItem("value", value);
    setFormStep((cur) => cur + 1);
  };

  const navigate = () => {
    localStorage.removeItem("age");

    history.push("/");
  };

  const ageValue = localStorage.getItem("age");

  const Error = () => (
    <div id="error" className="text-center">
      <p>Please enter the age</p>
    </div>
  );
  var genderValue = localStorage.getItem("age");
  const googleAnalatics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-182682161-1", { page_title: "Result" });
  };

  const getResult = () => {
    const x = localStorage.getItem("Spending Amount");
    QuestionAnalytics("How much are you looking to spend?", x);
    history.push("/data");
  };

  setTimeout(function () {
    let viewheight = $(window).height();
    let viewwidth = $(window).width();
    let viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute(
      "content",
      "height=" +
        viewheight +
        "px, width=" +
        viewwidth +
        "px, initial-scale=1.0"
    );
  }, 300);

  return (
    <Fragment>
      <section className="margin-bar-quick-survey">
        <button onClick={navigate} className="btncross">
          <i className="far fa-times-circle circle-bar-custom"></i>
        </button>

        {formStep === 0 && (
          <>
            <p className="paredesc" style={{ top: "9%" }}>
              You’re just a few clicks away from finding the perfect gift!
            </p>
            <h2 className="Text-header-main">
              How old is the person you’re shopping for?
            </h2>
            <div className="step-1-form middle" style={{ top: "48%" }}>
              <form className="text-center mt-4" onSubmit={validateAge}>
                <input
                  style={{ lineHeight: "revert" }}
                  type="text"
                  id="age-gess"
                  onChange={handleChange}
                  value={dd}
                  name="age"
                  className={`text-input  ${error ? "color-red" : ""}`}
                />
              </form>
              {error ? <Error /> : null}
              <div className="mt-4 text-center button-wrapper">
                <button
                  onClick={validateAge}
                  type="button"
                  className="continue-button multi-form-btn button-wrapper-rad"
                >
                  NEXT
                </button>
                {/* <button onClick = {postData}>aaa</button> */}
              </div>
            </div>
          </>
        )}
        {formStep === 1 && (
          <StateEight
            setstateforshoping={setfunctionforshoping}
            onBackClick={OnBackPress}
            stateforshoping={shopingFor}
          />
        )}
        {formStep === 2 && (
          <StateTwo
            onClick={genderCheck}
            onBackClick={OnBackPress}
            childCheck={genderCheckChild}
            genrationSelection={gender}
          />
        )}

        {formStep === 3 && (
          <StateNine
            onClick={LikeToDo}
            onBackClick={OnBackPress}
            like={Like}
            setlike={setLike}
            arr={arr}
            setarr={setarr}
          />
        )}
        {formStep === 4 && (
          <StateTen
            onClick={DoEnjoy}
            onBackClick={OnBackPress}
            arr={arr2}
            setarr={setarr2}
            physical={physical}
            setphysical={setphysical}
          />
        )}

        {formStep === 5 && (
          <StateEleven
            onClick={ElevenState}
            onBackClick={OnBackPress}
            arr={arr3}
            setarr={setarr3}
          />
        )}

        {formStep === 6 && (
          <StateTwelve
            onClick={TwelveState}
            onBackClick={OnBackPress}
            arr={arr4}
            setarr={setarr4}
          />
        )}

        {formStep === 7 && (
          <StateThirteen
            onClick={ThirteenState}
            onBackClick={OnBackPress}
            arr={arr5}
            setarr={setarr5}
          />
        )}

        {formStep === 8 && (
          <StateFourteen
            onClick={FourteenthState}
            onBackClick={OnBackPress}
            area={area}
            setarea={setarea}
          />
        )}

        {formStep === 9 && (
          <StateSeventeen
            onClick={SeventeenState}
            onBackClick={OnBackPress}
            selected={selected}
            setselected={setselected}
          />
        )}

        {formStep === 10 && (
          <StateFifthteen
            onClick={FifthteenState}
            onBackClick={OnBackPress}
            arr={business}
            setarr={setbusiness}
          />
        )}
        {formStep === 11 && (
          <StateSixthteen
            onClick={SixthteenState}
            onBackClick={OnBackPress}
            lookingspend={lookingspend}
            setlookingspend={setlookingspend}
          />
        )}

        <div className="progress-survey">
          <ProgressBar active={formStep} />
        </div>
      </section>

      <div className="mymobilesurvey">
        {formStep === 0 && (
          <>
            <section className="mobile-survey">
              <div className="bg-white m-3 p-3">
                <button onClick={navigate} className="btncross">
                  <img src={x} style={{ width: 14 }} />
                </button>

                <p className="screenp1" style={{ paddingTop: 50 }}>
                  You’re just a few clicks away from finding the perfect gift!
                </p>
                <p className="screenp3" style={{ top: "18%" }}>
                  First things first...
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    bottom: 21,
                  }}
                >
                  {" "}
                  <img width="80" className="imges" src={faqcenter} />
                </div>
                <p className="screenp2" style={{ top: "32%" }}>
                  How old is the person you’re shopping for?
                </p>

                <div className="step-1-form" style={{ top: "48%" }}>
                  <form className="text-center mt-4" onSubmit={validateAge}>
                    <input
                      style={{
                        lineHeight: "revert",
                        backgroundColor: "#F4F4F4",
                        height: "72.58px",
                      }}
                      type="text"
                      id="age-gess"
                      onChange={handleChange}
                      value={dd}
                      name="age"
                      className={`text-input  ${error ? "color-red" : ""}`}
                    />
                  </form>
                  {error ? <Error /> : null}
                </div>
              </div>
              <div className="whiteblock"></div>
            </section>
          </>
        )}
        {formStep === 1 && (
          <StateEight
            setstateforshoping={setfunctionforshoping}
            onBackClick={OnBackPress}
            stateforshoping={shopingFor}
          />
        )}
        {formStep === 2 && (
          <StateTwo
            onClick={genderCheck}
            onBackClick={OnBackPress}
            childCheck={genderCheckChild}
            genrationSelection={gender}
          />
        )}

        {formStep === 3 && (
          <StateNine
            onClick={LikeToDo}
            onBackClick={OnBackPress}
            like={Like}
            setlike={setLike}
            arr={arr}
            setarr={setarr}
            editmyself={props.editmyself}
          />
        )}
        {formStep === 4 && (
          <StateTen
            onClick={DoEnjoy}
            onBackClick={OnBackPress}
            arr={arr2}
            setarr={setarr2}
            physical={physical}
            setphysical={setphysical}
          />
        )}

        {formStep === 5 && (
          <StateEleven
            onClick={ElevenState}
            onBackClick={OnBackPress}
            arr={arr3}
            setarr={setarr3}
          />
        )}

        {formStep === 6 && (
          <StateTwelve
            onClick={TwelveState}
            onBackClick={OnBackPress}
            arr={arr4}
            setarr={setarr4}
          />
        )}

        {formStep === 7 && (
          <StateThirteen
            onClick={ThirteenState}
            onBackClick={OnBackPress}
            arr={arr5}
            setarr={setarr5}
          />
        )}

        {formStep === 8 && (
          <StateFourteen
            onClick={FourteenthState}
            onBackClick={OnBackPress}
            area={area}
            setarea={setarea}
          />
        )}

        {formStep === 9 && (
          <StateSeventeen
            onClick={SeventeenState}
            onBackClick={OnBackPress}
            selected={selected}
            setselected={setselected}
          />
        )}

        {formStep === 10 && (
          <StateFifthteen
            onClick={FifthteenState}
            onBackClick={OnBackPress}
            arr={business}
            setarr={setbusiness}
          />
        )}
        {formStep === 11 && (
          <StateSixthteen
            onClick={SixthteenState}
            onBackClick={OnBackPress}
            lookingspend={lookingspend}
            setlookingspend={setlookingspend}
          />
        )}

        {formStep === 4 ? (
          <div className="footer-survey trans">
            <div className="flexit">
              <img
                src={Back}
                style={{ width: 75 }}
                onClick={() => {
                  formStep !== 0 ? setFormStep(formStep - 1) : null;
                }}
              />

              {formStep === 11 ? (
                <button
                  type="button"
                  className="btnmobila"
                  style={{
                    fontSize: 15,
                    marginLeft: 50,
                    height: "71%",
                    width: "63%",
                  }}
                  onClick={getResult}
                >
                  Get Recommendations!
                </button>
              ) : formStep === 1 ? null : formStep == 2 ? null : (
                <button
                  type="button"
                  className="btnmobila"
                  onClick={() => setFormStep(formStep + 1)}
                >
                  NEXT
                </button>
              )}

              <div></div>
            </div>

            <div className="progress-survey">
              <ProgressBar active={formStep} />
            </div>
          </div>
        ) : (
          <div className="footer-survey">
            <div className="flexit">
              {formStep === 0 ? null : (
                <img
                  src={Back}
                  style={{ width: 75 }}
                  onClick={() => {
                    props.editmyself || props.editfriend
                      ? formStep == 2
                        ? setFormStep(formStep - 2)
                        : setFormStep(formStep - 1)
                      : setFormStep(formStep - 1);
                  }}
                />
              )}

              {formStep === 10 ? (
                <>
                  {props.editfriend ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={(e) => updatememberdata(props.editoccassion)}
                    >
                      Update friend
                    </button>
                  ) : props.editmyself ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={updatemobiledata}
                    >
                      Update
                    </button>
                  ) : props.addmyself ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={mobiledatasaving}
                    >
                      Save!
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btnmobila"
                      onClick={() => {
                        props.editmyself
                          ? formStep == 0
                            ? setFormStep(formStep + 2)
                            : setFormStep(formStep + 1)
                          : setFormStep(formStep + 1);
                      }}
                    >
                      NEXT
                    </button>
                  )}
                </>
              ) : formStep === 11 ? (
                <>
                  {props.addmyself ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={mobiledatasaving}
                    >
                      Save!
                    </button>
                  ) : props.addfriend ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={() => mobiledatasaving2(props.occassions)}
                    >
                      Save Giftgetter!
                    </button>
                  ) : props.editmyself ? (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={mobiledatasaving2}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btnmobila"
                      style={{
                        fontSize: 15,
                        marginLeft: 50,
                        height: "71%",
                        width: "63%",
                      }}
                      onClick={getResult}
                    >
                      Get Recommendations!
                    </button>
                  )}
                </>
              ) : formStep === 1 ? null : formStep == 2 ? null : (
                <button
                  type="button"
                  className="btnmobila"
                  onClick={() => {
                    props.editmyself || props.editfriend
                      ? formStep == 0
                        ? setFormStep(formStep + 2)
                        : setFormStep(formStep + 1)
                      : setFormStep(formStep + 1);
                  }}
                >
                  NEXT
                </button>
              )}

              <div></div>
            </div>

            <div className="progress-survey">
              <ProgressBar active={formStep} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default QuickSurvey;
