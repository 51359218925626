import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import discover_section_1_right from "../assets/images/ILLU2.png";
import discover_page_img from "../assets/images/discover_page_img.svg";
import discover_img_top_right from "../assets/images/discover_img_top_right.svg";
import discover_img_top_left from "../assets/images/discover_img_top_left.svg";
import yogaImg from "../assets/images/yogafinal.png";
import huntingimg from "../assets/images/huntingfinal.png";
import travelimg from "../assets/images/travelfinal.png";
import homewareimg from "../assets/images/homewarefinal.png";
import beachimg from "../assets/images/beachfinal.png";
import runningimg from "../assets/images/runningfinal.png";
import adventureimg from "../assets/images/adventurefinal.png";
import subscriptionimg from "../assets/images/subscriptionfinal.png";
import imagegolden from "../assets/images/imagegolden.svg";
import Footer from "../Footer/Footer";
import { SearchBrand, PopularSearches } from "../Services/ServicePhase2";
import { useHistory } from "react-router-dom";
import gif from "../assets/images/Loading.gif";
import "./Discover.css";
import DiscoverPage3 from "./DiscoverPage3";
import DiscoverPage2 from "./DiscoverPage2";
import search from "../assets/images/searchmobile.svg";
import volume from "../assets/images/volume.svg";
import yogam from "../assets/images/Group 372.png";
import huntingm from "../assets/images/Group 373.png";
import travelm from "../assets/images/Group 371.png";
import homewarem from "../assets/images/Group 374.png";
import beachm from "../assets/images/Group 376.png";
import runningm from "../assets/images/Group 375.png";

function discover(props) {
  let history = useHistory();
  const [searchBrand, setSearchBrand] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [showNext, setshowNext] = useState(false);
  const [dataNext, setdataNext] = useState();
  const [loadingbrand, setLoadingbrand] = useState(false);
  const [showdiscoverPage3, setdiscoverPage3] = useState(false);
  const onSearchChange = (e) => {
    setSearchBrand(e.target.value);
  };

  useEffect(() => {
    setLoading(true);

    PopularSearches().then((result) => {
      setData(result.data);
      setLoading(false);
    });
  }, []);

  const onSubmitPress = (e) => {
    setLoadingbrand(true);
    SearchBrand(searchBrand).then((result) => {
      let res = {
        name: searchBrand,
        data: result.data,
      };

      if (result.success) {
        // setdataNext(res)
        // setshowNext(true);

        history.push({
          pathname: "/discoverpage2",
          state: { detail: res },
        });
      } else {
        alert(result.message);
      }
      setLoadingbrand(false);
    });
    e.preventDefault();
  };

  const selectBrand = (row) => {
    setLoadingbrand(true);
    SearchBrand(row).then((result) => {
      let res = {
        name: row,
        data: result.data,
      };

      if (result.success) {
        // setdataNext(res)
        // setshowNext(true);
        history.push({
          pathname: "/discoverpage2",
          state: { detail: res },
        });
      } else {
        alert(result.message);
      }
      setLoadingbrand(false);
    });
    e.preventDefault();
  };

  return (
    <>
      {loadingbrand && (
        <div
          className="center-loading"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#8d8a8aa1",
          }}
        >
          <img className="loading" type="video/mp4" src={gif} />
        </div>
      )}

      {showdiscoverPage3 && <DiscoverPage3 dataPrev={{ bId: eventCapture }} />}

      {showNext ? (
        <DiscoverPage2
          dataPrev={{ details: dataNext }}
          loader={setLoadingbrand}
        />
      ) : (
        <div>
          <Navbar />
          <div className="desktop">
            <div className="discover_bg">
              <div className="row m-0 mt-3">
                <div className="col-2">
                  <img
                    style={{ width: "85px", float: "right" }}
                    src={discover_img_top_right}
                  />
                </div>
                <div className="col-8">
                  <div className="row">
                    <div className="col-7">
                      <h3
                        style={{
                          fontSize: 38,
                          color: "#1F294E",
                          fontFamily: "Bungee",
                          paddingTop: "14%",
                        }}
                      >
                        DISCOVER & shop <br /> OUR trusted <br /> brands
                        PARTNERS
                      </h3>
                      <img
                        style={{
                          width: "90px",
                          paddingTop: "40px",
                          paddingBottom: "40px",
                        }}
                        src={discover_page_img}
                      />
                      <form onSubmit={onSubmitPress}>
                        <div>
                          <input
                            onChange={onSearchChange}
                            placeholder="Search for brands, gift topics, activities..."
                            style={{
                              border: "2px solid #FCC9BE",
                              width: "100%",
                              paddingLeft: 18,
                              padding: "6px",
                            }}
                            type="text"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-5">
                      <img
                        style={{ width: 355 }}
                        src={discover_section_1_right}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <img
                    style={{ width: "85px", position: "absolute", bottom: 0 }}
                    src={discover_img_top_left}
                  />
                </div>
              </div>
              <div className="row m-0">
                <div className="col-2"></div>
                <div className="col-10">
                  <div>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "500",
                        color: "#1F294E",
                        fontStyle: "italic",
                      }}
                    >
                      Examples:
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      cursor: "pointer",
                    }}
                  >
                    {data &&
                      data.map((row, index) => (
                        <div
                          className="tag"
                          key={index}
                          onClick={() => selectBrand(row)}
                        >
                          {row}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="discover_section_two">
              <div className="row m-0">
                <div className="col-1"></div>
                {loading ? (
                  <div
                    className="center-loading"
                    style={{
                      position: "fixed",
                      width: "100%",
                      height: "100%",
                      zIndex: "100",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#8d8a8aa1",
                    }}
                  >
                    <img className="loading" type="video/mp4" src={gif} />
                  </div>
                ) : (
                  <div className="col-10">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        padding: 40,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 26,
                          fontWeight: "bold",
                          color: "#1F294E",
                        }}
                      >
                        Popular Searches:
                      </p>
                      <div className="row">
                        {data &&
                          data.map((row, i) => (
                            <div
                              className="col-3"
                              key={i}
                              style={{ marginBottom: 63 }}
                            >
                              <div className="textImg">
                                <div>
                                  {i == 0 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={yogaImg}
                                      onClick={() => selectBrand("yoga")}
                                    />
                                  ) : i == 1 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={huntingimg}
                                      onClick={() => selectBrand("hunting")}
                                    />
                                  ) : i == 2 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={travelimg}
                                      onClick={() => selectBrand("travel")}
                                    />
                                  ) : i == 3 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={homewareimg}
                                      onClick={() => selectBrand("homeware")}
                                    />
                                  ) : i == 4 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={beachimg}
                                      onClick={() => selectBrand("beach")}
                                    />
                                  ) : i == 5 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={runningimg}
                                      onClick={() => selectBrand("running")}
                                    />
                                  ) : i == 6 ? (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={subscriptionimg}
                                      onClick={() =>
                                        selectBrand("subscriptions")
                                      }
                                    />
                                  ) : (
                                    <img
                                      className="pic"
                                      style={{ width: "100%" }}
                                      src={adventureimg}
                                      onClick={() => selectBrand("adventure")}
                                    />
                                  )}
                                </div>
                                <p className="pic_text">{row}</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-1"></div>
              </div>
            </div>
          </div>

          {/* Mobile view  */}

          <div className="mobile">
            <div className="dsectionone">
              <div
                className="row mt-5 pt-3"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="col-1"></div>
                <div className="col-5">
                  <h3
                    style={{
                      fontSize: 18,
                      color: "#1F294E",
                      fontFamily: "Bungee",
                      paddingTop: "14%",
                    }}
                  >
                    DISCOVER & shop <br /> OUR trusted <br /> brands PARTNERS
                  </h3>
                  <img
                    className="imagegolden"
                    style={{ paddingBottom: "10px" }}
                    src={imagegolden}
                  />
                </div>
                <div className="col-5">
                  <img style={{ width: 155 }} src={discover_section_1_right} />
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row align-items-center">
                <div className="col-1"></div>
                <div className="col-1 ">
                  <img src={search} />
                </div>

                <div className="col-8 ">
                  {" "}
                  <form onSubmit={onSubmitPress}>
                    <input
                      onChange={onSearchChange}
                      placeholder="Search for brands, gift topics, activities..."
                    />
                  </form>
                </div>
                <div className="col-1 " style={{ position: "relative" }}>
                  <img
                    src={volume}
                    style={{
                      position: "absolute",
                      top: "-13px",
                      right: "17px",
                    }}
                  />
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row mt-2">
                <div className="col-2"></div>
                <div className="col-8">
                  <h5 className="popular">Popular Searches</h5>
                  <div className="row" style={{ marginLeft: "-5px" }}>
                    {data &&
                      data.slice(0, 3).map((row, index) => (
                        <div
                          className="mini"
                          key={index}
                          onClick={() => selectBrand(row)}
                        >
                          {row}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-2"></div>
              </div>

              <div className="spacer"></div>
            </div>

            <div className="dsectiontwo">
              <div
                className="row"
                style={{ padding: 35, overflow: "hidden", marginBottom: 20 }}
              >
                {data &&
                  data.map((row, i) => (
                    <div className="col-6" key={i} style={{ marginBottom: 13 }}>
                      <div className="textImg">
                        {i == 0 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("yoga")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={yogam}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : i == 1 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("hunting")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={huntingm}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : i == 2 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("travel")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={travelm}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : i == 3 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("homeware")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={homewarem}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : i == 4 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("beach")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={beachm}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : i == 5 ? (
                          <>
                            {" "}
                            <div
                              className="imis"
                              onClick={() => selectBrand("running")}
                            >
                              <img
                                className="pic"
                                style={{ width: "100%" }}
                                src={runningm}
                              />{" "}
                            </div>{" "}
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
      <button className="subscribe" onClick={() => window.subscribe()}>
        SUBSCRIBE
      </button>
    </>
  );
}
export default discover;
