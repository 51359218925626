import React, { useState } from "react";
import "./SignUp.css";
import logo from "../../components/assets/images/footer_logo.png";
import {
  SignUp,
  FaceBookSignUp,
  GmailSignUp,
  GettingLinkedinData,
} from "../Services/ServicePhase2";
import { Redirect, Link } from "react-router-dom";

function Signup(props) {
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [facebookData, setfacebookData] = useState();
  const [gmailData, setGmailData] = useState();
  const [gitHubData, setGitHubData] = useState();
  const [LinkedinData, setLinkedinData] = useState();
  const [loading, setLoading] = useState(false);

  const handleSocialLogin = (user) => {
    setfacebookData(user?.["_profile"]);
    console.log(user?.["_profile"]);
    FaceBookSignUp(
      user?.["_profile"].firstName,
      user?.["_profile"].lastName,
      user?.["_profile"].email,
      user?.["_profile"].id
    ).then((result) => {
      console.log("salamamamamamm", result);
      if (result.success == true) {
        props.history.push("/account_info");
      }
    });
  };
  const handleSocialLoginFailure = (err) => {
    console.error("error-------", err);
  };

  const authHandlers = (err, data) => {
    console.log(err, data);
  };

  const handleSocialLogin2 = (user) => {
    setGmailData(user?.["_profile"]);
    GmailSignUp(
      user?.["_profile"].firstName,
      user?.["_profile"].lastName,
      user?.["_profile"].email,
      user?.["_profile"].id
    ).then((result) => {
      if (result.success == true) {
        console.log("pakistananannansan", result);
        props.history.push("/account_info");
      }
    });
  };
  const handleSocialLogin3 = (user) => {
    // setGitHubData(user)
  };
  const handleSocialLoginFailure2 = (err) => {
    console.error(err);
  };
  const handleSocialLoginFailure3 = (err) => {
    console.error(err);
  };
  const OnNameChange = (e) => {
    setFname(e.target.value);
  };
  const OnLastNameChange = (e) => {
    setLname(e.target.value);
  };
  const OnEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const OnPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const OnConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const OnContinueClick = () => {
    setLoading(true);
    SignUp(Fname, Lname, Email, Password, ConfirmPassword).then((result) => {
      console.log("aldkdlkadkadl=-=-", result);
      if (result.success == true) {
        localStorage.setItem("user_id", result.data.id);
        localStorage.setItem("user_email", result.data.email);
        props.history.push("/verification");
        setLoading(false);
      } else {
        alert(result.message);
        setLoading(false);
      }
    });
  };
  const authHandler = (err, data) => {
    console.log(err, data);
  };

  return (
    <div>
      <div style={{ marginTop: 100 }}>
        <div className="row m-0">
          <div className="col-4"></div>
          <div className="col-4" style={{ textAlign: "center" }}>
            <img src={logo} />
            <p
              style={{
                fontSize: 22,
                textAlign: "center",
                fontWeight: 600,
                paddingTop: 20,
              }}
            >
              Welcome to Outdone
            </p>
            <p
              style={{
                fontSize: 14,
                textAlign: "center",
                fontWeight: 600,
                marginTop: -20,
              }}
            >
              Stress less, Celebrate More
            </p>
          </div>
          <div className="col-4"></div>
        </div>
      </div>

      <div className="maindiv1">
        <div className="fieldsdiv">
          <div className="formfield">
            <div className="namefields">
              <input onChange={OnNameChange} type="name" placeholder="First" />
            </div>
            <div className="namefieldsto">
              <input
                onChange={OnLastNameChange}
                type="name"
                placeholder="Last"
              />
            </div>
          </div>
          <div className="remth">
            <div className="fieldsto">
              <input
                onChange={OnEmailChange}
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="fieldsto">
              <input
                onChange={OnPasswordChange}
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="fieldsto">
              <input
                onChange={OnConfirmPassChange}
                type="password"
                placeholder="Confirm Password"
              />
            </div>
          </div>
          <div>
            <Link to="/sign-in">Sign in instead</Link>
          </div>
          {loading ? (
            <div
              className="spinner-border"
              style={{ float: "right" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div>
              <button className="butcol" onClick={OnContinueClick}>
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
