import React, { useState } from "react";
import "./Navbar.css";
import { Link, withRouter } from "react-router-dom";
import actico from "../assets/images/navico.png";
import logo from "../assets/images/HeaderLogo.png";

function navbar(props) {
  const { history } = props;
  function onMoreClick() {
    let el = document.getElementById("more");
    el.classList.toggle("none");
  }
  const onUserPress = () => {
    let token = localStorage.getItem("user_token");
    if (token == null) {
      history.push("/sign-in");
    } else {
      history.push("/dashboard-user");
    }
  };
  const onGetStartedPress = () => {
    history.push("/survey");
  };
  return (
    <div>
      {props.upperBar && (
        <div
          className="upperbar"
          style={{
            backgroundColor: "#CDE2F8",
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <div style={{ flexBasis: "33%" }}></div>
          <div style={{ flexBasis: "33%", textAlign: "center" }}>
            {/* <p style={{ padding: 13, textAlign: "center", fontSize: 16,marginBottom:0 }}>
            As Featured on Built in Boston’s Future of Tech{" "}
            <a
              target="_blank"
              href="https://www.builtinboston.com/2022/01/25/boston-future-5-startups-q1-2022"
              style={{ fontSize: 14,textDecoration:"underline" }}
            >
              Read More
            </a>
            <a href="https://www.producthunt.com/posts/outdone-v2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-outdone&#0045;v2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=369080&theme=light" alt="Outdone&#0032;V2 - AI&#0045;powered&#0032;gift&#0032;recommender | Product Hunt" style={{marginLeft:10}}  width="250" height="54" /></a>
       
          </p> */}
            <a
              href="https://www.producthunt.com/posts/outdone-v2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-outdone&#0045;v2"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=369080&theme=light"
                alt="Outdone&#0032;V2 - AI&#0045;powered&#0032;gift&#0032;recommender | Product Hunt"
                width="250"
                height="54"
              />
            </a>
          </div>
          <div
            style={{ flexBasis: "33%", display: "flex", justifyContent: "end" }}
          ></div>
        </div>
      )}

      <div className="newclass">
        {props.upperBar && (
          <div
            style={{
              backgroundColor: "#CDE2F8",
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              height: "6vh",
            }}
          >
            <div style={{ flexBasis: "33%" }}></div>
            <div
              style={{
                flexBasis: "33%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <p style={{ padding: 13, textAlign: "center", fontSize: 16,marginBottom:0 }}>
            As Featured on Built in Boston’s Future of Tech{" "}
            <a
              target="_blank"
              href="https://www.builtinboston.com/2022/01/25/boston-future-5-startups-q1-2022"
              style={{ fontSize: 14,textDecoration:"underline" }}
            >
              Read More
            </a>
            <a href="https://www.producthunt.com/posts/outdone-v2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-outdone&#0045;v2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=369080&theme=light" alt="Outdone&#0032;V2 - AI&#0045;powered&#0032;gift&#0032;recommender | Product Hunt" style={{marginLeft:10}}  width="250" height="54" /></a>
       
          </p> */}
              <a
                href="https://www.producthunt.com/posts/outdone-v2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-outdone&#0045;v2"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=369080&theme=light"
                  alt="Outdone&#0032;V2 - AI&#0045;powered&#0032;gift&#0032;recommender | Product Hunt"
                  width="150"
                  height="54"
                />
              </a>
            </div>
            <div
              style={{
                flexBasis: "33%",
                display: "flex",
                justifyContent: "end",
              }}
            ></div>
          </div>
        )}
        <div className="mobilenav">
          <div className="mobilelogo">
            <Link to="/">
              <img style={{ height: 25, cursor: "pointer" }} src={logo} />
            </Link>
          </div>

          <div onClick={onUserPress} className="userProfilemobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 58 58"
              fill="none"
            >
              <path
                d="M29.2201 55.974C44.1042 55.974 56.1701 43.908 56.1701 29.024C56.1701 14.1399 44.1042 2.07397 29.2201 2.07397C14.336 2.07397 2.27014 14.1399 2.27014 29.024C2.27014 43.908 14.336 55.974 29.2201 55.974Z"
                fill="#1F294E"
                stroke="#21294B"
                strokeWidth="3"
              />
              <path
                d="M29.2201 38.154C35.008 38.154 39.7001 32.0874 39.7001 24.604C39.7001 17.1205 35.008 11.054 29.2201 11.054C23.4321 11.054 18.7401 17.1205 18.7401 24.604C18.7401 32.0874 23.4321 38.154 29.2201 38.154Z"
                fill="white"
              />
              <path
                d="M29.2201 39.1641C20.4501 39.1641 12.7301 41.8441 8.22009 45.9041C17.5401 57.5041 34.5001 59.3441 46.0901 50.0341C47.6101 48.8141 48.9901 47.4341 50.2201 45.9041C45.7101 41.8441 37.9901 39.1641 29.2201 39.1641Z"
                fill="white"
              />
              <path
                d="M29.2202 29.0239C32.0802 29.0239 34.4001 31.3439 34.4001 34.2039V40.9639C34.4001 43.8239 32.0802 46.1439 29.2202 46.1439C26.3602 46.1439 24.0402 43.8239 24.0402 40.9639V34.2039C24.0402 31.3439 26.3602 29.0239 29.2202 29.0239Z"
                fill="white"
              />
              <path
                d="M20.1801 25.234C20.7901 26.994 20.5501 28.674 19.6401 28.984C18.7301 29.294 17.5001 28.134 16.8901 26.374C16.2801 24.614 16.5201 22.934 17.4301 22.624C18.3401 22.314 19.5701 23.484 20.1801 25.234Z"
                fill="white"
              />
              <path
                d="M41.5664 26.3941C42.1769 24.6358 41.9358 22.9549 41.028 22.6397C40.1201 22.3245 38.8893 23.4944 38.2788 25.2526C37.6683 27.0109 37.9094 28.6918 38.8172 29.007C39.7251 29.3222 40.9559 28.1524 41.5664 26.3941Z"
                fill="white"
              />
              <path
                d="M20.0401 25.1841C18.6801 22.7041 17.2801 20.0741 17.3501 17.2441C17.4201 14.4141 19.5701 11.4341 22.4001 11.5341C22.8901 11.5941 23.3801 11.6141 23.8801 11.5941C24.8201 11.2941 25.6801 10.7741 26.3801 10.0641C28.2201 8.73407 30.6101 8.43407 32.7201 9.26407C33.9901 9.78407 35.0701 10.6941 36.3201 11.2841C38.6901 12.3841 41.4401 12.2741 43.7101 10.9841C43.2401 11.8641 42.4701 12.5441 41.5401 12.9041C42.4001 13.1741 43.3201 13.1441 44.1601 12.8341C43.6501 13.7441 42.8401 14.4541 41.8601 14.8341L44.0101 14.9841C43.3901 15.8541 42.5301 16.5241 41.5301 16.9041L43.2201 16.9641C42.7801 18.2241 41.7401 19.1741 40.4401 19.5041L41.5301 19.5141L37.9701 24.6541C37.6601 25.1641 37.2601 25.6141 36.7801 25.9641C36.3501 26.2141 35.8801 26.3841 35.3901 26.4641C31.8601 27.2541 28.2401 27.4941 24.6401 27.1641C23.4001 27.1441 22.1901 26.7741 21.1501 26.0941C20.1001 25.3641 19.7501 23.9741 20.3201 22.8341"
                fill="white"
              />
              <path
                d="M22.4601 41.7339C22.5401 40.3139 22.7101 38.8939 22.9901 37.4939C23.8101 38.1939 24.8801 38.5339 25.9501 38.4439L30.8001 38.6439C30.9901 39.7439 31.1201 41.0439 30.3201 41.8239C29.9301 42.1639 29.4501 42.4039 28.9401 42.5039C27.8501 42.8039 26.7201 42.9639 25.5901 42.9739C24.2501 42.9839 22.6501 42.5839 22.2201 41.3139L22.6601 40.9539"
                fill="white"
              />
              <path
                d="M35.9801 41.7339C35.9001 40.3139 35.7301 38.8939 35.4501 37.4939C34.6301 38.1939 33.5601 38.5339 32.4901 38.4439L27.6401 38.6439C27.4501 39.7439 27.3201 41.0439 28.1201 41.8239C28.5101 42.1639 28.9901 42.4039 29.5001 42.5039C30.5901 42.8039 31.7201 42.9639 32.8501 42.9739C34.1901 42.9839 35.7901 42.5839 36.2201 41.3139L35.7801 40.9539"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <div className="mobilenav">
      
        <div className="mobilelogo">
          <Link to="/">
            <img style={{ height: 25, cursor: "pointer" }} src={logo} />
          </Link>
        </div>

        <div onClick={onUserPress} className="userProfilemobile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 58 58"
            fill="none"
          >
            <path
              d="M29.2201 55.974C44.1042 55.974 56.1701 43.908 56.1701 29.024C56.1701 14.1399 44.1042 2.07397 29.2201 2.07397C14.336 2.07397 2.27014 14.1399 2.27014 29.024C2.27014 43.908 14.336 55.974 29.2201 55.974Z"
              fill="#1F294E"
              stroke="#21294B"
              strokeWidth="3"
            />
            <path
              d="M29.2201 38.154C35.008 38.154 39.7001 32.0874 39.7001 24.604C39.7001 17.1205 35.008 11.054 29.2201 11.054C23.4321 11.054 18.7401 17.1205 18.7401 24.604C18.7401 32.0874 23.4321 38.154 29.2201 38.154Z"
              fill="white"
            />
            <path
              d="M29.2201 39.1641C20.4501 39.1641 12.7301 41.8441 8.22009 45.9041C17.5401 57.5041 34.5001 59.3441 46.0901 50.0341C47.6101 48.8141 48.9901 47.4341 50.2201 45.9041C45.7101 41.8441 37.9901 39.1641 29.2201 39.1641Z"
              fill="white"
            />
            <path
              d="M29.2202 29.0239C32.0802 29.0239 34.4001 31.3439 34.4001 34.2039V40.9639C34.4001 43.8239 32.0802 46.1439 29.2202 46.1439C26.3602 46.1439 24.0402 43.8239 24.0402 40.9639V34.2039C24.0402 31.3439 26.3602 29.0239 29.2202 29.0239Z"
              fill="white"
            />
            <path
              d="M20.1801 25.234C20.7901 26.994 20.5501 28.674 19.6401 28.984C18.7301 29.294 17.5001 28.134 16.8901 26.374C16.2801 24.614 16.5201 22.934 17.4301 22.624C18.3401 22.314 19.5701 23.484 20.1801 25.234Z"
              fill="white"
            />
            <path
              d="M41.5664 26.3941C42.1769 24.6358 41.9358 22.9549 41.028 22.6397C40.1201 22.3245 38.8893 23.4944 38.2788 25.2526C37.6683 27.0109 37.9094 28.6918 38.8172 29.007C39.7251 29.3222 40.9559 28.1524 41.5664 26.3941Z"
              fill="white"
            />
            <path
              d="M20.0401 25.1841C18.6801 22.7041 17.2801 20.0741 17.3501 17.2441C17.4201 14.4141 19.5701 11.4341 22.4001 11.5341C22.8901 11.5941 23.3801 11.6141 23.8801 11.5941C24.8201 11.2941 25.6801 10.7741 26.3801 10.0641C28.2201 8.73407 30.6101 8.43407 32.7201 9.26407C33.9901 9.78407 35.0701 10.6941 36.3201 11.2841C38.6901 12.3841 41.4401 12.2741 43.7101 10.9841C43.2401 11.8641 42.4701 12.5441 41.5401 12.9041C42.4001 13.1741 43.3201 13.1441 44.1601 12.8341C43.6501 13.7441 42.8401 14.4541 41.8601 14.8341L44.0101 14.9841C43.3901 15.8541 42.5301 16.5241 41.5301 16.9041L43.2201 16.9641C42.7801 18.2241 41.7401 19.1741 40.4401 19.5041L41.5301 19.5141L37.9701 24.6541C37.6601 25.1641 37.2601 25.6141 36.7801 25.9641C36.3501 26.2141 35.8801 26.3841 35.3901 26.4641C31.8601 27.2541 28.2401 27.4941 24.6401 27.1641C23.4001 27.1441 22.1901 26.7741 21.1501 26.0941C20.1001 25.3641 19.7501 23.9741 20.3201 22.8341"
              fill="white"
            />
            <path
              d="M22.4601 41.7339C22.5401 40.3139 22.7101 38.8939 22.9901 37.4939C23.8101 38.1939 24.8801 38.5339 25.9501 38.4439L30.8001 38.6439C30.9901 39.7439 31.1201 41.0439 30.3201 41.8239C29.9301 42.1639 29.4501 42.4039 28.9401 42.5039C27.8501 42.8039 26.7201 42.9639 25.5901 42.9739C24.2501 42.9839 22.6501 42.5839 22.2201 41.3139L22.6601 40.9539"
              fill="white"
            />
            <path
              d="M35.9801 41.7339C35.9001 40.3139 35.7301 38.8939 35.4501 37.4939C34.6301 38.1939 33.5601 38.5339 32.4901 38.4439L27.6401 38.6439C27.4501 39.7439 27.3201 41.0439 28.1201 41.8239C28.5101 42.1639 28.9901 42.4039 29.5001 42.5039C30.5901 42.8039 31.7201 42.9639 32.8501 42.9739C34.1901 42.9839 35.7901 42.5839 36.2201 41.3139L35.7801 40.9539"
              fill="white"
            />
          </svg>
        </div>
      </div> */}

      <div
        className="pt-2 desktopnav"
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 15,
        }}
      >
        <div>
          <a
            onClick={
              props.openmodal
                ? () => props.openmodal("/")
                : () => props.history.push("/")
            }
          >
            <img style={{ height: 45, cursor: "pointer" }} src={logo} />
          </a>
        </div>
        <div className="container1">
          <a
            style={{ fontFamily: "Nunito" }}
            onClick={
              props.openmodal
                ? () => props.openmodal("/")
                : () => props.history.push("/")
            }
            className={`${isActive(history, "/")} 
          ${isActive(history, "/data")} ${isActive(
              history,
              "/survey"
            )} ${isActive(history, "/results")} 
          `}
          >
            Home
            <div
              className={`${isSmallActive(history, "/")} 
          ${isSmallActive(history, "/data")} ${isSmallActive(
                history,
                "/survey"
              )} ${isSmallActive(history, "/results")} 
          `}
              style={{ fontSize: 12, fontFamily: "Nunito" }}
            >
              Try our gift recommender
            </div>
          </a>
          <a
            onClick={
              props.openmodal
                ? () => props.openmodal("/discover")
                : () => props.history.push("/discover")
            }
            style={{ fontFamily: "Nunito" }}
            className={`${isActive(history, "/discover")} ${isActive(
              history,
              "/discoverSearch"
            )}`}
          >
            Discover{" "}
            <div
              style={{ fontSize: 12, fontFamily: "Nunito" }}
              className={`${isSmallActive(
                history,
                "/discover"
              )} ${isSmallActive(history, "/discoverSearch")}`}
            >
              Explore our brand library
            </div>
          </a>
          <Link
            style={{ fontFamily: "Nunito" }}
            onClick={
              props.openmodal
                ? () => props.openmodal("/tips")
                : () => (window.location.href = "https://blog.outdone.io/")
            }
            className={`${isActive(history, "/tips")} `}
          >
            Tips & Spotlights{" "}
            <div
              style={{ fontSize: 12, fontFamily: "Nunito" }}
              className={`${isSmallActive(history, "/tips")} `}
            >
              Get smart on gifting
            </div>
          </Link>
          <Link
            className={`${isActive(history, "/faq")} ${isActive(
              history,
              "/about-us"
            )} ${isActive(history, "/brandSubmission")} `}
          >
            <div className="dropdown">
              <p className="dropbtn">
                More{" "}
                <div
                  style={{ fontSize: 12, fontFamily: "Nunito" }}
                  className={`${isSmallActive(history, "/faq")} ${isSmallActive(
                    history,
                    "/about-us"
                  )} ${isSmallActive(history, "/brandSubmission")} `}
                >
                  Learn About Outdone
                </div>
              </p>
              <div className="dropdown-content">
                <Link
                  onClick={
                    props.openmodal
                      ? () => props.openmodal("/faq")
                      : () => props.history.push("/faq")
                  }
                  style={{
                    display: "flex",
                    fontSize: "14",
                    fontFamily: "Nunito",
                  }}
                >
                  <img
                    className={`${issActive(history, "/faq")} none`}
                    src={actico}
                  />
                  FAQs
                </Link>
                <Link
                  onClick={
                    props.openmodal
                      ? () => props.openmodal("/about-us")
                      : () => props.history.push("/about-us")
                  }
                  style={{ display: "flex", fontFamily: "Nunito" }}
                >
                  <img
                    className={`${issActive(history, "/about-us")} none`}
                    src={actico}
                  />
                  About Us
                </Link>
                <Link
                  onClick={
                    props.openmodal
                      ? () => props.openmodal("/brandSubmission")
                      : () => props.history.push("/brandSubmission")
                  }
                  style={{ display: "flex", fontFamily: "Nunito" }}
                >
                  <img
                    className={`${issActive(history, "/brandSubmission")} none`}
                    src={actico}
                  />
                  Brand Submission
                </Link>
              </div>
            </div>
          </Link>
          <div>
            <button
              onClick={
                props.openmodal
                  ? () => props.openmodal("/survey")
                  : () => props.history.push("/survey")
              }
              style={{
                padding: 10,
                backgroundColor: "#F3A493",
                borderRadius: 16,
                border: "1px solid rgb(243, 164, 147)",
                width: 150,
                color: "#1F294E",
                fontWeight: 700,
                fontFamily: "Bungee",
              }}
            >
              GET STARTED{" "}
            </button>
          </div>
          <div
            onClick={
              props.openmodal
                ? () => props.openmodal("/dashboard-user")
                : () => props.history.push("/dashboard-user")
            }
            className="userProfile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="58"
              height="58"
              viewBox="0 0 58 58"
              fill="none"
            >
              <path
                d="M29.2201 55.974C44.1042 55.974 56.1701 43.908 56.1701 29.024C56.1701 14.1399 44.1042 2.07397 29.2201 2.07397C14.336 2.07397 2.27014 14.1399 2.27014 29.024C2.27014 43.908 14.336 55.974 29.2201 55.974Z"
                fill="#1F294E"
                stroke="#21294B"
                strokeWidth="3"
              />
              <path
                d="M29.2201 38.154C35.008 38.154 39.7001 32.0874 39.7001 24.604C39.7001 17.1205 35.008 11.054 29.2201 11.054C23.4321 11.054 18.7401 17.1205 18.7401 24.604C18.7401 32.0874 23.4321 38.154 29.2201 38.154Z"
                fill="white"
              />
              <path
                d="M29.2201 39.1641C20.4501 39.1641 12.7301 41.8441 8.22009 45.9041C17.5401 57.5041 34.5001 59.3441 46.0901 50.0341C47.6101 48.8141 48.9901 47.4341 50.2201 45.9041C45.7101 41.8441 37.9901 39.1641 29.2201 39.1641Z"
                fill="white"
              />
              <path
                d="M29.2202 29.0239C32.0802 29.0239 34.4001 31.3439 34.4001 34.2039V40.9639C34.4001 43.8239 32.0802 46.1439 29.2202 46.1439C26.3602 46.1439 24.0402 43.8239 24.0402 40.9639V34.2039C24.0402 31.3439 26.3602 29.0239 29.2202 29.0239Z"
                fill="white"
              />
              <path
                d="M20.1801 25.234C20.7901 26.994 20.5501 28.674 19.6401 28.984C18.7301 29.294 17.5001 28.134 16.8901 26.374C16.2801 24.614 16.5201 22.934 17.4301 22.624C18.3401 22.314 19.5701 23.484 20.1801 25.234Z"
                fill="white"
              />
              <path
                d="M41.5664 26.3941C42.1769 24.6358 41.9358 22.9549 41.028 22.6397C40.1201 22.3245 38.8893 23.4944 38.2788 25.2526C37.6683 27.0109 37.9094 28.6918 38.8172 29.007C39.7251 29.3222 40.9559 28.1524 41.5664 26.3941Z"
                fill="white"
              />
              <path
                d="M20.0401 25.1841C18.6801 22.7041 17.2801 20.0741 17.3501 17.2441C17.4201 14.4141 19.5701 11.4341 22.4001 11.5341C22.8901 11.5941 23.3801 11.6141 23.8801 11.5941C24.8201 11.2941 25.6801 10.7741 26.3801 10.0641C28.2201 8.73407 30.6101 8.43407 32.7201 9.26407C33.9901 9.78407 35.0701 10.6941 36.3201 11.2841C38.6901 12.3841 41.4401 12.2741 43.7101 10.9841C43.2401 11.8641 42.4701 12.5441 41.5401 12.9041C42.4001 13.1741 43.3201 13.1441 44.1601 12.8341C43.6501 13.7441 42.8401 14.4541 41.8601 14.8341L44.0101 14.9841C43.3901 15.8541 42.5301 16.5241 41.5301 16.9041L43.2201 16.9641C42.7801 18.2241 41.7401 19.1741 40.4401 19.5041L41.5301 19.5141L37.9701 24.6541C37.6601 25.1641 37.2601 25.6141 36.7801 25.9641C36.3501 26.2141 35.8801 26.3841 35.3901 26.4641C31.8601 27.2541 28.2401 27.4941 24.6401 27.1641C23.4001 27.1441 22.1901 26.7741 21.1501 26.0941C20.1001 25.3641 19.7501 23.9741 20.3201 22.8341"
                fill="white"
              />
              <path
                d="M22.4601 41.7339C22.5401 40.3139 22.7101 38.8939 22.9901 37.4939C23.8101 38.1939 24.8801 38.5339 25.9501 38.4439L30.8001 38.6439C30.9901 39.7439 31.1201 41.0439 30.3201 41.8239C29.9301 42.1639 29.4501 42.4039 28.9401 42.5039C27.8501 42.8039 26.7201 42.9639 25.5901 42.9739C24.2501 42.9839 22.6501 42.5839 22.2201 41.3139L22.6601 40.9539"
                fill="white"
              />
              <path
                d="M35.9801 41.7339C35.9001 40.3139 35.7301 38.8939 35.4501 37.4939C34.6301 38.1939 33.5601 38.5339 32.4901 38.4439L27.6401 38.6439C27.4501 39.7439 27.3201 41.0439 28.1201 41.8239C28.5101 42.1639 28.9901 42.4039 29.5001 42.5039C30.5901 42.8039 31.7201 42.9639 32.8501 42.9739C34.1901 42.9839 35.7901 42.5839 36.2201 41.3139L35.7801 40.9539"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
export const isActive = (history, path) => {
  if (history.location.pathname === path) {
    return "active";
  }
};
export const issActive = (history, path) => {
  if (history.location.pathname === path) {
    return "disblock";
  }
};
export const isSmallActive = (history, path) => {
  if (history.location.pathname === path) {
    return "smallactive";
  }
};
export default withRouter(navbar);
