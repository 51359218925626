import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import SidebarMain from "../Sidebar/sidebar";
import { Link } from "react-router-dom";
import Nike from "../../assets/images/Nike.webp";

import {
  getBrands,
  EditBrand,
  UpdateBrand,
  DeleteBrand,
} from "../Services/Services";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import {
  AuthGuardService,
  GetProductDetail,
} from "../../Services/ServicePhase2";
import ViewProducts from "./ViewProducts";

function ViewBrand(props) {
  const [data, setdata] = useState();
  const [loading, setloading] = useState(true);

  const [bBrandId, setbBrandId] = useState();
  const [bName, setbName] = useState();
  const [bYear, setbYear] = useState();
  const [bHead, setbHead] = useState();
  const [bDes, setbDes] = useState();
  const [bCat1, setbCat1] = useState();
  const [bCat2, setbCat2] = useState();
  const [bCat3, setbCat3] = useState();
  const [bId, setbId] = useState();
  const [bImg, setbImg] = useState();
  const [bChangeImg, setChangebImg] = useState();
  const [renderImg, setrenderImg] = useState();
  const [link, setlink] = useState();

  const [isauth, setisauth] = useState(false);
  const [showProduct, setshowProduct] = useState(false);
  const [ProductData, setProdcutData] = useState([]);
  const [Speciality, setSpeciality] = useState();
  const [Brandmission, setBrandmission] = useState();
  const [LifeImagnery, setLifeImagnery] = useState();
  const [image, setimage] = useState();
  const [gender, setgender] = useState();
  const [productFocus, setproductFocus] = useState();
  const [madeinusa, setmadeinusa] = useState();
  const [femaleled, setfemaleled] = useState();
  const [blackled, setblackled] = useState();
  const [pricecategory, setpricecateogory] = useState();
  const [productcategory, setproductcategory] = useState();
  const [aboutus, setaboutus] = useState();
  const [cdata, setcdata] = useState();
  const [art, setart] = useState(true);

  const onhangeFile = (e) => {
    setChangebImg(e.target.files[0]);
  };

  const openDetails = (e) => {
    e.persist();
    let id_ = e.target.id.split("-")[0];

    GetProductDetail(id_)
      .then((result) => {
        if (result.success == true) {
          setProdcutData(result.data[0]);
          setshowProduct(true);
        } else {
          console.log("NOT GOT IT");
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  useEffect(() => {
    AuthGuardService()
      .then((result) => {
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
        console.log("Error : ", err);
      });
  }, []);

  useEffect(() => {
    getBrands().then((result) => {
      let responseJSON = result;

      setdata(responseJSON.data);
      setcdata(responseJSON.data);
      setloading(false);
    });
  }, []);

  const handlesearch = (e) => {
    setart(false);

    const arr = cdata.filter((obj) => {
      if (obj.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        return obj;
      }
    });

    setdata(arr);
    setart(true);
  };

  function EditBrands(BrandId) {
    console.log(BrandId);
    setbId("");
    setbBrandId("");
    setbName("");
    setbYear("");
    setbHead("");
    setbDes("");
    setbCat1("");
    setbCat2("");
    setbCat3("");
    setbImg("");
    setrenderImg("");
    setlink("");
    setSpeciality("");
    setBrandmission("");
    setLifeImagnery("");
    setimage("");
    setgender("");
    setproductFocus("");
    setmadeinusa("");
    setfemaleled("");
    setblackled("");
    setaboutus("");
    setpricecateogory("");
    setproductcategory("");

    EditBrand(BrandId).then((result) => {
      let responseJSON = result;
      const GetData = responseJSON.data;

      setbId(GetData.id);
      setbBrandId(GetData.brand_id);
      setbName(GetData.name);
      setbYear(GetData.year_founded);
      setbHead(GetData.headquarters);
      setbDes(GetData.description);
      setbCat1(GetData.climate_neutral_certified);
      setbCat2(GetData.certified_b_corporation);
      setbCat3(GetData.for_the_planet);
      setbImg(GetData.image);
      setrenderImg(GetData.image_url);
      setlink(GetData.link);
      setSpeciality(GetData.specialty);
      setBrandmission(GetData.mission);
      setLifeImagnery(GetData.lifestyle_imagery);
      setimage(GetData.image_url);
      setgender(GetData.gender);
      setproductFocus(GetData.product_focus);
      setmadeinusa(GetData.made_in_usa);
      setfemaleled(GetData.female_led);
      setblackled(GetData.black_led);
      setaboutus(GetData.about_us_url);
      setpricecateogory(GetData.price_category);
      setproductcategory(GetData.product_category);
    });
  }

  function DeleteBrands(BrandId) {
    DeleteBrand(BrandId).then((result) => {
      let responseJSON = result;
      if (responseJSON.success == true) {
        Swal.fire(responseJSON.message, "", "success");
        setTimeout(function () {
          location.reload();
        }, 2000);

        // this.props.history.push("/dashboard");
      } else if (responseJSON.success == false) {
        alert(responseJSON.message);
      }
    });
  }

  const uploadFile = async (
    bChangeImg,
    bId,
    bname,
    bBrandId,
    byear,
    bhead,
    bdes,
    bcat1,
    bcat2,
    bcat3,
    link
  ) => {
    var formData = new FormData();
    formData.append("id", bId);
    formData.append("name", bname);
    formData.append("brand_id", bBrandId);
    formData.append("year_founded", byear);
    formData.append("headquarters", bhead);
    formData.append("description", bdes);
    formData.append("climate_neutral_certified", bcat1);
    formData.append("certified_b_corporation", bcat2);
    formData.append("for_the_planet", bcat3);
    formData.append("link", link);
    formData.append("gender", gender);
    formData.append("mission", Brandmission);
    formData.append("specialty", Speciality);
    formData.append("lifestyle_imagery", LifeImagnery);
    formData.append("image", image);
    formData.append("product_focus", productFocus);
    formData.append("made_in_usa", madeinusa);
    formData.append("female_led", femaleled);
    formData.append("black_led", blackled);
    formData.append("product_category", productcategory);
    formData.append("price_category", pricecategory);
    formData.append("about_us_url", aboutus);

    return await axios.post(
      "https://api.outdone.io/api/update_brand",
      formData,
      {
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin_token"),
        },
      }
    );
  };

  const UpdateApiCall = async (e) => {
    e.preventDefault();

    // if (id.length == 0) {
    //   alert("Enter brand id")
    // }

    const res = await uploadFile(
      bChangeImg,
      bId,
      bName,
      bBrandId,
      bYear,
      bHead,
      bDes,
      bCat1,
      bCat2,
      bCat3,
      link
    );
    const dataa = res.data;

    if (dataa.success == true) {
      Swal.fire(dataa.message, "", "success");
      setTimeout(function () {
        location.reload();
      }, 2000);
    } else {
      alert(dataa.message);
    }
  };

  return isauth ? (
    showProduct ? (
      <div>
        {" "}
        <ViewProducts dataProd={ProductData} />{" "}
      </div>
    ) : loading ? (
      <Loader
        className="text-center loader"
        type="Oval"
        color="#323A40"
        height={200}
        width={200}
      />
    ) : (
      <Fragment>
        <section className="dashboard-page">
          <div className="container-fluid p-l ">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0">
                <div className="full-dash">
                  <div className="side-bar-dash">
                    <SidebarMain />
                  </div>
                  <div className="dashboard-content">
                    <div className="row" style={{ justifyContent: "right" }}>
                      <input
                        type="text"
                        className="searchinput form-control"
                        style={{ right: 32 }}
                        placeholder="Search"
                        onChange={(e) => handlesearch(e)}
                      />

                      <div className="col-lg-12 scrool-bar">
                        <table className="table table-image">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Logo</th>
                              <th scope="col">Brand Name</th>
                              <th scope="col">Year </th>
                              <th scope="col">Headquarter</th>

                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, i) => (
                              <tr
                                style={{ cursor: "pointer" }}
                                onClick={(e) => openDetails(e)}
                                key={i}
                              >
                                <td id={`${row.id}-td1`} scope="row">
                                  {row.brand_id}
                                </td>
                                <td id={`${row.id}-td2`}>
                                  <img
                                    id={`${row.id}-td2child1`}
                                    src={row.image_url}
                                    className="img-fluid img-thumbnail img-view"
                                    alt="logo"
                                  />
                                </td>
                                <td id={`${row.id}-td3`}>{row.name}</td>
                                <td id={`${row.id}-td4`}>{row.year_founded}</td>
                                <td id={`${row.id}-td5`}>{row.headquarters}</td>

                                <td className="row">
                                  <button
                                    className="update"
                                    onClick={() => EditBrands(row.id)}
                                  >
                                    Edit
                                  </button>{" "}
                                  <button
                                    onClick={() => DeleteBrands(row.id)}
                                    className="delete"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="form-update">
                  <form
                    className="form-wrapper-update"
                    onSubmit={UpdateApiCall}
                  >
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="form-item">
                          <input
                            value={bName}
                            onChange={(e) => setbName(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="35"
                            id="Brandname"
                          />
                          <label for="Brandname">Brand Name</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={bBrandId}
                            onChange={(e) => setbBrandId(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="35"
                            className="form-control"
                            id="Brandid"
                          />
                          <label for="Brandid">Brand Id</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={bYear}
                            onChange={(e) => setbYear(e.target.value)}
                            type="number"
                            name="number"
                            maxLength="4"
                            className="form-control"
                            id="Brand Year Founded"
                          />
                          <label for="Brand Year Founded">
                            Brand Year Founded
                          </label>
                        </div>

                        <div className="form-item">
                          <input
                            value={bHead}
                            onChange={(e) => setbHead(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="35"
                            className="form-control"
                            id="Brand Headquarters"
                          />
                          <label for="Brand Headquarters">
                            Brand Headquarters
                          </label>
                        </div>
                        <div className="form-item">
                          <textarea
                            value={bDes}
                            onChange={(e) => setbDes(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Brand Description"
                          ></textarea>
                          <label for="Brand Description">
                            Brand Description
                          </label>
                        </div>
                        <div className="form-item">
                          <select
                            value={bCat1}
                            onChange={(e) => setbCat1(e.target.value)}
                            class="custom-select"
                            id="Brand Category Tag #1"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="Brand Category Tag #1">
                            Climate Neutral Certified
                          </label>
                        </div>
                        <div className="form-item">
                          <select
                            value={bCat2}
                            onChange={(e) => setbCat2(e.target.value)}
                            class="custom-select"
                            id="Brand Category Tag #2"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="Brand Category Tag #2">
                            Certified B Corporation
                          </label>
                        </div>
                        <div className="form-item">
                          <select
                            value={bCat3}
                            onChange={(e) => setbCat3(e.target.value)}
                            class="custom-select"
                            id="Brand Category Tag #3"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="Brand Category Tag #3">
                            1% For The Planet
                          </label>
                        </div>

                        <div className="form-item">
                          <input
                            value={link}
                            onChange={(e) => setlink(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="id"
                          />
                          <label for="Brand Link">Brand Link</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={productFocus}
                            onChange={(e) => setproductFocus(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="id"
                          />
                          <label for="Product Focus">Product Focus</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={Speciality}
                            onChange={(e) => setSpeciality(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="Speciality"
                          />
                          <label for="Speciality">Speciality</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={LifeImagnery}
                            onChange={(e) => setLifeImagnery(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="Lifestyle Imaginery"
                          />
                          <label for="Lifestyle Imaginery">
                            Lifestyle Imaginery
                          </label>
                        </div>
                        <div className="form-item">
                          <select
                            value={gender}
                            onChange={(e) => setgender(e.target.value)}
                            class="custom-select"
                            id="gender"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Neutral">Other</option>
                          </select>

                          <label for="gender">Gender</label>
                        </div>
                        <div className="form-item">
                          <select
                            value={madeinusa}
                            onChange={(e) => setmadeinusa(e.target.value)}
                            class="custom-select"
                            id="Made in USA"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="Made in USA">Made in USA</label>
                        </div>
                        <div className="form-item">
                          <select
                            value={femaleled}
                            onChange={(e) => setfemaleled(e.target.value)}
                            class="custom-select"
                            id="Female-Led"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="Female-Led">Female-Led</label>
                        </div>
                        <div className="form-item">
                          <select
                            value={blackled}
                            onChange={(e) => setblackled(e.target.value)}
                            class="custom-select"
                            id="gender"
                          >
                            <option selected disabled>
                              Choose...
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>

                          <label for="gender">Black-Led</label>
                        </div>

                        <div className="form-item">
                          <textarea
                            value={Brandmission}
                            onChange={(e) => setBrandmission(e.target.value)}
                            type="text"
                            name="text"
                            className="form-control"
                            id="Brand Mission"
                          ></textarea>

                          <label for="Brand Mission">Brand Mission</label>
                        </div>
                        <div className="form-item">
                          <input
                            value={image}
                            onChange={(e) => setimage(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="Image URL"
                          />
                          <label for="Image URL">Image URL</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={aboutus}
                            onChange={(e) => setaboutus(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="Image URL"
                          />
                          <label for="Image URL">About us URL</label>
                        </div>

                        <div className="form-item">
                          <input
                            value={productcategory}
                            onChange={(e) => setproductcategory(e.target.value)}
                            type="text"
                            name="text"
                            maxLength="200"
                            className="form-control"
                            id="Image URL"
                          />
                          <label for="Image URL">Product Cateogry</label>
                        </div>
                        <div className="form-item">
                          <select
                            class="custom-select"
                            value={pricecategory}
                            onChange={(e) => setpricecateogory(e.target.value)}
                            id="inputGroupSelect01"
                          >
                            <option selected hidden>
                              Choose...
                            </option>
                            <option value="$50-$150">$50-$150</option>
                            <option value="<50">&lt;$50</option>
                            <option value=">$150">&gt;$150</option>
                          </select>
                          <label for="Image URL">Price Cateogry</label>
                        </div>

                        <button className="btnContact">Update</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  ) : (
    <div></div>
  );
}

export default ViewBrand;
