import ReactDOM from "react-dom";
import React, { useState } from "react";
import outdonegirl from "../assets/images/out-done-girl.png";
import questiongirl from "../assets/images/questiongirl.png";
import faqLeft from "../assets/images/faqLeft.svg";
import faqRight from "../assets/images/faqLeft.svg";
import faqcenter from "../assets/images/Grupo1230.png";
import leftsprial from "../assets/images/aboutSecLeftImg.svg";
import middlesprial from "../assets/images/aboutImgBefore.svg";
import "./Jumbotron.css";
function Jumbotron(props) {
  return (
    <>
      <section>
        <div className="container-fluid  p-0 ">
          <div className="jumbotron-bg">
            <div className="container">
              <div className="row jumbotron-padding">
                <div className="col-lg-3">
                  <img width="80" style={{ float: "right" }} src={faqLeft} />
                </div>
                <div className="col-lg-6">
                  <p
                    className="Frequently-Asked-Questions onDesktop"
                    style={{
                      textAlign: "center",
                      fontSize: 40,
                      fontFamily: "Bungee",
                    }}
                  >
                    FREQUENTLY <br />
                    ASKED QUESTIONS
                  </p>
                  <p className="Frequently-Asked-Questions onMobile">FAQs</p>
                  <p
                    className="questions onDesktop"
                    style={{ textAlign: "center" }}
                  >
                    Have questions about Outdone? <br /> No problem - we've
                    answered a few of the ones we get most often.{" "}
                  </p>
                  <p
                    className="questions onMobile"
                    style={{ textAlign: "center" }}
                  >
                    Have questions about Outdone?
                    <br /> No problem - we've answered a few of the ones we get
                    most often.{" "}
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img width="100" src={faqcenter} />
                  </div>
                </div>
                <div className="col-lg-3">
                  <img width="80" src={faqRight} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="FAQmob">
          <div className="row">
            <div className="col-2">
              <img
                src={faqLeft}
                style={{
                  width: "50px",
                  height: "50px",
                  marginTop: "65px",
                  marginLeft: "20px",
                  transform: "scaleY(-1)",
                }}
              />
            </div>
            <div className="col-8">
              <p
                style={{
                  textAlign: "center",
                  //fontSize: 50,
                  fontFamily: "Bungee",
                  fontSize: "40px",
                  fontWeight: 400,
                  paddingBottom: 0,
                  marginTop: "3px",
                  color: "#1F294E",
                  marginBottom: 0,
                }}
              >
                FAQs
              </p>
              <p className="haveques">
                Have questions about Outdone? No problem - we’ve answered a few
                of the ones we get most often.
              </p>
            </div>
            <div className="col-2">
              <img
                src={faqRight}
                style={{
                  width: "42px",
                  height: "50px",
                  marginTop: "65px",
                  marginLeft: "-20px",
                  transform: "scale(-1)",
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <img
                src={leftsprial}
                style={{
                  padding: 0,
                  width: "42px",
                  height: "50px",
                  marginTop: "-18px",
                  marginLeft: "46px",
                  transform: "rotate(16deg)",
                }}
              />
            </div>
            <div className="col-4">
              <img
                src={middlesprial}
                style={{
                  padding: 0,
                  width: "60px",
                  height: "65px",
                  marginTop: "-18px",
                  marginLeft: "30px",
                }}
              />{" "}
            </div>
            <div className="col-4">
              <img
                src={leftsprial}
                style={{
                  padding: 0,
                  width: "42px",
                  height: "50px",
                  marginTop: "-18px",
                  marginLeft: "20px",
                  transform: "rotate(-16deg)",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Jumbotron;
