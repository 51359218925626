import React from "react";
import "./AboutUs.css";
import Description from "./Description";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import MetaTags from "react-meta-tags";

function AboutUs(props) {
  return (
    <>
      <MetaTags>
        <meta
          id="meta-description"
          name="description"
          content="Tired of stressing while shopping for every holiday, we set out to build the world's best gift recommender. Technology has made our lives easier in so many ways."
        />
      </MetaTags>
      <Navbar />
      {/* <Jumbotron /> */}

      <Description />
      {/* <NewsLetter /> */}
      <Footer />
    </>
  );
}
export default AboutUs;
