import React from "react";

const ProgressBar = (props) => {
  const mystyle = {
    color: "white",
    backgroundColor: "rgba(150, 133, 200, 0.4)",
    padding: "5px",

    fontFamily: "Arial",

    marginRight: "10px",
    width: "8.3%",
  };

  const bluestyle = {
    color: "white",
    backgroundColor: "#9685C8",
    padding: "5px",

    fontFamily: "Arial",

    marginRight: "10px",
    width: "8.3%",
  };
  const firststyle = {
    color: "white",
    backgroundColor: "#9685C8",
    padding: "5px",

    fontFamily: "Arial",
    borderBottomLeftRadius: "25px",
    marginRight: "10px",
    width: "8.3%",
  };

  const laststyle = {
    color: "white",
    backgroundColor: "rgba(150, 133, 200, 0.4)",
    padding: "5px",

    fontFamily: "Arial",
    borderBottomRightRadius: "25px",

    width: "8.3%",
  };
  const laststyle2 = {
    color: "white",
    background: "#9685C8",
    padding: "5px",
    fontFamily: "Arial",
    borderBottomRightRadius: "25px",
    width: "8.3%",
  };
  return (
    <>
      {props.active > -1 ? (
        <div style={firststyle}></div>
      ) : (
        <div style={firststyle}></div>
      )}
      {props.active > 0 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 1 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 2 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 3 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 4 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 5 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 6 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 7 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 8 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 9 ? (
        <div style={bluestyle}></div>
      ) : (
        <div style={mystyle}></div>
      )}
      {props.active > 10 ? (
        <div style={laststyle2}></div>
      ) : (
        <div style={laststyle}></div>
      )}
    </>
  );
};

export default ProgressBar;
