import ReactGA4 from "react-ga4";
import { GA4_TAG_ID } from "./constants";

const pageView = function (url) {
  ReactGA4.initialize([
    {
      trackingId: GA4_TAG_ID,
    },
    // {
    //   trackingId: "your second GA measurement id",
    // },
  ]);

  ReactGA4.send({
    hitType: "pageview",
    page: url /*, title: "Custom Title" */,
  });
};

const conversionEvent = function (category, action, label) {
  console.info("Sending conversion_event");
  ReactGA4.initialize([
    {
      trackingId: GA4_TAG_ID,
    },
    // {
    //   trackingId: "your second GA measurement id",
    // },
  ]);

  ReactGA4.event({
    category: category,
    action: action,
    label: label, // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};

export { pageView, conversionEvent };
