import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Typical from "react-typical";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Back from "../../assets/images/Backside.png";

import { QuestionAnalytics } from "../../Services/ServicePhase2";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateThree = (props) => {
  useEffect(() => {
    pageView("/surveyQ3");
  });

  const { watch, register } = useForm();

  const ageValue = localStorage.getItem("age");

  console.log("props gender is " + props.genrationSelection);

  const btClicked = (e, isRadio = false) => {
    e.persist();
    let title = document.getElementById("how-do-they").textContent;
    let answer = e.target.value;
    console.log(title, answer, isRadio);
    QuestionAnalytics(title, answer);
    // .then((result) => {
    //   if (result.success == true) {
    //     console.log('Question-3 Responce : ' , result)
    //     if(isRadio == false){
    //       console.log('NEXT PAGE')
    //       props.onClick(e)
    //     }
    //     else{
    //       console.log('CHILD CHECK')
    //       props.childCheck(e);
    //     }
    //   }
    //   else { alert(result.message) }
    //   })

    console.log("NEXT PAGE");
    props.onClick(e);
  };

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-182682161-1"
        ></script>
      </Helmet>

      <div className="desktop">
        <h2 id="how-do-they" className="Text-header-main">
          How do they identify?
        </h2>
        <div className="step-1-form middle">
          <div className="myrow">
            <label>
              <input
                type="checkbox"
                name="Male"
                value="1"
                checked={props.genrationSelection == "1"}
                onClick={(e) => btClicked(e)}
              />

              <div className="front-end box myboxes">Male</div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Female"
                value="2"
                onClick={(e) => btClicked(e)}
                checked={props.genrationSelection === "2"}
              />

              <div className="front-end box myboxes">Female</div>
            </label>
            <label>
              <input
                type="checkbox"
                name="Other"
                value="3"
                onClick={(e) => btClicked(e)}
                checked={props.genrationSelection === "3"}
              />

              <div className="front-end box myboxes">Other</div>
            </label>
          </div>

          <div
            style={{
              borderBottom: "3px solid #FCC9BE",
              color: "#1F294E",
              fontFamily: "Nunito",
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
              width: 75,
              paddingBottom: 6,
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={props.onBackClick}
          >
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <a className="backbtn">Back</a>
          </div>
        </div>
      </div>

      {/* mobile version start here */}

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh">
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <h2
                style={{
                  color: "#1F294E",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                How do they identify?
              </h2>
            </div>
          </div>

          <div class="mycol2">
            <label>
              <input
                type="checkbox"
                checked={props.genrationSelection == "1"}
                name="Male"
                value="1"
                onClick={(e) => btClicked(e)}
              />

              <div className="myboxes">Male</div>
            </label>
            <label>
              <input
                type="checkbox"
                checked={props.genrationSelection == "2"}
                name="Female"
                value="2"
                onClick={(e) => btClicked(e)}
              />

              <div className="myboxes">Female</div>
            </label>
            <label>
              <input
                type="checkbox"
                checked={props.genrationSelection == "3"}
                name="other"
                value="3"
                onClick={(e) => btClicked(e)}
              />

              <div className="myboxes">Other</div>
            </label>
          </div>
        </div>

        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateThree;
