import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Beer from "../../assets/images/Beer.png";
import Candy from "../../assets/images/Candy.png";
import Coffee from "../../assets/images/Coffee.png";
import Wine from "../../assets/images/Wine.png";
import Pasta from "../../assets/images/Pasta.png";
import Pizza from "../../assets/images/Pizza.png";
import Tea from "../../assets/images/Tea.png";
import Back from "../../assets/images/Backside.png";

import { useHistory } from "react-router-dom";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateThirteen = (props) => {
  useEffect(() => {
    pageView("/surveyQ8");
  });

  const { watch, register } = useForm();
  const [Items, setItems] = useState([]);

  useEffect(() => {
    console.log("Items Selected : ", Items);
  });
  const btClicked = (e) => {
    e.persist();
    let value = e.target.name;
    console.log("Event Value : ");
    console.log();
    let allItems = [...Items];
    if (allItems.includes(value)) {
      let index = allItems.indexOf(value);
      allItems.splice(index, 1);
    } else {
      allItems.push(value);
    }
    setItems(allItems);
  };

  const toggletotal = (e) => {
    let value = e.target.value;
    var inputs, index;
    let name = e.target.name;
    inputs = document.getElementsByClassName("datacollect");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }
      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  const toggletotal2 = (e) => {
    let value = e.target.value;
    var inputs, index;
    let name = e.target.name;
    inputs = document.getElementsByClassName("datacollect2");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }
      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  function shuffle(id, kid) {
    var container = document.getElementById(id);
    var elementsArray = Array.prototype.slice.call(
      container.getElementsByClassName(kid)
    );
    elementsArray.forEach(function (element) {
      container.removeChild(element);
    });
    shuffleArray(elementsArray);
    elementsArray.forEach(function (element) {
      container.appendChild(element);
    });
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  useEffect(() => {
    shuffle("container", "wrapper-passion");
    shuffle("container2", "wrapper-passion");
  }, []);

  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className="desktop">
        <h2 id="love-food" className="Text-header-main">
          Do they love any of the following foods or drinks?
        </h2>
        <p className="paredesc">Select all that apply, or skip!</p>

        <div className="step-1-form middle" style={{ top: "58%" }}>
          <div className="myrow" id="container">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Beer"
                  value="0"
                  id="Beer"
                  className="datacollect"
                  checked={props.arr[0] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img className="activity-image" src={Beer} />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 10 }} className="th-step-multi">
                      Beer
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Candy"
                  value="1"
                  id="Candy"
                  className="datacollect"
                  checked={props.arr[1] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img
                    style={{ width: 100, marginTop: 20 }}
                    className="activity-image"
                    src={Candy}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 20 }} className="th-step-multi">
                      Candy
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Coffee"
                  value="2"
                  className="datacollect"
                  id="Coffee"
                  checked={props.arr[2] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img
                    style={{ marginTop: 10 }}
                    className="activity-image"
                    src={Coffee}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 9 }} className="th-step-multi">
                      Coffee
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Wine"
                  value="3"
                  id="Wine"
                  className="datacollect"
                  checked={props.arr[3] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img
                    style={{ marginTop: 18, width: 30 }}
                    className="activity-image"
                    src={Wine}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 14 }} className="th-step-multi">
                      Wine
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="myrow" id="container2">
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Pasta"
                  value="4"
                  id="Pasta"
                  className="datacollect"
                  checked={props.arr[4] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img
                    className="activity-image"
                    style={{ width: 82 }}
                    src={Pasta}
                  />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 10 }} className="th-step-multi">
                      Pasta
                    </p>
                  </div>
                </div>
              </label>
            </div>

            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Pizza"
                  value="5"
                  id="Pizza"
                  className="datacollect"
                  checked={props.arr[5] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img className="activity-image" src={Pizza} />
                  <div className="passionate-activity">
                    <p style={{ marginTop: 13 }} className="th-step-multi">
                      Pizza
                    </p>
                  </div>
                </div>
              </label>
            </div>
            <div className="wrapper-passion">
              <label>
                <input
                  type="checkbox"
                  name="Tea"
                  value="6"
                  id="Tea"
                  className="datacollect"
                  checked={props.arr[6] == 1}
                  onChange={(e) => {
                    btClicked(e);
                    toggletotal(e);
                  }}
                />
                <div className="front-end box1 area-icon myboxes">
                  <img
                    style={{ width: 65 }}
                    className="activity-image"
                    src={Tea}
                  />
                  <div className="passsionate-activity">
                    <p style={{ marginTop: 6 }} className="th-step-multi">
                      Tea
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                borderBottom: "3px solid #FCC9BE",
                marginRight: 25,
                color: "#1F294E",
                fontFamily: "Nunito",
                paddingBottom: 6,
                cursor: "pointer",
              }}
              onClick={props.onBackClick}
            >
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <img src={Back} alt="" style={{ paddingBottom: 5 }} />
              <a className="backbtn">Back</a>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: "#478BA2",
                  border: "none",
                  fontFamily: "Bungee",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "10px 20px",
                  color: "#fff",
                  borderRadius: 10,
                }}
                onClick={(e) =>
                  props.onClick({
                    title: document.getElementById("love-food").textContent,
                    listItems: Items,
                  })
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <section className="mobile-survey">
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh" style={{ position: "relative", bottom: 15 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2
                style={{
                  color: "#1F294E",
                  textAlign: "center",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                Do they love any of the following foods or drinks?
              </h2>
            </div>

            <div className="selectall">Select all that apply, or skip!</div>
          </div>

          <div className="mycol">
            <label>
              <input
                type="checkbox"
                name="Beer"
                value="0"
                id="Beer"
                className="datacollect2"
                checked={props.arr[0] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img className="activity-image" src={Beer} />
                <div className="passionate-activity">
                  <p style={{ marginTop: 10 }} className="th-step-multi">
                    Beer
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Candy"
                value="1"
                id="Candy"
                checked={props.arr[1] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img style={{ width: 70, marginBottom: 20 }} src={Candy} />
                <div className="passionate-activity">
                  <p style={{ marginTop: 20 }} className="th-step-multi">
                    Candy
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Pasta"
                value="4"
                id="Pasta"
                checked={props.arr[4] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img
                  className="activity-image"
                  style={{ width: 68 }}
                  src={Pasta}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 10 }} className="th-step-multi">
                    Pasta
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Coffee"
                value="2"
                id="Coffee"
                checked={props.arr[2] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img
                  style={{ marginTop: 10, width: 41 }}
                  className="activity-image"
                  src={Coffee}
                />
                <div className="passionate-activity">
                  <p style={{ marginTop: 9 }} className="th-step-multi">
                    Coffee
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Pizza"
                value="5"
                id="Pizza"
                checked={props.arr[5] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img className="activity-image" src={Pizza} />
                <div className="passionate-activity">
                  <p style={{ marginTop: 13 }} className="th-step-multi">
                    Pizza
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Wine"
                value="3"
                id="Wine"
                checked={props.arr[3] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img style={{ marginBottom: 20, width: 30 }} src={Wine} />
                <div className="passionate-activity">
                  <p style={{ marginTop: 14 }} className="th-step-multi">
                    Wine
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Tea"
                value="6"
                id="Tea"
                checked={props.arr[6] == 1}
                className="datacollect2"
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div className="area-icon myboxes">
                <img
                  style={{ width: 56 }}
                  className="activity-image"
                  src={Tea}
                />
                <div className="passsionate-activity">
                  <p style={{ marginTop: 6 }} className="th-step-multi">
                    Tea
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateThirteen;
