import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
// import React, { Component } from 'react';
// import card from '../../src/assets/2.png';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuickSurvey from "../quickSurvey";
import Navbar from "../Navbar/Navbar";
import survayLeft from "../assets/images/survayLeft.svg";
import survayRight from "../assets/images/survayRight.svg";
import x from "../assets/images/x.svg";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import "./quicksurveyscreen.css";

function QuickSurveyScreen() {
  return (
    <>
      <div id="results" className="search-results">
        {/* <Navbar /> */}

        <div style={{ display: "flex", position: "relative", width: "100%" }}>
          <img
            src={survayLeft}
            style={{
              top: "120px",
              width: "200px",
              zIndex: 9,
              position: "absolute",
            }}
          />
          <div style={{ padding: 50, width: "100%" }}>
            <Link to="/">
              <div className="click-event">
                <i className="far fa-times-circle circle-bar-custom"></i>
              </div>
            </Link>{" "}
            <QuickSurvey />
          </div>
          <img
            src={survayRight}
            style={{
              top: "120px",
              width: "200px",
              zIndex: 9,
              right: 0,
              position: "absolute",
            }}
          />
        </div>
        <div style={{ marginTop: 100 }}>
          <Footer />
        </div>
      </div>

      <div className="mobile-survey-container">
        <Navbar />
        <QuickSurvey />
      </div>
    </>
  );
}

export default QuickSurveyScreen;
