import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import noimage from "../../../assets/images/No-Image-Placeholder.png";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../../../node_modules/swiper/swiper-bundle.css";
import { ProductClicked } from "../../../Dashboard/Services/Services";

const ProductmanuplationMobile = (props) => {
  const [pricerange, setpricerange] = useState();
  var product;
  const [length, setlength] = useState();
  useEffect(() => {
    console.log("calling calling");
    if (localStorage.getItem("Spending Amount") === "under") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price < 50) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    } else if (localStorage.getItem("Spending Amount") === "onefifty") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price >= 50 && obj.price <= 150) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    } else if (localStorage.getItem("Spending Amount") === "over") {
      product = props.brandproduct.filter((obj) => {
        if (obj.price > 150) {
          return obj;
        }
      });
      if (product.length > 3) {
        setlength(3);
      } else {
        setlength(product.length);
      }
    }
  }, []);

  const recordClick = (brand, product) => {
    ProductClicked(brand, product).then((result) => {
      if (result.sucess) {
      } else {
        console.log(result);
      }
    });
  };

  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        {localStorage.getItem("Spending Amount") === "under" && (
          <>
            {props.brandproduct
              .filter((obj) => {
                if (obj.price < 50) {
                  return obj;
                }
              })
              .slice(0, 3)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        {" "}
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price
                                ? `$${obj.price}.00`
                                : obj.product_description}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            {props.brandproduct
              .filter((obj) => {
                if (obj.price > 50) {
                  return obj;
                }
              })
              .slice(0, 3 - length)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price
                                ? `$${obj.price}.00 above 50$ range`
                                : obj.product_description}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </>
        )}

        {localStorage.getItem("Spending Amount") === "onefifty" && (
          <>
            {props.brandproduct
              .filter((obj) => {
                if (obj.price >= 50 && obj.price <= 150) {
                  return obj;
                }
              })
              .slice(0, 3)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price
                                ? `$${obj.price}.00`
                                : obj.product_description}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            {props.brandproduct
              .filter((obj) => {
                if (obj.price < 50 || obj.price > 150) {
                  return obj;
                }
              })
              .slice(0, 3 - length)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price < 50
                                ? `$${obj.price}.00 below the range`
                                : `$${obj.price}.00 above the range`}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </>
        )}

        {localStorage.getItem("Spending Amount") === "over" && (
          <>
            {props.brandproduct
              .filter((obj) => {
                if (obj.price > 150) {
                  return obj;
                }
              })
              .slice(0, 3)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price
                                ? `$${obj.price}.00`
                                : obj.product_description}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            {props.brandproduct
              .filter((obj) => {
                if (obj.price <= 150) {
                  return obj;
                }
              })
              .slice(0, 3 - length)
              .map((obj) => {
                return (
                  <SwiperSlide>
                    <div
                      onClick={() =>
                        recordClick(props.brandname, obj.product_name)
                      }
                    >
                      <a href={obj.primary_url} target="_blank">
                        <img
                          className="imi"
                          src={obj.product_image ? obj.product_image : noimage}
                          width="230"
                          height="255"
                        />
                      </a>

                      <div className="infof">
                        <div>
                          {obj.product_name.length >= 18 ? (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name
                                  ? `${obj.product_name.substring(0, 18)}...`
                                  : null}
                              </h2>
                            </a>
                          ) : (
                            <a href={obj.primary_url} target="_blank">
                              {" "}
                              <h2 className="productname">
                                {obj.product_name ? obj.product_name : null}
                              </h2>
                            </a>
                          )}
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="pricerange">
                              {obj.price
                                ? `$${obj.price}.00 below $150 range`
                                : obj.product_description}
                            </h2>
                          </a>
                        </div>
                        <div>
                          <a
                            href={obj.primary_url}
                            target="_blank"
                            className="buy"
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </>
        )}

        {localStorage.getItem("Spending Amount") === "Matter" && (
          <>
            {props.brandproduct.slice(0, 3).map((obj) => {
              return (
                <SwiperSlide>
                  <div
                    onClick={() =>
                      recordClick(props.brandname, obj.product_name)
                    }
                  >
                    <a href={obj.primary_url} target="_blank">
                      <img
                        className="imi"
                        src={obj.product_image ? obj.product_image : noimage}
                        width="230"
                        height="255"
                      />
                    </a>

                    <div className="infof">
                      <div>
                        {obj.product_name.length >= 18 ? (
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="productname">
                              {obj.product_name
                                ? `${obj.product_name.substring(0, 18)}...`
                                : null}
                            </h2>
                          </a>
                        ) : (
                          <a href={obj.primary_url} target="_blank">
                            {" "}
                            <h2 className="productname">
                              {obj.product_name ? obj.product_name : null}
                            </h2>
                          </a>
                        )}
                        <a href={obj.primary_url} target="_blank">
                          {" "}
                          <h2 className="pricerange">
                            {obj.price
                              ? `$${obj.price}.00`
                              : obj.product_description}
                          </h2>
                        </a>
                      </div>
                      <div>
                        <a
                          href={obj.primary_url}
                          target="_blank"
                          className="buy"
                        >
                          Buy
                        </a>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </>
        )}
      </Swiper>
    </>
  );
};

export default ProductmanuplationMobile;
