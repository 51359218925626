import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import disSearchImgLeft from "../assets/images/disSearchImgLeft.svg";
import disSearchImgRight from "../assets/images/Group 124.png";
import blueDiscover from "../assets/images/blueDiscover.svg";
import yellowDiscover from "../assets/images/yellowDiscover.svg";
import discoverInnerCard from "../assets/images/discoverInnerCard.svg";
import "./Discover.css";
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import { SearchBrand } from "../Services/ServicePhase2";
import { filterDiscover, getfiltercategories } from "../Services/ServicePhase2";
import gif from "../assets/images/Loading.gif";
import searchimg from "../assets/images/searchmobile.svg";
import volume from "../assets/images/volume.svg";
import DiscoverPage3 from "./DiscoverPage3";
import brandTop_right_svg from "../assets/images/brandTop_right_svg.svg";
function Discoverpage2(props) {
  let history = useHistory();

  const [userSearchData, setUserSearcData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [showNext, setshowNext] = useState(false);
  const [error, setError] = useState(false);
  const [dataNext, setdataNext] = useState();
  const [eventCapture, seteventCapture] = useState();
  const [searchBrand, setSearchBrand] = useState(
    props.location.state.detail.name
  );
  const [gender, setgender] = useState();
  const [categories, setCategories] = useState([]);
  var genderone;
  var otypeArraythree = [];
  var otypeArraytwo = [];
  var otypeArrayfour = [];

  const onSearchChange = (e) => {
    setSearchBrand(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    getfiltercategories().then((result) => {
      setLoading(false);
      setCategories(result.data);
    });

    let newData = props.location.state.detail.data;
    setLoading(true);
    setUserSearcData(newData);
    setLoading(false);
  }, []);

  const onBrandClick = (e) => {
    window.scrollTo(0, 0);
    // seteventCapture(e);
    // setshowNext(true);

    history.push({
      pathname: "/discoverpage3",
      state: { id: e },
    });
  };

  const onSubmitPress2 = (e) => {
    setError(false);
    setisloading(true);
    e.preventDefault();
    SearchBrand(searchBrand).then((result) => {
      let res = {
        name: searchBrand,
        data: result.data,
      };

      setUserSearcData([]);

      if (result.success) {
        let newData = res.data;
        setUserSearcData(newData);

        setError(false);

        setisloading(false);

        // history.push({
        //     pathname: '/discoverSearch',
        //     state: { detail: res }
        // });
      } else {
        setUserSearcData([]);
        setError(true);
        setisloading(false);
      }
    });
  };

  const QuestionOne = () => {
    var inputstwo = document.querySelectorAll(".gender");

    for (var i = 0; i < inputstwo.length; i++) {
      if (inputstwo[i].checked === true) {
        genderone = inputstwo[i].value;
      }
    }
  };

  const QuestionTwo = () => {
    var inputstwo = document.querySelectorAll(".categories");

    for (var i = 0; i < inputstwo.length; i++) {
      if (inputstwo[i].checked === true) {
        otypeArraytwo.push(inputstwo[i].value);
      }
    }
  };
  const QuestionThree = () => {
    var inputsthree = document.querySelectorAll(".speciality");

    for (var i = 0; i < inputsthree.length; i++) {
      if (inputsthree[i].checked === true) {
        otypeArraythree.push(inputsthree[i].value);
      }
    }
  };

  const QuestionFour = () => {
    var inputsfour = document.querySelectorAll(".Extras");

    for (var i = 0; i < inputsfour.length; i++) {
      if (inputsfour[i].checked === true) {
        otypeArrayfour.push(inputsfour[i].value);
      }
    }
  };
  const AllQuestions = () => {
    otypeArrayfour = [];
    otypeArraythree = [];
    otypeArraytwo = [];

    QuestionOne();
    QuestionTwo();
    QuestionThree();
    QuestionFour();
  };

  const search = (e) => {
    AllQuestions();

    setisloading(true);

    filterDiscover(
      searchBrand,
      genderone,
      otypeArraytwo.toString(),
      otypeArraythree.join(","),
      otypeArrayfour.join(",")
    ).then((result) => {
      if (result.success == true) {
        setUserSearcData(result.data);
        setisloading(false);
      } else {
        setisloading(false);
      }
    });
  };

  const onlyOne = (checkbox) => {
    var checkboxes = document.getElementsByName("check");
    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });

    search();
  };

  return (
    <>
      {showNext ? (
        <DiscoverPage3 dataPrev={{ bId: eventCapture }} />
      ) : (
        <>
          <div className="desktop">
            <Navbar />
            {loading ? (
              <div
                className="center-loading"
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  zIndex: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#8d8a8aa1",
                }}
              >
                <img className="loading" type="video/mp4" src={gif} />
              </div>
            ) : (
              <>
                <div>
                  <div className="discoverSearch_bg">
                    <div className="row m-0">
                      <div className="col-1"></div>
                      <div className="col-1">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            style={{ width: "14%", float: "right" }}
                            src={blueDiscover}
                          />
                          <img
                            style={{ width: "14%", float: "right" }}
                            src={yellowDiscover}
                          />
                        </div>
                        <img
                          style={{
                            width: "65%",
                            float: "right",
                            paddingTop: 30,
                          }}
                          src={disSearchImgLeft}
                        />
                      </div>
                      <div className="col-8">
                        <form onSubmit={onSubmitPress2}>
                          <div style={{ paddingTop: "55px" }}>
                            <input
                              onChange={onSearchChange}
                              value={searchBrand}
                              placeholder={props.location.state.detail.name}
                              type="text"
                              style={{
                                width: "100%",
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 14,
                                paddingRight: 14,
                                border: "2px solid #FCC9BE",
                              }}
                              required
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-1">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            style={{ width: "14%", float: "right" }}
                            src={yellowDiscover}
                          />
                          <img
                            style={{ width: "14%", float: "right" }}
                            src={blueDiscover}
                          />
                        </div>
                        <img
                          style={{
                            width: "65%",
                            float: "left",
                            paddingTop: 30,
                          }}
                          src={disSearchImgRight}
                        />
                      </div>
                      <div className="col-1"></div>
                    </div>
                  </div>
                  <div className="discoverSearch_sec2">
                    <img
                      width="40"
                      style={{ position: "relative", left: "23%" }}
                      src={brandTop_right_svg}
                    />
                    <img
                      width="40"
                      style={{ position: "relative", left: "60%" }}
                      src={brandTop_right_svg}
                    />
                    <img
                      width="40"
                      style={{ position: "relative", top: "400px", left: 20 }}
                      src={brandTop_right_svg}
                    />
                    <img
                      width="40"
                      style={{
                        position: "relative",
                        top: "400px",
                        left: "85%",
                      }}
                      src={brandTop_right_svg}
                    />

                    <div>
                      <div className="row m-0 pb-5">
                        <div className="col-1"></div>
                        <div className="col-10">
                          <div className="row m-0 p-5">
                            <div
                              style={{
                                padding: 15,
                                backgroundColor: "#fff",
                                borderRadius: 10,
                              }}
                              className="col-3"
                            >
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button open-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#disSecOne"
                                    aria-expanded="true"
                                    aria-controls="disSecOne"
                                    style={{ borderBottom: "none" }}
                                  >
                                    <span className="sideaccording">
                                      Gender
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="disSecOne"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div
                                      style={{ marginLeft: 25, marginTop: 5 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="gender"
                                          name="check"
                                          onClick={(e) => onlyOne(e.target)}
                                          value="Male"
                                          id="b1"
                                        />
                                        <label for="b1">Male</label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="gender"
                                          name="check"
                                          onClick={(e) => onlyOne(e.target)}
                                          id="b2"
                                          value="Female"
                                        />
                                        <label for="b2">Female</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button open-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#disAccessories"
                                    aria-expanded="true"
                                    aria-controls="disAccessories"
                                    style={{ borderBottom: "none" }}
                                  >
                                    <span className="sideaccording">
                                      Categories
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="disAccessories"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div
                                      style={{ marginLeft: 25, marginTop: 10 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Accessories"
                                          id="Accessories"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Accessories">
                                          Accessories
                                        </label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Children"
                                          id="Children"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Children">Children</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Clothing"
                                          id="Clothing"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Clothing">Clothing</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Food/Beverages"
                                          id="Food/Beverages"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Food/Beverages">
                                          Food/Beverages
                                        </label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Games"
                                          id="Games"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Games">Games</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Gear"
                                          id="Gear"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Gear">Gear</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Jewelry"
                                          id="Jewelry"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Jewelry">Jewelry</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Shoes"
                                          id="Shoes"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Shoes">Shoes</label>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Subscriptions"
                                          id="Subscriptions"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Subscriptions">
                                          Subscriptions
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="Travel"
                                          id="Travel"
                                          name="a"
                                          className="categories"
                                          onClick={search}
                                        />
                                        <label for="Travel">Travel</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button open-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#disstyle"
                                    aria-expanded="true"
                                    aria-controls="disstyle"
                                    style={{ borderBottom: "none" }}
                                  >
                                    <span className="sideaccording">
                                      Brand Speciality
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="disstyle"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div
                                      style={{ marginLeft: 25, marginTop: 10 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          onClick={search}
                                          type="checkbox"
                                          value="Beachwear"
                                          id="Beachwear"
                                          name="Beachwear"
                                          className="speciality"
                                        />
                                        <label for="Beachwear">Beachwear</label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          onClick={search}
                                          type="checkbox"
                                          value="Golf"
                                          id="Golf"
                                          name="a"
                                          className="speciality"
                                        />
                                        <label for="Golf">Golf</label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          onClick={search}
                                          type="checkbox"
                                          value="Hiking"
                                          id="Hiking"
                                          name="a"
                                          className="speciality"
                                        />
                                        <label for="Hiking">Hiking</label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          onClick={search}
                                          type="checkbox"
                                          value="Skateboarding"
                                          id="Skateboarding"
                                          name="a"
                                          className="speciality"
                                        />
                                        <label for="Skateboarding">
                                          Skateboarding
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          onClick={search}
                                          type="checkbox"
                                          id="Yoga"
                                          value="Yoga"
                                          name="a"
                                          className="speciality"
                                        />
                                        <label for="Yoga">Yoga</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button open-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#disExtras"
                                    aria-expanded="true"
                                    aria-controls="disExtras"
                                    style={{ borderBottom: "none" }}
                                  >
                                    <span className="sideaccording">
                                      Extras
                                    </span>
                                  </button>
                                </h2>
                                <div
                                  id="disExtras"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div
                                      style={{ marginLeft: 25, marginTop: 10 }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onClick={search}
                                          value="climate_neutral_certified"
                                          id="Climate Neutral Certified"
                                          name="a"
                                          className="Extras"
                                        />
                                        <label for="Climate Neutral Certified">
                                          Climate Neutral Certified
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onClick={search}
                                          value="certified_b_corporation"
                                          id="Certified B Corporation"
                                          name="a"
                                          className="Extras"
                                        />
                                        <label for="Certified B Corporation">
                                          Certified B Corporation
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          onClick={search}
                                          value="for_the_planet"
                                          id="1% For The Planet"
                                          name="a"
                                          className="Extras"
                                        />
                                        <label for="1% For The Planet">
                                          1% For The Planet
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="made_in_usa"
                                          id="Made in USA"
                                          onClick={search}
                                          name="a"
                                          className="Extras"
                                        />
                                        <label for="Made in USA">
                                          Made in USA
                                        </label>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          value="female_led"
                                          id="Female-Led"
                                          onClick={search}
                                          name="a"
                                          className="Extras"
                                        />
                                        <label for="Female-Led">
                                          Female-Led
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <div
                                style={{
                                  height: 952,
                                  paddingTop: 40,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingBottom: 80,
                                  backgroundColor: "#fff",
                                  borderRadius: 10,
                                  overflowY: "scroll",
                                }}
                              >
                                <div className="row">
                                  {}

                                  {isloading ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        class="spinner-border"
                                        style={{
                                          width: 150,
                                          height: 150,
                                          float: "right",
                                          marginLeft: 25,
                                          marginBottom: 12,
                                        }}
                                        role="status"
                                      >
                                        <span class="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  ) : (
                                    userSearchData &&
                                    userSearchData.map((row) => (
                                      <div
                                        className="col-6 mb-3"
                                        onClick={() => onBrandClick(row.id)}
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        <div
                                          className="threedots"
                                          style={{
                                            padding: 10,
                                            border: "3px solid #9685C8",
                                            borderRadius: 28,
                                            height: "250px",
                                            width: "100%",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              columnGap: 50,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "150px",
                                                height: "120px",
                                                borderRadius: 15,
                                              }}
                                              className="pt-1 pl-1"
                                            >
                                              <img
                                                className="brandimage"
                                                src={row.lifestyle_imagery}
                                              />
                                            </div>
                                            <div style={{ paddingTop: "10px" }}>
                                              <span
                                                style={{
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {row.name}
                                              </span>
                                              <br />
                                              <span
                                                style={{
                                                  fontSize: 14,
                                                  fontStyle: "italic",
                                                }}
                                              >
                                                {" "}
                                                {row.headquarters} <br />{" "}
                                                {row.product_category}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="pl-3 pt-3 pr-3 paragraph">
                                            <span
                                              title={row.mission}
                                              className="desc"
                                              style={{
                                                fontSize: 14,
                                                font: "Nunito",

                                                overflow: "hidden",
                                                height: "1.2em",
                                                maxLength: "95",
                                              }}
                                            >
                                              {row.mission &&
                                              row.mission.length > 95
                                                ? row.mission.substring(0, 95) +
                                                  "..."
                                                : row.mission}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}

                                  {error && (
                                    <h3 style={{ textAlign: "center" }}>
                                      Nothing Found
                                    </h3>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )}
          </div>

          {/* Mobile View start here */}

          <div className="mobile">
            <Navbar />
            <div className="d2sectionone">
              <div className="row align-items-center">
                <div className="col-1"></div>
                <div className="col-1 ">
                  <img src={searchimg} />
                </div>

                <div className="col-8 ">
                  {" "}
                  <form onSubmit={(e) => onSubmitPress2(e)}>
                    <div>
                      <input
                        onChange={onSearchChange}
                        value={searchBrand}
                        placeholder="Search for brands, gift topics, activities..."
                        type="text"
                        autoFocus={true}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-1 " style={{ position: "relative" }}></div>
                <div className="col-1"></div>
              </div>

              <div className="imagediv2">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    style={{ width: "14%", float: "right" }}
                    src={yellowDiscover}
                  />
                  <img
                    style={{ width: "14%", float: "right" }}
                    src={blueDiscover}
                  />
                </div>
                <img
                  style={{ width: "65%", float: "left", paddingTop: 15 }}
                  src={disSearchImgRight}
                />
              </div>
            </div>

            <div className="d2sectiontwo">
              <div style={{ backgroundColor: "#FFEDC1", paddingTop: 40 }}>
                {}

                {isloading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      class="spinner-border"
                      style={{
                        width: 150,
                        height: 150,
                        float: "right",
                        marginLeft: 25,
                        marginBottom: 12,
                      }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  userSearchData &&
                  userSearchData.map((row) => (
                    <div
                      className="col-12 mb-3"
                      onClick={() => onBrandClick(row.id)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        className="threedots"
                        style={{
                          padding: 10,
                          backgroundColor: "#fff",
                          border: "3px solid #9685C8",
                          borderRadius: 28,
                          height: "125px",
                          width: "85%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "150px",
                              height: "120px",
                              borderRadius: 15,
                            }}
                            className="pt-1 pl-1"
                          >
                            <img
                              className="brandimage"
                              style={{ opacity: "1" }}
                              src={row.lifestyle_imagery}
                            />
                          </div>
                          <div style={{ margin: "auto auto auto 10px" }}>
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              {row.name}
                            </p>
                            <p
                              style={{
                                fontSize: 10,
                                fontWeight: 500,
                                fontStyle: "italic",
                              }}
                            >
                              {" "}
                              {row.headquarters} <br /> {row.product_category}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}

                {error && (
                  <h3 style={{ textAlign: "center" }}>Nothing Found</h3>
                )}
              </div>
            </div>

            <Footer />
          </div>
        </>
      )}
    </>
  );
}
export default Discoverpage2;
