import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Typical from "react-typical";
import { Link, useHistory } from "react-router-dom";
import Back from "../../assets/images/Backside.png";

import { QuestionAnalytics } from "../../Services/ServicePhase2";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateEight = (props) => {
  const { watch, register } = useForm();

  useEffect(() => {
    pageView("/surveyQ2");
  }, []);

  const btClicked = (e, isselected = false) => {
    e.persist();
    let title = document.getElementById("shopping-for").textContent;
    let answer = e.target.value;

    QuestionAnalytics(title, answer);

    if (isselected == false) {
      console.log("NEXT PAGE");
      props.setstateforshoping(e);
    } else {
      console.log("CHILD CHECK");
    }
  };

  localStorage.setItem("homeenrty", 0);

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });

  return (
    <Fragment>
      <div className=" desktop">
        <h2
          id="shopping-for"
          className="Text-header-main"
          style={{ color: "#1F294E", fontFamily: "Nunito", fontWeight: 700 }}
        >
          Who are you shopping for?
        </h2>
        <div className="step-1-form middle" style={{ top: "58%" }}>
          <div style={{ display: "flex" }}>
            <div>
              <div className="myrow" style={{ marginRight: 10 }}>
                <label>
                  <input
                    type="radio"
                    name="Friend"
                    value="Friend"
                    checked={props.stateforshoping === "Friend"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Friend
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="RomanticPartner"
                    checked={props.stateforshoping === "RomanticPartner"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Romantic Partner
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="Mom"
                    checked={props.stateforshoping === "Mom"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Mom
                  </div>
                </label>
              </div>

              <div className="myrow" style={{ marginRight: 10 }}>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="Sibling"
                    checked={props.stateforshoping === "Sibling"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Sibling
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="Child"
                    checked={props.stateforshoping === "Child"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h2
                        style={{
                          fontSize: "20px",
                          fontWeight: "bolder",
                          fontFamily: "Nunito",
                        }}
                      >
                        Child
                      </h2>{" "}
                      <small style={{ fontSize: 12, fontWeight: 700 }}>
                        {" "}
                        (or Grandchild)
                      </small>
                    </div>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="Dad"
                    checked={props.stateforshoping === "Dad"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className={`front-end box myboxes  style={{fontFamily:"Poppins"}}         }`}
                  >
                    Dad
                  </div>
                </label>
              </div>
            </div>
            <div class="vl"></div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="Myself"
                    checked={props.stateforshoping === "Myself"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Myself
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="male"
                    value="else"
                    checked={props.stateforshoping === "else"}
                    onClick={(e) => btClicked(e)}
                  />
                  <div
                    className="front-end box myboxes"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Somebody Else
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              borderBottom: "3px solid #FCC9BE",
              marginRight: "auto",
              marginTop: 35,
              color: "#1F294E",
              fontFamily: "Nunito",
              marginLeft: "auto",
              display: "block",
              width: 75,
              paddingBottom: 6,
              cursor: "pointer",
            }}
            onClick={props.onBackClick}
          >
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <img src={Back} alt="" style={{ paddingBottom: 5 }} />
            <a className="backbtn">Back</a>
          </div>
        </div>
      </div>

      <section className="mobile-survey">
        <input type="text" autofocus style={{ display: "none" }} />
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>
          <div className="heigh">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2
                style={{
                  color: "#1F294E",
                  whiteSpace: "nowrap",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                Who are you gift shopping for?
              </h2>
            </div>
          </div>
          <div className="mycol">
            <label>
              <input
                type="radio"
                name="Friend"
                value="Friend"
                checked={props.stateforshoping === "Friend"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Friend
              </div>
            </label>

            <label>
              <input
                type="radio"
                name="male"
                value="RomanticPartner"
                checked={props.stateforshoping === "RomanticPartner"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Romantic Partner
              </div>
            </label>

            <label>
              <input
                type="radio"
                name="male"
                value="Sibling"
                checked={props.stateforshoping === "Sibling"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Sibling
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="Child"
                checked={props.stateforshoping === "Child"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: "bolder",
                      fontFamily: "Nunito",
                    }}
                  >
                    Child
                  </h1>{" "}
                  <small
                    style={{
                      fontSize: 10,
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    (or Grandchild)
                  </small>
                </div>
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="Mom"
                checked={props.stateforshoping === "Mom"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Mom
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="Dad"
                checked={props.stateforshoping === "Dad"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Dad
              </div>
            </label>
            <label>
              <input
                type="radio"
                name="male"
                value="Myself"
                checked={props.stateforshoping === "Myself"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Myself
              </div>
            </label>

            <label>
              <input
                type="radio"
                name="male"
                value="else"
                checked={props.stateforshoping === "else"}
                onClick={(e) => btClicked(e)}
              />
              <div className="myboxes" style={{ fontFamily: "Poppins" }}>
                Somebody Else
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateEight;
