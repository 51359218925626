import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import bran_sub_img from "../assets/images/bran_sub_img.svg";
import brand_User_Img from "../assets/images/brand_User_Img.svg";
import aboutCardImg from "../assets/images/aboutCardImg.svg";
import brand_awards from "../assets/images/brand_awards.svg";
import brand_feature from "../assets/images/brand_feature.svg";
import brandSub_sec from "../assets/images/brandSub_sec.svg";
import brandSub_sec2 from "../assets/images/brandSub_sec2.svg";
import brandSub_sec3 from "../assets/images/brandSub_sec3.svg";
import brandSub_sec4 from "../assets/images/brandSub_sec4.svg";
import brandSub_sec5 from "../assets/images/truck.svg";
import brandSub_sec6 from "../assets/images/truck2.png";
import Grupo7605 from "../assets/images/Squiggle.svg";
import brand_left_ico from "../assets/images/brand_left_ico.svg";
import BottomSecImg from "../assets/images/BottomSecImg.svg";
import brandTop_right_svg from "../assets/images/brandTop_right_svg.svg";
import yellowDiscover from "../assets/images/yellowDiscover.svg";
import blueDiscover from "../assets/images/blueDiscover.svg";
import BrandotherSec from "../assets/images/BrandotherSec.svg";
import brand_bottom from "../assets/images/brand_bottom.svg";
import Footer from "../Footer/Footer";
import "./BrandSubmission.css";
import whitewave from "../assets/images/whitewav.png";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.css";

function BrandSubmission() {
  return (
    <>
      <div className="desktop">
        <Navbar />
        <div className="brand_sub_topBanner pt-5">
          <div className="brand_container">
            <div className="d-flex justify-content-center">
              <div>
                <img width="80" src={bran_sub_img} />
              </div>
              <div>
                <p
                  className="pl-5 pr-5"
                  style={{ color: "#fff", fontSize: 18 }}
                >
                  Think your brand is a good fit for Outdone? Let us know!
                </p>
              </div>
              <div>
                <img width="80" src={bran_sub_img} />
              </div>
              <div style={{ right: 0, position: "absolute", paddingRight: 70 }}>
                <img width="30" src={brandTop_right_svg} />
              </div>
            </div>
            <div className="pt-1">
              <h1
                style={{
                  fontSize: 40,
                  color: "#fff",
                  textAlign: "center",
                  fontFamily: "Bungee",
                }}
              >
                WHY BECOME AN OUTDONE PARTNER BRAND?
              </h1>
            </div>
            <div className="pt-3">
              <div className="container">
                <div className="row pt-3" style={{ marginBottom: 80 }}>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 410,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -62, width: 125 }}
                          src={brand_User_Img}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 22,
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          REACH NEW <br /> AUDIENCES
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="80px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 40,
                            color: "#20294D",
                          }}
                        >
                          Becoming an Outdone partner opens your brand to
                          consumers you may not be focusing on, because they
                          don’t fit the mold of your typical target audience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 410,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -62, width: 125 }}
                          src={brand_awards}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 22,
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          WIN OUTDONE <br /> AWARDS
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="80px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 40,
                            color: "#20294D",
                          }}
                        >
                          Every year we compile our ‘Best in Gifts’ awards to
                          crown our favorite brands. Becoming an Outdone partner
                          automatically throws you in for consideration!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 100,
                        height: 410,
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginTop: -52, width: 165 }}
                          src={brand_feature}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            padding: 20,
                            textAlign: "center",
                            paddingBottom: 0,
                            fontSize: 22,
                            color: "#1F294E",
                            fontFamily: "Bungee",
                          }}
                        >
                          GET CONTENT <br /> FEATURES
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img width="80px" src={aboutCardImg} />
                      </div>
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            padding: 20,
                            fontWeight: 500,
                            paddingTop: 0,
                            paddingBottom: 40,
                            color: "#20294D",
                          }}
                        >
                          We’re always looking to showcase our brand partners in
                          new and creative ways — whether that’s through gift
                          guides, social content, or our popular brand spotlight
                          posts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ left: 0, position: "absolute", paddingLeft: 70 }}>
                <img width="30" src={brandTop_right_svg} />
              </div>
            </div>

            <div
              className="d-flex justify-content-center"
              style={{ paddingBottom: 80 }}
            >
              <div
                style={{
                  padding: 10,
                  paddingLeft: 70,
                  paddingRight: 70,
                  borderRadius: 80,
                  color: "#000",
                  backgroundColor: "#92E3A9",
                  fontFamily: "Bungee",
                  marginTop: -35,
                }}
              >
                <a
                  style={{ color: "#1F294E" }}
                  target="_blank"
                  href="https://forms.gle/bf4AjZ6XdweTBX69A"
                >
                  SUBMISSION FORM
                </a>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="bg_last111 mt-5 pt-5">
          <div style={{ position: "absolute", bottom: "600px" }}>
            <img
              width="15"
              style={{ position: "absolute", top: -88 }}
              src={yellowDiscover}
            />
            <img width="60" src={BrandotherSec} />
            <img
              width="15"
              style={{ position: "absolute", top: -40 }}
              src={blueDiscover}
            />
          </div>
          <div style={{ position: "absolute", bottom: "400px", right: 0 }}>
            <img
              width="15"
              style={{ position: "absolute", top: -100, right: 60 }}
              src={yellowDiscover}
            />
            <img width="60" src={BrandotherSec} />
            <img
              width="15"
              style={{ position: "absolute", right: 15, top: -50 }}
              src={blueDiscover}
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <img
                    width="15"
                    style={{
                      position: "absolute",
                      top: "-15px",
                      left: "-45px",
                    }}
                    src={blueDiscover}
                  />
                  <img
                    width="15"
                    style={{ position: "absolute", top: "-55px" }}
                    src={yellowDiscover}
                  />
                </div>
                <h1 className="top_text_brand" style={{ fontSize: 40 }}>
                  WHAT WE LOOK FOR:
                </h1>
                <div>
                  <img src={Grupo7605} />
                  <img
                    width="60"
                    style={{ position: "absolute", right: 40 }}
                    src={brand_left_ico}
                  />
                  <img
                    width="15"
                    style={{ position: "absolute", top: 5, right: 20 }}
                    src={blueDiscover}
                  />
                  <img
                    width="15"
                    style={{ position: "absolute", top: "-45px", right: -45 }}
                    src={yellowDiscover}
                  />
                </div>
                <div className="row">
                  <div className="col-5 brand_sub_logo">
                    <img width="200" src={brandSub_sec} />
                  </div>
                  <div className="col-2 center_num">
                    <h1 className="num">1</h1>
                  </div>
                  <div className="col-5 last_sec_div">
                    <div>
                      <h4 className="text_banner">UP AND COMMING BRANDS</h4>
                      <p
                        className="text_brand_banner"
                        style={{ fontFamily: "Nunito" }}
                      >
                        We hope Outdone shoppers always find something new and
                        exciting when using our site. That’s why we’re always
                        looking for new brands that are bringing a fresh feel to
                        their product categories.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 pt-5">
                  <div className="col-5 last_sec_div">
                    <div style={{ textAlign: "end" }}>
                      <h4 className="text_banner">
                        singularly focused identity
                      </h4>
                      <p
                        className="text_brand_banner"
                        style={{ fontFamily: "Nunito" }}
                      >
                        We want to work with brands that are deliberate about
                        the products they manufacture and the brand identities
                        they’re sharing with the world.
                      </p>
                    </div>
                  </div>
                  <div className="col-2 center_num">
                    <h1 className="num">2</h1>
                  </div>
                  <div className="col-5 brand_sub_logo">
                    <img width="200" src={brandSub_sec2} />
                  </div>
                </div>
                <div className="row pt-5 mt-5">
                  <div className="col-5 brand_sub_logo">
                    <img width="200" src={brandSub_sec3} />
                  </div>
                  <div className="col-2 center_num">
                    <h1 className="num">3</h1>
                  </div>
                  <div className="col-5 last_sec_div">
                    <div>
                      <h4 className="text_banner">
                        High-quality,durable goods
                      </h4>
                      <p
                        className="text_brand_banner"
                        style={{ fontFamily: "Nunito" }}
                      >
                        They say it’s the thought that counts... but let’s not
                        overlook quality. We want to partner with brands selling
                        well-made products that can last a lifetime.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 pt-5">
                  <div className="col-5 last_sec_div">
                    <div style={{ textAlign: "end" }}>
                      <h4 className="text_banner">Unique brand missions</h4>
                      <p
                        className="text_brand_banner"
                        style={{ fontFamily: "Nunito" }}
                      >
                        We’re a mission-driven company and we want partners who
                        are, too. That’s why we look for brands with inspiring
                        stories that are working towards ambitious goals we
                        believe in.
                      </p>
                    </div>
                  </div>
                  <div className="col-2 center_num">
                    <h1 className="num">4</h1>
                  </div>
                  <div className="col-5 brand_sub_logo">
                    <img width="200" src={brandSub_sec4} />
                  </div>
                </div>
                <div className="row pt-5 mt-5">
                  <div className="col-5 brand_sub_logo">
                    <img width="400" src={brandSub_sec5} />
                  </div>
                  <div className="col-2 center_num">
                    <h1 className="num">5</h1>
                  </div>
                  <div className="col-5 last_sec_div">
                    <div>
                      <h4 className="text_banner">
                        Consistent, dependable delivery
                      </h4>
                      <p
                        className="text_brand_banner"
                        style={{ fontFamily: "Nunito" }}
                      >
                        Many of our Outdone visitors are shopping against a firm
                        deadline. So, we need brand partners who can reliably
                        fulfill and ship orders for our time-strapped shoppers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg_last1">
          <div className="row m-0">
            <div className="col-4">
              <div>
                <img
                  width="20"
                  style={{ position: "absolute", left: 170 }}
                  src={brandTop_right_svg}
                />
                <img
                  width="20"
                  style={{ position: "absolute", top: -40, right: 0 }}
                  src={brandTop_right_svg}
                />
                <img
                  width="20"
                  style={{ position: "absolute", right: 0, bottom: 20 }}
                  src={brandTop_right_svg}
                />
              </div>
            </div>
            <div className="col-4">
              <div
                className="d-flex justify-content-center"
                style={{ paddingBottom: 80 }}
              >
                <div
                  style={{
                    padding: 10,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 80,
                    color: "#000",
                    backgroundColor: "#F2D37E",
                  }}
                >
                  <a
                    style={{
                      color: "#1F294E",
                      fontSize: 24,
                      fontFamily: "Bungee",
                    }}
                    target="_blank"
                    href="https://forms.gle/bf4AjZ6XdweTBX69A"
                  >
                    SUBMISSION FORM
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div>
                <img
                  width="20"
                  style={{ position: "absolute", right: 200, top: -60 }}
                  src={brandTop_right_svg}
                />
                <img
                  width="20"
                  style={{ position: "absolute", right: 200, top: 75 }}
                  src={brandTop_right_svg}
                />
                <img width="20" src={brandTop_right_svg} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <button className="subscribe" onClick={() => window.subscribe()}>
          SUBSCRIBE
        </button>
      </div>

      <div className="mobile brand">
        <Navbar />

        <div className="subsectionone">
          <div>
            <div className="slogan">
              Think your brand is a good fit for Outdone?<br></br> Let us know!
            </div>

            <h1>WHY BECOME AN OUTDONE</h1>
            <div
              style={{
                display: "flex",
                alignContent: "baseline",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                width="26"
                style={{ position: "relative", right: 8, bottom: 9 }}
                src={bran_sub_img}
              />{" "}
              <h1>PARTNER BRAND?</h1>{" "}
              <img
                width="26"
                style={{ position: "relative", left: 8, bottom: 9 }}
                src={bran_sub_img}
              />
            </div>

            <Swiper
              pagination={true}
              modules={[Pagination]}
              className="mySwiper2 redefine"
            >
              <SwiperSlide>
                <div className="mycard">
                  <img
                    className="img1"
                    style={{ width: 55 }}
                    src={brand_User_Img}
                  />

                  <div className="audience">Reach New Audences</div>
                  <img className="img2" width="80px" src={aboutCardImg} />
                  <p>
                    Becoming an Outdone partner opens your brand to consumers
                    you may not be focusing on, because they don’t fit the mold
                    of your typical target audience.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="mycard">
                  <img
                    className="img1"
                    style={{ width: 55 }}
                    src={brand_awards}
                  />

                  <div className="audience">WIN OUTDONE AWARDS</div>
                  <img className="img2" width="80px" src={aboutCardImg} />
                  <p>
                    Every year we compile our ‘Best in Gifts’ awards to crown
                    our favorite brands. Becoming an Outdone partner
                    automatically throws you in for consideration!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="mycard">
                  <img
                    className="img1"
                    style={{ width: 55 }}
                    src={brand_feature}
                  />

                  <div className="audience">GET CONTENT FEATURES</div>
                  <img className="img2" width="80px" src={aboutCardImg} />
                  <p>
                    We’re always looking to showcase our brand partners in new
                    and creative ways — whether that’s through gift guides,
                    social content, or our popular brand spotlight posts.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>

            <div style={{ textAlign: "center", borderRadius: 16 }}>
              <button className="submis">
                <a
                  style={{ color: "black" }}
                  target="_blank"
                  href="https://forms.gle/bf4AjZ6XdweTBX69A"
                >
                  SUBMISSION FORM
                </a>
              </button>
            </div>
            <img width="20" className="star1" src={brandTop_right_svg} />
            <img width="20" className="star2" src={brandTop_right_svg} />
            <img width="20" className="star3" src={brandTop_right_svg} />
            <img width="20" className="star4" src={brandTop_right_svg} />
          </div>
        </div>

        <div className="subsectiontwo">
          <div style={{ position: "relative" }}>
            <img
              width="60"
              style={{ position: "absolute", right: 40, width: 25 }}
              src={brand_left_ico}
            />
            <img
              width="15"
              style={{ position: "absolute", top: -5, right: 30, width: 4 }}
              src={blueDiscover}
            />
            <img
              width="15"
              style={{ position: "absolute", top: "-15px", right: 9, width: 6 }}
              src={yellowDiscover}
            />
          </div>
          <div className="whatwe">WHAT WE LOOK FOR:</div>
          <div style={{ textAlign: "center" }}>
            <img className="img2" width="40px" src={aboutCardImg} />
          </div>

          <div className="row align-items-center">
            <div className="col-2 ml-3">
              <img width="55" src={brandSub_sec} />
            </div>
            <div className="col-2">
              <h1 className="num1">1</h1>
            </div>
            <div className="col-7">
              <h2 className="up">Up and coming brands</h2>
              <p className="parete">
                {" "}
                We hope Outdone shoppers always find something new and exciting
                when using our site. That’s why we’re always looking for new
                brands that are bringing a fresh feel to their product
                categories.
              </p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row align-items-center">
            <div className="col-2 ml-3">
              <img width="55" src={brandSub_sec2} />
            </div>
            <div className="col-2">
              <h1 className="num1">2</h1>
            </div>
            <div className="col-7">
              <h2 className="up">singularly focused identity</h2>
              <p className="parete">
                We want to work with brands that are deliberate about the
                products they manufacture and the brand identities they’re
                sharing with the world.
              </p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row align-items-center">
            <div className="col-2 ml-3">
              <img width="55" src={brandSub_sec3} />
            </div>
            <div className="col-2">
              <h1 className="num1">3</h1>
            </div>
            <div className="col-7">
              <h2 className="up">High-quality, durable goods</h2>
              <p className="parete">
                They say it’s the thought that counts... but let’s not overlook
                quality. We want to partner with brands selling well-made
                products that can last a lifetime.
              </p>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row align-items-center">
            <div className="col-2 ml-3">
              <img width="55" src={brandSub_sec4} />
            </div>
            <div className="col-2">
              <h1 className="num1">4</h1>
            </div>
            <div className="col-7">
              <h2 className="up">Unique brand mission</h2>
              <p className="parete">
                We’re a mission-driven company and we want partners who are,
                too. That’s why we look for brands with inspiring stories that
                are working towards ambitious goals we believe in.
              </p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row align-items-center">
            <div className="col-2 ml-3">
              <img width="67" src={brandSub_sec6} />
            </div>
            <div className="col-2">
              <h1 className="num1">5</h1>
            </div>
            <div className="col-7">
              <h2 className="up">dependable Inventory</h2>
              <p className="parete">
                Many of our Outdone visitors are shopping against a firm
                deadline. So, we need brand partners who can reliably fill and
                ship orders for our time-strapped shoppers.
              </p>
            </div>
            <div className="col-1"></div>
          </div>

          <div
            style={{
              position: "relative",
              transform: "scaleX(-1)",
              top: "-18px",
            }}
          >
            <img
              width="60"
              style={{ position: "absolute", right: 40, width: 25 }}
              src={brand_left_ico}
            />
            <img
              width="15"
              style={{ position: "absolute", top: -5, right: 30, width: 4 }}
              src={blueDiscover}
            />
            <img
              width="15"
              style={{ position: "absolute", top: "-15px", right: 9, width: 6 }}
              src={yellowDiscover}
            />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
export default BrandSubmission;
