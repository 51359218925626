import React, { useState, useEffect } from "react";
import {
  OutDoneApi,
  choseFavoriteBrand,
  removeFavoriteBrand,
  Outdonephase2check,
  getFavoriteBrand,
} from "../../../Services/service";
import "./ResultApi.css";
import Footer from "../../../Footer/Footer";
import Navbar from "../../../Navbar/Navbar";
import recoRight from "../../../assets/images/reco_asset.png";
import recoLeft from "../../../assets/images/reco_asset_right.png";
import crown from "../../../assets/images/recoCrwon.svg";
import recoSap from "../../../assets/images/recoSap.svg";
import crow from "../../../assets/images/crownIMAGE.svg";
import { SendzResponce } from "../../../Dashboard/Services/Services";
import recoLike from "../../../assets/images/reco_like.svg";
import recoBottomRight from "../../../assets/images/recoBottomRight.svg";
import recoBottomLeft from "../../../assets/images/recoBottomLeft.svg";
import recoDisLike from "../../../assets/images/recoDisLike.svg";
import recoContainerImg from "../../../assets/images/recoContainerImg.jpg";
import gif from "../../../assets/images/Loading.gif";
import { useHistory } from "react-router-dom";
import thumpsup from "../../../assets/images/thumpsup.png";
import thumpdown from "../../../assets/images/thumpsdown.png";
import thump from "../../../assets/images/thumps.png";
import thump2 from "../../../assets/images/thump2.png";
import noimage from "../../../assets/images/No-Image-Placeholder.png";
import FemaleLed from "../../../assets/images/FemaleLed.png";
import Charitable from "../../../assets/images/Charitable.png";
import Sustainable from "../../../assets/images/Sustainable.png";
import Madeinusa from "../../../assets/images/MadeinUSA.png";
import { Helmet } from "react-helmet";
import Productmanuplation from "./Productmanuplation";
import stars3 from "../../../assets/images/Grupo_706.svg";
import homeicon from "../../../assets/images/Home.svg";
import "../../../../../../node_modules/swiper/swiper-bundle.css";
import { ProductClicked } from "../../../Dashboard/Services/Services";
import { pageView, conversionEvent } from "../../../../ga4Service";

function ApiResultData() {
  useEffect(() => {
    pageView("/recommendation");
    conversionEvent("funnel", "funnel-complete");
  });

  const [data, setData] = useState();
  const [loading, isLoading] = useState(true);
  const [getId, setGetId] = useState();
  const [selectedBrand, setSelectedBrand] = useState(getId);

  const ageValue = localStorage.getItem("age");
  const genderValue = localStorage.getItem("gender");
  const [isLike, setisLike] = useState();
  const [id, isid] = useState();
  const [userfavourite, setuserfavourite] = useState([]);
  const [move, setmove] = useState(0);
  const [anylike, setanylike] = useState(3);
  const [mobilestate, setmobilestate] = useState(1);
  const [pricetag, setpricetag] = useState(
    localStorage.getItem("Spending Amount")
  );
  const history = useHistory();
  useEffect(() => {
    callingApi();
    const token = localStorage.getItem("user_token");

    getFavoriteBrand().then((result) => {
      if (result.success == true) {
        setuserfavourite(result.data);
      } else {
        setanylike(2);
      }
    });
  }, []);

  const recordClick = (brand, product, link) => {
    ProductClicked(brand, product, link).then((result) => {
      if (result.sucess) {
      } else {
        console.log(result);
      }
    });
  };

  const callingApi = () => {
    const age = localStorage.getItem("age");
    const ages = parseInt(age);

    Outdonephase2check().then(async (result) => {
      const getResult = result.all_models_result;

      if ("Kids Brands - Finalized V2 Brands.csv" in result.all_models_result) {
        const res = getResult["Kids Brands - Finalized V2 Brands.csv"];

        await SendzResponce(res).then((responseJson) => {
          if (responseJson.Brand.length == 0) {
            alert("No brand found for Kids");
            history.push("/");
            isLoading(false);
          } else {
            let abc = responseJson.Brand.slice(0, 4)["0"].id;
            setSelectedBrand(responseJson.Brand[0].id);
            setGetId(abc);
            setData(responseJson.Brand);
          }
          isLoading(false);
        });
      } else {
        const res = getResult["rating matrix GCGAT 39.csv"];
        await SendzResponce(res).then((responseJson) => {
          if (responseJson.Brand.length == 0) {
            alert("No brand found");
            history.push("/");
            isLoading(false);
          } else {
            let abc = responseJson.Brand.slice(0, 4)["0"].id;
            setSelectedBrand(responseJson.Brand[0].id);
            setGetId(abc);
            setData(responseJson.Brand.slice(0, 4));
          }
          isLoading(false);
        });
      }

      localStorage.removeItem("age");
    });
  };

  const GetId = (id) => {
    if (selectedBrand != id) {
      setSelectedBrand(id != selectedBrand ? id : null);
      setGetId(id != selectedBrand ? id : null);
    }
  };

  const GetId2 = (id) => {
    setSelectedBrand(id);
    setGetId(id);
    setmobilestate(2);

    history.push("/ApiResultDataMobile2");
  };

  const addlike = (arg) => {
    //TODO: Add conversion event here
    choseFavoriteBrand(arg).then((result) => {
      if (result.success == true) {
        getFavoriteBrand().then((result) => {
          if (result.success == true) {
            setuserfavourite(result.data);
          } else {
            alert(result);
          }
        });
      }
    });
  };

  const addlike2 = (arg) => {
    //TODO: Add conversion event here

    choseFavoriteBrand(arg).then((result) => {});

    const check = userfavourite.filter((obj) => {
      if (obj.brand_id === arg) {
        return obj;
      }
    });

    if (check.length === 0) {
      setuserfavourite([...userfavourite, { brand_id: arg, is_like: 1 }]);
    } else {
      const newarr = userfavourite.map((obj, index) => {
        if (obj.brand_id == arg) {
          if (obj.is_like === 1) {
            return { brand_id: obj.brand_id, is_like: 3 };
          } else {
            return { brand_id: obj.brand_id, is_like: 1 };
          }
        } else {
          return obj;
        }
      });

      setuserfavourite(newarr);
    }
  };

  const applydislike = (arg) => {
    //TODO: Add conversion event here

    removeFavoriteBrand(arg).then((result) => {});

    const check = userfavourite.filter((obj) => {
      if (obj.brand_id === arg) {
        return obj;
      }
    });

    console.log(check.length);

    if (check.length === 0) {
      setuserfavourite([...userfavourite, { brand_id: arg, is_like: 0 }]);
    } else {
      const newarr = userfavourite.map((obj, index) => {
        if (obj.brand_id == arg) {
          if (obj.is_like === 0) {
            return { brand_id: obj.brand_id, is_like: 3 };
          } else {
            return { brand_id: obj.brand_id, is_like: 0 };
          }
        } else {
          return obj;
        }
      });

      setuserfavourite(newarr);
    }

    console.log(userfavourite);
  };

  const removelike2 = (arg) => {
    const arr = userfavourite.filter((obj) => {
      return obj.brand_id != arg;
    });

    setuserfavourite(arr);

    console.log(userfavourite);
  };

  const removelike = (arg) => {
    //TODO: Add conversion event here

    removeFavoriteBrand(arg).then((result) => {
      if (result.success == true) {
        getFavoriteBrand().then((result) => {
          if (result.success == true) {
            setuserfavourite(result.data);
          } else {
            alert(result);
          }
        });
      }
    });
  };

  const islike = (rowid) => {
    const arr = userfavourite;

    console.log("hello");
    console.log(rowid);

    for (var i = 0; i < arr.length; i++) {
      if (arr[i].brand_id == rowid) {
        if (arr[i].is_like == 1) {
          return 1;
        }
        if (arr[i].is_like == 0) {
          return 0;
        }
        if (arr[i].is_like === 2) {
          return 2;
        }
      }
    }

    return 10;
  };

  const shouldBlockNavigation = () => {
    $("#exampleModalCenter").modal("show");
  };

  const stylemodal = {
    position: "absolute",
    width: 439,
    height: 296,

    top: "50%",
    left: "50%",

    transform: "translateX(-50%) translateY(-50%)",
    background: "#FFFFFF",
    border: "2px solid #1F294E",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: 15,
  };

  const stylemodal2 = {
    position: "absolute",
    width: 327,
    height: 304,

    top: "50%",
    left: "50%",

    transform: "translateX(-50%) translateY(-50%)",
    background: "#FFFFFF",
    border: "3px solid #1F294E",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: 15,
  };

  const handlestyle = (length, arg) => {
    if (arg == "farward") {
      console.log(length);
      console.log(move);
      if (move >= -(length - 4) * 350) setmove((prevstate) => prevstate - 350);
    } else {
      if (move != 0) {
        setmove((prevstate) => prevstate + 350);
      }
    }
  };
  const [url, seturl] = useState();
  const openmodal = (url) => {
    seturl(url);
    $("#exampleModalCenter").modal("show");
  };

  const openmodal2 = () => {
    $("#exampleModalCenter2").modal("show");
  };

  const navigate = () => {
    $("#exampleModalCenter").modal("hide");

    if (url.includes("https")) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  const navigate2 = () => {
    $("#exampleModalCenter2").modal("hide");

    history.push("/");
  };

  const dismiss = () => {
    $("#exampleModalCenter").modal("hide");
  };

  const dismiss2 = () => {
    $("#exampleModalCenter2").modal("hide");
  };

  return (
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="desktop">
        <Navbar openmodal={openmodal} seturl={seturl} upperBar={false} />
        {loading ? (
          <div className="center-loading">
            <img className="loading" type="video/mp4" src={gif} />
          </div>
        ) : (
          <div>
            <div className="bg_container">
              <div className="row m-0">
                <div className="col-12 ">
                  <div className="row m-0 mt-5 recomend">
                    <div className="col-2">
                      <img
                        height="80px"
                        style={{ float: "right" }}
                        src={recoRight}
                      />
                    </div>
                    <div className="col-8">
                      <h1 className="recotext">RECOMMENDATIONS ARE IN!</h1>
                    </div>
                    <div className="col-2">
                      <img height="80px" src={recoLeft} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                      <p className="foutext">
                        We've found some gifts your giftgetter is sure to love.
                        <br />
                        Shop each brand with confidence knowing they come with
                        Outdone's seal of approval!
                      </p>
                    </div>
                    <div className="col-2"></div>
                  </div>
                  <div className="brdiv">
                    {data.map((row, i) => (
                      <>
                        {!(ageValue > 28 && row.brand_id == 36) &&
                          !(ageValue > 30 && row.brand_id == 19) &&
                          !(
                            ageValue < 30 &&
                            genderValue == 2 &&
                            row.brand_id == 25
                          ) && (
                            <div
                              onClick={() => GetId(row.id)}
                              className="brand-div"
                              style={{ position: "relative" }}
                            >
                              {i == 0 && (
                                <div className="crown">
                                  <img
                                    className={
                                      selectedBrand === row.id
                                        ? "crown-img active"
                                        : "crown-img"
                                    }
                                    src={crown}
                                  />
                                </div>
                              )}
                              <img
                                className={
                                  row.id == selectedBrand
                                    ? "brimgActive"
                                    : "brimg"
                                }
                                src={row.image_url ? row.image_url : noimage}
                              />
                            </div>
                          )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg2">
              <div className="row m-0">
                <div className="col-1">
                  <img className="item1" src={recoSap} />
                </div>
                <div
                  className="col-10"
                  style={{
                    marginTop: 75,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="bluediv"></div>
                  <div
                    style={{
                      margin: "50px 30px 0px 30px",
                      width: "100%",
                      zIndex: 2,
                      top: 0,
                      backgroundColor: "#fff",
                      padding: 20,
                      borderRadius: 10,
                    }}
                  >
                    {data.map((row, i) => (
                      <>
                        {getId == row.id && (
                          <div style={{ marginTop: 25 }}>
                            <div className="likedislike">
                              <div className="detailtext">
                                <div>
                                  <p className="brandtext">{row.name}</p>
                                  <p className="heat">
                                    {row.headquarters} {row.year_founded}
                                  </p>
                                </div>
                              </div>
                              <div>
                                {anylike === 3 && (
                                  <>
                                    {islike(row.id) === 1 ? (
                                      <div className="likediv">
                                        <p className="raterec mb-2" style={{}}>
                                          Rate this Rec!
                                        </p>
                                        <div>
                                          <img
                                            className="ldico"
                                            src={thumpsup}
                                          />
                                          <img
                                            className="ldico"
                                            src={thump}
                                            onClick={() => removelike(row.id)}
                                          />
                                        </div>
                                      </div>
                                    ) : islike(row.id) === 0 ? (
                                      <div className="likediv">
                                        <p className="raterec mb-2">
                                          Rate this Rec!
                                        </p>
                                        <div>
                                          <img
                                            className="ldico"
                                            onClick={() => addlike(row.id)}
                                            src={thump2}
                                          />
                                          <img
                                            className="ldico"
                                            src={thumpdown}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="islikediv">
                                        <p className="raterec mb-2">
                                          Rate this Rec!
                                        </p>{" "}
                                        <div>
                                          <img
                                            className="ldico"
                                            onClick={() => addlike(row.id)}
                                            src={recoLike}
                                          />
                                          <img
                                            className="ldico"
                                            src={recoDisLike}
                                            onClick={() => removelike(row.id)}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}

                                {anylike === 2 && (
                                  <>
                                    {islike(row.id) === 1 ? (
                                      <div className="likediv">
                                        <p className="raterec mb-2">
                                          Rate this Rec!
                                        </p>
                                        <div>
                                          <img
                                            className="ldico"
                                            onClick={() => addlike2(row.id)}
                                            src={thumpsup}
                                          />
                                          <img
                                            className="ldico"
                                            src={thump}
                                            onClick={() => applydislike(row.id)}
                                          />
                                        </div>
                                      </div>
                                    ) : islike(row.id) === 0 ? (
                                      <div className="likediv">
                                        <p className="raterec mb-2">
                                          Rate this Rec!
                                        </p>
                                        <div>
                                          <img
                                            className="ldico"
                                            onClick={() => addlike2(row.id)}
                                            src={thump2}
                                          />
                                          <img
                                            className="ldico"
                                            src={thumpdown}
                                            onClick={() => applydislike(row.id)}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="islikediv">
                                        <p className="raterec mb-2">
                                          Rate this Rec!
                                        </p>{" "}
                                        <div>
                                          <img
                                            className="ldico"
                                            onClick={() => addlike2(row.id)}
                                            src={recoLike}
                                          />
                                          <img
                                            className="ldico"
                                            src={recoDisLike}
                                            onClick={() => applydislike(row.id)}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="tetdiv">{row.mission}</div>
                            <div
                              style={{
                                display: "flex",
                                height: 20,
                                marginLeft: 30,
                                marginTop: 5,
                              }}
                            >
                              {row.female_led === "Yes" ? (
                                <img
                                  style={{ marginRight: 8 }}
                                  src={FemaleLed}
                                />
                              ) : null}
                              {row.made_in_usa === "Yes" ? (
                                <img
                                  style={{ marginRight: 8 }}
                                  src={Madeinusa}
                                />
                              ) : null}
                              {row.for_the_planet === "Yes" ? (
                                <img
                                  style={{ marginRight: 8 }}
                                  src={Charitable}
                                />
                              ) : null}
                              {row.certified_b_corporation === "Yes" ||
                              row.climate_neutral_certified === "Yes" ? (
                                <img
                                  style={{ marginRight: 8 }}
                                  src={Sustainable}
                                />
                              ) : null}
                            </div>
                            <div className="disfle">
                              {row.brand_products.length ? (
                                <div
                                  class="carousel slide w-100"
                                  data-ride="carousel"
                                  data-type="multi"
                                  data-interval="false"
                                >
                                  <div
                                    class="carousel-inner w-100"
                                    role="listbox"
                                  >
                                    <div
                                      className="carousel-item active"
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        transform: `translateX(${move}px)`,
                                        transition:
                                          "transform 0.5s ease-in-out",
                                      }}
                                    >
                                      <Productmanuplation
                                        brandname={row.name}
                                        brandproduct={row.brand_products}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  <h2>No product to show</h2>
                                </div>
                              )}
                            </div>
                            <div className="imageend">
                              <img
                                className="imageclass"
                                height="100%"
                                width="100%"
                                style={{ borderRadius: "25px" }}
                                src={
                                  row.lifestyle_imagery
                                    ? row.lifestyle_imagery
                                    : recoContainerImg
                                }
                              />
                              <a
                                href={row.link ? row.link : row.about_us_url}
                                onClick={() =>
                                  recordClick(row.name, "Shop more", row.link)
                                }
                                target="_blank"
                                style={{ cursor: "pointer" }}
                                className="brandmore"
                              >
                                SHOP MORE FROM THIS BRAND
                              </a>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="col-1">
                  <img
                    style={{
                      width: 80,
                      position: "absolute",
                      bottom: "-180px",
                      right: -12,
                      transform: "rotate(115deg)",
                    }}
                    src={recoSap}
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="row m-0"
                style={{ paddingTop: "120px", paddingBottom: "120px" }}
              >
                <div className="col-2">
                  <img style={{ float: "right" }} src={recoBottomLeft} />
                </div>
                <div className="col-1"></div>
                <div className="col-6">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ marginRight: 16, marginTop: 15 }}
                      width="60px"
                      src={crow}
                    />
                    <h4
                      style={{
                        color: "#1F294E",
                        fontSize: "32px",
                        fontFamily: "Bungee",
                        lineHeight: "40px",
                      }}
                    >
                      Our ENGINE’S TOP RECOMMENDED <br /> BRAND FOR YOUR
                      GIFTGETTER
                    </h4>
                  </div>
                </div>
                <div className="col-1"></div>
                <div className="col-2">
                  <img src={recoBottomRight} />
                </div>
              </div>
            </div>
            <div>
              <Footer openmodal={openmodal} seturl={seturl} />
            </div>
          </div>
        )}
        <div
          className="modal fade"
          style={stylemodal}
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            style={{ height: 290, margin: 0 }}
            role="document"
          >
            <div
              class="modal-content"
              style={{ textAlign: "center", marginTop: 28 }}
            >
              <p className="para">
                You will not be able to return to these recommendations after
                leaving this page.{" "}
              </p>
              <p className="para">Are you sure you’d like to leave?</p>

              <div className="btnclass">
                <button
                  onClick={() => navigate()}
                  className="btnsize"
                  style={{
                    marginRight: 15,
                    backgroundColor: "rgba(190, 191, 199, 0.58)",
                  }}
                >
                  Yes
                </button>
                <button
                  className="btnsize"
                  onClick={() => dismiss()}
                  style={{
                    marginLeft: 15,
                    backgroundColor: "rgba(172, 244, 193, 0.55)",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      {loading ? (
        <div className="center-loading">
          <img className="loading" type="video/mp4" src={gif} />
        </div>
      ) : (
        <>
          {mobilestate == 1 && (
            <>
              <div className="mobile">
                <div className="reco1sec1">
                  <div style={{ backgroundColor: "#fff", marginBottom: 17 }}>
                    <div className="header1">
                      We’ve found some brands your giftgetter will love!
                    </div>

                    <div style={{ position: "relative", bottom: 10 }}>
                      {" "}
                      <img src={stars3} />
                    </div>

                    <div className="parag">
                      <p style={{ marginBottom: 0 }}>
                        Shop each brand with confidence knowing they come with a
                        seal of approval from Outdone’s Ai (and our humans,
                        too!)
                      </p>
                    </div>
                  </div>
                </div>

                <div className="reco1sec2">
                  <div className="header1">
                    Click a logo to reveal awesome gifts!
                  </div>
                  <div className="brand1">
                    {data.map((row, i) => (
                      <>
                        <div
                          onClick={(e) =>
                            history.push({
                              pathname: "/data2",
                              state: { id: row.id, data: data },
                            })
                          }
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: 15,
                            position: "relative",
                          }}
                        >
                          {i == 0 ? (
                            <>
                              <div className="crown2">
                                <img
                                  className={
                                    selectedBrand === row.id
                                      ? "crown-img active"
                                      : "crown-img"
                                  }
                                  src={crown}
                                />
                              </div>
                              <img
                                className="imgsrc"
                                style={{ border: "2px solid #F8D375" }}
                                src={row.image_url ? row.image_url : noimage}
                              />
                            </>
                          ) : (
                            <img
                              className="imgsrc"
                              src={row.image_url ? row.image_url : noimage}
                            />
                          )}
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="lastrow">
                    <img src={crown} />
                    <p>
                      Our engine’s top recommended brand for your giftgetter
                    </p>
                  </div>
                </div>

                <div className="homenav">
                  <img onClick={openmodal2} src={homeicon} />
                </div>

                <div
                  className="modal fade"
                  style={stylemodal2}
                  id="exampleModalCenter2"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    style={{ height: 290, margin: 0 }}
                    role="document"
                  >
                    <div
                      class="modal-content"
                      style={{ textAlign: "center", marginTop: 28 }}
                    >
                      <p className="para">
                        You will not be able to return to these recommendations
                        after leaving this page.{" "}
                      </p>
                      <p className="para">Are you sure you’d like to leave?</p>

                      <div>
                        <button
                          onClick={() => navigate2()}
                          className="btnsize"
                          style={{
                            marginRight: 15,
                            backgroundColor: "rgba(190, 191, 199, 0.58)",
                          }}
                        >
                          Yes
                        </button>
                        <button
                          className="btnsize"
                          onClick={() => dismiss2()}
                          style={{
                            marginLeft: 15,
                            backgroundColor: "rgba(172, 244, 193, 0.55)",
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
export default ApiResultData;
