import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import top from "../assets/images/Polygon 15.png";
import bottom from "../assets/images/Polygon 16.png";

import leftIco from "../assets/images/left_ico.png";
import rightIco from "../assets/images/right_ico.png";
import { SearchBrandDtails, peopleAlsoLike } from "../Services/ServicePhase2";

import "./Discover.css";
import { useLocation } from "react-router-dom";
import gif from "../assets/images/Loading.gif";
import recob1 from "../assets/images/recob1.png";
import searchimg from "../assets/images/searchmobile.svg";
import { auto } from "@popperjs/core";
import imagegolden from "../assets/images/imagegolden.svg";
import { ProductClicked, SendzResponce } from "../Dashboard/Services/Services";
import { Outdonephase2check } from "../Services/service";
import { doc } from "prettier";

const settings = {
  speed: 300,
  slidesToShow: 7,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 500,
  ltr: true,
  arrows: false,
  centerMode: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2.1,
        slidesToScroll: 2,
        centerPadding: "20px",
      },
    },
  ],
};

function Discoverpage3(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setloading2] = useState(false);
  const location = useLocation();

  const [gender, setgender] = useState();
  const [checked, setchecked] = useState(false);
  const [age, setage] = useState("");
  const [birthday, setbirthday] = useState("");
  const [State, setState] = useState("");
  const [typeArray, settypeArray] = useState([]);
  const [typeArrayTwo, settypeArrayTwo] = useState([]);
  const [typeArrayThree, settypeArrayThree] = useState([]);
  const [typeArrayFour, settypeArrayFour] = useState([]);
  const [typeArrayFive, settypeArrayFive] = useState([]);
  const [typeArraySix, settypeArraySix] = useState([]);
  const [typeArraySeven, settypeArraySeven] = useState([]);
  const [typeArrayEight, settypeArrayEight] = useState([]);
  const [occarray, setoccarray] = useState([1]);
  const [searches, setsearches] = useState([]);
  const [range, setrange] = useState(60);
  var otypeArrayeight = [];
  var otypeArraySeven = [];
  var otypeArraythree = [];
  var otypeArraytwo = [];
  var otypeArrayFour = [];
  var otypeArrayFive = [];
  var otypeArraySix = [];
  var occaions = [];

  const [move, setmove] = useState(0);

  const Stategender = () => {
    var inputs = document.querySelectorAll(".gendercheck");

    var otypeArray = [];
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].checked === true) {
        otypeArray.push(inputs[i].value);
      }
    }
    settypeArray(otypeArray);
  };

  const QuestionTwo = () => {
    var inputstwo = document.querySelectorAll(".QuestTwo");

    for (var i = 0; i < inputstwo.length; i++) {
      if (inputstwo[i].checked === true) {
        otypeArraytwo.push(inputstwo[i].value);
        // console.log(typeArray);
        localStorage.setItem(inputstwo[i].value, 1);
      } else {
        localStorage.setItem(inputstwo[i].value, 0);
      }
    }
    settypeArrayTwo(otypeArraytwo);
  };
  const QuestionThree = () => {
    var inputsthree = document.querySelectorAll(".QuestThree");
    // console.log(inputsthree);

    for (var i = 0; i < inputsthree.length; i++) {
      if (inputsthree[i].checked === true) {
        otypeArraythree.push(inputsthree[i].value);
        // console.log(typeArray);
        localStorage.setItem(inputsthree[i].value, 1);
      } else {
        localStorage.setItem(inputsthree[i].value, 0);
      }
    }
    settypeArrayThree(otypeArraythree);
  };

  const QuestionSix = () => {
    var inputsSix = document.querySelectorAll(".QuestSix");
    // console.log(inputsSix);

    for (var i = 0; i < inputsSix.length; i++) {
      if (inputsSix[i].checked === true) {
        otypeArraySix.push(inputsSix[i].value);
        // console.log(typeArray);
        localStorage.setItem(inputsSix[i].value, 1);
      } else {
        localStorage.setItem(inputsSix[i].value, 0);
      }
    }
    settypeArraySix(otypeArraySix);
  };
  const QuestionSeven = () => {
    var inputsSeven = document.querySelectorAll(".QuestSeven");
    // console.log(inputsSeven);

    for (var i = 0; i < inputsSeven.length; i++) {
      if (inputsSeven[i].checked === true) {
        otypeArraySeven.push(inputsSeven[i].value);
        // console.log(typeArray);
      }
    }
    settypeArraySeven(otypeArraySeven);
  };
  const QuestionEight = () => {
    var inputseight = document.querySelectorAll(".QuestEight");
    // console.log(inputseight);

    for (var i = 0; i < inputseight.length; i++) {
      if (inputseight[i].checked === true) {
        otypeArrayeight.push(inputseight[i].value);
        // console.log(typeArray);
      }
    }
    settypeArrayEight(otypeArrayeight);
  };

  const Occasions = () => {
    var inputname = document.querySelectorAll(".occassionname");
    // console.log(inputseight);
    var inputdate = document.querySelectorAll(".occassiondate");
    for (var i = 0; i < inputname.length; i++) {
      if (inputname[i].value != "") {
        occaions.push({
          name: inputname[i].value,
          date: inputdate[i].value,
        });

        // console.log(typeArray);
      }
    }
    settypeArrayEight(otypeArrayeight);
  };

  const AllQuestions = () => {
    QuestionTwo();
    QuestionThree();
    QuestionSix();
  };

  const OnSaveClick = (obj) => {
    AllQuestions();
    setloading2(true);
    $(".inpt").prop("disabled", true);
    Outdonephase2check().then(async (result) => {
      const getResult = result.all_models_result;

      if ("Kids Brands - Finalized V2 Brands.csv" in result.all_models_result) {
        const res = getResult["Kids Brands - Finalized V2 Brands.csv"];

        await SendzResponce(res).then((responseJson) => {
          if (responseJson.Brand.length == 0) {
            alert("No brand found for Kids");
            history.push("/");
          } else {
            let percentage = 0;

            responseJson.Brand.map((obj, i) => {
              if (obj.name == data[0].name) {
                percentage = 70;
              }
            });

            setrange(percentage);
          }
        });
      } else {
        const res = getResult["rating matrix GCGAT 39.csv"];
        await SendzResponce(res).then((responseJson) => {
          if (responseJson.Brand.length == 0) {
            alert("No brand found");
            history.push("/");
          } else {
            console.log(res.length);
            var j = 0;
            for (const [key, value] of Object.entries(res)) {
              console.log(`${key}: ${value.Brand_Name}`);

              if (value.Brand_Name == data[0].name) {
                let calculate = (1 - value.Distance) * 100;
                j = 1;
                setrange(calculate);
              }
            }

            if (j == 0) {
              setrange(0);
            }
          }
        });
      }

      setloading2(false);
      $(".inpt").prop("disabled", false);
    });
  };

  const check = (url) => {
    window.open(url, "_blank");
  };

  const handlestyle = (length, arg) => {
    if (arg == "farward") {
      console.log(length);
      console.log(move);
      if (move >= -(length - 4) * 370) setmove((prevstate) => prevstate - 370);
    } else {
      if (move != 0) {
        setmove((prevstate) => prevstate + 370);
      }
    }
  };

  const changeage = (e) => {
    var enterage = e.target.valueAsNumber;
    if (enterage <= 109 && enterage > 0) {
      setage(enterage);
      localStorage.setItem("age", enterage);
      OnSaveClick();
    } else if (enterage > 109) {
    } else {
      setage("");
    }
  };

  useEffect(() => {
    setLoading(true);
    let sId;
    // if(location.state!=null)
    // {

    //   sId=location.state.id;
    // }
    // else
    // {
    //   sId = props.dataPrev.bId;
    // }

    sId = props.location.state.id;

    console.log(">>>", sId);
    SearchBrandDtails(sId).then((result) => {
      setData(result.data);

      setLoading(false);
    });

    peopleAlsoLike(sId).then((result) => {
      console.log("Popular Searches : ", result);
      setsearches(result.message);
      setLoading(false);
    });
  }, []);

  const recordClick = (brand, product) => {
    ProductClicked(brand, product).then((result) => {
      if (result.sucess) {
      } else {
        console.log(result);
      }
    });
  };

  const counter = (x) => {
    if (x === "incre") {
      if (age === "") {
        setage(1);
        localStorage.setItem("age", 1);
      } else if (age >= 109) {
      } else {
        let iage = parseInt(age);
        setage(iage + 1);
        localStorage.setItem("age", iage + 1);
      }
    } else if (x === "decre") {
      if (age > 1) {
        setage(age - 1);
        localStorage.setItem("age", age - 1);
      }
    }

    OnSaveClick();
  };

  return (
    <>
      <div className="desktop">
        <Navbar />
        <>
          {loading ? (
            <div
              className="center-loading"
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                zIndex: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#8d8a8aa1",
              }}
            >
              <img className="loading" type="video/mp4" src={gif} />
            </div>
          ) : (
            <div>
              {data &&
                data.map((row) => (
                  <div>
                    <div className="alignflex">
                      <div style={{ width: "15%" }}></div>
                      <div>
                        <p className="vuori" style={{ fontFamily: "Bungee" }}>
                          {row.name}
                        </p>
                        <img
                          className="discoverimage"
                          src={row.lifestyle_imagery}
                          alt=""
                        />
                      </div>
                      <div>
                        <div style={{ height: 310 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="75"
                            height="40"
                            viewBox="0 0 181 40"
                            fill="none"
                          >
                            <path
                              d="M179.491 28.9573L146.504 0.948428L115.737 22.8034L90.4931 1.94326L60.198 24.1911L34.9458 3.3371L1.01224 28.2372L6.53478 37.7421L33.2179 18.1641L58.4701 39.0181L88.7634 16.7843L114.008 37.6444L144.309 15.4045L169.553 36.2646L179.491 28.9573Z"
                              fill="#CDE2F8"
                              stroke="#2D002E"
                              stroke-width="1.5"
                              f
                              stroke-miterlimit="10"
                            />
                          </svg>
                          <p className="bantext">Est: {row.year_founded}</p>
                          <p className="bantext">
                            Headquarters: {row.headquarters}
                          </p>

                          <div
                            className="spaceflex"
                            style={{ marginTop: 30, fontFamily: "Bungee" }}
                          >
                            {row.specialty ? (
                              row.specialty.split(",").map((obj) => {
                                return <p className="backtext">{obj}</p>;
                              })
                            ) : (
                              <>
                                <p className="backtext">Fitness</p>
                                <p className="backtext">Yoga</p>
                                <p className="backtext">Women</p>
                                <p className="backtext">Clothing</p>
                              </>
                            )}
                          </div>

                          <div
                            style={{ display: "flex", width: 500 }}
                            onClick={() => recordClick(row.name, "Shop more")}
                          >
                            <a
                              href={row.link}
                              target="_blank"
                              className="shopnow"
                              style={{
                                fontFamily: "Bungee",
                                cursor: "pointer",
                              }}
                            >
                              Shop Now!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="vouritext" style={{ fontFamily: "Nunito" }}>
                      {row.mission}
                    </p>

                    <div className="backgroundimage">
                      <div
                        style={{
                          backgroundColor: "#fff",
                          marginLeft: "10%",
                          marginRight: "10%",
                          borderRadius: 15,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <section
                          className="pb-5 pt-5"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="container-fluid"
                            style={{ maxWidth: "95%" }}
                          >
                            <div class="container my-3">
                              {row.brand_products.length > 0 ? (
                                <div
                                  class="carousel slide w-100"
                                  data-ride="carousel"
                                  data-type="multi"
                                  data-interval="false"
                                >
                                  <div
                                    class="carousel-inner w-100"
                                    role="listbox"
                                  >
                                    <div
                                      className="carousel-item active"
                                      style={{
                                        transform: `translateX(${move}px)`,
                                        transition:
                                          "transform 0.5s ease-in-out",
                                      }}
                                    >
                                      {row.brand_products.map((obj, index) => {
                                        return (
                                          <div
                                            onClick={() =>
                                              recordClick(
                                                row.name,
                                                obj.product_name
                                              )
                                            }
                                            className="imagediv"
                                            style={{ marginRight: "20px" }}
                                          >
                                            <a
                                              href={
                                                obj.primary_url
                                                  ? obj.primary_url
                                                  : obj.backup_url
                                              }
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                className="longimage"
                                                src={
                                                  obj.product_image
                                                    ? obj.product_image
                                                    : recob1
                                                }
                                              />
                                            </a>
                                            <div className="desc2">
                                              <p
                                                className="proname2 pl-3"
                                                title={obj.product_name}
                                              >
                                                {obj.product_name.length > 25
                                                  ? obj.product_name.substring(
                                                      0,
                                                      25
                                                    ) + "..."
                                                  : obj.product_name}
                                              </p>
                                              <p
                                                className="pl-3"
                                                style={{
                                                  fontSize: 16,
                                                  fontWeight: 800,
                                                }}
                                              >
                                                ${obj.price}.00
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: -40,
                                      marginBottom: 80,
                                    }}
                                    class="carousel-control-prev w-auto"
                                    href="#recipeCarousel"
                                    role="button"
                                    data-slide="prev"
                                  >
                                    <img
                                      src={leftIco}
                                      style={{ height: 25 }}
                                      onClick={() =>
                                        handlestyle(row.brand_products.length)
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      marginRight: -40,
                                      marginBottom: 80,
                                    }}
                                    class="carousel-control-next w-auto"
                                    href="#recipeCarousel"
                                    role="button"
                                    data-slide="next"
                                  >
                                    <img
                                      src={rightIco}
                                      style={{ height: 25 }}
                                      onClick={() =>
                                        handlestyle(
                                          row.brand_products.length,
                                          "farward"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  <h2>No product to show</h2>
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                      <div>
                        <div className="secondpart">
                          <p
                            className="recomm"
                            style={{ fontFamily: "Bungee" }}
                          >
                            Recommendation Widget
                          </p>
                          <p className="thinkvouri">
                            Think {row.name} would be perfect for someone you’re
                            shopping for? Let’s find out! <br />
                            Enter your giftgetter’s details below and we’ll tell
                            you how well suited {row.name} is for them!
                          </p>
                          <div
                            style={{
                              display: "flex",
                              marginTop: 25,
                              justifyContent: "space-around",
                            }}
                          >
                            <div style={{ marginLeft: 20 }}>
                              <p className="disccheckhead">Age </p>
                              <div
                                className="ageinput"
                                style={{
                                  borderColor: "#FCC9BE",
                                  position: "relative",
                                  height: 68,
                                  borderRadius: 25,
                                }}
                              >
                                <img
                                  src={top}
                                  alt=""
                                  className="inpt"
                                  onClick={() => counter("incre")}
                                  style={{ position: "absolute", top: 15 }}
                                />
                                <img
                                  src={bottom}
                                  className="inpt"
                                  alt=""
                                  onClick={() => counter("decre")}
                                  style={{ position: "absolute", bottom: 15 }}
                                />
                                <input
                                  type="number"
                                  className="inpt"
                                  value={age}
                                  onChange={(e) => changeage(e)}
                                  style={{
                                    border: "none",
                                    width: "60px",
                                    textAlign: "center",
                                    top: 5,
                                    fontSize: 32,
                                    color: "rgb(71, 139, 162)",
                                    fontWeight: 700,
                                    position: "absolute",
                                    right: 20,
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ marginLeft: 20, width: 151 }}>
                              <p className="disccheckhead">Gender</p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={gender === "1"}
                                  value="1"
                                  onChange={(e) => {
                                    setgender(e.target.value), OnSaveClick();
                                  }}
                                  className="genderCheck"
                                  id="a1"
                                  name="a"
                                />
                                <label for="a1">Male</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={gender === "2"}
                                  value="2"
                                  onChange={(e) => {
                                    setgender(e.target.value), OnSaveClick();
                                  }}
                                  className="genderCheck"
                                  id="a2"
                                  name="a"
                                />
                                <label for="a2">Female</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={gender === "3"}
                                  value="3"
                                  onChange={(e) => {
                                    setgender(e.target.value), OnSaveClick();
                                  }}
                                  className="genderCheck"
                                  id="a3"
                                  name="a"
                                />
                                <label for="a3">Other</label>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{ width: 210 }}
                                className="disccheckhead"
                              >
                                What do they like to do at home?
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  className="QuestTwo"
                                  onClick={OnSaveClick}
                                  value="Cook"
                                  type="checkbox"
                                  id="g1"
                                  name="a"
                                />
                                <label for="g1">Cook</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  className="QuestTwo"
                                  onClick={OnSaveClick}
                                  value="Alcohol"
                                  type="checkbox"
                                  id="g2"
                                  name="a"
                                />
                                <label for="g2">Drink Alcohol</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  className="QuestTwo"
                                  onClick={OnSaveClick}
                                  value="Coffee"
                                  type="checkbox"
                                  id="g3"
                                  name="a"
                                />
                                <label for="g3">Make Coffee</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  className="QuestTwo"
                                  onClick={OnSaveClick}
                                  value="Read"
                                  type="checkbox"
                                  id="g5"
                                  name="a"
                                />
                                <label for="g5">Read</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  className="QuestTwo"
                                  onClick={OnSaveClick}
                                  value="Puzzlegame"
                                  type="checkbox"
                                  id="g4"
                                  name="a"
                                />
                                <label for="g4">Puzzles/Games</label>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{ width: 245 }}
                                className="disccheckhead"
                              >
                                Do they enjoy any of these physical activities?
                              </p>
                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 20 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Biking"
                                      type="checkbox"
                                      id="t1"
                                      name="a"
                                    />
                                    <label for="t1">Biking</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Yoga"
                                      type="checkbox"
                                      id="t2"
                                      name="a"
                                    />
                                    <label for="t2">Yoga</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Golfing"
                                      type="checkbox"
                                      id="t3"
                                      name="a"
                                    />
                                    <label for="t3">Golfing</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Lifting"
                                      type="checkbox"
                                      id="t4"
                                      name="a"
                                    />
                                    <label for="t4">Lifting</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Running"
                                      type="checkbox"
                                      id="t5"
                                      name="a"
                                    />
                                    <label for="t5">Running</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Camping"
                                      type="checkbox"
                                      id="t6"
                                      name="a"
                                    />
                                    <label for="t6">Camping</label>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Hunting"
                                      type="checkbox"
                                      id="k1"
                                      name="a"
                                    />
                                    <label for="k1">Hunting</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Fishing"
                                      type="checkbox"
                                      id="k2"
                                      name="a"
                                    />
                                    <label for="k2">Fishing</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Swimming"
                                      type="checkbox"
                                      id="k3"
                                      name="a"
                                    />
                                    <label for="k3">Swimming</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Skiing"
                                      type="checkbox"
                                      id="k4"
                                      name="a"
                                    />
                                    <label for="k4">Skiing</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="snowboard"
                                      type="checkbox"
                                      id="k5"
                                      name="a"
                                    />
                                    <label for="k5">Snowboarding</label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      className="QuestThree"
                                      onClick={OnSaveClick}
                                      value="Hiking"
                                      type="checkbox"
                                      id="k6"
                                      name="a"
                                    />
                                    <label for="k6">Hiking</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{ width: 260 }}
                                className="disccheckhead"
                              >
                                Do they regularly go to any of the following
                                places?
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value="Beach"
                                  onClick={OnSaveClick}
                                  className="QuestSix"
                                  id="l1"
                                  name="a"
                                />
                                <label for="l1">The Beach</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value="Bars"
                                  onClick={OnSaveClick}
                                  className="QuestSix"
                                  id="l2"
                                  name="a"
                                />
                                <label for="l2">Bars & Clubs</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value="Concerts"
                                  onClick={OnSaveClick}
                                  className="QuestSix"
                                  id="l3"
                                  name="a"
                                />
                                <label for="l3">Concerts</label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "baseline",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value="Airport"
                                  onClick={OnSaveClick}
                                  className="QuestSix"
                                  id="l4"
                                  name="a"
                                />
                                <label for="l4">The Airport</label>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: 60,
                              marginRight: 60,
                              marginTop: "3%",
                              marginBottom: "3%",
                            }}
                          >
                            <p className="brandthey">
                              {" "}
                              {row.name} Is A Brand They’ll...
                              {loading2 && (
                                <div
                                  className="spinner-border"
                                  style={{ float: "right" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                            </p>

                            <input
                              class="range"
                              type="range"
                              min="0"
                              value={range}
                              max="100"
                            />
                          </div>
                          <button
                            className="shopnoww"
                            onClick={() => check(row.link)}
                            style={{
                              fontFamily: "Bungee",
                              marginTop: 20,
                              display: "block",
                              marginLeft: auto,
                              marginRight: auto,
                              marginBottom: "3%",
                            }}
                          >
                            Shop Now!
                          </button>
                        </div>
                      </div>
                      <div className="thirdpart">
                        <p
                          className="peoplev"
                          style={{
                            fontFamily: "Bungee",
                            marginTop: "5%",
                            paddingTop: "3%",
                          }}
                        >
                          People Who Like {row.name} Also Like...
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: 20,
                            paddingTop: 30,
                          }}
                        >
                          {searches.map((obj) => {
                            return (
                              <div style={{ textAlign: "center" }}>
                                <div className="boxshad">
                                  <img
                                    className="shadimage"
                                    style={{ marginTop: 12 }}
                                    src={obj.image_url}
                                    alt=""
                                  />
                                </div>
                                <a
                                  href={obj.link}
                                  style={{
                                    marginTop: 20,
                                    marginBottom: 35,
                                    fontSize: 12,
                                    color: "#1F294E",
                                    borderBottom: "1px solid #F3A493",
                                  }}
                                >
                                  {obj.name}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <Footer />
            </div>
          )}
        </>
      </div>

      {/* mobile view start here */}

      <div className="mobile">
        <Navbar />

        <div className="d3sectionone">
          <div className="" style={{ backgroundColor: "#fff" }}>
            <div className="row align-items-center">
              {/* <div className='col-1'></div>
                        <div className='col-1 '><img src={searchimg} /></div>
                        
                        <div className='col-8 '> <form ><input  placeholder='' /></form>
                        
                         
                        
                        
                        </div>
                        <div className='col-1 '></div>
                        <div className='col-1'></div> */}
            </div>

            <div className="row" style={{ marginTop: 35 }}>
              <div className="col-1"></div>
              <div className="col-3">
                <p className="vuori2" style={{ whiteSpace: "nowrap" }}>
                  {data && data.map((row) => <span>{row.name}</span>)}
                </p>
                <img
                  className="imagegolden"
                  style={{
                    paddingBottom: "10px",
                    position: "absolute",
                    top: 15,
                  }}
                  src={imagegolden}
                />
              </div>
              <div className="col-8"></div>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ padding: "12px 0px" }}
          >
            <div className="col-1"></div>
            <div className="col-3">
              {data &&
                data.map((row) => (
                  <img className="discoverimage" src={row.image_url} alt="" />
                ))}
            </div>
            <div className="col-7">
              {data &&
                data.map((row) => (
                  <>
                    <p className="bantext">Est: {row.year_founded}</p>
                    <p className="bantext">Headquarters: {row.headquarters}</p>
                  </>
                ))}
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              {data &&
                data.map((row) => (
                  <>
                    <p className="bantext">{row.mission}</p>
                  </>
                ))}
            </div>
            <div className="col-1"></div>
          </div>
        </div>

        <div className="d3sectiontwo">
          {data &&
            data.map((row) => (
              <>
                <div className="mycard">
                  {row.brand_products.length > 0 ? (
                    <>
                      <div className="myheader">
                        Our Favourte {row.name} Items{" "}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginBottom: 20,
                          width: "100%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {row.brand_products.slice(0, 3).map((obj, index) => {
                          return (
                            <div>
                              <a href={obj.primary_url}>
                                <img
                                  className="longimage"
                                  src={
                                    obj.product_image
                                      ? obj.product_image
                                      : recob1
                                  }
                                />
                              </a>
                              <a href={obj.primary_url}>
                                <div className="namepro">
                                  {obj.product_name.length > 18
                                    ? obj.product_name.substring(0, 18) + "..."
                                    : obj.product_name}
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <h2>No product to show</h2>
                    </div>
                  )}
                </div>

                <div
                  className="mycard comingsoonmobile"
                  style={{ position: "relative" }}
                >
                  <h3 class="comingsoon" style={{ fontSize: 41 }}>
                    COMING SOON
                  </h3>
                  <div className="header2">
                    Wondering if {row.name} would be perfect for someone you’re
                    shopping for?
                  </div>

                  <div className="">
                    {" "}
                    <img
                      className="imagegolden2"
                      style={{ paddingBottom: "10px" }}
                      src={imagegolden}
                    />
                    <div className="howold">How old are they?</div>
                    <div className="btnclass">
                      <input />
                      <img className="up" src={top} />
                      <img className="down" src={bottom} />
                    </div>
                    <button className="btnmobila">Next</button>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a href={row.link} className="shopnow2">
                    <span>SHOP {row.name} NOW</span>{" "}
                  </a>
                </div>

                {searches.length > 0 && (
                  <div className="mycard">
                    <div className="header2">
                      <span>People Who Like {row.name} Also Like...</span>
                    </div>

                    {searches.map((obj) => {
                      return (
                        <div className="wholike">
                          <div className="imgdiv">
                            <a href={obj.link}>
                              {" "}
                              <img alt="" src={obj.image_url} />
                            </a>
                          </div>
                          <div className="namediv">
                            {" "}
                            <a href={obj.link} style={{ color: "black" }}>
                              {obj.name}
                            </a>
                          </div>
                          <div>
                            <a href={obj.link} className="explore">
                              Explore
                            </a>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ))}
        </div>

        <Footer />
      </div>
    </>
  );
}
export default Discoverpage3;
