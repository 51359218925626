import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import Biking from "../../assets/images/Biking.png";
import Yoga from "../../assets/images/YOGA.png";
import Golfing from "../../assets/images/Golfing.png";
import lifting from "../../assets/images/lifting.png";
import Running from "../../assets/images/Running.png";
import Swimming from "../../assets/images/Swimming.png";
import Skiing from "../../assets/images/Skiing.png";
import snowboard from "../../assets/images/snowboard.png";
import Camping from "../../assets/images/Camping.png";
import Hiking from "../../assets/images/Hiking.png";
import Fishing from "../../assets/images/Fishing.png";
import Hunting from "../../assets/images/Hunting.png";
import Back from "../../assets/images/Backside.png";
import { useHistory } from "react-router-dom";
import x from "../../assets/images/x2.png";
import { pageView } from "../../../ga4Service";

const StateTen = (props) => {
  useEffect(() => {
    pageView("/surveyQ5");
  });

  const { watch, register } = useForm();
  const [Items, setItems] = useState([]);

  useEffect(() => {
    console.log("Items Selected : ", Items);
  });
  const btClicked = (e) => {
    e.persist();
    let value = e.target.name;
    console.log("Event Value : ");
    console.log();
    let allItems = [...Items];
    if (allItems.includes(value)) {
      let index = allItems.indexOf(value);
      allItems.splice(index, 1);
    } else {
      allItems.push(value);
    }
    setItems(allItems);
  };

  const toggletotal = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    var inputs, index;

    console.log("age is " + localStorage.getItem("age"));
    inputs = document.getElementsByClassName("datacollect");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }
    console.log("age is " + localStorage.getItem("age"));

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          localStorage.setItem(name, 0);
          return 0;
        }
      }

      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };
  window.scroll({
    top: document.body.offsetTop,
    left: 0,
    behavior: "smooth",
  });
  const toggletotal2 = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    var inputs, index;

    inputs = document.getElementsByClassName("datacollect2");
    for (index = 0; index < inputs.length; ++index) {
      // deal with inputs[index] element.
      var val = 0;
      if (inputs[index].checked) {
        localStorage.setItem(inputs[index].name, 1);
      } else {
        localStorage.setItem(inputs[index].name, 0);
      }
    }

    let array = props.arr.map((obj, index) => {
      if (index == value) {
        console.log(index);
        if (props.arr[value] === 0) {
          localStorage.setItem(name, 1);
          return 1;
        } else {
          return 0;
        }
      }
      return obj;
    });

    props.setarr(array);
    console.log(props.arr);
  };

  function shuffle(id, kid) {
    var container = document.getElementById(id);
    var elementsArray = Array.prototype.slice.call(
      container.getElementsByClassName(kid)
    );
    elementsArray.forEach(function (element) {
      container.removeChild(element);
    });
    shuffleArray(elementsArray);
    elementsArray.forEach(function (element) {
      container.appendChild(element);
    });
  }

  const history = useHistory();
  const navigate = () => {
    localStorage.removeItem("age");
    console.log("home");
    history.push("/");
  };

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  useEffect(() => {
    shuffle("container", "wrapper-passion");
    shuffle("container2", "wrapper-passion");
  }, []);

  return (
    <Fragment>
      <div className="desktop" id="desktop">
        <h2 id="do-physical" className="Text-header-main">
          Do they enjoy any of these physical activities?
        </h2>
        <p className="paredesc">Select all that apply, or skip!</p>

        <div className="step-1-form middle" style={{ top: "58%" }}>
          <div className="row">
            <div className="myrow" id="container">
              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Biking"
                    value="0"
                    id="Biking"
                    className="datacollect"
                    checked={props.arr[0] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img className="activity-image" src={Biking} />
                    <div className="passionate-activity">
                      <p className="align">Biking</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Yoga"
                    value="1"
                    id="Yoga"
                    className="datacollect"
                    checked={props.arr[1] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ width: 45 }}
                      className="activity-image"
                      src={Yoga}
                    />
                    <div className="passionate-activity">
                      <p className="align">Yoga</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Golfing"
                    value="2"
                    id="Golfing"
                    checked={props.arr[2] == 1}
                    className="datacollect"
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 10 }}
                      className="activity-image"
                      src={Golfing}
                    />
                    <div className="passionate-activity">
                      <p className="align">Golfing</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Lifting"
                    value="3"
                    id="ifting"
                    className="datacollect"
                    checked={props.arr[3] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 18 }}
                      className="activity-image"
                      src={lifting}
                    />
                    <div className="passionate-activity">
                      <p className="align">Lifting</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Running"
                    value="4"
                    id="Running"
                    className="datacollect"
                    checked={props.arr[4] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 12 }}
                      className="activity-image"
                      src={Running}
                    />
                    <div className="passionate-activity">
                      <p className="align">Running</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Swimming"
                    value="5"
                    id="Swimming"
                    className="datacollect"
                    checked={props.arr[5] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 22 }}
                      className="activity-image"
                      src={Swimming}
                    />
                    <div className="passionate-activity">
                      <p className="align">Swimming</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="myrow" id="container2">
              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Skiing"
                    value="6"
                    id="Skiing"
                    className="datacollect"
                    checked={props.arr[6] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      className="activity-image"
                      style={{ width: 82 }}
                      src={Skiing}
                    />
                    <div className="passionate-activity">
                      <p className="align">Skiing</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Snowboard"
                    value="7"
                    className="datacollect"
                    id="snowboard"
                    checked={props.arr[7] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      className="activity-image"
                      style={{ marginTop: 20 }}
                      src={snowboard}
                    />
                    <div className="passionate-activity">
                      <p className="align">Snowboarding</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Camping"
                    value="8"
                    className="datacollect"
                    id="Camping"
                    checked={props.arr[8] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ width: 65 }}
                      className="activity-image"
                      src={Camping}
                    />
                    <div className="passsionate-activity">
                      <p className="align">Camping</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Hiking"
                    className="datacollect"
                    value="9"
                    id="Hiking"
                    checked={props.arr[9] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 13 }}
                      className="activity-image"
                      src={Hiking}
                    />
                    <div className="passionate-activity">
                      <p className="align">Hiking</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label>
                  <input
                    type="checkbox"
                    name="Fishing"
                    value="10"
                    id="Fishing"
                    className="datacollect"
                    checked={props.arr[10] == 1}
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ marginTop: 22, width: 67 }}
                      className="activity-image"
                      src={Fishing}
                    />
                    <div className="passionate-activity">
                      <p className="align">Fishing</p>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper-passion">
                <label style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    name="Hunting"
                    value="11"
                    id="Hunting"
                    checked={props.arr[11] == 1}
                    className="datacollect"
                    onChange={(e) => {
                      btClicked(e);
                      toggletotal(e);
                    }}
                  />
                  <div
                    Style="width : 120px !important;height: 120px !important;position:relative"
                    className="front-end box1 myboxes area-icon"
                  >
                    <img
                      style={{ width: 40 }}
                      className="activity-image"
                      src={Hunting}
                    />
                    <div className="passionate-activity">
                      <p className="align">Hunting</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  borderBottom: "3px solid #FCC9BE",
                  color: "#1F294E",
                  fontFamily: "Nunito",
                  marginRight: 25,
                  paddingBottom: 6,
                  cursor: "pointer",
                }}
                onClick={props.onBackClick}
              >
                <img src={Back} alt="" style={{ paddingBottom: 5 }} />
                <img src={Back} alt="" style={{ paddingBottom: 5 }} />
                <a className="backbtn">Back</a>
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: "#478BA2",
                    border: "none",
                    fontFamily: "Bungee",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "10px 20px",
                    color: "#fff",
                    borderRadius: 10,
                  }}
                  onClick={(e) =>
                    props.onClick({
                      title: document.getElementById("do-physical").textContent,
                      listItems: Items,
                    })
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile start here */}

      <section
        className="mobile-survey"
        style={{ overflow: "scroll" }}
        id="mobile"
      >
        <div className="bg-white m-3 p-3">
          <button onClick={navigate} className="btncross2">
            <img src={x} />
          </button>

          <div className="heigh" style={{ position: "relative", bottom: 15 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2
                style={{
                  color: "#1F294E",
                  textAlign: "center",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                Do they enjoy any of these physical activities?
              </h2>
            </div>
            <div className="selectall">Select all that apply, or skip!</div>
          </div>

          <div className="mycol">
            <label>
              <input
                type="checkbox"
                name="Running"
                value="4"
                id="Running"
                checked={props.arr[4] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 12 }}
                  className="activity-image"
                  src={Running}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Running</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Yoga"
                value="1"
                id="Yoga"
                checked={props.arr[1] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ transform: "scale(0.9)" }}
                  className="activity-image"
                  src={Yoga}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Yoga</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Golfing"
                value="2"
                id="Golfing"
                checked={props.arr[2] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 10, transform: "scale(0.9)" }}
                  className="activity-image"
                  src={Golfing}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Golfing</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="lifting"
                value="3"
                id="lifting"
                checked={props.arr[3] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 18 }}
                  className="activity-image"
                  src={lifting}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Lifting</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Camping"
                value="8"
                id="Camping"
                checked={props.arr[8] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ width: 65 }}
                  className="activity-image"
                  src={Camping}
                />
                <div className="passsionate-activity">
                  <p className="th-step-multi">Camping</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Hiking"
                value="9"
                id="Hiking"
                checked={props.arr[9] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 13 }}
                  className="activity-image"
                  src={Hiking}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Hiking</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Skiing"
                value="6"
                id="Skiing"
                checked={props.arr[6] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  className="activity-image"
                  style={{ width: 82 }}
                  src={Skiing}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Skiing</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="snowboard"
                value="7"
                id="snowboard"
                checked={props.arr[7] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  className="activity-image"
                  style={{ marginTop: 20 }}
                  src={snowboard}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi" style={{ fontSize: 12 }}>
                    Snowboarding
                  </p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Fishing"
                value="10"
                id="Fishing"
                checked={props.arr[10] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 22, width: 67 }}
                  className="activity-image"
                  src={Fishing}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Fishing</p>
                </div>
              </div>
            </label>

            <label style={{ position: "relative" }}>
              <input
                type="checkbox"
                name="Hunting"
                value="11"
                id="Hunting"
                checked={props.arr[11] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ transform: "scale(0.8)" }}
                  className="activity-image"
                  src={Hunting}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Hunting</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Biking"
                value="0"
                id="Biking"
                className="datacollect2"
                checked={props.arr[0] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img className="activity-image" src={Biking} />
                <div className="passionate-activity">
                  <p className="th-step-multi">Biking</p>
                </div>
              </div>
            </label>

            <label>
              <input
                type="checkbox"
                name="Swimming"
                value="5"
                id="Swimming"
                checked={props.arr[5] == 1}
                onChange={(e) => {
                  btClicked(e);
                  toggletotal2(e);
                }}
                className="datacollect2"
              />
              <div Style="position:relative" className="myboxes area-icon">
                <img
                  style={{ marginTop: 22 }}
                  className="activity-image"
                  src={Swimming}
                />
                <div className="passionate-activity">
                  <p className="th-step-multi">Swimming</p>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="whiteblock"></div>
      </section>
    </Fragment>
  );
};

export default StateTen;
