import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import SidebarMain from "../Sidebar/sidebar";
import { Link } from "react-router-dom";
import Nike from "../../assets/images/Nike.webp";
import {
  getBrands,
  EditBrand,
  UpdateBrand,
  DeleteBrand,
} from "../Services/Services";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import Cookies from "js-cookie";
import { AuthGuardService } from "../../Services/ServicePhase2";

function ExportBrand(props) {
  const [isauth, setisauth] = useState(false);

  useEffect(() => {
    AuthGuardService()
      .then((result) => {
        if (result.success == true) {
          setisauth(true);
        } else {
          setisauth(false);
          props.history.push("/login");
        }
      })
      .catch((err) => {
        setisauth(false);
        props.history.push("/login");
      });
  }, []);

  const dataBrand = async (e) => {
    e.preventDefault();
    var starting_date = jQuery("input#starting_date").val();
    localStorage.setItem("starting_date", starting_date);

    var ending_date = jQuery("input#ending_date").val();
    localStorage.setItem("ending_date", ending_date);

    var starting_time = jQuery("input#appt-time").val();
    localStorage.setItem("starting_time", starting_time);

    var ending_time = jQuery("input#appt-ending-time").val();
    localStorage.setItem("ending_time", ending_time);

    if (starting_date == "") {
      alert("Enter starting date");
      return false;
    } else if (ending_date == "") {
      alert("Enter ending date");
      return false;
    } else if (starting_time == "") {
      alert("Enter starting time");
      return false;
    } else if (ending_time == "") {
      alert("Enter ending time");
      return false;
    } else {
      var starting_date = localStorage.getItem("starting_date");
      var ending_date = localStorage.getItem("ending_date");
      var starting_time = localStorage.getItem("starting_time");
      var ending_time = localStorage.getItem("ending_time");
      var url = `https://api.outdone.io/api/download_predictions?start_date=${starting_date}%20${starting_time}&end_date=${ending_date}%20${ending_time}`;

      window.open(url, "_blank");
    }
  };
  return isauth ? (
    <Fragment>
      <section className="dashboard-page">
        <div className="container-fluid p-l ">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0">
              <div className="full-dash">
                <div className="side-bar-dash">
                  <SidebarMain />
                </div>
                <div className="dashboard-content">
                  <div className="data-export">
                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label for="starting_date">Starting Date:</label>
                          <br />
                          <input
                            type="date"
                            id="starting_date"
                            name="start_date"
                            required
                          />
                          <br />
                          <label for="appt-time">Starting Time UTC:</label>{" "}
                          <br />
                          <input
                            id="appt-time"
                            type="time"
                            name="appt-time"
                            step="2"
                          ></input>
                          <br />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label for="birthday">Ending Date:</label>
                          <br />
                          <input
                            type="date"
                            id="ending_date"
                            name="ending_date"
                            required
                          />
                          <br />

                          <label for="appt-time">Ending Time UTC:</label>
                          <br />
                          <input
                            id="appt-ending-time"
                            type="time"
                            name="appt-time"
                            step="2"
                          ></input>
                          <br />
                        </div>
                      </div>
                      <input
                        type="submit"
                        onClick={dataBrand}
                        value="Download CSV File"
                        className="data-bar"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  ) : (
    <div></div>
  );
}

export default ExportBrand;
