import React, { useEffect } from "react";
import { logOut } from "../Services/Services";

function Logout() {
  useEffect(() => {
    logOut();
  }, []);
  return <div></div>;
}
export default Logout;
